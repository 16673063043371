import React, { useEffect } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import PropTypes from "prop-types";

const ConfirmationPopup = (props) => {
  const { open, onAccept, onCancel, className, ...otherProps } = props;
  const accept = () => {
    onAccept();
  };

  const reject = () => {
    onCancel();
  };

  const OpenModal = () => {
    confirmDialog({
      message: props.msg,
      header: props.title,
      icon: props.icon,
      acceptLabel: props.acceptLabel ?? "Confirm",
      rejectLabel: props.rejectLabel ?? "Cancel",
      defaultFocus: props.defaultFocus ?? "accept",
      accept,
      reject
    });
  };
  useEffect(() => {
    if (open) {
      OpenModal();
    }
  }, [open]);
  return (
    <ConfirmDialog
      onHide={onCancel}
      onMaskClick={onCancel}
      visible={open}
      className={`${className}`}
      draggable={props.draggable ?? false}
      resizable={props.resizable ?? false}
      {...otherProps}
    />
  );
};
ConfirmationPopup.propTypes = {
  open: PropTypes.bool,
  msg: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  defaultFocus: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  className: PropTypes.string,
  draggable: PropTypes.bool,
  resizable: PropTypes.bool,
  acceptLabel: PropTypes.string,
  rejectLabel: PropTypes.string
};
export default ConfirmationPopup;
