import React from "react";
import PropTypes from "prop-types";
import { attachParams } from "./url-helper";
import { getRequest } from "./http-helper";

export const getChangeLogWarehouse = async (acc, date_range) => {
  const params = {
    data_wh_id: acc.id,
    date_range: date_range
  };
  const URL = attachParams(
    `/v2/governance/changelog/warehouse_events_table`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getChangeLogUserRole = async (acc, date_range) => {
  const params = {
    data_wh_id: acc?.id,
    date_range: date_range
  };
  const URL = attachParams(
    `/v2/governance/changelog/user_role_events_table`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getChangeLogFilters = async ({ data_wh_id, date_range }) => {
  const params = {
    data_wh_id: data_wh_id,
    date_range: date_range
  };
  const URL = attachParams(`/v2/governance/changelog/filters_list`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

const CustomListForKeyValueComponent = ({ text }) => {
  let json = JSON.parse(text);
  return (
    <ul
      style={{
        padding: "0px 12px 0px 12px"
      }}>
      {Object.keys(json).map((item, index) => {
        return (
          <li style={{ wordBreak: "normal" }} key={index}>
            <b style={{ textTransform: "capitalize" }}>
              {item.toLowerCase().replaceAll("_", " ")}
            </b>
            :{" "}
            {item !== "Set_Properties" ? (
              json[item].toString()
            ) : (
              <CustomListForKeyValueComponent text={json[item]} />
            )}
          </li>
        );
      })}
    </ul>
  );
};
CustomListForKeyValueComponent.propTypes = {
  text: PropTypes.string.isRequired
};

export const CustomListForKeyValue = (text) => {
  return <CustomListForKeyValueComponent text={text} />;
};

export const CustomListForKeyValuePlusHeader = (text) => {
  let header = text.split("\n")[0];
  let headerTitle = header.split(":")[0];
  let headerValue = header.split(":")[1];
  text = text.split("\n").slice(1).join("\n");
  let json = JSON.parse(text);
  return (
    <>
      <span>
        <b>{headerTitle}:</b> {headerValue}
      </span>
      <ul>
        {Object.keys(json).map((item, index) => {
          return (
            <li key={index}>
              <b style={{ textTransform: "capitalize" }}>
                {item.replaceAll("_", " ")}
              </b>
              : {json[item]}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export const CapitalizeText = (text) => {
  return (
    <span style={{ textTransform: "capitalize" }}>
      {text.replaceAll("_", " ").toLowerCase()}
    </span>
  );
};
