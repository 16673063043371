import React, { useEffect, useMemo, useRef } from "react";
import "./gridcomponent.scss";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import CustomNoRowsOverlay from "../../utils/noRowsComponent";
import { cleanJSONkeys__, removeHTML__ } from "../../utils/common-helper";
import Pagination from "../Pagination";

const GridComponent = ({
  columnDefs,
  rowData,
  title,
  gridStyle,
  gridLoading,
  noRowsText,
  fallbackHTML,
  suppressColumnsSize,
  downloadCSV,
  hasTrialFallback,
  changeActiveModule,
  tableName,
  exportColumns,
  customRowHeight,
  customHeaderHeight,
  paginationInfo,
  handlePreviousPage,
  handleNextPage,
  handlePageClick
}) => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    autoHeaderHeight: true
  }));
  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay.bind(this, changeActiveModule);
  }, []);
  const getRowStyle = (params) => {
    if (hasTrialFallback && rowData?.length > 2 && params.node?.rowIndex >= 2) {
      return {
        filter: "blur(4px)"
      };
    }
  };
  const onGridReady = () => {
    if (suppressColumnsSize) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 130
      });
    }
    if (hasTrialFallback && rowData?.length > 2) {
      gridRef.current.api.showNoRowsOverlay();
    }
  };
  const style = !rowData?.length
    ? {
        width: "100%",
        height: gridStyle?.height ? gridStyle?.height : 500
      }
    : rowData?.length <= 5
    ? {
        width: "100%",
        height: gridStyle?.height ? gridStyle?.height : "100%"
      }
    : {
        width: "100%",
        height: gridStyle?.height ? gridStyle?.height : 500
      };

  const defaultStyle = {
    rowHeight: customRowHeight ?? 30,
    headerHeight: customHeaderHeight ?? 50
  };

  const onBtnExport = () => {
    if (downloadCSV) {
      const params = {
        fileName: tableName,
        columnKeys: exportColumns,
        processCellCallback: (params) => {
          const colDef = params?.column?.getColDef();
          if (colDef?.valueFormatter) {
            const valueFormatterParams = {
              ...params,
              data: params?.node?.data,
              node: params?.node,
              colDef: params?.column?.getColDef()
            };
            return colDef?.valueFormatter(valueFormatterParams);
          }
          return removeHTML__(
            colDef?.hasCustomListforKeys
              ? cleanJSONkeys__(JSON.stringify(params.value))
              : params?.value
          );
        }
      };
      gridRef?.current?.api?.exportDataAsCsv(params);
    }
  };
  useEffect(() => {
    onBtnExport();
  }, [downloadCSV]);
  useEffect(() => {
    if (gridRef?.current?.api) {
      if (suppressColumnsSize) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 130
        });
      }
      if (hasTrialFallback && rowData?.length > 2) {
        gridRef.current.api.showNoRowsOverlay();
      }
    }
  }, [columnDefs]);
  return (
    <div
      className={`grid-container ${
        gridStyle?.trialFallbackClass ? gridStyle?.trialFallbackClass : ""
      }`}>
      {title && (
        <div className="title-container">
          <span>{title ? title : "Grid Title"}</span>
        </div>
      )}
      <div
        className={`grid ag-theme-alpine ${
          gridStyle?.noborder ? "noborder" : ""
        } ${gridStyle?.additionalClass ? gridStyle?.additionalClass : ""}`}
        style={style}>
        {!gridLoading && rowData && rowData?.length ? (
          <>
            <AgGridReact
              ref={gridRef} // Ref for accessing Grid's API
              rowData={rowData} // Row Data for Rows
              columnDefs={columnDefs} // Column Defs for Columns
              defaultColDef={defaultColDef} // Default Column Properties
              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
              rowSelection="multiple" // Options - allows click selection of rows
              domLayout={
                rowData?.length < 15 && !gridStyle?.avoidAutoHeightOverflow
                  ? "autoHeight"
                  : ""
              }
              rowHeight={
                gridStyle?.rowHeight
                  ? gridStyle?.rowHeight
                  : defaultStyle.rowHeight
              }
              pagination={paginationInfo?.pagination}
              paginationPageSize={paginationInfo?.page_size}
              suppressPaginationPanel={true}
              noRowsOverlayComponent={noRowsOverlayComponent}
              headerHeight={defaultStyle?.headerHeight}
              getRowStyle={getRowStyle}
              gridOptions={{ autoSizeColumns: true }}
              onGridReady={onGridReady}
              suppressLoadingOverlay={true}
              enableCellTextSelection={true}
            />
            {paginationInfo?.pagination ? (
              <Pagination
                paginationInfo={paginationInfo}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
                handlePageClick={handlePageClick}
              />
            ) : null}
          </>
        ) : null}
        {!gridLoading && rowData && !rowData?.length ? (
          fallbackHTML ? (
            <div className="no-rows">{fallbackHTML}</div>
          ) : (
            <div className="no-rows">
              <span>{noRowsText ? noRowsText : "Coming soon..."}</span>
            </div>
          )
        ) : null}
        {gridLoading || !rowData ? (
          <div className="load">
            <div className="preload"></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
GridComponent.propTypes = {
  columnDefs: PropTypes.array,
  rowData: PropTypes.array,
  title: PropTypes.string,
  gridStyle: PropTypes.object,
  gridLoading: PropTypes.bool,
  noRowsText: PropTypes.string,
  fallbackHTML: PropTypes.element,
  suppressColumnsSize: PropTypes.bool,
  downloadCSV: PropTypes.bool,
  hasTrialFallback: PropTypes.bool,
  changeActiveModule: PropTypes.func,
  tableName: PropTypes.string,
  customRowHeight: PropTypes.number,
  customHeaderHeight: PropTypes.number,
  exportColumns: PropTypes.array,
  paginationInfo: PropTypes.object,
  handlePreviousPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handlePageClick: PropTypes.func
};
export default GridComponent;
