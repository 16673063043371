import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "../../components/customElements/Button";
import FilterIcon from "../../assets/images/grid/Filter.svg";
import chev_down from "../../assets/images/common/chev_down.svg";
import Select, { components } from "react-select";
import "./costexplorer.scss";
import GraphContainer from "../../components/CostExplorer/GraphContainer";
import GridComponent from "../../components/GridComponent";
import _ from "lodash";
import { DateTime } from "luxon";
import { useLocation, useNavigate } from "react-router-dom";
import SVG from "react-inlinesvg";
import drill_down from "../../assets/images/common/drill_down.svg";
import jpeg_export from "../../assets/images/common/jpeg_export.svg";
import ExportCSV from "../../assets/images/grid/exportcsv.svg";
import {
  getChartData,
  getChartFilteredData,
  getCostExplorerDateRanges,
  getCostExplorerMetaData,
  getCostExplorerOverView,
  getCurrentCostText,
  getForecastCost,
  getGroupbyID,
  getQueryData,
  granularityArray,
  linkStyle
} from "../../utils/cost-explorer-helper";
import { getMonthRange, getMonthsArray } from "../../utils/date-helper";
import groupSvg from "../../assets/images/dashboard/group.svg";
import {
  createColDefs,
  getAutoheightOverflow,
  getGridHeight,
  handleGridResolution,
  moveInArray,
  prettyPrintBytes,
  roundToSigDigits
} from "../../utils/common-helper";
import { costFormatter } from "../../utils/organization-helper";
import { getCostExpProps, getQGProps } from "./CostExplorer.props";
import { getSessionStorage } from "../../utils/storage-helper";
import CustomFilter from "../../components/CustomFilter/CustomFilter";
import expand from "../../assets/images/common/expand.svg";
import {
  applyFilterToGrid,
  clearAllFilters,
  filterTypes,
  postFilter
} from "../../utils/filter-helper";
import ExpandComponent from "../../components/ExpandComponent";
function CostExplorer({ acc }) {
  const navigate = useNavigate();
  const chartRef = useRef(null);
  const location = useLocation();
  const [gridData, setGridData] = useState({
    rowData: [],
    columnDefs: []
  });
  const [tableData, setTableData] = useState({
    rowData: [],
    columnDefs: []
  });
  const [queryData, setQueryData] = useState(null);
  const [queryColDefs, setQueryColDefs] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [metaData, setMetaData] = useState(null);
  const [analysisData, setAnalysisData] = useState(null);
  const [granularity, setGranularity] = useState(null);
  const currentKey = location.pathname.split("/").pop();
  const [granularityOptions] = useState(granularityArray);
  const [dateRange, setDateRange] = useState(null);
  const [qgDateRange, setQgDateRange] = useState(null);
  const [groupByOptions, setGroupByOptions] = useState([]);
  const [downloadCSV, setDownloadCSV] = useState(false);
  const [groupBy, setGroupBy] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [copyChartData, setCopyChartData] = useState(null);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [costSpent, setCostSpent] = useState(null);
  const [currentCostSpent, setCurrentCostSpent] = useState({});
  const [filterDisabled, setFilterDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [expandModal, setExpandModal] = useState(false);
  const [gridTotalWidth, setGridTotalWidth] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterColumns, setFilterColumns] = useState([]);
  const [forecastOn, setForecastOn] = useState(false);
  const [forecastCost, setForecastCost] = useState(null);
  const [navigationState, setNavigationState] = useState([]);
  const [gridResolution, setGridResolution] = useState(true);
  const [gridfallback, setGridfallback] = useState(false);
  const [queryDataFallback, setQueryDataFallback] = useState(false);
  const [gridLoading, setGridLoading] = useState(true);
  const [chartLoading, setChartLoading] = useState(true);
  const [applyFilter, setApplyFilter] = useState([
    {
      column: filterColumns?.[0],
      condition: filterTypes?.[0],
      value: [],
      dropDown: false,
      previuosValue: [],
      previousMenuList: []
    }
  ]);
  const [hasTrialFallback] = useState(
    getSessionStorage("plan_name").toLowerCase() === "trial" ? true : false
  );

  const univerSalStyle = {
    cellStyle: (params) => {
      if (hasTrialFallback && params.rowIndex > 1) {
        return {
          borderRight: "1px solid #eaeaeb",
          userSelect: "none",
          "-webkit-user-select": "none"
        };
      }
      return { borderRight: "1px solid #eaeaeb" };
    }
  };

  const handleExpand = (bool) => {
    setExpandModal(bool);
  };
  const clearGridFilter = (clearURl = true) => {
    if (clearURl) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.forEach((_, key) => {
        if (key.startsWith("col") || key.startsWith("val")) {
          urlParams.delete(key);
        }
      });
      urlParams?.delete("filter");
      urlParams.set("filter", false);
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      navigate(newUrl);
    }

    const groupByArr = metaData?.groupby_drilldowns[groupBy?.groupby_id];
    const rowData_ = createRowData(analysisData, groupBy, groupByArr);
    const updatedData = {
      rowData: rowData_,
      columnDefs: gridData?.columnDefs
    };
    setChartData(copyChartData);
    setGridData(updatedData);
    setTableData(updatedData);
    const filterData = clearAllFilters(applyFilter);
    setApplyFilter(filterData);
    setFilterApplied(false);
    setIsPageLoading(false);
  };
  const getValuesFromUrl = (key) => {
    if (
      navigationState &&
      navigationState?.length &&
      navigationState[navigationState?.length - 1][key]
    ) {
      return navigationState[navigationState?.length - 1][key];
    } else {
      return new URLSearchParams(location.search).get(key);
    }
  };

  const tabOptions =
    currentKey.toLowerCase() === "cloud_services"
      ? [
          { value: "1", label: `Cost by ${groupBy?.label}`, id: "1" },
          { value: "2", label: `Top 50 Query Groups`, id: "2" }
        ]
      : null;
  const tabView = tabOptions?.map((tab) => {
    return (
      <div
        key={tab?.id}
        className={`tab ${tab?.id === activeTab ? "active" : "normal-btn"}`}
        onClick={() => {
          setActiveTab(tab?.id);
          clearGridFilter();
          if (tab?.id === "2") {
            setGridLoading(true);
            setFilterDisabled(true);
            const dateRangeOptionsArr = [...dateRangeOptions];
            const dateRangeObj = { ...dateRange };
            if (
              dateRangeObj?.label?.toLowerCase() !== "last 3 months" &&
              dateRangeObj?.label?.toLowerCase() !== "last 6 months" &&
              dateRangeObj?.label?.toLowerCase() !== "last 1 year"
            ) {
              setQgDateRange({ ...dateRangeObj });
            } else {
              const filter = dateRangeOptionsArr?.filter((item) => {
                return (
                  item?.label?.toLowerCase() !== "last 3 months" &&
                  item?.label?.toLowerCase() !== "last 6 months" &&
                  item?.label?.toLowerCase() !== "last 1 year"
                );
              });
              setQgDateRange({ ...filter[0] });
            }
          } else if (tab?.id === "1") {
            setGridLoading(true);
            setFilterDisabled(false);
            const dateRangeOptionsArr = [...dateRangeOptions];
            let startDate = getValuesFromUrl("startDate");
            let endDate = getValuesFromUrl("endDate");
            const index = dateRangeOptionsArr?.findIndex((date) => {
              return date?.label?.toLowerCase() === "last 6 months";
            });
            if (startDate && endDate) {
              let dateRangeObj = dateRangeOptionsArr.find((item) => {
                return (
                  item?.value?.start_date === startDate &&
                  item?.value?.end_date === endDate
                );
              });
              if (dateRangeObj) {
                setDateRange({ ...dateRangeObj });
              } else {
                setDateRange(
                  index >= 0
                    ? { ...dateRangeOptionsArr[index] }
                    : { ...dateRangeOptionsArr[0] }
                );
              }
            } else {
              setDateRange(
                index >= 0
                  ? { ...dateRangeOptionsArr[index] }
                  : { ...dateRangeOptionsArr[0] }
              );
            }
          }
        }}>
        <span>{tab?.label}</span>
      </div>
    );
  });

  const updateUrlWithParams = (applyfilters) => {
    const urlParams = new URLSearchParams(window.location.search);

    // Clear existing relevant params to avoid duplicates
    urlParams.forEach((_, key) => {
      if (key.startsWith("filterid") || key.startsWith("filterval")) {
        urlParams.delete(key);
      }
    });
    urlParams.set("filter", true);
    applyfilters.forEach((item, index) => {
      if (item?.column && item?.value) {
        const columnKey = `filterid${index + 1}`;
        const valueKey = `filterval${index + 1}`;
        urlParams.set(columnKey, item.column.value);

        if (Array.isArray(item?.value)) {
          urlParams.set(valueKey, item?.value.join(","));
        } else {
          urlParams.set(valueKey, item?.value);
        }
      }
    });

    // Update the URL without reloading the page
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    navigate(newUrl);
  };
  const restoreFromUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dataArray = [];

    // Extract all columns and values from URL
    const columnValues = Array.from(urlParams.keys()).filter((key) =>
      key.startsWith("filterid")
    );
    const values = Array.from(urlParams.keys()).filter((key) =>
      key.startsWith("filterval")
    );

    // Iterate through the columns and values to rebuild the objects
    columnValues.forEach((columnValueKey, index) => {
      const valueKey = values.find((v) => v.endsWith((index + 1).toString()));
      if (valueKey) {
        const columnValue = urlParams.get(columnValueKey);
        if (columnValue && metaData?.groupby.length > 0) {
          let columnLabel = metaData?.groupby?.filter(
            (obj) => obj.groupby_column === columnValue
          );
          const value = urlParams.get(valueKey).split(",");
          dataArray.push({
            column: {
              label: columnLabel?.[0]?.groupby_title,
              value: columnValue
            },
            condition: { label: "Equals", value: "Equals" },
            defaultMenuList: [],
            dropDown: false,
            previousMenuList: [],
            previuosValue: [],
            value
          });
        }
      }
    });

    return dataArray;
  };

  const updateDataWithFilters = (
    applyfilters,
    rowData_,
    columnDefs,
    ChartData
  ) => {
    const filteredData = applyFilterToGrid(applyfilters, rowData_);
    setGridData({
      rowData: filteredData,
      columnDefs: columnDefs
    });
    const handleFilterValue = postFilter(applyfilters);

    const chartFilter = getChartFilteredData(
      applyfilters,
      ChartData?.y,
      "name"
    );
    setChartData({ ...chartData, y: chartFilter, x: ChartData?.x });
    setApplyFilter(handleFilterValue);
    setFilterApplied(true);
  };

  const handleCostExpGridFilter = (applyfilters, triggerLocation = true) => {
    if (triggerLocation) {
      updateUrlWithParams(applyfilters);
    }

    const groupByArr = metaData?.groupby_drilldowns[groupBy?.groupby_id];
    let rowData_ = createRowData(analysisData, groupBy, groupByArr);
    updateDataWithFilters(
      applyfilters,
      rowData_,
      gridData?.columnDefs,
      copyChartData
    );
  };

  const toggleSeriesButton = () => {
    setAnalysisData(analysisData);
    setMetaData(metaData);
    return {
      headerName: "",
      field: "action",
      width: 74,
      suppressSizeToFit: true,
      cellClass: (params) => {
        return `cell-border-right ${
          params?.data?.drilldown_possible
            ? "cursor-pointer"
            : "cursor-not-allowed"
        }`;
      },
      onCellClicked: (params) => {
        handleClick(params);
      },
      cellRenderer: (params) => {
        return (
          <div className="table-select-col">
            <span
              data-id="2"
              className={`select-image ${
                params?.data?.drilldown_possible &&
                +roundToSigDigits(+params?.data?.cost, 2, false, 2) !== 0
                  ? ""
                  : "disabled"
              }`}>
              <SVG src={drill_down} width={30} height={30}></SVG>
            </span>
          </div>
        );
      }
    };
  };

  const handleClick = (params) => {
    handleExpand(false);
    if (
      params?.data?.drilldown_possible &&
      +roundToSigDigits(+params?.data?.cost, 2, false, 2) !== 0
    ) {
      navigate(appendPathToCurrentLocation(params?.data?.drilldown_key));
    } else {
      return;
    }
  };

  const createRowData = (data, grpObj, groupByArr) => {
    let rowData = [];
    const group = grpObj?.groupby_column;
    const grp = _.groupBy(data?.analysis_data?.data, group);
    let validCostExpProps = getCostExpProps();
    let nonNumericalUniqueKeys = [];
    rowData = _.map(_.keys(grp), function (e) {
      const grpByobj = groupByArr?.find((item) => {
        return item?.option_id === e;
      });
      const formattedData = grp[e]?.map((obj) => ({
        ...obj,
        _rawdate: DateTime.fromISO(obj?.date).toFormat("yyyy-MM-dd")
      }));
      const latestDate = _.maxBy(formattedData, "_rawdate");
      return _.reduce(
        grp[e],
        function (r, o) {
          _.forEach(_.keys(o), function (key) {
            if (!isNaN(o[key]) && validCostExpProps[currentKey][key]) {
              if (key?.toLowerCase() === "cost") {
                r["current_cost"] =
                  (r["current_cost"] || 0) +
                  DateTime.fromFormat(
                    DateTime.fromISO(o?.date).toFormat("yyyy-MM-dd"),
                    "yyyy-MM-dd"
                  ).equals(
                    DateTime.fromFormat(latestDate?._rawdate, "yyyy-MM-dd")
                  )
                    ? o?.cost
                    : 0;
                r["current_date"] = DateTime.fromFormat(
                  DateTime.fromISO(o?.date).toFormat("yyyy-MM-dd"),
                  "yyyy-MM-dd"
                ).equals(
                  DateTime.fromFormat(latestDate?._rawdate, "yyyy-MM-dd")
                )
                  ? latestDate?._rawdate
                  : null;
              }
              r[key] = (r[key] || 0) + +o[key];
            } else {
              nonNumericalUniqueKeys.push(key);
            }
          });
          return {
            ...r,
            drilldown_possible: grpByobj?.drilldown_possible,
            drilldown_key: grpByobj?.drilldown_key
          };
        },
        { [group]: e }
      );
    });
    mapValuesToFilter(data, _.uniq(nonNumericalUniqueKeys));
    return rowData ? rowData : [];
  };

  const getOverviewData = async (acc, dateRange) => {
    try {
      if (currentKey?.toLowerCase() !== "cloud_services" || activeTab !== "2") {
        if (acc && dateRange && groupBy && granularity) {
          const currentKey_ =
            currentKey == "costexplorer" ? "overview" : currentKey;
          const overviewData = await getCostExplorerOverView(
            acc,
            dateRange?.value?.start_date,
            dateRange?.value?.end_date,
            groupBy?.groupby_id,
            currentKey_,
            granularity?.value
          );
          if (
            granularity?.value?.toLowerCase() === "monthly" &&
            currentKey?.toLowerCase() === "costexplorer" &&
            dateRange?.mtd
          ) {
            try {
              setForecastOn(true);
              const forecastCost = await getForecastCost(
                acc,
                dateRange?.value?.start_date,
                dateRange?.value?.end_date
              );
              setForecastCost(forecastCost);
            } catch (e) {
              setForecastOn(false);
              setForecastCost(null);
            }
          } else {
            setForecastOn(false);
            setForecastCost(null);
          }
          setAnalysisData(overviewData);
          if (gridfallback) {
            setGridfallback(false);
          }
          if (gridLoading) {
            setGridLoading(false);
          }
          if (chartLoading) {
            setChartLoading(false);
          }
          if (isPageLoading) {
            setIsPageLoading(false);
          }
        }
      } else {
        const customProps = getQGProps();
        const query_data = await getQueryData(
          acc,
          dateRange?.value?.start_date,
          dateRange?.value?.end_date
        );
        if (query_data && query_data?.data && query_data?.data?.length) {
          const colDefsArr = createColDefs(
            query_data?.columns,
            customProps,
            univerSalStyle,
            query_data?.column_display_name_mappping
          );
          if (colDefsArr && query_data?.data) {
            const newData = colDefsArr.map((colDef) => ({
              ...colDef,
              unSortIcon: true,
              sortable: true,
              filter: false
            }));

            setQueryColDefs(newData);
            let screenInnerWidth = window.innerWidth;
            let gridWidth = Object.values(newData).reduce((sum, prop) => {
              if (typeof prop.width === "number" && !isNaN(prop.width)) {
                return sum + prop.width;
              }
              return sum;
            }, 0);
            setGridResolution(
              handleGridResolution(gridWidth, screenInnerWidth, 360)
            );
            setQueryData(query_data?.data);
            setGridLoading(false);
            if (queryDataFallback) {
              setQueryDataFallback(false);
            }
          }
        } else {
          setQueryData(null);
          setQueryColDefs(null);
          setGridLoading(false);
          if (!queryDataFallback) {
            setQueryDataFallback(true);
          }
        }
      }
    } catch (er) {
      setAnalysisData(null);
      setChartData(null);
      setCopyChartData(null);
      setQueryData(null);
      setQueryColDefs(null);
      if (!queryDataFallback) {
        setQueryDataFallback(true);
      }
      if (!gridfallback) {
        setGridfallback(true);
      }
      if (chartLoading) {
        setChartLoading(false);
      }
      if (gridLoading) {
        setGridLoading(false);
      }
      if (isPageLoading) {
        setIsPageLoading(false);
      }
      navigate({
        pathname: location.pathname,
        search: ""
      });
    }
  };
  const getDateRangeAndMetaData = async (acc) => {
    try {
      let currentKey_ = currentKey == "costexplorer" ? "overview" : currentKey;
      const [dateRanges, metadata] = await Promise.all([
        getCostExplorerDateRanges(acc, currentKey_),
        getCostExplorerMetaData(currentKey_)
      ]);
      await Promise.all([dateRanges, metadata]);
      if (dateRanges && metadata) {
        setMetaData(metadata);
        const groupByOptionsArray = metadata?.groupby?.map((item) => {
          return {
            ...item,
            label:
              item?.groupby_title?.toLowerCase() === "none"
                ? "Ungrouped"
                : item?.groupby_title,
            value: item?.groupby_id
          };
        });
        setGroupByOptions(groupByOptionsArray);
        let dateRangesArray = getMonthsArray(
          dateRanges?.values?.start_date,
          dateRanges?.values?.end_date
        )
          .reverse()
          .map((item) => {
            let value = getMonthRange(item, dateRanges?.values?.end_date);
            return {
              label: value?.mtd
                ? `${DateTime.fromISO(value?.startDate).toFormat(
                    "dd"
                  )} - ${DateTime.fromISO(value?.endDate)
                    .minus({ days: 1 })
                    .toFormat("dd LLL yyyy")} (MTD)`
                : `${DateTime.fromISO(item).toFormat("LLL yyyy")}`,
              value: {
                start_date: DateTime.fromISO(value?.startDate).toFormat(
                  "yyyy-MM-dd"
                ),
                end_date: DateTime.fromISO(value?.endDate).toFormat(
                  "yyyy-MM-dd"
                )
              },
              mtd: value?.mtd
            };
          });
        dateRangesArray.push({
          label: "Last 3 Months",
          show_range: true,
          value: {
            start_date: DateTime.fromISO(dateRanges?.values?.end_date)
              .set({
                day: 1
              })
              .minus({ months: 3 })
              .toFormat("yyyy-MM-dd"),
            end_date: dateRanges?.values?.end_date
          },
          mtd: true
        });
        dateRangesArray.push({
          label: "Last 6 Months",
          show_range: true,
          value: {
            start_date: DateTime.fromISO(dateRanges?.values?.end_date)
              .set({
                day: 1
              })
              .minus({ months: 6 })
              .toFormat("yyyy-MM-dd"),
            end_date: dateRanges?.values?.end_date
          },
          mtd: true
        });
        dateRangesArray.push({
          label: "Last 1 Year",
          show_range: true,
          value: {
            start_date: DateTime.fromISO(dateRanges?.values?.end_date)
              .set({
                day: 1
              })
              .minus({ months: 12 })
              .toFormat("yyyy-MM-dd"),
            end_date: dateRanges?.values?.end_date
          },
          mtd: true
        });
        setDateRangeOptions(dateRangesArray);
        if (
          getValuesFromUrl("startDate") &&
          getValuesFromUrl("endDate") &&
          getValuesFromUrl("granularity") &&
          getValuesFromUrl("groupby")
        ) {
          noramlizeUrl(
            granularityOptions,
            groupByOptionsArray,
            dateRangesArray
          );
        } else {
          const currentPath = location?.pathname;
          const currentPathArr = currentPath?.split("/");
          const copyObj = [...navigationState];
          const obj = {};
          let url = new URLSearchParams(location.search || "");
          const defaultGroupById = getGroupbyID(
            currentKey
              ? currentKey
              : currentPathArr[currentPathArr?.length - 1].toLowerCase()
          );
          const grpByObj = groupByOptionsArray?.find((item) => {
            return item?.groupby_id === defaultGroupById;
          });
          const index = dateRangesArray?.findIndex((date) => {
            return date?.label?.toLowerCase() === "last 6 months";
          });
          if (!(getValuesFromUrl("startDate") && getValuesFromUrl("endDate"))) {
            if (index >= 0) {
              setDateRange(dateRangesArray[index]);
              url.set("startDate", dateRangesArray[index]?.value?.start_date);
              url.set("endDate", dateRangesArray[index]?.value?.end_date);
            } else {
              setDateRange(dateRangesArray[0]);
              url.set("startDate", dateRangesArray[0]?.value?.start_date);
              url.set("endDate", dateRangesArray[0]?.value?.end_date);
            }
          }
          if (!getValuesFromUrl("granularity")) {
            setGranularity(granularityOptions[2]);
            url.set("granularity", granularityOptions[2]?.value);
          }
          if (!getValuesFromUrl("groupby")) {
            setGroupBy(
              Object.keys(grpByObj)?.length ? grpByObj : groupByOptionsArray[0]
            );
            url.set(
              "groupby",
              Object.keys(grpByObj)?.length
                ? grpByObj?.groupby_id
                : groupByOptionsArray[0]?.groupby_id
            );
          }
          obj.startDate =
            index >= 0
              ? dateRangesArray[index]?.value?.start_date
              : dateRangesArray[0]?.value?.start_date;
          obj.endDate =
            index >= 0
              ? dateRangesArray[index]?.value?.end_date
              : dateRangesArray[0]?.value?.end_date;
          obj.granularity = granularityOptions[2]?.value;

          copyObj?.push({
            ...obj,
            groupby: Object.keys(grpByObj)?.length
              ? grpByObj?.groupby_id
              : groupByOptionsArray[0]?.groupby_id,
            path: currentPathArr[currentPathArr?.length - 1].toLowerCase()
          });
          setNavigationState(copyObj);
          navigate({
            pathname: location.pathname,
            search: url.toString()
          });
        }
      }
    } catch (er) {
      setAnalysisData(null);
      setChartData(null);
      setCopyChartData(null);
      setMetaData(null);
      setCostSpent(null);
      setForecastCost(null);
      setGroupByOptions([]);
      setDateRangeOptions([]);
      setGranularity(null);
      setDateRange(null);
      setGroupBy(null);
      if (!gridfallback) {
        setGridfallback(true);
      }
      if (gridLoading) {
        setGridLoading(false);
      }
      if (chartLoading) {
        setChartLoading(false);
      }
      if (isPageLoading) {
        setIsPageLoading(false);
      }
    }
  };
  const appendPathToCurrentLocation = (path) => {
    const obj = {};
    const copyObj = [...navigationState];
    const currentPath = location.pathname;
    const currentPathArr = currentPath.split("/");
    currentPathArr.push(path);
    if (dateRange) {
      obj.startDate = dateRange?.value?.start_date;
      obj.endDate = dateRange?.value?.end_date;
    }
    if (granularity) {
      obj.granularity = granularity?.value;
    }
    obj.path = path?.toLowerCase();
    copyObj?.push({
      ...obj,
      groupby: groupBy?.value,
      path: path?.toLowerCase()
    });
    setNavigationState(copyObj);
    return currentPathArr.join("/");
  };
  const mapValuesToFilter = (analysisData, uniqueColumnsFromTable) => {
    let validCostExpProps = getCostExpProps();
    let filterColumns_ = analysisData?.metadata?.columns
      ?.filter(
        (item) =>
          validCostExpProps[currentKey][item?.id] &&
          item?.id != "date" &&
          uniqueColumnsFromTable.includes(item?.id)
      )
      .map((item) => {
        return {
          label: item?.title,
          value: item?.id
        };
      });
    setFilterColumns(filterColumns_);
    setApplyFilter([
      {
        column: filterColumns_?.[0],
        condition: filterTypes?.[0],
        value: [],
        dropDown: false,
        previuosValue: [],
        previousMenuList: []
      }
    ]);
  };

  useEffect(() => {
    clearGridFilter(false);
    let columnDefs;
    if (analysisData && metaData) {
      let __rawcurrentCost = 0;
      const groupByArr = metaData?.groupby_drilldowns[groupBy?.groupby_id];
      let validCostExpProps = getCostExpProps();
      let columns = analysisData?.metadata?.columns;
      const grpIndex = analysisData?.metadata?.columns?.findIndex((item) => {
        return item?.is_groupby_possible;
      });
      if (grpIndex >= 0) {
        columns = moveInArray(analysisData?.metadata?.columns, grpIndex, 1);
      }

      columnDefs = columns?.map((item) => {
        return {
          headerName:
            item?.title?.toLowerCase() == "cost"
              ? validCostExpProps[currentKey][item?.id]?.title
              : item?.title,
          field: item?.id,
          ...(validCostExpProps[currentKey][item?.id]?.isNumber && {
            width: validCostExpProps[currentKey][item?.id]?.overrideWidth
              ? validCostExpProps[currentKey][item?.id]?.overrideWidth
              : 150,
            suppressSizeToFit: true
          }),

          headerClass: validCostExpProps[currentKey][item?.id]?.isNumber
            ? "text-align-right"
            : "",
          sortable: true,
          filter: false,
          unSortIcon: true,
          // pinned:
          //   item?.id?.toLowerCase() === "cost" || item?.is_groupby_possible
          //     ? "left"
          //     : false,
          cellClass: (params) => {
            return isNaN(+params?.value)
              ? "grid-cell-left-aligned cell-border-right"
              : "ag-right-aligned-cell cell-border-right";
          },
          resizable:
            validCostExpProps[currentKey][item?.id]?.resizable?.toString() ===
            "false"
              ? false
              : true,
          useValueFormatterForExport: true,
          hide:
            item?.title?.toLowerCase() === "date" ||
            !validCostExpProps[currentKey][item?.id]
              ? true
              : false,
          valueFormatter: (params) => {
            const formatedValue = validCostExpProps[currentKey][item?.id]?.isAvg
              ? +params?.value / analysisData?.analysis_data?.data?.length
              : params?.value;
            const hasSuffix =
              validCostExpProps[currentKey][item?.id]?.hasSuffix;
            const prettyPrint =
              validCostExpProps[currentKey][item?.id]?.prettyPrint;
            return isNaN(+formatedValue)
              ? formatedValue
              : hasSuffix
              ? `${
                  prettyPrint
                    ? prettyPrintBytes(
                        roundToSigDigits(formatedValue, 2, false),
                        true
                      )
                    : costFormatter(roundToSigDigits(formatedValue, 2, false))
                }`
              : prettyPrint
              ? prettyPrintBytes(
                  roundToSigDigits(formatedValue, 2, false),
                  true
                )
              : roundToSigDigits(formatedValue, 2, false).toLocaleString(
                  "en-US"
                );
          }
        };
      });

      if (columnDefs) {
        if (groupBy?.value?.toLowerCase() === "none") {
          columnDefs?.unshift({
            headerName:
              currentKey?.toLowerCase() === "costexplorer"
                ? "Cost Explorer"
                : currentKey
                    .split("_")
                    .map(
                      (item) => item.slice(0, 1).toUpperCase() + item.slice(1)
                    )
                    .join(" "),
            cellClass: "grid-cell-left-aligned  cell-border-right",
            cellRenderer: () => <span>All</span>
          });
        }
        columnDefs?.unshift(toggleSeriesButton());
        const __rowData = createRowData(analysisData, groupBy, groupByArr);
        if (__rowData && __rowData?.length) {
          __rawcurrentCost = roundToSigDigits(
            _.sumBy(__rowData, "current_cost"),
            2,
            false
          );
          setCurrentCostSpent({
            ...currentCostSpent,
            value: costFormatter(__rawcurrentCost),
            rawValue: __rawcurrentCost,
            date: __rowData[0]?.current_date
          });
        }
        if (dateRange?.mtd && +__rawcurrentCost !== 0) {
          columnDefs.push({
            headerName: "Current Cost($)",
            field: "current_cost",
            width: 150,
            suppressSizeToFit: true,
            headerClass: "text-align-right",
            sortable: true,
            filter: false,
            unSortIcon: true,
            cellClass: "ag-right-aligned-cell",
            resizable: false,
            useValueFormatterForExport: true,
            hide: false,
            valueFormatter: (params) => {
              return `${costFormatter(
                roundToSigDigits(params?.value, 2, false)
              )}`;
            }
          });
        }
        let screenInnerWidth = window.innerWidth;
        let gridWidth = Object.values(columnDefs).reduce((sum, prop) => {
          if (typeof prop.width === "number" && !isNaN(prop.width)) {
            return sum + prop.width;
          }
          return sum;
        }, 0);
        setGridResolution(
          handleGridResolution(gridWidth, screenInnerWidth, 360)
        );
        setGridTotalWidth(gridWidth);
        setGridData({
          rowData: __rowData,
          columnDefs
        });
        setTableData({
          rowData: __rowData,
          columnDefs
        });
      }
    } else {
      setGridData({
        rowData: [],
        columnDefs: []
      });
      setTableData({
        rowData: [],
        columnDefs: []
      });
    }
    if (analysisData && granularity && dateRange && groupBy) {
      const chartUpdate = getChartData(
        analysisData?.analysis_data?.data,
        granularity,
        groupBy
      );
      setCostSpent(
        costFormatter(
          roundToSigDigits(
            _.sumBy(analysisData?.analysis_data?.data, "cost"),
            2,
            false
          )
        )
      );

      let filter = getValuesFromUrl("filter");
      if (filter === "true") {
        setCopyChartData(JSON.parse(JSON.stringify(chartUpdate)));
        const groupByArr = metaData?.groupby_drilldowns[groupBy?.groupby_id];
        const __rowData = createRowData(analysisData, groupBy, groupByArr);
        const getfilters = restoreFromUrlParams();
        updateDataWithFilters(getfilters, __rowData, columnDefs, chartUpdate);
      } else {
        setChartData(chartUpdate);
        setCopyChartData(JSON.parse(JSON.stringify(chartUpdate)));
      }
    } else {
      setChartData(null);
      setCopyChartData(null);
    }
  }, [
    location.pathname,
    location.search,
    groupBy,
    granularity,
    dateRange,
    analysisData
  ]);
  useEffect(() => {
    if (dateRange && dateRangeOptions && dateRangeOptions?.length > 0) {
      getOverviewData(acc, dateRange);
    }
  }, [dateRange, granularity, groupBy, dateRangeOptions]);
  useEffect(() => {
    if (qgDateRange && Object?.keys(qgDateRange)?.length) {
      getOverviewData(acc, qgDateRange);
    }
  }, [qgDateRange]);
  const noramlizeUrl = (
    granularityOptions,
    groupByOptions,
    dateRangeOptions
  ) => {
    let url = new URLSearchParams(location.search || "");
    const copyObj = [...navigationState];
    const currentPath = location?.pathname;
    const currentPathArr = currentPath?.split("/");
    const defaultGroupById = getGroupbyID(
      currentPathArr[currentPathArr?.length - 1].toLowerCase()
    );
    const grpByObj = groupByOptions?.find((item) => {
      return item?.groupby_id === defaultGroupById;
    });
    const obj = {};
    if (
      getValuesFromUrl("groupby") ||
      getValuesFromUrl("granularity") ||
      getValuesFromUrl("startDate") ||
      getValuesFromUrl("endDate")
    ) {
      let granularityObj = granularityOptions.find((item) => {
        return item?.value === getValuesFromUrl("granularity");
      });

      if (granularityObj && getValuesFromUrl("granularity")) {
        setGranularity(granularityObj);
        obj.granularity = granularityObj?.value;
        url.set("granularity", granularityObj?.value);
      } else {
        setGranularity(granularityOptions[2]);
        obj.granularity = granularityOptions[2]?.value;
        url.set("granularity", granularityOptions[2]?.value);
      }

      let groupByObj = groupByOptions?.find((item) => {
        return item?.groupby_id === getValuesFromUrl("groupby");
      });
      if (groupByObj && getValuesFromUrl("groupby")) {
        setGroupBy(groupByObj);
        obj.groupby = groupByObj?.groupby_id;
        url.set("groupby", groupByObj?.groupby_id);
      } else {
        setGroupBy(
          Object.keys(grpByObj)?.length ? grpByObj : groupByOptions[0]
        );
        obj.groupby = Object.keys(grpByObj)?.length
          ? grpByObj?.groupby_id
          : groupByOptions[0]?.groupby_id;
        url.set(
          "groupby",
          Object.keys(grpByObj)?.length
            ? grpByObj?.groupby_id
            : groupByOptions[0]?.groupby_id
        );
      }

      let startDate = getValuesFromUrl("startDate");
      let endDate = getValuesFromUrl("endDate");

      const index = dateRangeOptions?.findIndex((date) => {
        return date?.label?.toLowerCase() === "last 6 months";
      });
      if (startDate && endDate) {
        let dateRangeObj = dateRangeOptions.find((item) => {
          return (
            item?.value?.start_date === startDate &&
            item?.value?.end_date === endDate
          );
        });
        if (dateRangeObj) {
          setDateRange(dateRangeObj);
          obj.startDate = dateRangeObj?.value?.start_date;
          obj.endDate = dateRangeObj?.value?.end_date;
          url.set("startDate", dateRangeObj?.value?.start_date);
          url.set("endDate", dateRangeObj?.value?.end_date);
        } else {
          setDateRange(
            index >= 0 ? dateRangeOptions[index] : dateRangeOptions[0]
          );
          obj.startDate =
            index >= 0
              ? dateRangeOptions[index]?.value?.start_date
              : dateRangeOptions[0]?.value?.start_date;
          obj.endDate =
            index >= 0
              ? dateRangeOptions[index]?.value?.end_date
              : dateRangeOptions[0]?.value?.end_date;
          url.set(
            "startDate",
            index >= 0
              ? dateRangeOptions[index]?.value?.start_date
              : dateRangeOptions[0]?.value?.start_date
          );
          url.set(
            "endDate",
            index >= 0
              ? dateRangeOptions[index]?.value?.end_date
              : dateRangeOptions[0]?.value?.end_date
          );
        }
      } else {
        setDateRange(
          index >= 0 ? dateRangeOptions[index] : dateRangeOptions[0]
        );
        obj.startDate =
          index >= 0
            ? dateRangeOptions[index]?.value?.start_date
            : dateRangeOptions[0]?.value?.start_date;
        obj.endDate =
          index >= 0
            ? dateRangeOptions[index]?.value?.end_date
            : dateRangeOptions[0]?.value?.end_date;
        url.set(
          "startDate",
          index >= 0
            ? dateRangeOptions[index]?.value?.start_date
            : dateRangeOptions[0]?.value?.start_date
        );
        url.set(
          "endDate",
          index >= 0
            ? dateRangeOptions[index]?.value?.end_date
            : dateRangeOptions[0]?.value?.end_date
        );
      }
      obj.path = currentPathArr[currentPathArr?.length - 1]?.toLowerCase();
      copyObj[copyObj?.length - 1] = obj;

      setNavigationState(copyObj);
      navigate({
        pathname: location.pathname,
        search: url.toString()
      });
    } else {
      if (granularity) {
        url.set("granularity", granularity?.value);
        navigate({
          pathname: location.pathname,
          search: url.toString()
        });
      }
      if (groupBy) {
        url.set("groupby", groupBy?.groupby_id);
        navigate({
          pathname: location.pathname,
          search: url.toString()
        });
      }
      if (dateRange) {
        url.set("startDate", dateRange?.value?.start_date);
        url.set("endDate", dateRange?.value?.end_date);
        navigate({
          pathname: location.pathname,
          search: url.toString()
        });
      }
    }
  };

  useEffect(() => {
    setIsPageLoading(true);
    setGridLoading(true);
    setChartLoading(true);
    setDateRangeOptions([]);
    setDateRange(null);
    setCostSpent(null);
    setCurrentCostSpent(null);
    setForecastCost(null);
    (async () => {
      await getDateRangeAndMetaData(acc);
    })();
  }, [acc, location.pathname]);

  const setAndNavigateParams = (key, value) => {
    let url = new URLSearchParams(location.search || "");
    url.set(key, value);
    navigate({
      pathname: location.pathname,
      search: url.toString()
    });
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const handlePopover = (e) => {
    if (!hasTrialFallback && groupBy?.groupby_column) {
      e?.stopPropagation();
      setShowFilters(!showFilters);
    }
  };
  const handleBreadCrumbClick = (item) => {
    item = item.toLowerCase();
    let path = location.pathname.split("/");
    let index = path.indexOf(item);
    const navigationStateArr = [...navigationState];
    const currPath = navigationStateArr?.findIndex((value) => {
      return item?.toLowerCase() === value?.path?.toLowerCase();
    });
    if (currPath >= 0) {
      navigationStateArr?.splice(currPath + 1);
      setNavigationState(navigationStateArr);
    }
    navigate(
      {
        pathname: path.splice(0, index + 1).join("/")
      },
      {
        replace: true
      }
    );
  };
  DropdownIndicator.propTypes = {
    isDisabled: PropTypes.bool
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
    })
  };
  const handleExport = () => {
    if (!hasTrialFallback) {
      setDownloadCSV(true);
      setTimeout(() => {
        setDownloadCSV(false);
      }, 3000);
    }
  };
  const getColumnDefs = (tabOptions, activeTab, gridData, queryColDefs) => {
    return !tabOptions || activeTab !== "2"
      ? gridData?.columnDefs?.length
        ? gridData?.columnDefs
        : []
      : queryColDefs;
  };
  const getRowData = (
    tabOptions,
    activeTab,
    gridData,
    costSpent,
    queryData
  ) => {
    return !tabOptions || activeTab !== "2"
      ? gridData?.rowData?.length && costSpent?.toString() !== "$0"
        ? _.orderBy(gridData?.rowData, ["cost"], ["desc"])
        : []
      : queryData && queryData?.length
      ? queryData
      : [];
  };
  const getGridFallback = (
    tabOptions,
    activeTab,
    costSpent,
    gridfallback,
    queryDataFallback
  ) => {
    return !tabOptions || activeTab !== "2"
      ? costSpent?.toString() !== "$0"
        ? gridfallback
        : true
      : queryDataFallback;
  };
  const getExportColumns = (tabOptions, activeTab, gridData, queryColDefs) => {
    return !tabOptions || activeTab !== "2"
      ? gridData?.columnDefs?.length
        ? gridData?.columnDefs
            ?.filter((item) => {
              return (
                item?.field?.toLowerCase() !== "action" &&
                item?.field?.toLowerCase() !== "date" &&
                !item?.hide
              );
            })
            .map((item) => item?.field)
        : []
      : queryColDefs && queryColDefs?.length
      ? queryColDefs?.columnDefs
          ?.filter((item) => {
            return item?.field?.toLowerCase() !== "action" && !item?.hide;
          })
          .map((item) => item?.field)
      : [];
  };
  const getGridStyle = (
    tabOptions,
    activeTab,
    gridData,
    queryData,
    rowHeight,
    innerHeight,
    minHeight
  ) => {
    return !tabOptions || activeTab !== "2"
      ? {
          height: getGridHeight(
            gridData?.rowData,
            innerHeight,
            rowHeight,
            minHeight
          ),
          avoidAutoHeightOverflow: getAutoheightOverflow(gridData?.rowData)
        }
      : {
          height: getGridHeight(queryData, innerHeight, rowHeight, minHeight),
          avoidAutoHeightOverflow: getAutoheightOverflow(queryData)
        };
  };
  return (
    <>
      {expandModal && (
        <>
          <ExpandComponent
            columnDefs={getColumnDefs(
              tabOptions,
              activeTab,
              gridData,
              queryColDefs
            )}
            rowData={getRowData(
              tabOptions,
              activeTab,
              gridData,
              costSpent,
              queryData
            )}
            gridFallback={getGridFallback(
              tabOptions,
              activeTab,
              costSpent,
              gridfallback,
              queryDataFallback
            )}
            suppressColumnsSize={handleGridResolution(
              gridTotalWidth,
              window.innerWidth,
              96
            )}
            exportColumns={getExportColumns(
              tabOptions,
              activeTab,
              gridData,
              queryColDefs
            )}
            handleExpand={handleExpand}
            downloadCSV={downloadCSV}
            gridStyle={getGridStyle(
              tabOptions,
              activeTab,
              gridData,
              queryData,
              40,
              window?.innerHeight,
              180
            )}
            gridLoading={gridLoading}
            customRowHeight={40}
            noRowsText={"No data Found"}
            tableName={"Cost Explorer"}
            hasTrialFallback={hasTrialFallback}
          />
        </>
      )}
      <div className="ce-main-container">
        {isPageLoading ? (
          <div className="load">
            <div className="preload"></div>
          </div>
        ) : (
          <>
            <div className="header-container">
              <span>Cost Explorer</span>
            </div>
            <div className="ce-page-container">
              <div className="sub-heading-block">
                <p className="breadcrumbs-container">
                  {location.pathname
                    .split("/")
                    .splice(2)
                    .map((item, key) => (
                      <span
                        onClick={() => handleBreadCrumbClick(item, key)}
                        key={key}
                        className={
                          item === location.pathname.split("/").pop()
                            ? "inactive"
                            : "active"
                        }
                        style={
                          item === location.pathname.split("/").pop()
                            ? linkStyle.inactive
                            : linkStyle.active
                        }>
                        {item == "costexplorer"
                          ? "Overview"
                          : item
                              .split("_")
                              .map((item) => {
                                if (item == "ai") {
                                  return item.toUpperCase();
                                } else {
                                  return (
                                    item.slice(0, 1).toUpperCase() +
                                    item.slice(1)
                                  );
                                }
                              })
                              .join(" ")}
                        {location.pathname.split("/").pop() === item
                          ? ""
                          : " > "}
                      </span>
                    ))}
                </p>
              </div>
              <div className="filter-container">
                <div className="filter-row">
                  <div className="filter-section">
                    <div className="select-container">
                      <Button
                        onClick={handlePopover}
                        disabled={
                          hasTrialFallback ||
                          !groupBy?.groupby_column ||
                          filterDisabled
                        }
                        className={
                          showFilters
                            ? "qg-filter-button-active "
                            : filterApplied
                            ? "qg-filter-active"
                            : "qg-filter-button"
                        }>
                        <img src={FilterIcon} alt="" />
                        <span>Filter</span>
                      </Button>

                      {showFilters && !hasTrialFallback && !filterDisabled && (
                        <CustomFilter
                          column={filterColumns}
                          gridData={tableData?.rowData}
                          applyFilter={applyFilter}
                          setApplyFilter={setApplyFilter}
                          close={handlePopover}
                          handleFilter={handleCostExpGridFilter}
                          clearFilter={clearGridFilter}
                          isMenuOpen={showFilters}
                        />
                      )}
                    </div>
                  </div>
                  <div className="select-container-groupby">
                    <Select
                      value={(() => {
                        return {
                          ...groupBy,
                          label: `Group By • ${
                            groupBy?.groupby_title == "None" || groupBy == null
                              ? "Ungrouped"
                              : groupBy?.groupby_title
                          }`
                        };
                      })()}
                      isSearchable={false}
                      classNamePrefix="selectcategory"
                      placeholder="select"
                      components={{ group: groupSvg, DropdownIndicator }}
                      styles={customStyles}
                      options={groupByOptions}
                      onChange={(item) => {
                        setActiveTab("1");
                        setFilterDisabled(false);
                        if (
                          item?.value?.toString() !== groupBy?.value?.toString()
                        ) {
                          setChartLoading(true);
                          setGridLoading(true);
                        }
                        const objArr = [...navigationState];
                        const obj = {
                          ...navigationState[navigationState?.length - 1]
                        };
                        obj.groupby = item?.value;
                        objArr[objArr?.length - 1] = obj;
                        setNavigationState(objArr);
                        setGroupBy(item);
                        setAndNavigateParams("groupby", item?.groupby_id);
                      }}
                    />
                  </div>
                </div>

                <div className="date-range-container">
                  <div className="select-container">
                    <div>
                      <Select
                        styles={customStyles}
                        options={dateRangeOptions}
                        value={dateRange}
                        classNamePrefix="selectcategory"
                        placeholder="Select Date"
                        isSearchable={false}
                        className="date-range-select"
                        components={{ DropdownIndicator }}
                        onChange={(item) => {
                          setActiveTab("1");
                          setFilterDisabled(false);
                          if (
                            item?.label?.toString() !==
                            dateRange?.label?.toString()
                          ) {
                            setChartLoading(true);
                            setGridLoading(true);
                          }
                          const objArr = [...navigationState];
                          objArr?.forEach((nav) => {
                            nav.startDate = item?.value?.start_date;
                            nav.endDate = item?.value?.end_date;
                          });
                          setNavigationState(objArr);
                          setDateRange(item);
                          let url = new URLSearchParams(location.search || "");
                          url.set("startDate", item?.value?.start_date);
                          url.set("endDate", item?.value?.end_date);
                          navigate({
                            pathname: location.pathname,
                            search: url.toString()
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="month-container">
                    <Select
                      styles={customStyles}
                      classNamePrefix="selectcategory"
                      placeholder="Select"
                      className="granularity-select"
                      options={granularityOptions}
                      isSearchable={false}
                      components={{ DropdownIndicator }}
                      onChange={(item) => {
                        setActiveTab("1");
                        setFilterDisabled(false);
                        if (
                          item?.value?.toString() !==
                          granularity?.value?.toString()
                        ) {
                          setChartLoading(true);
                          setGridLoading(true);
                        }
                        const objArr = [...navigationState];
                        objArr?.forEach((nav) => {
                          nav.granularity = item?.value;
                        });
                        setNavigationState(objArr);
                        setGranularity(item);
                        setAndNavigateParams("granularity", item?.value);
                      }}
                      value={granularity}
                    />
                  </div>
                  <div className="select-container export-container">
                    <Button
                      onClick={() => {
                        // chart to image
                        const chart = chartRef?.current?.chart;
                        chart?.exportChart({ type: "image/jpeg" });
                      }}
                      className={"qg-filter-button ce-filter-button"}>
                      <img src={jpeg_export} alt="" />
                      <span>Export Chart</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="overall-value-container">
                <div>
                  <p className="overall-value">{costSpent ? costSpent : "⸻"}</p>
                  <p className="value-title">{`Accrued Cost (${
                    dateRange
                      ? dateRange?.show_range
                        ? `${DateTime.fromISO(
                            dateRange?.value?.start_date?.trim()
                          ).toFormat("LLL yyyy")} - ${DateTime.fromISO(
                            dateRange?.value?.end_date?.trim()
                          ).toFormat("LLL yyyy")}`
                        : dateRange?.label?.trim().replace("(MTD)", "MTD")
                      : ""
                  })`}</p>
                </div>
                {currentCostSpent &&
                currentCostSpent?.rawValue &&
                currentCostSpent?.value &&
                dateRange?.mtd ? (
                  <div>
                    <p className="overall-value">{currentCostSpent?.value}</p>
                    <p className="value-title">{`Current Cost (${getCurrentCostText(
                      currentCostSpent?.date,
                      granularity
                    )})`}</p>
                  </div>
                ) : null}
                {forecastOn && forecastCost?.values?.cost ? (
                  <div>
                    <p className="overall-value">
                      {costFormatter(
                        roundToSigDigits(forecastCost?.values?.cost, 2, false)
                      )}
                    </p>
                    <p className="value-title">{`Forcasted Cost (${DateTime.fromISO(
                      forecastCost?.values?.month_name
                    ).toFormat("LLL yyyy")})`}</p>
                  </div>
                ) : null}
              </div>

              <div className="graph-container">
                {!chartLoading ? (
                  <>
                    {analysisData &&
                    chartData &&
                    costSpent &&
                    costSpent.toString() !== "$0" ? (
                      <GraphContainer
                        chartRef={chartRef}
                        chartData={chartData ? chartData : {}}
                        chartTitle={`${
                          currentKey.slice(0, 1).toUpperCase() +
                          currentKey.slice(1)
                        } by ${groupBy?.label} (${dateRange?.label})`}
                        granularity={granularity}
                        forecastCost={
                          forecastOn && forecastCost ? forecastCost : {}
                        }
                        filterApplied={filterApplied}
                      />
                    ) : (
                      <div className="chart-placeholder">No Data Found</div>
                    )}
                  </>
                ) : (
                  <div className="load">
                    <div className="preload"></div>
                  </div>
                )}
              </div>

              <div className="row-container">
                <span className="row-heading">
                  <p className="breadcrumbs-container">
                    {location.pathname
                      .split("/")
                      .splice(2)
                      .map((item, key) => (
                        <span
                          onClick={() => handleBreadCrumbClick(item, key)}
                          key={key}
                          className={
                            item === location.pathname.split("/").pop()
                              ? "inactive"
                              : "active"
                          }
                          style={
                            item === location.pathname.split("/").pop()
                              ? linkStyle.inactive
                              : linkStyle.active
                          }>
                          {item == "costexplorer" ? (
                            "Overview"
                          ) : !tabOptions || activeTab !== "2" ? (
                            item
                              .split("_")
                              .map((item) => {
                                if (item == "ai") {
                                  return item.toUpperCase();
                                } else {
                                  return (
                                    item.slice(0, 1).toUpperCase() +
                                    item.slice(1)
                                  );
                                }
                              })
                              .join(" ")
                          ) : (
                            <>Cloud Services Billed</>
                          )}
                          {location.pathname.split("/").pop() === item
                            ? ""
                            : " > "}
                        </span>
                      ))}
                  </p>
                </span>
                <div className="row-end">
                  {tabOptions ? (
                    <div className="tabs__container">{tabView}</div>
                  ) : null}
                  <Button
                    onClick={() => {
                      handleExpand(true);
                    }}
                    className={
                      "qg-filter-button expand__button align-self-end"
                    }>
                    <img src={expand} alt="" />
                    <span>Expand</span>
                  </Button>
                  <div className="select-container export-container">
                    <Button
                      onClick={handleExport}
                      className={"qg-filter-button"}>
                      <img src={ExportCSV} alt="" />
                      <span>Export</span>
                    </Button>
                  </div>
                </div>
              </div>
              {!tabOptions || activeTab !== "2" ? null : (
                <div className="row-container">
                  <span className="row-heading">
                    <p className="breadcrumbs-container">
                      <span className="inactive">
                        <span className="sub___text">{` Grouped by Queries - Top 50 for ${qgDateRange?.label}`}</span>
                      </span>
                    </p>
                  </span>
                </div>
              )}
              <div className="grid-container">
                <GridComponent
                  columnDefs={getColumnDefs(
                    tabOptions,
                    activeTab,
                    gridData,
                    queryColDefs
                  )}
                  rowData={getRowData(
                    tabOptions,
                    activeTab,
                    gridData,
                    costSpent,
                    queryData
                  )}
                  gridFallback={getGridFallback(
                    tabOptions,
                    activeTab,
                    costSpent,
                    gridfallback,
                    queryDataFallback
                  )}
                  suppressColumnsSize={gridResolution}
                  noRowsText={"No Data Found"}
                  tableName={"Cost Explorer"}
                  exportColumns={getExportColumns(
                    tabOptions,
                    activeTab,
                    gridData,
                    queryColDefs
                  )}
                  downloadCSV={downloadCSV}
                  gridStyle={getGridStyle(
                    tabOptions,
                    activeTab,
                    gridData,
                    queryData,
                    40,
                    window?.innerHeight,
                    180
                  )}
                  gridLoading={gridLoading}
                  customRowHeight={40}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
CostExplorer.propTypes = {
  acc: PropTypes.object
};

export default CostExplorer;
