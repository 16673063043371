export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

const COMPANYEMAIL_REGEX =
  /^[a-z0-9]((\.|\+)?[a-z0-9]){0,}@g(oogle)?mail|yahoo\.com$/; //eslint-disable-line

export const validateEmail = (email) => {
  return EMAIL_REGEX.test(String(email).toLowerCase());
};

export const validateCompanyEmail = (email) => {
  return COMPANYEMAIL_REGEX.test(String(email).toLowerCase());
};
export const validateEmailDomain = (email, userDomain) => {
  if (userDomain && email && userDomain?.length && email?.length) {
    return (
      email?.slice(email?.length - userDomain?.length, email?.length) ===
      userDomain
    );
  } else {
    return false;
  }
};
