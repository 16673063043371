import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./failedqueries.scss";
import { CustomTooltip } from "../../utils/tooltip-helper";
import chev_down from "../../assets/images/common/chev_down.svg";
import {
  createColDefs,
  getAutoheightOverflow,
  getGridHeight,
  getSortingValueObj,
  getValueObj,
  handleGridResolution,
  PrettyFormat,
  roundToSigDigits,
  secondsConverter
} from "../../utils/common-helper";
import GridComponent from "../../components/GridComponent";
import Select, { components } from "react-select";
import {
  getFailedQueries,
  getFailedQueriesDistributionOptions,
  getFailedQueriesSortingOptions,
  getFQDistribution,
  getTotalQueries
} from "../../utils/user-helper";
import {
  DebugQuery,
  DownloadLinkButton,
  PieDistributionAPI,
  getFailedQueriesDateRange
} from "../../utils/query-helper";
import ExportCSV from "../../assets/images/grid/exportcsv.svg";
import FilterIcon from "../../assets/images/grid/Filter.svg";
import DataCards from "../../components/OrganizationSummary/components/DataCards";
import { getDonutData } from "../../utils/organization-helper";
import DonutCharts from "../../components/OrganizationSummary/components/DonutChart";
import CustomFilter from "../../components/CustomFilter/CustomFilter";
import Button from "../../components/customElements/Button";
import { getSessionStorage } from "../../utils/storage-helper";
import groupSvg from "../../assets/images/dashboard/group.svg";
import expand from "../../assets/images/common/expand.svg";
import {
  FQFilterColumns,
  applyFilterToGrid,
  clearAllFilters,
  distributionFilterColumns,
  exportCSVcolumns,
  filterTypes,
  postFilter
} from "../../utils/filter-helper";
import ExpandComponent from "../../components/ExpandComponent";
//import _ from "lodash";

const FailedQueries = ({ acc, changeActiveModule }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E4E4E7"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };
  const [hasTrialFallback] = useState(
    getSessionStorage("plan_name").toLowerCase() === "trial" ? true : false
  );
  const customProps = {
    index: {
      type: "rightAligned",
      width: 75,
      pinned: "left",
      headerClass: "text-align-right"
    },
    regex_id: {
      hide: true
    },
    query_text_regex: {
      width: 300,
      filter: true,
      pinned: "left",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(PrettyFormat(params.value), params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    warehouses: {
      width: 160,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    users: {
      width: 100,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    user_name: {
      width: 350,
      pinned: "left",
      cellClass: "grid-cell-left-aligned"
    },
    count: {
      width: 100,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    warehouse_name: {
      width: 300,
      cellClass: "grid-cell-left-aligned"
    },
    sum_query_cost: {
      width: 100,
      type: "rightAligned",
      headerClass: "text-align-right",
      pinned: "right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_query_cost: {
      width: 215,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    "perc of sum_query_cost": {
      width: 100,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_queued_overload_time: {
      width: 180,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_execution_time: {
      width: 187,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        return secondsConverter(roundToSigDigits(params?.value, 2, false));
      }
    },
    avg_execution_time: {
      width: 194,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        return secondsConverter(roundToSigDigits(params?.value, 2, false));
      }
    },
    avg_queued_overload_time: {
      width: 180,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_gb_spilled_to_remote_storage: {
      width: 160,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_gb_spilled_to_remote_storage: {
      width: 160,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_partitions_scanned: {
      width: 172,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_partitions_scanned: {
      width: 172,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    "query_cost (USD)": {
      width: 100,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    "perc of total_execution_time": {
      width: 100,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    "perc of count": {
      width: 170,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    perc_sum_failed_cost: {
      width: 135,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    perc_sum_execution_time: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    perc_count: {
      width: 165,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    "Debug Query": {
      width: 100,
      pinned: "right",
      headerClass: "text-align-center",
      cellRenderer: (params) => {
        return DebugQuery(
          params.value,
          changeReasonObj,
          changeReasonModal,
          hasTrialFallback && params?.rowIndex > 1 ? true : false,
          modalGridLoading,
          changeModalGridLoading,
          modalGridFallBack,
          setModalGridFallback
        );
      }
    },
    "Query Ids": {
      width: 90,
      pinned: "right",
      headerClass: "text-align-center",
      cellRenderer: (params) => {
        return DownloadLinkButton(
          params.value,
          hasTrialFallback && params?.rowIndex > 1 ? true : false
        );
      }
    }
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const univerSalStyle = {
    cellStyle: (params) => {
      if (hasTrialFallback && params.rowIndex > 1) {
        return {
          // borderRight: "1px solid #eaeaeb",
          userSelect: "none",
          "-webkit-user-select": "none"
        };
      }
      return {};
    }
  };

  const [requiredCols, setRequiredCols] = useState([]);
  const [distributionRowData, setDistributionRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [failedQueriesRowData, setFailedQueriesRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [distributionGridLoading, setDistributionGridLoading] = useState(true);
  const [failedQueriesGridLoading, setFailedQueriesGridLoading] =
    useState(true);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [sortingOptions, setSortingOptions] = useState([]);
  const [sorting, setSorting] = useState(null);
  const [distributionOptions, setDistributionOptions] = useState([]);
  const [distribution, setDistribution] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [total, setTotal] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [distributionGridFallback, setDistributionGridFallback] =
    useState(false);
  const [modalGridFallBack, setModalGridFallback] = useState(false);
  const [failedqueriesGridFallback, setFailedQueriesGridFallback] =
    useState(false);
  const [downloadCSV, setDownloadCSV] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [failedQueryGridData, setFailedQueryGridData] = useState([]);
  const [distributionGridData, setdistributionGridData] = useState([]);
  const [distributionGridResolution, setDistributionGridResolution] =
    useState(true);
  const [failedQueriesGridResolution, setFailedQueriesGridResolution] =
    useState(true);
  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);

  // Each Column Definition results in one Column.
  const [distributionColDefs, setDistributionColumnDefs] = useState([]);
  const [failedQueriesColDefs, setFailedQueriesColumnDefs] = useState([]);
  const [WHpieArray, setWHPieArray] = useState(null);
  const [userPieArray, setUserPieArray] = useState(null);
  //const [pieLoading, setPieLoading] = useState(true);
  const [pieChartLoaded, setPieChartLoaded] = useState(true);
  const [filterApplied, setFilterApplied] = useState(false);
  const [modalGridLoading, setModalGridLoaing] = useState(false);
  const [gridTotalWidth, setGridTotalWidth] = useState(null);
  const [applyFilter, setApplyFilter] = useState([
    {
      column: FQFilterColumns?.[0],
      condition: filterTypes?.[0],
      value: [],
      dropDown: false,
      previuosValue: [],
      previousMenuList: []
    }
  ]);
  const [expandModal, setExpandModal] = useState(false);
  const [reasonObj, setReasonObj] = useState(null);

  const changeReasonObj = (obj) => {
    setReasonObj({
      ...reasonObj,
      rowData: obj?.rowData,
      colDef: obj?.colDef,
      total_runs: obj?.total_runs,
      total_reasons: obj?.total_reasons
    });
  };

  const handleExpand = (bool) => {
    setExpandModal(bool);
  };

  const changeModalGridLoading = (bool) => {
    setModalGridLoaing(bool);
  };

  const tabs = [
    { id: "1", label: "Failed Queries" },
    { id: "2", label: "Failed Queries by Users and Warehouses" }
  ];
  const tabView =
    tabs &&
    tabs.map((tab) => {
      return (
        <div
          className={`tab ${tab?.id === activeTab ? "active" : "normal-btn"}`}
          key={tab?.id}
          onClick={() => {
            setActiveTab(tab?.id);
            if (tab.id === "1") {
              setApplyFilter([
                {
                  column: FQFilterColumns?.[0],
                  condition: filterTypes?.[0],
                  value: [],
                  dropDown: false,
                  previuosValue: [],
                  previousMenuList: []
                }
              ]);
              if (
                sorting &&
                dateRange &&
                (!failedQueryGridData ||
                  !failedQueryGridData?.length ||
                  !failedQueriesColDefs ||
                  !failedQueriesColDefs?.length ||
                  !failedQueriesRowData ||
                  !failedQueriesRowData?.length)
              ) {
                setFailedQueriesGridLoading(true);
                getFailedQueriesTableData(dateRange, sorting);
              }
            } else {
              setApplyFilter([
                {
                  column: distributionFilterColumns?.[0],
                  condition: filterTypes?.[0],
                  value: [],
                  dropDown: false,
                  previuosValue: [],
                  previousMenuList: []
                }
              ]);
              if (
                distribution &&
                dateRange &&
                (!distributionColDefs ||
                  !distributionColDefs?.length ||
                  !distributionGridData ||
                  !distributionGridData?.length ||
                  !distributionRowData ||
                  !distributionRowData?.length)
              ) {
                setDistributionGridLoading(true);
                //setPieLoading(true);

                getDistributionTableData(dateRange, distribution);
              }
              if (
                distribution &&
                dateRange &&
                (!WHpieArray ||
                  !WHpieArray?.length ||
                  !userPieArray ||
                  !userPieArray?.length)
              ) {
                setPieChartLoaded(true);
                getPieChart(dateRange, distribution);
              }
            }
            setFilterApplied(false);
            setShowFilters(false);
          }}>
          <span className="title">{tab?.label}</span>
        </div>
      );
    });
  const getDistributionTableData = async (dateRange, distribution) => {
    try {
      if (
        distribution &&
        Object.keys(distribution).length > 0 &&
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        acc &&
        Object.keys(acc).length > 0
      ) {
        const data = await getFQDistribution({
          data_wh_id: acc?.id,
          date_range: dateRange?.label,
          dimension: distribution?.value
        });
        if (data) {
          const colDefsArr = createColDefs(
            data?.columns,
            customProps,
            univerSalStyle,
            data?.column_display_name_mappping,
            hasTrialFallback
          );
          if (colDefsArr && data?.data) {
            const newData = colDefsArr.map((colDef) => ({
              ...colDef,
              unSortIcon: true,
              sortable: true,
              filter: false
            }));
            setDistributionColumnDefs(newData);
            setDistributionRowData(data?.data);
            setdistributionGridData(data?.data);
            let screenInnerWidth = window.innerWidth;
            let gridWidth = Object.values(newData).reduce((sum, prop) => {
              if (typeof prop.width === "number" && !isNaN(prop.width)) {
                return sum + prop.width;
              }
              return sum;
            }, 0);
            setDistributionGridResolution(
              handleGridResolution(gridWidth, screenInnerWidth, 360)
            );
            setGridTotalWidth(gridWidth);
          }
          setDistributionGridLoading(false);
          //setPieLoading(false);
        } else {
          clearDistributionData();
          setDistributionGridLoading(false);
          //setPieLoading(false);
        }

        if (distributionGridFallback) {
          setDistributionGridFallback(false);
        }
      }
    } catch (err) {
      console.log(err);
      setDistributionGridLoading(false);
      // setPieLoading(false);
      if (!distributionGridFallback) {
        setDistributionGridFallback(true);
      }
    }
  };

  const getFailedQueriesTableData = async (dateRange, sorting) => {
    try {
      if (
        sorting &&
        Object.keys(sorting).length > 0 &&
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        acc &&
        Object.keys(acc).length > 0
      ) {
        const data = await getFailedQueries({
          data_wh_id: acc?.id,
          date_range: dateRange?.label,
          sorted_by: sorting?.value
        });
        if (data) {
          const debugqueryColName = "Debug Query";
          const downloadColName = "Query Ids";
          data?.data?.forEach((item) => {
            item[downloadColName] = {
              data_wh_id: acc?.id,
              date_range: dateRange?.label,
              regex_id: item.regex_id,
              filter_type: "failed"
            };
          });
          data?.data?.forEach((item) => {
            item[debugqueryColName] = {
              data_wh_id: acc?.id,
              date_range: dateRange?.label,
              regex_id: item.regex_id
            };
          });
          data?.columns?.push(debugqueryColName);
          data?.columns?.push?.(downloadColName);

          const colDefsArr = createColDefs(
            data?.columns,
            customProps,
            univerSalStyle,
            data?.column_display_name_mappping
          );
          if (colDefsArr && data?.data) {
            const newData = colDefsArr.map((colDef) => ({
              ...colDef,
              unSortIcon: true,
              sortable: true,
              filter: false
            }));
            setFailedQueriesColumnDefs(newData);
            setFailedQueriesRowData(data?.data);
            setFailedQueryGridData(data?.data);
            setFailedQueriesGridLoading(false);
            //setPieLoading(false);
            setPieChartLoaded(false);
            let screenInnerWidth = window.innerWidth;
            let gridWidth = Object.values(newData).reduce((sum, prop) => {
              if (typeof prop.width === "number" && !isNaN(prop.width)) {
                return sum + prop.width;
              }
              return sum;
            }, 0);
            setFailedQueriesGridResolution(
              handleGridResolution(gridWidth, screenInnerWidth, 360)
            );
            setGridTotalWidth(gridWidth);
          }
        } else {
          clearFailedQueriesData();
          setFailedQueriesGridLoading(false);
          //setPieLoading(false);
          setPieChartLoaded(false);
        }

        if (failedqueriesGridFallback) {
          setFailedQueriesGridFallback(false);
        }
      }
    } catch (err) {
      console.log(err);
      setFailedQueriesGridLoading(false);
      //setPieLoading(false);
      setPieChartLoaded(false);
      if (!failedqueriesGridFallback) {
        setFailedQueriesGridFallback(true);
      }
    }
  };
  const handleExport = () => {
    if (!hasTrialFallback) {
      setDownloadCSV(true);
      setTimeout(() => {
        setDownloadCSV(false);
      }, 3000);
    }
  };
  const handlePopover = (e) => {
    if (!hasTrialFallback) {
      e?.stopPropagation();
      setShowFilters(!showFilters);
    }
  };

  const clearGridFilter = () => {
    setFailedQueryGridData(failedQueriesRowData);
    setdistributionGridData(distributionRowData);
    const filterData = clearAllFilters(applyFilter);
    setApplyFilter(filterData);
    setFilterApplied(false);
  };

  const handleFailedQueryGridFilter = (applyifilters, gridRowData) => {
    // setGridLoading(true);
    const filteredData = applyFilterToGrid(applyifilters, gridRowData);
    setFailedQueryGridData(filteredData);
    const handleFilterValue = postFilter(applyifilters);
    setApplyFilter(handleFilterValue);
    setFilterApplied(true);
  };
  const handleDistibutionGridFilter = (applyifilters, gridRowData) => {
    // setGridLoading(true);
    const filteredData = applyFilterToGrid(applyifilters, gridRowData);
    setdistributionGridData(filteredData);
    const handleFilterValue = postFilter(applyifilters);
    setApplyFilter(handleFilterValue);
    setFilterApplied(true);
  };
  const getTotals = async (dateRange) => {
    try {
      if (
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        Object.keys(acc).length > 0
      ) {
        const totalObj = await getTotalQueries({
          data_wh_id: acc?.id,
          date_range: dateRange?.label
        });
        if (totalObj?.values) {
          setTotal(totalObj?.values);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clearDistributionData = () => {
    setDistributionRowData([]);
  };
  const clearFailedQueriesData = () => {
    setFailedQueriesRowData([]);
  };
  const getPieChart = async (dateRange, distribution) => {
    try {
      if (
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        Object.keys(acc).length > 0 &&
        distribution &&
        Object.keys(distribution)?.length > 0
      ) {
        const [userNamePieData, WHPieData] = await Promise.all([
          PieDistributionAPI({
            data_wh_id: acc?.id,
            date_range: dateRange?.label,
            dimension: "user_name"
          }),
          PieDistributionAPI({
            data_wh_id: acc?.id,
            date_range: dateRange?.label,
            dimension: "warehouse_name"
          })
        ]);

        if (userNamePieData) {
          const formattedData = getDonutData(userNamePieData, "", "user_name");
          setUserPieArray(formattedData);
        }
        if (WHPieData) {
          const formattedData = getDonutData(WHPieData, "", "warehouse_name");
          setWHPieArray(formattedData);
        }
        setPieChartLoaded(false);
      }
    } catch (err) {
      console.log(err);
      setUserPieArray(null);
      setWHPieArray(null);
      setPieChartLoaded(false);
    }
  };

  const getData = async () => {
    let dateRangeArr = null;
    let sortingArr = null;
    let distributionArr = null;
    let tempDtRange = null;
    let tempSorting = null;
    let tempDist = null;
    try {
      const [dateRangeObj, sortingObj, distributionObj] = await Promise.all([
        getFailedQueriesDateRange(acc ? acc?.id : ""),
        getFailedQueriesSortingOptions(),
        getFailedQueriesDistributionOptions()
      ]);
      if (dateRangeObj?.values) {
        dateRangeArr = getValueObj(dateRangeObj?.values);
        setDateRangeOptions(dateRangeArr);
        if (dateRange) {
          const dtObj = dateRangeArr.find((date) => {
            return date?.label === dateRange?.label;
          });
          if (dtObj && Object.keys(dtObj).length) {
            tempDtRange = dtObj;
            setDateRange(dtObj);
          } else {
            setDateRange(dateRangeArr[0]);
            tempDtRange = dateRangeArr[0];
          }
        } else {
          setDateRange(dateRangeArr[0]);
          tempDtRange = dateRangeArr[0];
        }
      }

      if (sortingObj?.values) {
        sortingArr = getSortingValueObj(sortingObj?.values);
        setSortingOptions(sortingArr);
        if (sorting) {
          const sortingObj = sortingArr.find((sort) => {
            return sorting?.label === sort?.label;
          });
          if (sortingObj && Object.keys(sortingObj).length) {
            tempSorting = sortingObj;
            setSorting(sortingObj);
          } else {
            setSorting(sortingArr[0]);
            tempSorting = sortingArr[0];
          }
        } else {
          setSorting(sortingArr[0]);
          tempSorting = sortingArr[0];
        }
      }
      if (distributionObj?.values) {
        distributionArr = getSortingValueObj(distributionObj?.values);
        setDistributionOptions(distributionArr);
        if (distribution) {
          const distributionObj = distributionArr.find((dist) => {
            return distribution?.label === dist?.label;
          });
          if (distributionObj && Object.keys(distributionObj).length) {
            tempDist = distributionObj;
            setDistribution(distributionObj);
          } else {
            setDistribution(distributionArr[0]);
            tempDist = distributionArr[0];
          }
        } else {
          setDistribution(distributionArr[0]);
          tempDist = distributionArr[0];
        }
        //setDistribution(distributionArr[0]);
      }
      if (tempDtRange && tempDist && acc) {
        getPieChart(tempDtRange, tempDist);
      }
      if (activeTab.toString() === "2") {
        if (tempDtRange && tempDist && acc) {
          getDistributionTableData(tempDtRange, tempDist);
        } else {
          clearDistributionData();
        }
      } else if (activeTab.toString() === "1") {
        if (tempDtRange && tempSorting && acc) {
          getFailedQueriesTableData(tempDtRange, tempSorting);
        } else {
          clearFailedQueriesData();
        }
      }
      if (tempDtRange && acc) {
        getTotals(tempDtRange);
      }
      setIsPageLoading(false);
    } catch (err) {
      clearDistributionData();
      clearFailedQueriesData();
      if (activeTab?.toString() === "2") {
        setDistributionGridLoading(false);
      } else if (activeTab?.toString() === "1") {
        setFailedQueriesGridLoading(false);
      }
      //setPieLoading(false);
      setIsPageLoading(false);
      if (!distributionGridFallback) {
        setDistributionGridFallback(true);
      }
      if (!failedqueriesGridFallback) {
        setFailedQueriesGridFallback(true);
      }
      console.log(err);
    }
  };
  const [breadCrumbs] = useState(false);
  useEffect(() => {
    if (acc) {
      setIsPageLoading(true);
      if (activeTab?.toString() === "2") {
        clearFailedQueriesData();
        setDistributionGridLoading(true);
      } else if (activeTab?.toString() === "1") {
        clearDistributionData();
        setFailedQueriesGridLoading(true);
      }
      //setPieLoading(true);
      setPieChartLoaded(true);
      getData();
    }
  }, [acc]);

  useEffect(() => {
    let requiredColumns;
    if (activeTab === "1") {
      requiredColumns = exportCSVcolumns(failedQueriesColDefs);
    } else {
      requiredColumns = exportCSVcolumns(distributionColDefs);
    }

    setRequiredCols(requiredColumns);
  }, [activeTab, distributionColDefs, failedQueriesColDefs]);

  const changeReasonModal = (bool) => {
    if (bool?.toString() === "false" || !bool) {
      changeReasonObj(null);
    }
    setIsReasonModalOpen(bool);
  };
  const getColumnDefs = (
    activeTab,
    failedQueriesColDefs,
    distributionColDefs
  ) => {
    return activeTab === "1" ? failedQueriesColDefs : distributionColDefs;
  };
  const getRowData = (activeTab, failedQueryGridData, distributionGridData) => {
    return activeTab === "1" ? failedQueryGridData : distributionGridData;
  };
  const getGridFallback = (
    activeTab,
    failedqueriesGridFallback,
    distributionGridFallback
  ) => {
    return activeTab === "1"
      ? failedqueriesGridFallback
      : distributionGridFallback;
  };
  const getGridStyle = (
    activeTab,
    failedQueryGridData,
    distributionGridData,
    innerHeight,
    rowHeight,
    minHeight
  ) => {
    return {
      height:
        activeTab === "1"
          ? getGridHeight(
              failedQueryGridData,
              innerHeight,
              rowHeight,
              minHeight
            )
          : getGridHeight(
              distributionGridData,
              innerHeight,
              rowHeight,
              minHeight
            ),
      avoidAutoHeightOverflow:
        activeTab === "1"
          ? failedQueryGridData?.length >= 0 && failedQueryGridData?.length < 5
            ? false
            : true
          : distributionGridData?.length >= 0 &&
            distributionGridData?.length < 5
          ? false
          : true
    };
  };
  const getGridLoading = (
    activeTab,
    failedQueriesGridLoading,
    distributionGridLoading
  ) => {
    return activeTab === "1"
      ? failedQueriesGridLoading
      : distributionGridLoading;
  };
  const getNoRowsText = (activeTab) => {
    return activeTab === "1"
      ? "Failed Queries for the selected period or account are currently unavailable. Coming soon..."
      : "Distribution for the selected period or account are currently unavailable. Coming soon...";
  };
  const getTableName = (activeTab) => {
    return activeTab === "1"
      ? "Failed Queries"
      : "Failed Queries by Users and Warehouses";
  };
  return (
    <>
      {expandModal && (
        <>
          <ExpandComponent
            columnDefs={getColumnDefs(
              activeTab,
              failedQueriesColDefs,
              distributionColDefs
            )}
            rowData={getRowData(
              activeTab,
              failedQueryGridData,
              distributionGridData
            )}
            gridFallback={getGridFallback(
              activeTab,
              failedqueriesGridFallback,
              distributionGridFallback
            )}
            gridStyle={getGridStyle(
              activeTab,
              failedQueryGridData,
              distributionGridData,
              window.innerHeight,
              30,
              180
            )}
            handleExpand={handleExpand}
            gridLoading={getGridLoading(
              activeTab,
              failedQueriesGridLoading,
              distributionGridLoading
            )}
            noRowsText={getNoRowsText(activeTab)}
            downloadCSV={downloadCSV}
            tableName={getTableName(activeTab)}
            hasTrialFallback={hasTrialFallback}
            changeActiveModule={changeActiveModule}
            exportColumns={requiredCols}
            suppressColumnsSize={handleGridResolution(
              gridTotalWidth,
              window.innerWidth,
              96
            )}
          />
        </>
      )}

      <div className="failedqueries-container overflow-auto">
        {!isPageLoading ? (
          <>
            <div className="header-container">
              <div className="header-block">
                {breadCrumbs ? (
                  <div className="breadcrumbs-container"></div>
                ) : null}
                <div className="header">
                  <span>Failed Queries Stats</span>
                </div>
              </div>
              <div className="top-filter">
                <div className="select-container">
                  <Select
                    value={dateRange}
                    options={dateRangeOptions}
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                    classNamePrefix="selectcategory"
                    placeholder="select"
                    isSearchable={false}
                    onChange={(e) => {
                      setDateRange(e);
                      if (distribution && activeTab?.toString() === "2") {
                        clearFailedQueriesData();
                        setDistributionGridLoading(true);
                        //setPieLoading(true);
                        setPieChartLoaded(true);
                        getDistributionTableData(e, distribution);
                        getPieChart(e, distribution);
                      } else if (
                        distribution &&
                        sorting &&
                        activeTab?.toString() === "1"
                      ) {
                        clearDistributionData();
                        setPieChartLoaded(true);
                        setFailedQueriesGridLoading(true);
                        getFailedQueriesTableData(e, sorting);
                        getPieChart(e, distribution);
                      }
                      getTotals(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="stats__parent">
              <div className="stats__container">
                {total && (
                  <>
                    {total?.count ? (
                      <DataCards
                        data={{
                          metric_name: "Total Failed Queries",
                          value: total.count
                        }}
                      />
                    ) : null}
                    {total?.sum_execution_time_hours ? (
                      <DataCards
                        data={{
                          metric_name: "Total Execution Time (Hrs)",
                          value: total.sum_execution_time_hours
                        }}
                      />
                    ) : null}
                    {total?.total_query_cost ? (
                      <DataCards
                        data={{
                          metric_name: "Total Query Cost ($)",
                          value: total.total_query_cost
                        }}
                      />
                    ) : null}
                    {total?.total_credits_used ? (
                      <DataCards
                        data={{
                          metric_name: "Total Credits Used",
                          value: total.total_credits_used
                        }}
                      />
                    ) : null}
                  </>
                )}
              </div>
              <div className="donut-main-container">
                {!pieChartLoaded ? (
                  <>
                    {userPieArray ? (
                      <DonutCharts
                        chartData={userPieArray}
                        additionalStyle={{
                          animation: false,
                          title: {
                            text: `$${roundToSigDigits(
                              userPieArray?.total,
                              1,
                              false,
                              1
                            ).toLocaleString("en-US")}`
                          }
                        }}
                      />
                    ) : (
                      <div className="empty__donut">
                        <span>No Data Found</span>
                      </div>
                    )}
                    {WHpieArray ? (
                      <DonutCharts
                        chartData={WHpieArray}
                        additionalStyle={{
                          animation: false,
                          title: {
                            text: `$${roundToSigDigits(
                              WHpieArray?.total,
                              1,
                              false,
                              1
                            ).toLocaleString("en-US")}`
                          }
                        }}
                      />
                    ) : (
                      <div className="empty__donut">
                        <span>No Data Found</span>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="load">
                    <div className="preload"></div>
                  </div>
                )}
              </div>
            </div>
            <div className="tabs__container">{tabView}</div>
            <div
              className={`grid-block ${
                activeTab === "1" ? "active" : "inactive"
              }`}>
              <div className="grid-header-container">
                <div className="grid-filter-container">
                  <div className="grid-filter-wrapper">
                    <div className="select-container-groupby">
                      <Select
                        value={(() => {
                          return {
                            ...sorting,
                            label: `Sort By • ${
                              sorting?.label == "None" || sorting == null
                                ? "Unsorted"
                                : sorting?.label
                            }`
                          };
                        })()}
                        isSearchable={false}
                        classNamePrefix="selectcategory"
                        placeholder="select"
                        components={{ group: groupSvg, DropdownIndicator }}
                        styles={customStyles}
                        options={sortingOptions}
                        onChange={(e) => {
                          setSorting(e);
                          if (dateRange) {
                            setFailedQueriesGridLoading(true);
                            getFailedQueriesTableData(dateRange, e);
                          }
                        }}
                      />
                    </div>
                    <div className="select-container">
                      <Button
                        onClick={handlePopover}
                        disabled={hasTrialFallback}
                        className={
                          showFilters
                            ? "qg-filter-button-active "
                            : filterApplied
                            ? "qg-filter-active"
                            : "qg-filter-button"
                        }>
                        <img src={FilterIcon} alt="" />
                        <span>Filter</span>
                      </Button>

                      {showFilters && !hasTrialFallback && (
                        <CustomFilter
                          column={FQFilterColumns}
                          gridData={failedQueriesRowData}
                          applyFilter={applyFilter}
                          setApplyFilter={setApplyFilter}
                          close={handlePopover}
                          handleFilter={handleFailedQueryGridFilter}
                          clearFilter={clearGridFilter}
                          isMenuOpen={showFilters}
                        />
                      )}
                    </div>
                  </div>
                  <div className="grid-filter-wrapper-right">
                    <Button
                      onClick={() => {
                        handleExpand(true);
                      }}
                      className={"qg-filter-button expand__button"}>
                      <img src={expand} alt="" />
                      <span>Expand</span>
                    </Button>
                    <div className="select-container export-container">
                      <Button
                        onClick={handleExport}
                        className={"qg-filter-button"}>
                        <img src={ExportCSV} alt="" />
                        <span>Export</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <GridComponent
                columnDefs={failedQueriesColDefs}
                rowData={failedQueryGridData}
                gridFallback={failedqueriesGridFallback}
                gridStyle={{
                  height: getGridHeight(
                    failedQueryGridData,
                    window.innerHeight - 355,
                    30,
                    180
                  ),
                  avoidAutoHeightOverflow:
                    getAutoheightOverflow(failedQueryGridData)
                }}
                gridLoading={failedQueriesGridLoading}
                noRowsText={
                  "Failed Queries for the selected period or account are currently unavailable. Coming soon..."
                }
                hasTrialFallback={hasTrialFallback}
                changeActiveModule={changeActiveModule}
                tableName={"Failed Queries"}
                exportColumns={requiredCols}
                downloadCSV={downloadCSV}
                suppressColumnsSize={failedQueriesGridResolution}
              />
            </div>

            {activeTab === "2" && (
              <div
                className={`grid-block ${
                  activeTab === "2" ? "active" : "inactive"
                }`}>
                <div className="grid-header-container">
                  <div className="grid-filter-container">
                    <div className="select-filter__wrapper">
                      <div className="select-container-groupby">
                        <Select
                          value={(() => {
                            return {
                              ...distribution,
                              label: `Sort By • ${
                                distribution?.label == "None" ||
                                distribution == null
                                  ? "Unsorted"
                                  : distribution?.label
                              }`
                            };
                          })()}
                          isSearchable={false}
                          classNamePrefix="selectcategory"
                          placeholder="select"
                          components={{ group: groupSvg, DropdownIndicator }}
                          styles={customStyles}
                          options={distributionOptions}
                          onChange={(e) => {
                            setDistribution(e);
                            if (dateRange) {
                              setDistributionGridLoading(true);
                              getDistributionTableData(dateRange, e);
                            }
                          }}
                        />
                      </div>
                      <div className="select-container">
                        <Button
                          onClick={handlePopover}
                          className={
                            showFilters
                              ? "qg-filter-button-active "
                              : filterApplied
                              ? "qg-filter-active"
                              : "qg-filter-button"
                          }>
                          <img src={FilterIcon} alt="" />
                          <span>Filter</span>
                        </Button>
                        {showFilters && (
                          <CustomFilter
                            column={distributionFilterColumns}
                            gridData={distributionRowData}
                            applyFilter={applyFilter}
                            setApplyFilter={setApplyFilter}
                            close={handlePopover}
                            handleFilter={handleDistibutionGridFilter}
                            clearFilter={clearGridFilter}
                            isMenuOpen={showFilters}
                          />
                        )}
                      </div>
                    </div>
                    <div className="select-container select-export">
                      <Button
                        onClick={() => {
                          handleExpand(true);
                        }}
                        className={"qg-filter-button expand__button"}>
                        <img src={expand} alt="" />
                        <span>Expand</span>
                      </Button>
                      <Button
                        onClick={handleExport}
                        className={"qg-filter-button"}>
                        <img src={ExportCSV} alt="" />
                        <span>Export</span>
                      </Button>
                    </div>
                  </div>
                </div>
                <GridComponent
                  columnDefs={distributionColDefs}
                  rowData={distributionGridData}
                  gridFallback={distributionGridFallback}
                  gridStyle={{
                    height: getGridHeight(
                      distributionGridData,
                      window.innerHeight - 355,
                      30,
                      180
                    ),
                    avoidAutoHeightOverflow:
                      getAutoheightOverflow(distributionGridData)
                  }}
                  noRowsText={
                    "Distribution for the selected period or account are currently unavailable. Coming soon..."
                  }
                  gridLoading={distributionGridLoading}
                  downloadCSV={downloadCSV}
                  hasTrialFallback={hasTrialFallback}
                  changeActiveModule={changeActiveModule}
                  tableName={"Failed Queries by Users and Warehouses"}
                  exportColumns={requiredCols}
                  suppressColumnsSize={distributionGridResolution}
                />
              </div>
            )}
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
      {activeTab === "1" ? (
        <>
          <div
            className={`reason-modal-wrapper ${
              isReasonModalOpen ? "show" : ""
            }`}></div>
          <div
            className={`modal-container reason-modal ${
              isReasonModalOpen ? "show" : ""
            }`}>
            <div
              className={`reason-modal-dialog ${
                isReasonModalOpen ? "show" : ""
              }`}>
              <div className="modal-content">
                <div className="modal-header">
                  <span className="modal-title small-font">Debug Query</span>
                  <button
                    className="close"
                    onClick={() => {
                      changeReasonModal(false);
                    }}>
                    <span>&#xD7;</span>
                  </button>
                </div>
                <div className="modal-body display-flex flex-column">
                  <div className="totals__container display-flex flex-row">
                    {reasonObj?.total_runs ? (
                      <div className="total display-flex">
                        <span className="title">Total Failed Runs: </span>
                        <span className="value">{reasonObj?.total_runs}</span>
                      </div>
                    ) : null}
                    {reasonObj?.total_reasons ? (
                      <div className="total display-flex">
                        <span className="title">Reasons For Failure: </span>
                        <span className="value">
                          {reasonObj?.total_reasons}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="modal-grid-block">
                    <GridComponent
                      columnDefs={reasonObj?.colDef}
                      rowData={reasonObj?.rowData}
                      gridFallback={modalGridFallBack}
                      gridStyle={{
                        height:
                          !reasonObj?.rowData?.length ||
                          reasonObj?.rowData?.length < 5
                            ? 300
                            : 400,
                        avoidAutoHeightOverflow:
                          reasonObj?.rowData?.length >= 0 &&
                          reasonObj?.rowData?.length < 5
                            ? false
                            : true,
                        additionalClass:
                          "cell-flex-12 header__font__12 ag-row-uniformbackground"
                        //trialFallbackClass: "custom__65__top"
                      }}
                      gridLoading={modalGridLoading}
                      changeActiveModule={changeActiveModule}
                      tableName={"Failed Queries Reasons"}
                      noRowsText={"No Failed Queries Reasons Found"}
                      suppressColumnsSize={false}
                      customRowHeight={24}
                      customHeaderHeight={28}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
FailedQueries.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  changeActiveModule: PropTypes.func
};
export default FailedQueries;
