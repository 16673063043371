import _ from "lodash";
import { roundToSigDigits } from "./common-helper";
import { attachParams } from "./url-helper";
import { getRequest } from "./http-helper";

export const getDonutData = (
  data,
  type = "",
  account_name = "account_name"
) => {
  const totalcost = data.data?.reduce((prev, sum) => prev + sum.cost, 0);
  if (!totalcost) {
    return null;
  }
  const groupedData = _.groupBy(data.data, account_name);
  const finaldata = _.map(groupedData, (group, account_name) => {
    const usedcost = _.sumBy(group, "cost");
    const percentage = roundToSigDigits((usedcost / totalcost) * 100, 2, false);
    return {
      name: account_name,
      y: Number(percentage),
      totalcost: usedcost
    };
  });

  if (type === "") {
    finaldata.title = data?.meta_data?.title;
    finaldata.subTitle = data?.meta_data?.sub_title;
  } else if (type.toLowerCase() === "pie") {
    finaldata.title = data?.meta_data?.pie_chart_title;
    finaldata.subTitle = data?.meta_data?.pie_chart_sub_title;
  } else if (type.toLowerCase() === "line") {
    finaldata.title = data?.meta_data?.line_chart_title;
    finaldata.subTitle = data?.meta_data?.line_chart_sub_title;
  }
  finaldata.total = totalcost;
  return finaldata;
};

export const getSplineChartData = (computeCostData) => {
  const transformedData = _.groupBy(computeCostData.data, "account_name");
  const axisXdata = _.chain(computeCostData.data)
    .groupBy("account_name")
    .map((groupedData, name) => ({
      name,
      data: groupedData.map((item) => item.cost)
    }))
    .value();

  const axisXDates = Object.keys(transformedData).map((item) =>
    transformedData[item].map((val) => val.date)
  );
  const concatenatedAxisXDates = [].concat(...axisXDates).map((date) => date);
  const uniqueAxisXDates = Array.from(concatenatedAxisXDates);

  const modifiedData = {
    title: computeCostData?.meta_data?.line_chart_title,
    subTitle: computeCostData?.meta_data?.line_chart_sub_title,
    data: axisXdata,
    dates: uniqueAxisXDates
  };

  return modifiedData;
};

export const handleDonutFilter = (rawData, filterVal, dateRangeArr) => {
  let filteredData = [];
  if (filterVal.value !== "Last 3 Months") {
    filteredData = rawData?.data.filter(
      (item) => item.date_range_str === filterVal.value
    );
    filteredData = { data: filteredData, meta_data: rawData?.meta_data };
  } else {
    const filteredDateRange =
      dateRangeArr &&
      dateRangeArr?.length &&
      dateRangeArr?.filter((date) => {
        return !date?.value?.includes("MTD");
      });

    if (filteredDateRange && filteredDateRange?.length) {
      filteredDateRange?.forEach((date) => {
        const arr = rawData?.data.filter(
          (item) => item?.date_range_str === date?.value
        );
        filteredData = [...filteredData, ...arr];
      });

      filteredData = { data: filteredData, meta_data: rawData?.meta_data };
    }
  }

  return filteredData;
};

export const handleLineChartFilter = (rawData, filterVal) => {
  const filteredData = rawData?.data.filter(
    (item) => item.date_range_str === filterVal.value
  );
  const formattedData = getSplineChartData(filteredData);
  return formattedData;
};
export const getAccountSummaryKPICardsAPI = async (date_range, acc) => {
  const params = { date_range: date_range?.value, data_wh_id: acc.id };
  const URL = attachParams(`/v2/organization/summary/kpi_cards`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getAccountSummaryTotalCostAPI = async (date_range, acc) => {
  // const params = { date_range: date_range?.value, data_wh_id: acc?.id };
  const params = { date_range: date_range?.value, data_wh_id: acc.id };
  const URL = attachParams(`/v2/organization/summary/total_cost`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getAccountSummaryComputeCostAPI = async (date_range, acc) => {
  const params = { date_range: date_range?.value, data_wh_id: acc.id };
  const URL = attachParams(`/v2/organization/summary/compute_cost`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getAccountSummaryStorageCostAPI = async (date_range, acc) => {
  const params = { date_range: date_range?.value, data_wh_id: acc.id };
  const URL = attachParams(`/v2/organization/summary/storage_cost`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getTopWHDataAPI = async (date_range, acc) => {
  const params = { date_range: date_range?.value, data_wh_id: acc.id };
  const URL = attachParams(
    `/v2/organization/summary/top_10_warehouses`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getTopUserDataAPI = async (date_range, acc) => {
  const params = { date_range: date_range?.value, data_wh_id: acc.id };
  const URL = attachParams(`/v2/organization/summary/top_10_users`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getContractUtilizationDateRangeAPI = async (accID) => {
  const params = { data_wh_id: accID };
  const URL = attachParams(
    `/v2/contract_usage/usage_analysis/date_ranges`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getContractUtilizationData = async (date_range, acc) => {
  const params = { date_range: date_range?.value, data_wh_id: acc.id };
  const URL = attachParams(`/v2/contract_usage/usage_analysis`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const summaryTabs = [
  { id: "1", label: "Cost Overview" },
  { id: "2", label: "Contract Utilization" }
];

export const costFormatter = (cost) => {
  const newCost = roundToSigDigits(cost, 2, false, 2).toLocaleString("en-US");
  if (newCost === "-") {
    return "-";
  }

  if (cost < 0) {
    let data = newCost.charAt(0) + "$" + newCost.slice(1);
    return data;
  } else {
    let data = "$" + newCost;
    return data;
  }
};
