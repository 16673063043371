import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import CustomTabs from "../../customElements/Tabs/CustomTabs";
import DisplayCards from "../../customElements/DisplayCards/DisplayCards";
import Button from "../../customElements/Button";
import CustomSelect from "../../customElements/Select/CustomSelect";
import jpeg_export from "../../../assets/images/common/jpeg_export.svg";
import chev_down from "../../../assets/images/common/chev_down.svg";
import FilterIcon from "../../../assets/images/grid/Filter.svg";
import {
  getSessionStorage,
  removeSSItem,
  setSessinStorage
} from "../../../utils/storage-helper";
import { costFormatter } from "../../../utils/organization-helper";
import { roundToSigDigits } from "../../../utils/common-helper";
import GridComponent from "../../GridComponent";
import CustomFilter from "../../CustomFilter/CustomFilter";
import { filterOperatorTypes } from "../../../utils/filter-helper";
import ExportCSV from "../../../assets/images/grid/exportcsv.svg";
import expand from "../../../assets/images/common/expand.svg";
import SkeletonLoader from "../../customElements/SkeletonLoader/SkeletonLoader";
import ExpandComponent from "../../ExpandComponent";
import Multiselects from "../../customElements/MultiSelect";
import Pagination from "../../customElements/Pagination/Pagination";
import CustomLegends from "./CustomLegends";
import DBXCOverviewGraphContainer from "./DBXCOverviewGraphContainer";

const ClusterOverviewPage = (props) => {
  const {
    acc,
    dateRange,
    dateRangeOption,
    handleDateRangeChange,
    pageTabOptions,
    pageTabs,
    groupBy,
    groupByOptions,
    granularityOptions,
    granularity,
    handlePageTabs,
    handleGroupByChange,
    handleGranularityChange,
    totalValues,
    columnDefs,
    gridData,
    costGraphData,
    clusterGraphData,
    costGraphTabs,
    clusterGraphTabs,
    handleCostGraphTabs,
    handleClusterGraphTabs,
    gridLoading,
    chartLoading,
    getOverviewData,
    navigationState,
    handleGridAndChartLoading,
    paginationInfo,
    metaAPIData,
    allWorkspaces,
    clearMemorizedData,
    setSelectedWorkSpace,
    selectedWorkSpace
  } = props;
  const costChartRef = useRef();
  const clusterChartRef = useRef();
  const [showAllWorkspaces, setShowAllWorkspaces] = useState(false);
  const [disabledLegends, setDisabledLegends] = useState([]);
  const [expandModal, setExpandModal] = useState(false);
  const [downloadCSV, setDownloadCSV] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filterDisabled, setFilterDisabled] = useState(false);
  const [filterApplied, setFilterApplied] = useState(null);
  const [applyFilter, setApplyFilter] = useState([]);

  const [hasTrialFallback] = useState(
    getSessionStorage("plan_name").toLowerCase() === "trial" ? true : false
  );

  // toggle legends
  const handleLegendClick = (obj) => {
    costChartRef.current?.chart?.series?.forEach((item) => {
      if (item.name === obj?.name) {
        if (item.visible) {
          item.hide();
          setDisabledLegends([...disabledLegends, obj.name]);
        } else {
          item.show();
          const filteredArr = disabledLegends.filter(
            (item) => item !== obj.name
          );
          setDisabledLegends(filteredArr);
        }
      }
    });
    clusterChartRef.current?.chart?.series?.forEach((item) => {
      if (item.name === obj?.name) {
        if (item.visible) {
          item.hide();
        } else {
          item.show();
        }
      }
    });
  };

  // Toggle filter popup
  const handlePopover = (e) => {
    if (!hasTrialFallback) {
      e?.stopPropagation();
      setShowFilters(!showFilters);
    }
  };

  // Filter Function
  const handleFilters = (applyifilters) => {
    handleGridAndChartLoading(true);
    let filterPayload = applyifilters.map((item) => {
      return {
        entity: item?.column?.value,
        op: item?.condition?.value,
        values: item?.value
      };
    });
    setSessinStorage("pageFilters", JSON.stringify(filterPayload));
    getOverviewData(
      acc,
      navigationState?.start_date,
      navigationState?.end_date,
      groupBy.value,
      granularity?.value,
      pageTabs?.value,
      1,
      50,
      filterPayload,
      selectedWorkSpace
    );

    setFilterApplied(true);
  };
  const clearGridFilter = () => {
    handleGridAndChartLoading(true);
    setFilterApplied(false);
    removeFilterFromStorage();
    setApplyFilter([
      {
        column: groupBy,
        condition: filterOperatorTypes?.[0],
        value: filterOperatorTypes?.[0]?.value !== "in" ? "" : [],
        dropDown: false
      }
    ]);
    getOverviewData(
      acc,
      navigationState?.start_date,
      navigationState?.end_date,
      groupBy.value,
      granularity?.value,
      pageTabs?.value,
      1,
      50,
      [],
      selectedWorkSpace
    );
  };

  const handlePagination = (pageNumber, pageSize, type = "pageSize") => {
    handleGridAndChartLoading(true);
    let filterVlues = getSessionStorage("pageFilters");
    filterVlues = JSON.parse(filterVlues);
    getOverviewData(
      acc,
      navigationState?.start_date,
      navigationState?.end_date,
      groupBy.value,
      granularity?.value,
      pageTabs?.value,
      pageNumber,
      pageSize,
      filterVlues ?? [],
      selectedWorkSpace,
      type === "pageSize" ? false : true
    );
  };

  const getFilertDropdown = (data, key) => {
    const result = data?.[key].map((item) => ({
      label: item,
      [`${key}`]: item
    }));
    return result;
  };

  const handleExport = () => {
    if (!hasTrialFallback) {
      setDownloadCSV(true);
      setTimeout(() => {
        setDownloadCSV(false);
      }, 3000);
    }
  };
  const handleExpand = (bool) => {
    setExpandModal(bool);
  };
  const removeFilterFromStorage = () => {
    setShowFilters(false);
    removeSSItem("pageFilters");
    setDisabledLegends([]);
  };
  useEffect(() => {
    if (groupBy?.value === "workspace_id") {
      setFilterDisabled(true);
      setFilterApplied(false);
    } else {
      setFilterDisabled(false);
      let filterValues = getSessionStorage("pageFilters");
      if (filterValues) {
        filterValues = JSON.parse(filterValues);
        let filterData = filterValues?.map((item) => {
          const entity = groupByOptions?.find(
            (columnOptions) => columnOptions?.value === item?.entity
          );
          const operators = filterOperatorTypes?.find(
            (operatorOptions) => operatorOptions?.value === item?.op
          );
          return {
            column: entity,
            condition: operators,
            value: item?.values ?? [],
            dropDown: false
          };
        });
        setApplyFilter(filterData);
        setFilterApplied(true);
      } else {
        setApplyFilter([
          {
            column: groupBy,
            condition: filterOperatorTypes?.[0],
            value: filterOperatorTypes?.[0]?.value !== "in" ? "" : [],
            dropDown: false
          }
        ]);
        setFilterApplied(false);
      }
    }
  }, [groupBy]);
  return (
    <>
      {expandModal ? (
        <ExpandComponent
          columnDefs={columnDefs}
          rowData={gridData}
          suppressColumnsSize={true}
          exportColumns={gridData}
          handleExpand={handleExpand}
          downloadCSV={downloadCSV}
          customRowHeight={40}
          noRowsText={"No data Found"}
          tableName={"Cluster Overview"}
          hasTrialFallback={hasTrialFallback}
        />
      ) : (
        <div className="cluster-overview-container">
          <div className="title-section">
            <div className="title-tabs">
              <CustomTabs
                tabs={pageTabOptions}
                value={pageTabs}
                onChange={(obj) => {
                  removeFilterFromStorage();
                  handlePageTabs(obj);
                }}
              />
            </div>
            <div className="granularity-container">
              <CustomSelect
                type="granularity"
                options={dateRangeOption}
                value={dateRange}
                onChange={(e) => {
                  clearMemorizedData();
                  removeFilterFromStorage();
                  handleGridAndChartLoading(true);
                  handleDateRangeChange(e);
                }}
              />
            </div>
          </div>
          <div className="data-card-container">
            <DisplayCards
              label={"Total Cost"}
              value={costFormatter(roundToSigDigits(totalValues?.totals?.cost))}
              align={"reverse"}
            />
            <DisplayCards
              label={"Total DBUs"}
              value={roundToSigDigits(
                totalValues?.totals?.dbus,
                2
              ).toLocaleString("en-US")}
              align={"reverse"}
            />
            <DisplayCards
              label={"Active Clusters"}
              value={roundToSigDigits(
                totalValues?.totals?.active_clusters,
                2
              ).toLocaleString("en-US")}
              align={"reverse"}
            />
            <DisplayCards
              label={"Total Session Runtime (Hrs)"}
              value={roundToSigDigits(
                totalValues?.totals?.runtime_hrs,
                2
              ).toLocaleString("en-US")}
              align={"reverse"}
            />
          </div>
          <div className="date-range-wrapper">
            <div className="filter-groupby-wrapper">
              <div className="filter-wrapper">
                <Button
                  onClick={handlePopover}
                  disabled={hasTrialFallback || filterDisabled}
                  className={
                    showFilters
                      ? "qg-filter-button-active "
                      : filterApplied
                      ? "qg-filter-active"
                      : "qg-filter-button"
                  }>
                  <img src={FilterIcon} alt="" />
                  <span>Filter</span>
                </Button>
                {showFilters && !hasTrialFallback && !filterDisabled && (
                  <CustomFilter
                    column={[groupBy]}
                    gridData={getFilertDropdown(
                      metaAPIData?.filter_values,
                      groupBy?.value
                    )}
                    filterOperators={filterOperatorTypes}
                    applyFilter={applyFilter}
                    setApplyFilter={setApplyFilter}
                    close={handlePopover}
                    handleFilter={(filterValue, gridData) => {
                      handleFilters(filterValue, gridData);
                    }}
                    clearFilter={() => {
                      clearGridFilter();
                    }}
                    maxSelectionLimit={{ limit: 50, isActive: true }}
                    isMenuOpen={showFilters}
                  />
                )}
              </div>
              <div className="groupby-wrapper">
                <CustomSelect
                  type="groupBy"
                  value={groupBy}
                  options={groupByOptions}
                  onChange={(e) => {
                    removeFilterFromStorage();
                    clearMemorizedData();
                    handleGridAndChartLoading(true);
                    handleGroupByChange(e);
                  }}
                />
              </div>
            </div>
            <div className="daterange-export-wrapper">
              <div className="daterange-wrapper">
                <CustomSelect
                  type="granularity"
                  value={granularity}
                  options={granularityOptions}
                  onChange={(e) => {
                    setDisabledLegends([])
                    clearMemorizedData();
                    handleGridAndChartLoading(true);
                    handleGranularityChange(e);
                  }}
                />
              </div>
              <div>
                <Button
                  onClick={() => {
                    // chart to image
                    const costChart = costChartRef?.current?.chart;
                    costChart?.exportChart({ type: "image/jpeg" });
                    setTimeout(() => {
                      const clusterChart = clusterChartRef?.current?.chart;
                      clusterChart?.exportChart({ type: "image/jpeg" });
                    }, 1000);
                  }}
                  className={"qg-filter-button ce-filter-button"}>
                  <img src={jpeg_export} alt="" />
                  <span>Export Chart</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="custom-legend-wrapper">
            <div className="custom-legends">
              {chartLoading || !costGraphData?.y ? (
                <SkeletonLoader
                  count={2}
                  direction={"horizontal"}
                  height={"10px"}
                />
              ) : (
                <CustomLegends
                  data={costGraphData?.y}
                  filterApplied={filterApplied}
                  handleLegendClick={handleLegendClick}
                  disabledLegends={disabledLegends}
                />
              )}
            </div>
          </div>
          {!clusterGraphData.y && !costGraphData?.y && !chartLoading ? (
            <div className="cluster-graph-container no-graph">
              <p>No data found</p>
            </div>
          ) : (
            <div className="cluster-graph-container">
              <div className="graph-container">
                <div className="title-section">
                  <p>Costs</p>
                  <div>
                    <CustomTabs
                      tabs={[
                        { label: "$", value: "cost" },
                        { label: "DBUs", value: "usage_quantity" }
                      ]}
                      value={costGraphTabs}
                      onChange={handleCostGraphTabs}
                    />
                  </div>
                </div>
                <div className="graph-wrapper">
                  {chartLoading ? (
                    <SkeletonLoader
                      count={6}
                      direction={"vertical"}
                      width={"75px"}
                      height={"270px"}
                    />
                  ) : (
                    <DBXCOverviewGraphContainer
                      chartData={costGraphData}
                      chartRef={costChartRef}
                      enableLegends={false}
                      filterApplied={filterApplied}
                      granularity={granularity}
                      legendDetails={{
                        align: "center",
                        verticalAlign: "top",
                        layout: "horizontal"
                      }}
                      totals={totalValues}
                      tabValue={
                        costGraphTabs?.value === "cost" ? "cost" : "dbus"
                      }
                      yLabel={
                        costGraphTabs?.value === "cost" ? "Cost ($)" : "DBUs"
                      }
                      useCostFormatter={
                        costGraphTabs?.value === "cost" ? true : false
                      }
                    />
                  )}
                </div>
              </div>
              <div className="graph-container">
                <div className="title-section">
                  <p>Clusters</p>
                  <div>
                    <CustomTabs
                      tabs={[
                        { label: "Count", value: "active_cluster_count" },
                        { label: "Runtime", value: "runtime" }
                      ]}
                      value={clusterGraphTabs}
                      onChange={handleClusterGraphTabs}
                    />
                  </div>
                </div>
                <div className="graph-wrapper">
                  {chartLoading || !clusterGraphData?.y ? (
                    <SkeletonLoader
                      count={6}
                      direction={"vertical"}
                      width={"75px"}
                      height={"270px"}
                    />
                  ) : (
                    <DBXCOverviewGraphContainer
                      chartRef={clusterChartRef}
                      chartData={clusterGraphData}
                      enableLegends={false}
                      filterApplied={filterApplied}
                      granularity={granularity}
                      legendDetails={{
                        align: "center",
                        verticalAlign: "top",
                        layout: "horizontal"
                      }}
                      totals={totalValues}
                      tabValue={
                        clusterGraphTabs?.value === "active_cluster_count"
                          ? "active_cluster_count"
                          : "runtime_hrs"
                      }
                      yLabel={
                        clusterGraphTabs?.value === "active_cluster_count"
                          ? "Active Clusters"
                          : "Runtime (Hrs)"
                      }
                      useCostFormatter={false}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="cluster-grid-container">
            <div className="expand-wrapper">
              <Button
                onClick={() => {
                  handleExpand(true);
                }}
                className={"qg-filter-button expand__button align-self-end"}>
                <img src={expand} alt="" />
                <span>Expand</span>
              </Button>
              <Button onClick={handleExport} className={"qg-filter-button"}>
                <img src={ExportCSV} alt="" />
                <span>Export</span>
              </Button>
            </div>
            {gridLoading ? (
              <SkeletonLoader
                count={10}
                direction={"horizontal"}
                height={"40px"}
              />
            ) : (
              <div className="overview-grid-container">
                <GridComponent
                  columnDefs={columnDefs}
                  rowData={gridData}
                  tableName={"Cluster Overview"}
                  downloadCSV={downloadCSV}
                  suppressColumnsSize={true}
                  customRowHeight={40}
                  noRowsText={"No data Found"}
                  exportColumns={gridData}
                  hasTrialFallback={hasTrialFallback}
                />
                {paginationInfo && (
                  <Pagination
                    currentPage={paginationInfo?.current_page}
                    previousPage={paginationInfo?.prev_page}
                    nextPage={paginationInfo?.next_page}
                    totalPages={paginationInfo?.total_pages}
                    pageSize={paginationInfo?.page_size}
                    totalRecords={paginationInfo?.total_records}
                    onPageChange={handlePagination}
                  />
                )}
              </div>
            )}
          </div>
          <div className="all-workspace-wrapper">
            <div className="workspace-dropdown">
              {showAllWorkspaces ? (
                <Multiselects
                  value={selectedWorkSpace}
                  options={allWorkspaces}
                  filter={false}
                  saveButtonText={"Apply"}
                  placeholder=""
                  onHide={() => setShowAllWorkspaces(false)}
                  onChange={(e) => {
                    let filterValues = getSessionStorage("pageFilters");
                    filterValues = JSON.parse(filterValues);
                    setSelectedWorkSpace(e);
                    handleGridAndChartLoading(true);
                    getOverviewData(
                      acc,
                      navigationState?.start_date,
                      navigationState?.end_date,
                      groupBy.value,
                      granularity?.value,
                      pageTabs?.value,
                      1,
                      50,
                      filterValues ?? [],
                      e
                    );
                  }}
                />
              ) : (
                <Button
                  className={"all-workspace-button"}
                  onClick={() => setShowAllWorkspaces(true)}>
                  {selectedWorkSpace?.length <= 0 ? (
                    <span className="placeholder-container">
                      <span className="placeholder-text">All Workspaces</span>
                      <img className="dropdown-arrow" src={chev_down} alt="" />
                    </span>
                  ) : (
                    <>
                      {selectedWorkSpace?.length <= 2 ? (
                        <span className="chip-container">
                          <span className="chip-mid">
                            {selectedWorkSpace?.[0]}
                          </span>
                          {selectedWorkSpace?.length >= 2 && (
                            <span className="chip-mid">
                              {selectedWorkSpace?.[1]}
                            </span>
                          )}
                          <img
                            className="dropdown-arrow"
                            src={chev_down}
                            alt=""
                          />
                        </span>
                      ) : (
                        <span className="chip-container">
                          <span className="chip">{selectedWorkSpace?.[0]}</span>
                          <span className="chip">{selectedWorkSpace?.[1]}</span>
                          <span className="chip-count">
                            +{selectedWorkSpace?.length - 2}
                          </span>
                          <img
                            className="dropdown-arrow"
                            src={chev_down}
                            alt=""
                          />
                        </span>
                      )}
                    </>
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
ClusterOverviewPage.propTypes = {
  acc: PropTypes.object,
  dateRange: PropTypes?.object,
  dateRangeOption: PropTypes.array,
  groupBy: PropTypes.object,
  groupByOptions: PropTypes.array,
  granularityOptions: PropTypes.array,
  granularity: PropTypes.object,
  handlePageTabs: PropTypes.func,
  handleGroupByChange: PropTypes.func,
  handleDateRangeChange: PropTypes.func,
  pageTabOptions: PropTypes.array,
  pageTabs: PropTypes.object,
  handleGranularityChange: PropTypes.func,
  totalValues: PropTypes.object,
  columnDefs: PropTypes.array,
  gridData: PropTypes.array,
  costGraphData: PropTypes.object,
  clusterGraphData: PropTypes.object,
  costGraphTabs: PropTypes.object,
  clusterGraphTabs: PropTypes.object,
  handleCostGraphTabs: PropTypes.func,
  handleClusterGraphTabs: PropTypes.func,
  gridLoading: PropTypes.bool,
  chartLoading: PropTypes.bool,
  getOverviewData: PropTypes.func,
  navigationState: PropTypes.object,
  handleGridAndChartLoading: PropTypes.func,
  paginationInfo: PropTypes.object,
  metaAPIData: PropTypes.object,
  allWorkspaces: PropTypes.array,
  clearMemorizedData: PropTypes.func,
  gridResolution: PropTypes.bool,
  gridTotalWidth: PropTypes.number,
  setSelectedWorkSpace: PropTypes.func,
  selectedWorkSpace: PropTypes.array
};

export default ClusterOverviewPage;
