import _ from "lodash";

export const filterTypes = [
  // { label: "Contains", value: "Contains" },
  // { label: "Not contains", value: "Not contains" },
  { label: "Equals", value: "in" }
  // { label: "Not equals", value: "Not equals" },
  // { label: "Starts with", value: "Starts with" },
  // { label: "Ends with", value: "Ends with" },
  // { label: "Blank", value: "Blank" },
  // { label: "Not blank", value: "Not blank" }
];
export const filterOperatorTypes = [
  { label: "Contains", value: "co" },
  { label: "Equals", value: "in" },
  { label: "Starts with", value: "sw" },
  { label: "Ends with", value: "ew" }
];
export const TGFilterColumns = [
  { label: "Table Name", value: "table_name" },
  { label: "Schema", value: "table_schema" },
  { label: "Database", value: "table_catalog" },
  { label: "Table Owner (Role)", value: "table_owner" }
];

export const CGWHFilterColumns = [
  { label: "Event Type", value: "Event Type" },
  { label: "Owner", value: "Owner (Role)" },
  { label: "User Name", value: "User Name" },
  { label: "Warehouse Name", value: "Warehouse Name" }
];
export const CGURFilterColumns = [
  { label: "Event Type", value: "Event Type" },
  { label: "Owner", value: "Owner (Role)" }
];
export const QGFilterColumns = [
  { label: "Warehouses", value: "warehouses" },
  { label: "Users", value: "users" }
];
export const FQFilterColumns = [
  { label: "Users", value: "users" },
  { label: "Warehouses", value: "warehouses" }
];
export const distributionFilterColumns = [
  { label: "Users", value: "user_name" }
];

export const WHRecColumns = [
  { label: "Warehouse Name", value: "warehouse_name" }
];

export const WHInventoryFilterColumns = [
  { label: "Warehouses", value: "warehouse_name" },
  { label: "Owner", value: "warehouse_owner" }
];
export const costCenterTableColumns = [
  // { label: "Accounts", value: "dw_ids" },
  { label: "Cost Center", value: "name" }
];

export const groupTableData = (data, value) => {
  const initailGrouping = _.groupBy(data, value);
  const FormatData = Object.keys(initailGrouping)?.map((item) => {
    const uniqData = item?.trim().split(",");
    const splittedData = uniqData?.map((val) => ({
      label: val,
      value: val,
      name: val
    }));
    let options = [].concat(...splittedData);
    return options;
  });
  const finalData = [].concat(...FormatData);
  let uniqueArray = _.uniqBy(finalData, "value");

  return uniqueArray;
};

export const checkValue = (filter) => {
  let flag = false;
  for (let i = 0; i < filter.length; i++) {
    if (filter?.[i].value.length <= 0) {
      flag = true;
      break;
    }
  }
  return flag;
};

export const applyFilterToGrid = (applyifilters, gridData) => {
  if (!applyifilters || !gridData) return [];

  const filteredData = applyifilters.flatMap((filterItem) => {
    const column = filterItem?.column?.value;
    if (!column) return [];

    return filterItem?.value.flatMap((val) => {
      return gridData.filter((item) => {
        const itemValue = item[column];
        return (
          (val === null && itemValue === null) ||
          (itemValue !== null && itemValue === val)
        );
      });
    });
  });

  let uniqueData = _.uniqWith(filteredData, _.isEqual);
  uniqueData = uniqueData.map((item, index) => ({ ...item, index: index + 1 }));
  return uniqueData;
};

export const clearAllFilters = (applyFilter) => {
  const defaultState = [];
  let updatedArr = applyFilter?.filter((item, index) => index === 0);
  let new_obj = { ...updatedArr[0] };
  new_obj = { ...new_obj, value: [], previuosValue: [], previousMenuList: [] };
  defaultState.push(new_obj);

  return defaultState;
};

export const getCostCenterFilteredData = (
  applyifilters,
  data,
  key,
  isAccountFilter
) => {
  const filteredData = applyifilters.flatMap((filterItem) =>
    filterItem.value.flatMap((filterValue) =>
      data.filter((val) =>
        isAccountFilter
          ? _.get(val, key)?.includes(filterValue)
          : _.get(val, key) === filterValue
      )
    )
  );

  return _.uniqBy(filteredData, (item) =>
    _.get(item, key === "dw_ids" || key === "name" ? "name" : "data.name")
  );
};

export const createOptions = (list = null) => {
  const result = list?.map((item) => ({
    value: item,
    name: item,
    label: item
  }));
  return result;
};
export const exportCSVcolumns = (columns) => {
  const new_arr = columns.map((val) =>
    val.field !== "Query Ids" &&
    val.field !== "regex_id" &&
    val?.field !== "Debug Query"
      ? val.field
      : null
  );
  return new_arr;
};

export const postFilter = (applyFilter) => {
  const newArr = applyFilter.map((item) => ({
    ...item,
    previuosValue: item.value,
    previousMenuList: item.defaultMenuList
  }));

  return newArr;
};

export const updateUrlWithParams = (applyfilters, path) => {
  const urlParams = new URLSearchParams(path);
  // Clear existing relevant params to avoid duplicates
  urlParams.forEach((_, key) => {
    if (key.startsWith("filterid") || key.startsWith("filterval")) {
      urlParams.delete(key);
    }
  });
  urlParams.set("filter", true);
  applyfilters.forEach((item, index) => {
    if (item?.column && item?.value) {
      const columnKey = `filterid${index + 1}`;
      const valueKey = `filterval${index + 1}`;
      urlParams.set(columnKey, item.column.value);

      if (Array.isArray(item?.value)) {
        urlParams.set(valueKey, item?.value.join(","));
      } else {
        urlParams.set(valueKey, item?.value);
      }
    }
  });
  const newUrl = `${urlParams.toString()}`;
  return newUrl;
};

export const restoreFromUrlParams = (metaData, path) => {
  const urlParams = new URLSearchParams(path);
  const dataArray = [];

  // Extract all columns and values from URL
  const columnValues = Array.from(urlParams.keys()).filter((key) =>
    key.startsWith("filterid")
  );
  const values = Array.from(urlParams.keys()).filter((key) =>
    key.startsWith("filterval")
  );

  // Iterate through the columns and values to rebuild the objects
  columnValues.forEach((columnValueKey, index) => {
    const valueKey = values.find((v) => v.endsWith((index + 1).toString()));
    if (valueKey) {
      const columnValue = urlParams.get(columnValueKey);
      if (columnValue && metaData?.groupby.length > 0) {
        let columnLabel = metaData?.groupby?.filter(
          (obj) => obj.groupby_column === columnValue
        );
        const value = urlParams.get(valueKey).split(",");
        dataArray.push({
          column: {
            label: columnLabel?.[0]?.groupby_title,
            value: columnValue
          },
          condition: { label: "Equals", value: "Equals" },
          defaultMenuList: [],
          dropDown: false,
          previousMenuList: [],
          previuosValue: [],
          value
        });
      }
    }
  });

  return dataArray;
};

export const deleteFromURL = (url) => {
  const queryString = url.split("?")?.[1];
  if (!queryString) return url;
  const urlParams = new URLSearchParams(queryString);
  const keysToDelete = [];
  urlParams.forEach((_, key) => {
    if (key.startsWith("filter") || key.startsWith("filterid")) {
      keysToDelete.push(key);
    }
  });
  keysToDelete.forEach((key) => urlParams.delete(key));
  urlParams.set("filter", "false");
  return urlParams;
};
