import React, { useEffect, useRef, useState } from "react";
import "./costreporting.scss";
import _ from "lodash";
import Select, { components } from "react-select";
import { flattenArray, getGridHeight } from "../../utils/common-helper";
import PropTypes from "prop-types";
import chev_down from "../../assets/images/common/chev_down.svg";
import StackGraphContainer from "../../components/CostReporting/StackGraphContainer";
import Graph_icon_active from "../../assets/images/grid/Graph_icon_active.svg";
import Graph_icon_default from "../../assets/images/grid/Graph_icon_default.svg";
import Bar_icon_active from "../../assets/images/grid/Bar_icon_active.svg";
import Bar_Icon_default from "../../assets/images/grid/Bar_Icon_default.svg";
import jpeg_export from "../../assets/images/common/jpeg_export.svg";
import expand from "../../assets/images/common/expand.svg";
import ExportCSV from "../../assets/images/grid/exportcsv.svg";
import {
  createGraphData,
  formatPageData,
  getCRDataAPI,
  getCRdateRangeDropdowns,
  timeIntervalOptions
} from "../../utils/cost-reporting-helper";
import { createTreeData, getcostcentres } from "../../utils/chargeback-helper";
import EmptyState from "../../components/Chargeback/EmptyState";
import { useNavigate } from "react-router-dom";
import Button from "../../components/customElements/Button";
import CustomFilter from "../../components/CustomFilter/CustomFilter";
import FilterIcon from "../../assets/images/grid/Filter.svg";

import {
  clearAllFilters,
  costCenterTableColumns,
  filterTypes,
  getCostCenterFilteredData,
  groupTableData,
  postFilter
} from "../../utils/filter-helper";
import TreeComponent from "../../components/TreeComponent";
import ExpandComponent from "../../components/ExpandComponent";
import Multiselects from "../../components/customElements/MultiSelect";
import { getColumns } from "./CostReportingHelper";
import { exportHTML } from "../../utils/export-helper";
import {
  getDateRangeOptionsArray,
  getMonthsArray,
  getStartAndEndDates
} from "../../utils/date-helper";

function CostReporting({ acc, dwAccOptions }) {
  const navigate = useNavigate();
  const chartRef = useRef(null);
  const [dateRange, setDateRange] = useState(null);
  const [dateRangeAPIValues, setDateRangeAPIValues] = useState({});
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [dateIntervals, setDateIntervals] = useState({});

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [showAccountFilter, setShowAccountFilter] = useState(false);
  const [accountsDropdown, setAccountsDropdown] = useState([]);
  const [accountsValue, setAccountsValue] = useState([]);

  const [rawData, setRawData] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [copyGraphData, setCopyGraphData] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [expandModal, setExpandModal] = useState(false);
  const [columnView, setColumnView] = useState(null);
  const [treeData, setTreeData] = useState(null);
  const [copyTreeData, setCopyTreeData] = useState(null);
  const [applyFilter, setApplyFilter] = useState([
    {
      column: costCenterTableColumns?.[0],
      condition: filterTypes?.[0],
      value: [],
      dropDown: false,
      previuosValue: [],
      previousMenuList: []
    }
  ]);
  const columnsToExclude = ["id", "key"];
  const convertToCSV = (data, columns) => {
    const filteredColumns = columns.filter(
      (col) => !columnsToExclude.includes(col.field)
    );
    const headers = filteredColumns.map((col) => col.name).join(",") + "\n";
    const rows = [];

    const traverse = (nodes) => {
      nodes.forEach((node) => {
        const row = filteredColumns
          .map((col) => node.data[col.field])
          .join(",");
        rows.push(row);
        if (node.children && node.children.length > 0) {
          traverse(node.children);
        }
      });
    };

    traverse(data);
    return headers + rows.join("\n");
  };

  const handleModal = () => {
    navigate(`/dashboard/chargeback/costcenter?modal=${true}`);
  };
  const tabs = [
    {
      id: "1",
      label: (
        <img
          src={activeTab === "1" ? Bar_icon_active : Bar_Icon_default}
          alt=""
        />
      )
    },
    {
      id: "2",
      label: (
        <img
          src={activeTab === "2" ? Graph_icon_active : Graph_icon_default}
          alt=""
        />
      )
    }
  ];
  const tabView =
    tabs &&
    tabs.map((tab) => {
      return (
        <div
          className={`tab ${tab?.id === activeTab ? "active" : "normal-btn"}`}
          key={tab?.id}
          onClick={() => {
            setActiveTab(tab?.id);
          }}>
          <span className="title">{tab?.label}</span>
        </div>
      );
    });

  const handleAccountFilter = (e, tree = [], chart = []) => {
    let unique_chart_arr, unique_tree_arr;
    if (e?.length >= 1) {
      setAccountsValue(e);
      const filtered_chart_arr = _.flatMap(e, (accountValue) => {
        return _.filter(copyGraphData, (obj) =>
          _.includes(obj?.dw_ids, accountValue)
        );
      });
      const filtered_tree_arr = _.flatMap(e, (accountValue) => {
        return _.filter(copyTreeData, (obj) =>
          _.includes(obj?.data?.dw_ids, accountValue)
        );
      });

      if (tree?.length > 0) {
        const combined_tree_arr = [...filtered_tree_arr, ...tree];
        unique_tree_arr = _.uniqBy(combined_tree_arr, "data.name");
      } else {
        if (applyFilter?.[0]?.value?.length > 0) {
          const combined_tree_arr = [...filtered_tree_arr, ...treeData];
          unique_tree_arr = _.uniqBy(combined_tree_arr, "data.name");
        } else {
          unique_tree_arr = _.uniqBy(filtered_tree_arr, "data.name");
        }
      }
      if (chart?.length > 0) {
        const combined_chart_arr = [...filtered_chart_arr, ...chart];
        unique_chart_arr = _.uniqBy(combined_chart_arr, "name");
      } else {
        if (applyFilter?.[0]?.value?.length > 0) {
          const combined_chart_arr = [...filtered_chart_arr, ...graphData];
          unique_chart_arr = _.uniqBy(combined_chart_arr, "name");
        } else {
          unique_chart_arr = _.uniqBy(filtered_chart_arr, "name");
        }
      }
      setGraphData(unique_chart_arr);
      setTreeData(unique_tree_arr);
    } else {
      setAccountsValue([]);
      if (applyFilter?.[0]?.value?.length > 0) {
        handleCostExpGridFilter(applyFilter, true);
      } else {
        setGraphData(copyGraphData);
        setTreeData(copyTreeData);
      }
    }
    setShowAccountFilter(false);
  };

  const getAllcostcenters = async (testData, date_label) => {
    try {
      const response = await getcostcentres();
      if (response) {
        const tree = createTreeData(
          testData?.data,
          response,
          dwAccOptions,
          testData?.date_aggregation === "monthly" ? "MMM yyyy" : "dd MMM yyyy"
        );
        setTreeData(tree);
        setCopyTreeData(tree);
        const columns = getColumns(
          testData?.data,
          testData?.date_aggregation === "monthly" ? "MMM yyyy" : "dd MMM yyyy",
          date_label
        );
        setColumnView(columns);
        const final_data = formatPageData(response, tree);
        setPageData(final_data);
        setAccountsDropdown(groupTableData(final_data, "dw_ids"));
        const getGraphData = createGraphData(final_data, date_label);
        setGraphData(getGraphData);
        setCopyGraphData(JSON.parse(JSON.stringify(getGraphData)));
        setIsPageLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsPageLoading(false);
    }
  };

  const clearGridFilter = () => {
    const filterData = clearAllFilters(applyFilter);
    if (accountsValue?.length > 0) {
      handleAccountFilter(accountsValue, [], []);
    } else {
      setTreeData(copyTreeData);
      setGraphData(copyGraphData);
    }
    setApplyFilter(filterData);
    setFilterApplied(false);
    setIsPageLoading(false);
  };

  const handleCostExpGridFilter = (applyifilters, isClear = false) => {
    applyifilters.map((item) =>
      item.column.label === "Accounts" ? item.value.sort((a, b) => a - b) : item
    );
    // Declare arrays to collect results
    let combinedFilteredDataArray = [];
    let combinedGraphDataArray = [];

    // Process each filter and collect results
    applyifilters.forEach((filterObj) => {
      const isAccountFilter = filterObj?.column?.label === "Accounts";

      // Get filtered data for table and graph, ensuring immutability
      const filteredDataForTable = getCostCenterFilteredData(
        applyifilters,
        _.cloneDeep(copyTreeData),
        isAccountFilter ? "data.dw_ids" : "data.name",
        isAccountFilter
      );
      const filteredDataForGraph = getCostCenterFilteredData(
        applyifilters,
        _.cloneDeep(copyGraphData),
        isAccountFilter ? "dw_ids" : "name",
        isAccountFilter
      );

      combinedFilteredDataArray.push(filteredDataForTable);
      combinedGraphDataArray.push(filteredDataForGraph);
    });

    const uniqGraphData = _.uniqBy(
      _.flattenDeep(combinedGraphDataArray),
      "name"
    );
    const uniqTableData = _.uniqBy(
      _.flattenDeep(combinedFilteredDataArray),
      "data.name"
    );

    const saveFilter = postFilter(applyifilters);
    setApplyFilter(saveFilter);

    if (accountsValue?.length > 0 && !isClear) {
      handleAccountFilter(accountsValue, uniqTableData, uniqGraphData);
    } else {
      setGraphData(uniqGraphData);
      setTreeData(uniqTableData);
    }
    setFilterApplied(true);
  };
  const getPageData = async (
    acc,
    start_date,
    end_date,
    date_grouping = "monthly",
    date_label
  ) => {
    try {
      const costCenters = await getCRDataAPI(
        acc?.id,
        start_date,
        end_date,
        date_grouping
      );

      if (costCenters && costCenters?.data) {
        await getAllcostcenters(costCenters, date_label);
        setRawData(costCenters);
        const value = costCenters?.date_aggregation;
        setDateIntervals({
          label: value.charAt(0).toUpperCase() + value.slice(1),
          value: value
        });
        setIsPageLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsPageLoading(false);
      resetData();
    }
  };
  const getAPIs = async (acc) => {
    if (acc) {
      try {
        let dateRangeOption = await getCRdateRangeDropdowns(acc?.id);
        if (dateRangeOption?.values) {
          setDateRangeAPIValues({
            start_date: dateRangeOption?.values?.start_date,
            end_date: dateRangeOption?.values?.end_date
          });

          let options = await getMonthsArray(
            dateRangeOption?.values?.start_date,
            dateRangeOption?.values?.end_date
          );
          options = await getDateRangeOptionsArray(
            options,
            dateRangeOption?.values?.end_date
          )?.reverse();

          const customOptions = [
            {
              label: "Last 3 months",
              value: options[3].value
            },
            {
              label: "Last 6 months",
              value: options[6].value
            },
            { label: "Last 1 year", value: options[options?.length - 1].value }
          ];

          options = options.slice(0, 4);
          options = [...options, ...customOptions];
          setDateRangeOptions(options);
          setDateRange(options[5]);
          const newDates = getStartAndEndDates(
            options?.[5]?.value,
            6,
            dateRangeOption?.values?.end_date
          );
          getPageData(
            acc,
            newDates?.startDate,
            newDates?.endDate,
            "monthly",
            options[5]?.label
          );
        }
      } catch (er) {
        console.log(er);
        setIsPageLoading(false);
        resetData();
      }
    }
  };
  const resetData = () => {
    setRawData([]);
    setPageData([]);
    setGraphData([]);
    setCopyGraphData([]);
    setTreeData(null);
  };
  useEffect(() => {
    setIsPageLoading(true);
    getAPIs(acc);
  }, [acc]);

  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
    })
  };

  const handlePopover = (e) => {
    // if (!hasTrialFallback && groupBy?.groupby_column) {
    //if (groupBy?.groupby_column) {
    e?.stopPropagation();
    setShowFilters(!showFilters);
    // }
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const handleExpand = (bool) => {
    setExpandModal(bool);
  };
  DropdownIndicator.propTypes = {
    isDisabled: PropTypes.bool
  };

  const handleDateRangeChange = (e, date_grouping = dateIntervals?.value) => {
    setIsPageLoading(true);
    setDateRange(e);
    let newDates;
    if (
      e.label === "Last 3 months" ||
      e.label === "Last 6 months" ||
      e.label === "Last 1 year"
    ) {
      if (e.label === "Last 1 year") {
        newDates = getStartAndEndDates(
          e?.value,
          13,
          dateRangeAPIValues?.end_date
        );
      } else if (e.label === "Last 6 months") {
        newDates = getStartAndEndDates(
          e?.value,
          6,
          dateRangeAPIValues?.end_date
        );
      } else if (e.label === "Last 3 months") {
        newDates = getStartAndEndDates(
          e?.value,
          3,
          dateRangeAPIValues?.end_date
        );
      }
    } else {
      newDates = getStartAndEndDates(e?.value, 1, dateRangeAPIValues?.end_date);
    }
    getPageData(
      acc,
      newDates?.startDate,
      newDates?.endDate,
      date_grouping,
      e?.label
    );
  };
  const handleTimeIntervalsChange = (e) => {
    setDateIntervals(e);
    handleDateRangeChange(dateRange, e?.value);
  };
  const downloadCSV = (csvString, filename) => {
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const handleExport = () => {
    const csvString = convertToCSV(treeData, columnView);
    downloadCSV(csvString, "Cost Trend.csv");
  };

  return (
    <>
      {expandModal && (
        <>
          <ExpandComponent
            altTable={true}
            treeData={treeData}
            tableClass={"custom-tree-table"}
            columnView={columnView}
            tableStyle={{
              scrollable: true,
              scrollHeight:
                getGridHeight(
                  flattenArray(treeData),
                  window?.innerHeight,
                  40,
                  180
                ) -
                100 +
                "px",
              resizableColumns: true,
              showGridlines: true,
              width: "100%"
            }}
            handleExpand={handleExpand}
            // hasTrialFallback={hasTrialFallback}
          />
        </>
      )}
      {!isPageLoading ? (
        <div className="cr-main-wrapper">
          <div className="cr-container">
            <div className="cr-header">
              <p>Cost Trend</p>
              <div className="date-tab-container">
                <div className="select-container">
                  <Select
                    value={dateRange}
                    options={dateRangeOptions}
                    styles={customStyles}
                    classNamePrefix="selectcategory"
                    placeholder="select"
                    isSearchable={false}
                    components={{ DropdownIndicator }}
                    onChange={(e) => handleDateRangeChange(e)}
                  />
                </div>
                <div className="select-container-dateIntervals">
                  <Select
                    value={dateIntervals}
                    options={timeIntervalOptions}
                    styles={customStyles}
                    classNamePrefix="selectcategory"
                    placeholder="select"
                    isSearchable={false}
                    components={{ DropdownIndicator }}
                    onChange={handleTimeIntervalsChange}
                  />
                </div>
              </div>
            </div>
            <div className="filter__row">
              <div className="filter-section align-self-start">
                <div className="select-container">
                  <Button
                    onClick={handlePopover}
                    className={
                      showFilters
                        ? "qg-filter-button-active "
                        : filterApplied
                        ? "qg-filter-active"
                        : "qg-filter-button"
                    }>
                    <img src={FilterIcon} alt="" />
                    <span>Filter</span>
                  </Button>

                  {showFilters && (
                    <CustomFilter
                      column={costCenterTableColumns}
                      gridData={pageData}
                      applyFilter={applyFilter}
                      setApplyFilter={setApplyFilter}
                      close={handlePopover}
                      handleFilter={(e) => handleCostExpGridFilter(e)}
                      clearFilter={clearGridFilter}
                      isMenuOpen={showFilters}
                      isMultiRowDisabled={false}
                    />
                  )}
                </div>
                <div className="account-filter-container">
                  {!showAccountFilter ? (
                    <Button
                      className={"wrapper-button"}
                      onClick={() => setShowAccountFilter(!showAccountFilter)}>
                      {accountsValue?.length === 0 ? (
                        <div className="placeholer-container">
                          <span> Account Name </span>
                          <img src={chev_down} />
                        </div>
                      ) : (
                        <>
                          {accountsValue?.length <= 2 ? (
                            <span className="chip-container">
                              <span className="chip-mid">
                                {accountsValue?.[0]}
                              </span>
                              {accountsValue?.length >= 2 && (
                                <span className="chip-mid">
                                  {accountsValue?.[1]}
                                </span>
                              )}
                              <img
                                className="dropdown-arrow"
                                src={chev_down}
                                alt=""
                              />
                            </span>
                          ) : (
                            <span className="chip-container">
                              <span className="chip">{accountsValue?.[0]}</span>
                              <span className="chip">{accountsValue?.[1]}</span>
                              <span className="chip-count">
                                +{accountsValue?.length - 2}
                              </span>
                              <img
                                className="dropdown-arrow"
                                src={chev_down}
                                alt=""
                              />
                            </span>
                          )}
                        </>
                      )}
                    </Button>
                  ) : (
                    <Multiselects
                      value={accountsValue}
                      options={accountsDropdown}
                      onHide={() => setShowAccountFilter(!showAccountFilter)}
                      saveButtonText={"Apply"}
                      onChange={(e) => handleAccountFilter(e)}
                    />
                  )}
                </div>
              </div>
              <div className="select-container export-container align-self-end">
                <div className="date-tab-container">
                  <div className="tabs-container ">{tabView}</div>
                </div>
                <Button
                  onClick={() => {
                    if (
                      !graphData ||
                      !graphData?.length ||
                      !treeData ||
                      !treeData?.length
                    )
                      return;
                    exportHTML(
                      graphData,
                      {
                        columns: columnView,
                        tableClass: "custom-tree-table",
                        data: treeData,
                        tableStyle: {
                          scrollable: true,
                          scrollHeight: "350px",
                          resizableColumns: true,
                          showGridlines: true,
                          width: "100%"
                        }
                      },
                      rawData
                    );
                  }}
                  className={"qg-filter-button ce-filter-button"}>
                  <img src={jpeg_export} alt="" />
                  <span>Export Report</span>
                </Button>
              </div>
            </div>
            {graphData?.length > 0 && (
              <div className="stack-graph-container">
                <StackGraphContainer
                  activeTab={activeTab}
                  dateRange={dateRange}
                  rawData={rawData}
                  graphData={graphData}
                  chartRef={chartRef}
                  chartTitle={"Cost Trend"}
                />
              </div>
            )}
            {pageData.length > 0 ? (
              <>
                <div className="row-end">
                  <Button
                    onClick={() => {
                      handleExpand(true);
                    }}
                    className={
                      "qg-filter-button expand__button align-self-end"
                    }>
                    <img src={expand} alt="" />
                    <span>Expand</span>
                  </Button>
                  <div className="select-container export-container">
                    <Button
                      onClick={handleExport}
                      className={"qg-filter-button"}>
                      <img src={ExportCSV} alt="" />
                      <span>Export</span>
                    </Button>
                  </div>
                </div>
                {treeData && columnView ? (
                  <TreeComponent
                    data={treeData}
                    tableClass={"custom-tree-table"}
                    columnView={columnView}
                    tableStyle={{
                      scrollable: true,
                      scrollHeight: "350px",
                      resizableColumns: true,
                      showGridlines: true,
                      width: "100%"
                    }}
                  />
                ) : null}
              </>
            ) : (
              <div className="grid-container">
                <EmptyState handleModal={handleModal} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="load">
          <div className="preload"></div>
        </div>
      )}
    </>
  );
}

CostReporting.propTypes = {
  acc: PropTypes.object,
  dwAccOptions: PropTypes?.array
};
export default CostReporting;
