import React from "react";
import { InputSwitch } from "primereact/inputswitch";
import PropTypes from "prop-types";
import "./botton.scss";

const ToggleSwitch = (props) => {
  const { checked, onChange, className, ...otherProps } = props;
  return (
    <InputSwitch
      className={` ${className}`}
      checked={checked}
      onChange={(e) => onChange(e)}
      {...otherProps}
    />
  );
};
ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};
export default ToggleSwitch;
