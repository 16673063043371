import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCardItem, getInstructionList } from "../../utils/cards-helper";
import success_blk from "../../assets/images/common/success_blk.png";
import expanded from "../../assets/images/common/expanded.svg";
import collapsed from "../../assets/images/common/collapsed.svg";
import copy from "../../assets/images/connection/copy1.svg";
import copied from "../../assets/images/connection/copied.svg";
import back_arrow from "../../assets/images/common/back_arrow.svg";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { getException } from "../../utils/exceptiondictionary";
import "./connectaccounts.scss";
import ConnectionForm from "../../components/ConnectionForm";
import { useToast } from "react-toast-wnm";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

import {
  completeDWConnection,
  getAppUserInfo,
  getRateSheet,
  setQM,
  setUpDW
} from "../../utils/user-helper";
import { useAuth } from "../../utils/auth-helper";
import {
  getSessionStorage,
  setSessinStorage
} from "../../utils/storage-helper";
import Select, { components } from "react-select";
import drpdwn from "../../assets/images/common/drpdwn_indicator.svg";
import external_link from "../../assets/images/common/external_link.svg";
import chat from "../../assets/images/connection/chat.svg";
import { getValueObj } from "../../utils/common-helper";
import DatabricksForm from "../../components/DatabricksForm";
import thnk_u from "../../assets/images/connection/thnk_u.svg";
import Sidebar from "../../components/Sidebar";

const ConnectAccounts = (props) => {
  const { isOnboarded = false } = props;
  const toast = useToast();
  const { setUserinfo, userInfo } = useAuth();

  const sfVersionOptions = [
    { label: "Standard", value: "Standard" },
    {
      label: "Enterprise",
      value: "Enterprise"
    }
  ];
  const [sfVersion, setSFVersion] = useState({
    value: "Standard",
    label: "Standard"
  });

  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={drpdwn} />
      </components.DropdownIndicator>
    );
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#0597F2",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px"
  };
  const params = useParams();
  const event_scheduled = getSessionStorage("event_scheduled");
  const navigate = useNavigate();
  const dbx_enabled = getSessionStorage("dbx_enabled");
  const [dwAccID, setDWAccID] = useState(
    params?.dwacc !== undefined && params?.dwacc !== null ? params?.dwacc : null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [textCopied, setTextCopied] = useState(false);
  const [Step4TextCopied, setStep4TextCopied] = useState(false);
  const [rateStatus, setRateStatus] = useState(
    "We never directly access your organization’s data."
  );
  const [activeStep, setActiveStep] = useState(
    params?.step === "step_3" ? "Step 3" : "Step 1"
  );
  const [activeDbxStep] = useState(
    dbx_enabled?.toString() === "true" ? "Step 2" : "Step 1"
  );
  const [faq1Collapse, setFaq1Collapse] = useState(true);
  const [faq2Collapse, setFaq2Collapse] = useState(true);
  const [faq3Collapse, setFaq3Collapse] = useState(true);
  const [computeCost, setComputeCost] = useState("2");
  const [storageCost, setStorageCost] = useState("23");
  const [creditRate, setCreditRate] = useState(null);
  const [creditRateDisabled, setCreditRateDisabled] = useState(false);
  const [stepComplete, setStepComplete] = useState(
    params?.step === "step_3"
      ? {
          step_1: true,
          step_2: true,
          step_3: false,
          step_4: false
        }
      : {
          step_1: false,
          step_2: false,
          step_3: false,
          step_4: false
        }
  );
  const [dbxStepComplete, setDbxStepComplete] = useState(
    dbx_enabled?.toString() === "true" || event_scheduled?.toString() === "true"
      ? {
          step_1: true,
          step_2: false
        }
      : {
          step_1: false,
          step_2: false
        }
  );
  const [infoError, setInfoError] = useState({
    computeCost: "",
    storageCost: "",
    sfVersion: ""
  });

  const connection = getCardItem(params?.acc);
  const integrations = "/selectconnection";
  const setUpRateSheet = async (id) => {
    const creditRate = await getRateSheet(id);
    setCreditRate(creditRate);
    if (creditRate) {
      const version = getValueObj([creditRate?.edition]);
      setComputeCost(creditRate?.compute_rate.toString());
      setStorageCost(creditRate?.storage_rate.toString());
      setSFVersion(
        version && version?.length ? version[0] : sfVersionOptions[0]
      );
      setCreditRateDisabled(true);
      setRateStatus(
        "Billing rates below are fetched from your Snowflake account. Press “Complete Account Setup” to finish account setup."
      );
    } else {
      setComputeCost("2");
      setStorageCost("23");
      setSFVersion(sfVersionOptions[0]);
      setCreditRateDisabled(false);
      setRateStatus(
        "Confirm your Snowflake billing rates to proceed with account setup."
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (dwAccID !== null && dwAccID !== undefined) {
      setIsLoading(true);
      setUpRateSheet(dwAccID);
    }
  }, []);

  const docsList = [
    // { label: "Documentation", to: "", img: documentation },
    {
      label: "Chat with our team on Slack",
      to: "https://join.slack.com/t/chaosgenius/shared_invite/zt-140042uac-rrm~xbx9o_aydi6PTmp_Mg",
      img: chat
    }
  ];
  const {
    codeInstructionList,
    fullCodeInstructionText,
    step4CodeInstructionList,
    Step4FullCodeInstructionText
  } = getInstructionList();
  const featureList = [
    {
      id: "1",
      dangerouslySetHTML: true,
      question:
        connection?.name?.toLowerCase() === "databricks"
          ? "What level of access does ChaosGenius need within my Databricks account?"
          : "Will Chaos Genius have access to underlying data in the warehouse?",
      ans:
        connection?.name?.toLowerCase() === "databricks"
          ? "<p>Chaos Genius only needs read access to your Databricks account database. This database contains details about how the customer employs the account, but it doesn’t store any actual customer data or sensitive information. </p><p>For more information, consult our <a style='text-decoration: none; color:#0597F2;' href='https://www.chaosgenius.io/security.html' target='_blank'>security documentation</a> or get in touch with us at <a style='text-decoration: none; color:#0597F2;' href='mailto:founders@chaosgenius.io'target='_blank'> security@chaosgenius.io</a></p> "
          : "<p>We never directly access your organization’s data and only work with usage & metadata to analyze, generate insights & optimize usage.</p> <p>CG workflow & processes ensure that no PII or sensitive data in your usage & metadata is shared.</p>",
      collapsed: faq1Collapse ? true : false
    },
    {
      id: "2",
      dangerouslySetHTML: true,
      collapsed: faq2Collapse ? true : false,
      hidden: connection?.name?.toLowerCase() === "databricks" ? true : false,
      question: "How will PII handling be taken care of?",
      ans:
        connection?.name?.toLowerCase() === "databricks"
          ? `<p>CG does not require access to any PII information for performing analysis and generating cost savings recommendations.</p><p>If underlying Usage tables have PII data, CG suggests to mask the data`
          : `<p>CG does not require access to any PII information for performing analysis and generating cost savings recommendations.</p><p>If underlying Usage tables have PII data, CG suggests the following as two levels of measures to prevent access to PII.</p><div class='faq'><p>&#8226;</p><p> <span style='color: #222222;'>Level 1:</span> As a default, we suggest that all columns that have PII or sensitive data be masked with <a style='text-decoration: none; color:#0597F2;' href='https://docs.snowflake.com/en/user-guide/security-column-ddm-use.html' target='_blank'>Dynamic Data Masking <img src=${external_link} /></a>. </p></div><div class='faq'><p>&#8226;</p> <p><span style='color: #222222;'>Level 2:</span> As a safeguard measure we also run PII identifiers on all query texts and mask the data before ingesting it into any of our analysis.</p></div>`
    },
    // {
    //   id: "3",
    //   dangerouslySetHTML: true,
    //   collapsed: faq3Collapse ? true : false,
    //   question:
    //     "How to ensure our Confidentiality agreements with our respective customers are not breached?",
    //   ans: "<p>Chaos Genius does not get access to any sensitive data or confidential information.</p><p>In case some table's data falls within confidentiality obligations, the access to those tables in ACCOUNT_USAGE schema can be selectively blocked.</p><p>In addition, before the onset of the engagement we will be sharing & signing a confidentiality agreement with you.</p>"
    // },
    {
      id: "3",
      dangerouslySetHTML: true,
      hidden: connection?.name?.toLowerCase() === "databricks" ? true : false,
      collapsed: faq3Collapse ? true : false,
      question: "I do not know my Snowflake Billing rates. (Step 3)",
      ans: "<p>Your Snowflake billing rates are auto-fetched from your Snowflake account.</p><p> In case the billing rates are not available, you might be asked to fill in your billing rates.</p><p> If you do not know the billing rates, Chaos Genius will use the standard billing rates for your region to setup your account.</p><p> You can edit the rates later via Organization Settings.</p>"
    }
  ];
  const changeActiveStep = (step) => {
    if (step) {
      setActiveStep(step);
    } else {
      setActiveStep("Step 1");
    }
  };
  const stepContainer = [
    {
      label: "Step 1",
      id: "1",
      value: "Step 1",
      visible: stepComplete?.step_1 ? false : true
    },
    {
      label: "Step 2",
      id: "2",
      value: "Step 2",
      visible: stepComplete?.step_2 ? false : true
    },
    {
      label: "Step 3",
      id: "3",
      value: "Step 3",
      visible: stepComplete?.step_3 ? false : true
    },
    {
      label: "Step 4",
      id: "4",
      value: "Step 4",
      visible: stepComplete?.step_4 ? false : true
    }
  ];
  const dbxSteps = [
    {
      label: "Step 1",
      id: "1",
      value: "Step 1",
      visible: dbxStepComplete?.step_1 ? false : true
    },
    {
      label: "Step 2",
      id: "2",
      value: "Step 2",
      visible: dbxStepComplete?.step_2 ? false : true
    }
  ];

  const dbxStepView = dbxSteps?.map((step) => {
    return (
      <div className="step" key={step?.id} onClick={step?.handleClick}>
        <div
          className={`step-divider dbx__divider ${
            activeDbxStep === step?.label ? "active" : ""
          }`}></div>
        {step?.visible ? (
          <div className="form-check check-box">
            <input
              className="form-check-input"
              type="radio"
              id={step.id}
              name={step.label}
              checked={activeDbxStep === step?.value ? true : false}
              readOnly={true}
              //onChange={(e) => onChangeFilter(e)}
            />
            <label
              className={`form-check-label ${
                activeDbxStep === step?.label ? "active" : ""
              }`}
              htmlFor={step?.value}>
              {step?.label}
            </label>
          </div>
        ) : null}
        {!step?.visible ? (
          <div className="success_container">
            <div className="success_img">
              <img src={success_blk} />
            </div>
            <div className="success-label">
              <span>{step?.label}</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  });

  const stepView = stepContainer?.map((step) => {
    return (
      <div className="step" key={step?.id} onClick={step?.handleClick}>
        <div
          className={`step-divider ${
            activeStep === step?.label ? "active" : ""
          }`}></div>
        {step?.visible ? (
          <div className="form-check check-box">
            <input
              className="form-check-input"
              type="radio"
              id={step.id}
              name={step.label}
              checked={activeStep === step?.value ? true : false}
              readOnly={true}
              //onChange={(e) => onChangeFilter(e)}
            />
            <label
              className={`form-check-label ${
                activeStep === step?.label ? "active" : ""
              }`}
              htmlFor={step?.value}>
              {step?.label}
            </label>
          </div>
        ) : null}
        {!step?.visible ? (
          <div className="success_container">
            <div className="success_img">
              <img src={success_blk} />
            </div>
            <div className="success-label">
              <span>{step?.label}</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  });
  const docslinks = docsList?.map((doc, index) => {
    return (
      <a
        className="doc"
        key={index}
        href={doc.to}
        target={"_blank"}
        rel="noreferrer">
        <div className="img-container">
          <img src={doc.img} />
        </div>
        <div className="text">
          <span>{doc.label}</span>
        </div>
      </a>
    );
  });
  const features = featureList?.map((feature) => {
    return (
      <div
        className={`feature-block ${feature?.hidden ? "hidden" : ""}`}
        key={feature?.id}>
        <div className="qs-container">
          <div className="qs-parent">
            <span>{feature?.question}</span>
          </div>
          <div
            className="qs-collapse"
            onClick={() => {
              if (feature?.id === "1") {
                setFaq1Collapse(!faq1Collapse);
              } else if (feature?.id === "2") {
                setFaq2Collapse(!faq2Collapse);
              } else if (feature?.id === "3") {
                setFaq3Collapse(!faq3Collapse);
              }
            }}>
            {feature?.collapsed ? (
              <img src={collapsed} />
            ) : (
              <img src={expanded} />
            )}
          </div>
        </div>
        {!feature?.collapsed && (
          <div className="ans-container">
            {!feature?.collapsed ? (
              feature?.dangerouslySetHTML ? (
                <div
                  className="ans-text"
                  dangerouslySetInnerHTML={{ __html: feature?.ans }}></div>
              ) : (
                <span>{feature?.ans}</span>
              )
            ) : null}
          </div>
        )}
      </div>
    );
  });
  const copyInstruction = () => {
    navigator.clipboard.writeText(fullCodeInstructionText);
    if (!textCopied) {
      setTextCopied(true);
    }
    customToast({ type: "success", ...getException("TextCopySuccessful") });
  };
  const copyStep4Instruction = () => {
    navigator.clipboard.writeText(Step4FullCodeInstructionText);
    if (!Step4TextCopied) {
      setStep4TextCopied(true);
    }
    customToast({ type: "success", ...getException("TextCopySuccessful") });
  };
  const handleBackClick = () => {
    if (activeStep !== "Step 1") {
      if (activeStep === "Step 2") {
        changeActiveStep("Step 1");
        setStepComplete({
          ...stepComplete,
          step_1: false,
          step_2: false,
          step_3: false
        });
      } else if (activeStep === "Step 3") {
        changeActiveStep("Step 2");
        setStepComplete({
          ...stepComplete,
          step_2: false,
          step_3: false
        });
        setIsModalOpen(true);
      }
    } else if (activeStep === "Step 1") {
      navigateTo(-1);
    }
  };
  const completeConnection = async (
    computeCost,
    storageCost,
    sfVersion,
    dwAccID
  ) => {
    const params = { computeCost, storageCost, sfVersion };
    try {
      if (creditRate === null) {
        const status = await completeDWConnection(dwAccID, params, customToast);
        if (status) {
          changeIsLoading(false);
          customToast({ type: "success", ...getException("ACCOUNTCREATED") });
          changeActiveStep("Step 4");
          setStepComplete({
            ...stepComplete,
            step_1: true,
            step_2: true,
            step_3: true
          });
          //navigateTo(`/dashboard/connectionfallback`);
        } else {
          changeIsLoading(false);
        }
      } else {
        changeIsLoading(false);
        // customToast({ type: "success", ...getException("ACCOUNTCREATED") });
        //navigateTo(`/dashboard/connectionfallback`);
        changeActiveStep("Step 4");
        setStepComplete({
          ...stepComplete,
          step_1: true,
          step_2: true,
          step_3: true
        });
      }
    } catch (err) {
      customToast({
        type: "error",
        ...getException("ACCOUNTCREATIONFAILURE")
      });
      changeIsLoading(false);
    }
  };
  const handleAccCompletion = () => {
    let errorObj = { ...infoError };
    if (computeCost.trim() === "") {
      errorObj["computeCost"] = "Please enter the compute cost";
    }
    if (storageCost.trim() === "") {
      errorObj["storageCost"] = "Please enter the storage cost";
    }
    if (sfVersion?.value.trim() === "") {
      errorObj["sfVersion"] = "Please enter snowflake version";
    }
    setInfoError(errorObj);
    if (
      errorObj.computeCost === "" &&
      errorObj.storageCost === "" &&
      errorObj.sfVersion === "" &&
      dwAccID
    ) {
      changeIsLoading(true);
      completeConnection(
        computeCost.trim(),
        storageCost.trim(),
        sfVersion?.value.trim(),
        dwAccID
      );
    }
  };
  const getStep4Instruction = () => {
    return (
      <div className="code-instruction-parent">
        <div className="code-instruction">
          {step4CodeInstructionList.map((instr) => {
            return (
              <div className="code" key={instr.index}>
                {/* <p>{instr.label}</p> */}
                <p dangerouslySetInnerHTML={{ __html: instr.label }}></p>
              </div>
            );
          })}
        </div>
        <div
          className="copy-image fixed"
          onClick={() => {
            copyStep4Instruction();
          }}>
          {!Step4TextCopied && <img className="copy" src={copy} />}
          {Step4TextCopied && <img className="copied" src={copied} />}
        </div>
      </div>
    );
  };
  useCalendlyEventListener({
    onEventScheduled: (e) => {
      console.log(e);
      setSessinStorage("event_scheduled", true);
      setDbxStepComplete({ ...stepComplete, step_1: true, step_2: false });
    }
  });
  const getInstruction = () => {
    return (
      <div className="code-instruction-parent">
        <div className="code-instruction">
          {codeInstructionList.map((instr) => {
            return (
              <div className="code" key={instr.index}>
                {/* <p>{instr.label}</p> */}
                <p dangerouslySetInnerHTML={{ __html: instr.label }}></p>
              </div>
            );
          })}
        </div>
        <div
          className="copy-image"
          onClick={() => {
            copyInstruction();
          }}>
          {!textCopied && <img className="copy" src={copy} />}
          {textCopied && <img className="copied" src={copied} />}
        </div>
      </div>
    );
  };

  const handleClick = () => {
    changeActiveStep("Step 2");
    setStepComplete({ ...stepComplete, step_1: true });
    setIsModalOpen(true);
  };
  const handleConfirm = async (status) => {
    try {
      const qmstatus = await setQM(
        {
          data_wh_id: dwAccID ? dwAccID : "",
          status: status
        },
        customToast
      );
      if (qmstatus) {
        setIsLoading(false);
        navigateTo(`/dashboard/connectionfallback`);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      customToast({
        type: "error",
        ...getException("ACCOUNTCREATIONFAILURE")
      });
      setIsLoading(false);
    }
  };
  const closeModal = (status) => {
    if (status) {
      changeActiveStep("Step 1");
      setStepComplete({
        ...stepComplete,
        step_1: false,
        step_2: false,
        step_3: false
      });
    }
    setIsModalOpen(false);
  };
  const changeIsLoading = (b) => {
    setIsLoading(b);
  };
  const navigateTo = (path) => {
    navigate(path);
  };
  const setupConnectionDBT = async (fields) => {
    try {
      const dw = await setUpDW(fields, customToast, connection);
      if (dw) {
        customToast({ type: "success", ...getException("ACCOUNTCREATED") });
        changeIsLoading(false);
        navigateTo(`/dashboard/`);
      } else {
        setDWAccID(null);
        changeIsLoading(false);
      }
    } catch (error) {
      setDWAccID(null);
      console.log(error);
      customToast({
        type: "error",
        ...getException("ACCOUNTCREATIONFAILURE")
      });
      changeIsLoading(false);
    }
  };
  const setupConnection = async (fields) => {
    try {
      const dw = await setUpDW(fields, customToast, connection);
      if (dw) {
        if (dw?.data_warehouse_info_id?.toString() !== dwAccID?.toString()) {
          setDWAccID(dw?.data_warehouse_info_id);
        }
        customToast({ type: "success", ...getException("ACCOUNTCREATED") });
        //navigateTo(`/dashboard/connectionfallback`);

        const info = await getAppUserInfo(customToast);
        if (info) {
          const { num_data_wh_acc } = info;
          setSessinStorage("num_data_wh_acc", num_data_wh_acc);
          setUserinfo({
            ...userInfo,
            info: {
              ...userInfo.info,
              num_data_wh_acc
            }
          });
        }

        const creditRate = await getRateSheet(dw?.data_warehouse_info_id);
        setCreditRate(creditRate);
        if (creditRate) {
          const version = getValueObj([creditRate?.edition]);
          setComputeCost(creditRate?.compute_rate.toString());
          setStorageCost(creditRate?.storage_rate.toString());
          setSFVersion(
            version && version?.length ? version[0] : sfVersionOptions[0]
          );
          setCreditRateDisabled(true);
          setRateStatus(
            "Billing rates below are fetched from your Snowflake account. Press “Complete Account Setup” to finish account setup."
          );
        } else {
          setComputeCost("2");
          setStorageCost("23");
          setSFVersion(sfVersionOptions[0]);
          setCreditRateDisabled(false);
          setRateStatus(
            "Confirm your Snowflake billing rates to proceed with account setup."
          );
        }
        changeIsLoading(false);
        changeActiveStep("Step 3");
        setStepComplete({ ...stepComplete, step_2: true });
      } else {
        setDWAccID(null);
        changeIsLoading(false);
        changeActiveStep("Step 1");
        setStepComplete({
          ...stepComplete,
          step_1: false,
          step_2: false,
          step_3: false
        });
        // changeActiveStep("Step 3");
        // setStepComplete({ ...stepComplete, step_2: true });
      }
    } catch (error) {
      setDWAccID(null);
      console.log(error);
      customToast({
        type: "error",
        ...getException("ACCOUNTCREATIONFAILURE")
      });
      changeActiveStep("Step 1");
      setStepComplete({
        ...stepComplete,
        step_1: false,
        step_2: false,
        step_3: false
      });
      changeIsLoading(false);
    }
  };
  // const onDBTSubmit = (fields) => {
  //   console.log(fields);
  // };
  const closeDatabricksModal = () => {};
  return (
    <div className="app-container flex-row select-connections-container">
      {isModalOpen && (
        <ConnectionForm
          connection={connection}
          closeModal={closeModal}
          setupConnection={setupConnection}
          changeIsLoading={changeIsLoading}
        />
      )}
      {!isOnboarded && (
        <Sidebar
          visible={true}
          disableversionmargin={true}
        />
      )}
      <div
        className={`accounts-container ${
          connection?.name?.toLowerCase() === "snowflake"
            ? ""
            : "dbx__accounts-container"
        }`}>
        <div className="account-info">
          <div className="link-container">
            <Link
              to={integrations}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              style={linkStyle}>
              Integrations
            </Link>
            <span>{` / ${connection?.name}`}</span>
          </div>
          <div className="header-container">
            <div className="back_btn" onClick={handleBackClick}>
              <img
                src={back_arrow}
                alt="SF Optimization Onboarding back button"
              />
            </div>
            <div className="header">
              <span>Connect {connection?.name} Account</span>
            </div>
          </div>
          {connection?.name?.toLowerCase() === "snowflake" ? (
            <>
              <div className="steps-container">{stepView}</div>
              {activeStep === "Step 1" || activeStep === "Step 2" ? (
                <div className="info-header">
                  <div className="hdr">
                    <span>Create CHAOS GENIUS user using script below</span>
                  </div>
                  <div className="info-subtitle">
                    <span>
                      {" "}
                      (Note: please do not modify the script except password){" "}
                    </span>
                  </div>
                </div>
              ) : activeStep === "Step 3" ? (
                <div className="info-header">
                  <div className="hdr step3">
                    <span>Confirm Snowflake billing rates</span>
                  </div>
                  <div className="info-subtitle step3">
                    <span>{rateStatus}</span>
                  </div>
                </div>
              ) : (
                <div className="info-header">
                  <div className="hdr">
                    <span>
                      (Optional) Additional permissions for Query
                      Recommendations
                    </span>
                  </div>
                  <div className="info-subtitle">
                    <span>
                      {" "}
                      (Note: please do not modify the script except password){" "}
                    </span>
                  </div>
                </div>
              )}
              {activeStep === "Step 3" ? (
                <>
                  <div className="contract-container">
                    <div className="credit">
                      <div className="credit-container">
                        <span>Compute Cost per Credit </span>
                        <span style={{ marginLeft: 50 }}>= </span>
                        <input
                          type="text"
                          value={computeCost}
                          disabled={creditRateDisabled}
                          onChange={(e) => {
                            if (infoError["computeCost"] !== "") {
                              setInfoError({ ...infoError, computeCost: "" });
                            }
                            const re = /^[0-9.\b]+$/;
                            const dec = /^\d*\.?\d*$/;

                            if (
                              e.target.value === "" ||
                              (re.test(e.target.value) &&
                                dec.test(e.target.value))
                            ) {
                              setComputeCost(e.target.value);
                            }
                          }}
                        />
                        <span>USD</span>
                      </div>
                      {infoError.computeCost !== "" ? (
                        <div className="connection__fail">
                          <p>{infoError.computeCost}</p>
                        </div>
                      ) : null}
                    </div>
                    <div className="seperator" />
                    <div className="credit">
                      <div className="credit-container">
                        <span>
                          Storage Cost per TB
                          <span className="credit-subtext">(Monthly) </span>
                        </span>
                        <span style={{ marginLeft: 20 }}>= </span>
                        <input
                          type="text"
                          value={storageCost}
                          disabled={creditRateDisabled}
                          onChange={(e) => {
                            if (infoError["storageCost"] !== "") {
                              setInfoError({ ...infoError, storageCost: "" });
                            }
                            const re = /^[0-9.\b]+$/;
                            const dec = /^\d*\.?\d*$/;
                            if (
                              e.target.value === "" ||
                              (re.test(e.target.value) &&
                                dec.test(e.target.value))
                            ) {
                              setStorageCost(e.target.value);
                            }
                          }}
                        />
                        <span>USD</span>
                      </div>
                      {infoError.storageCost !== "" ? (
                        <div className="connection__fail">
                          <p>{infoError.storageCost}</p>
                        </div>
                      ) : null}
                    </div>
                    <div className="seperator" />
                    <div className="credit">
                      <div className="credit-container">
                        <span>Snowflake Edition</span>
                        <span style={{ marginLeft: 20 }}></span>
                        <div className="select-container">
                          <Select
                            value={sfVersion}
                            options={sfVersionOptions}
                            styles={customStyles}
                            isDisabled={creditRateDisabled}
                            components={{ DropdownIndicator }}
                            classNamePrefix="selectcategory"
                            placeholder="select"
                            isSearchable={false}
                            onChange={(e) => {
                              setSFVersion(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-container finalsetup">
                    <button
                      className="btn btn-dark"
                      onClick={handleAccCompletion}>
                      Complete Account Setup
                    </button>
                  </div>
                </>
              ) : activeStep === "Step 1" || activeStep === "Step 2" ? (
                <>
                  <div className="info-container">
                    <div className="info">
                      <span>
                        Run this SQL Installation script to create the required
                        resources for Chaos Genius connection
                      </span>
                    </div>
                  </div>
                  <div className="instruction-container">
                    {getInstruction()}
                  </div>
                  <div className="btn-container">
                    <button className="btn btn-dark" onClick={handleClick}>
                      Setup Account
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="info-container">
                    <div className="info">
                      <span>
                        Run this SQL Installation script to create the required
                        resources for Chaos Genius connection
                      </span>
                    </div>
                  </div>
                  <div className="instruction-container">
                    {getStep4Instruction()}
                  </div>
                  <div className="btn-container">
                    <button
                      className="btn btn-dark"
                      onClick={() => {
                        setIsLoading(true);
                        handleConfirm("enable");
                      }}>
                      Confirm
                    </button>
                    <button
                      className="btn btn-outline-dark"
                      onClick={() => {
                        setIsLoading(true);
                        handleConfirm("disable");
                      }}>
                      Skip
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="steps-container dbx__container">
                {dbxStepView}
              </div>
              <span className="demo__header">
                Schedule a demo to get started with Databricks Cost Optimization
              </span>
              <div className="dbks__form__container">
                {dbx_enabled?.toString() === "true" &&
                activeDbxStep === "Step 2" ? (
                  <DatabricksForm
                    closeModal={closeDatabricksModal}
                    connection={connection}
                    setupConnection={setupConnectionDBT}
                    changeIsLoading={changeIsLoading}
                  />
                ) : activeDbxStep === "Step 1" &&
                  event_scheduled?.toString() !== "true" ? (
                  <InlineWidget
                    url="https://calendly.com/sahan-chaosgenius/30min"
                    styles={{
                      height: "100%"
                    }}
                  />
                ) : (
                  <div className="thank__u">
                    <img src={thnk_u} />
                    <div className="th__header">
                      <span>Thank You</span>
                    </div>
                    <div className="th__content">
                      <span>
                        We will get in touch shortly. Step 2 will be enabled
                        post the Product Demo
                      </span>
                    </div>
                  </div>
                )}
                {/* <DatabricksForm
                  closeModal={closeDatabricksModal}
                  connection={connection}
                  setupConnection={setupConnectionDBT}
                  changeIsLoading={changeIsLoading}
                /> */}
              </div>
            </>
          )}
          {/* <div className="line"></div> */}
          {/* <div className="docs-link-container">{docslinks}</div> */}
        </div>
        <div className="features-info">
          <div className="faq-header">
            <span>Support</span>
            <div className="docs-link-container">{docslinks}</div>
          </div>
          <div className="faq-header faq__text__div">
            <div className="docs-link-container">
              <span>FAQs</span>
            </div>
          </div>
          <div className="features-parent">{features}</div>
        </div>
      </div>
      {isLoading && (
        <div className="load">
          <div className="preload"></div>
        </div>
      )}
    </div>
  );
};
ConnectAccounts.propTypes = {
  isOnboarded: PropTypes.bool
};
export default ConnectAccounts;
