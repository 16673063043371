import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import PropTypes from "prop-types";
function StackGraph({ options, chartRef }) {
  return (
    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
  );
}
StackGraph.propTypes = {
  options: PropTypes.object,
  chartRef: PropTypes.object
};

export default StackGraph;
