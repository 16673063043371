import { CustomTooltip } from "../../../utils/tooltip-helper";
import { roundToSigDigits } from "../../../utils/common-helper";

export const getClusterInventoryProps = (hasTrialFallback) => {
  const customProps = {
    "S No.": {
      type: "rightAligned",
      width: 75,
      pinned: "left",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return params?.data["S No."];
      }
    },
    "Cluster Name": {
      width: 247,
      filter: true,
      pinned: "left",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    "Cost ($)": {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Total Instances": {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    DBUs: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    SKU: {
      width: 200,
      headerName: "Cluster SKU",
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Owned By": {
      width: 200,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Driver Node Type": {
      width: 160,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Worker Node Type": {
      width: 180,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Worker Count": {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Min Autoscale Workers": {
      width: 170,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Max Autoscale Workers": {
      width: 170,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false, false);
      }
    },
    "Auto Termination Minutes": {
      width: 170,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false, false);
      }
    },
    "Enable Elastic disk": {
      width: 170,
      cellClass: "grid-cell-left-aligned"
    },
    "DBR Version": {
      width: 155,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Spot Bid Price Percent": {
      width: 200,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false, false);
      }
    },
    "Cluster source": {
      width: 150,
      cellClass: "grid-cell-left-aligned"
    },
    "Is Autoscale": {
      width: 150,
      cellClass: "grid-cell-left-aligned"
    },
    "Workspace ID": {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right"
    },
    "Cluster ID": {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      resizable: false,
      sortable: false,
      //pinned: "right",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    }
  };

  return customProps;
};
