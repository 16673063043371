import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./dbxclustersinventory.scss";
import { getSessionStorage } from "../../../utils/storage-helper";
import { getClusterInventoryProps } from "./clusterinventorycoldefs";
import Select, { components } from "react-select";
import chev_down from "../../../assets/images/common/chev_down.svg";
import { exportCSVcolumns } from "../../../utils/filter-helper";
import Button from "../../../components/customElements/Button";
import { DateTime } from "luxon";
import {
  createColDefs,
  getAutoheightOverflow,
  getGridHeight,
  handleGridResolution
} from "../../../utils/common-helper";
import { getException } from "../../../utils/exceptiondictionary";
import ExpandComponent from "../../../components/ExpandComponent";
import ExportCSV from "../../../assets/images/grid/exportcsv.svg";
import expand from "../../../assets/images/common/expand.svg";
import GridComponent from "../../../components/GridComponent";
import DataCards from "../../../components/OrganizationSummary/components/DataCards";
import { getMonthRange, getMonthsArray } from "../../../utils/date-helper";
import {
  getCIData,
  getCIDateRanges,
  getCITotals
} from "../../../utils/compute-helper";

const DBXClustersInventory = ({ acc, changeActiveModule }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };
  const [hasTrialFallback] = useState(
    getSessionStorage("plan_name").toLowerCase() === "trial" ? true : false
  );
  const customProps = getClusterInventoryProps(hasTrialFallback);
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const univerSalStyle = {
    cellStyle: (params) => {
      if (hasTrialFallback && params.rowIndex > 1) {
        return {
          userSelect: "none",
          "-webkit-user-select": "none"
        };
      }
      return {};
    }
  };
  const [gridData, setGridData] = useState(null); // Set gridData to
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [gridFallback, setGridFallback] = useState(false);
  const [expandModal, setExpandModal] = useState(false);
  const [gridTotalWidth, setGridTotalWidth] = useState(null);
  const [activeTab, setActiveTab] = useState("all_purpose");
  const [totals, setTotals] = useState(undefined);
  const [noRowsText, setNoRowsText] = useState(
    "Clusters Inventory for the selected period or account are currently unavailable. Coming soon..."
  );
  const [requiredCols, setRequiredCols] = useState([]);
  const [gridResolution, setGridResolution] = useState(true);

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([]);
  const [downloadCSV, setDownloadCSV] = useState(false);
  const handleExpand = (bool) => {
    setExpandModal(bool);
  };
  const getTableData = async (dateRange, activeTab) => {
    try {
      if (
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        acc &&
        Object.keys(acc).length > 0
      ) {
        const [data, totals] = await Promise.all([
          getCIData({
            acc: acc,
            start_date: dateRange?.value?.start_date,
            end_date: dateRange?.value?.end_date,
            cluster_type: activeTab
          }),
          getCITotals({
            acc: acc,
            start_date: dateRange?.value?.start_date,
            end_date: dateRange?.value?.end_date,
            cluster_type: activeTab
          })
        ]);
        if (data) {
          const colDefsArr = createColDefs(
            data?.columns,
            customProps,
            univerSalStyle,
            data?.column_display_name_mappping
          );
          if (colDefsArr && data?.data) {
            const newData = colDefsArr.map((colDef) => ({
              ...colDef,
              unSortIcon: true,
              sortable: colDef?.sortable,
              filter: false
            }));
            setColumnDefs(newData);
            const requiredColumns = exportCSVcolumns(newData);
            setRequiredCols(requiredColumns);
            setGridData(data?.data);
            let screenInnerWidth = window.innerWidth;
            let gridWidth = Object.values(newData).reduce((sum, prop) => {
              if (typeof prop.width === "number" && !isNaN(prop.width)) {
                return sum + prop.width;
              }
              return sum;
            }, 0);
            setGridResolution(
              handleGridResolution(gridWidth, screenInnerWidth, 360)
            );
            setGridTotalWidth(gridWidth);
          }
          setTotals({
            active_clusters: totals?.values?.active_clusters,
            total_cost: totals?.values?.total_cost,
            total_dbus: totals?.values?.total_dbus
          });
        } else {
          clearData();
        }
        setGridLoading(false);
        if (gridFallback) {
          setGridFallback(false);
        }
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      const message = getException(err?.response?.data?.errorKey);

      if (
        !gridFallback &&
        err?.response?.data?.errorKey !== "USER_PLAN_NOT_ALLOWED"
      ) {
        setNoRowsText(
          "Clusters Inventory for the selected period or account are currently unavailable. Coming soon..."
        );
        setGridFallback(true);
      } else if (err?.response?.data?.errorKey === "USER_PLAN_NOT_ALLOWED") {
        setGridFallback(true);
        setNoRowsText(message?.description);
      }
      console.log(err);
    }
  };

  const tabs = [
    { id: "all_purpose", label: "All Purpose Clusters" },
    { id: "job", label: "Job Clusters" }
  ];
  const tabView =
    tabs &&
    tabs.map((tab) => {
      return (
        <div
          className={`tab ${tab?.id === activeTab ? "active" : "normal-btn"}`}
          key={tab?.id}
          onClick={() => {
            setGridLoading(true);
            setActiveTab(tab?.id);
            getTableData(dateRange, tab?.id);
          }}>
          <span className="title">{tab?.label}</span>
        </div>
      );
    });
  const clearData = () => {
    setGridData([]);
  };

  const getData = async () => {
    let dateRangeArr = [];
    try {
      const dateRangeObj = await getCIDateRanges(acc);
      if (dateRangeObj?.values) {
        dateRangeArr = getMonthsArray(
          dateRangeObj?.values?.start_date,
          dateRangeObj?.values?.end_date
        )
          .reverse()
          .map((item) => {
            let value = getMonthRange(item, dateRangeObj?.values?.end_date);
            const startDate = DateTime.fromISO(value?.startDate);
            const endDate = DateTime.fromISO(value?.endDate);
            return {
              label: value?.mtd
                ? `${startDate.toFormat("dd")} - ${endDate
                    .minus({ days: 1 })
                    .toFormat("dd LLL yyyy")} (MTD)`
                : `${DateTime.fromISO(item).toFormat("LLL yyyy")}`,
              value: {
                start_date: startDate.toFormat("yyyy-MM-dd"),
                end_date: endDate.toFormat("yyyy-MM-dd")
              },
              mtd: value?.mtd
            };
          });
        dateRangeArr.push({
          label: "Last 3 Months",
          show_range: true,
          value: {
            start_date: DateTime.fromISO(dateRangeObj?.values?.end_date)
              .set({
                day: 1
              })
              .minus({ months: 3 })
              .toFormat("yyyy-MM-dd"),
            end_date: dateRangeObj?.values?.end_date
          },
          mtd: true
        });
        dateRangeArr.push({
          label: "Last 6 Months",
          show_range: true,
          value: {
            start_date: DateTime.fromISO(dateRangeObj?.values?.end_date)
              .set({
                day: 1
              })
              .minus({ months: 6 })
              .toFormat("yyyy-MM-dd"),
            end_date: dateRangeObj?.values?.end_date
          },
          mtd: true
        });
        setDateRangeOptions(dateRangeArr);
        setDateRange(dateRangeArr[0]);
      }
      if (dateRangeArr && acc) {
        getTableData(dateRangeArr[0], activeTab);
      } else {
        clearData();
      }
      setGridLoading(false);
      setIsPageLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      console.log(err);
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      const message = getException(err?.response?.data?.errorKey);

      if (
        !gridFallback &&
        err?.response?.data?.errorKey !== "USER_PLAN_NOT_ALLOWED"
      ) {
        setNoRowsText(
          "Clusters Inventory for the selected period or account are currently unavailable. Coming soon..."
        );
        setGridFallback(true);
      } else if (err?.response?.data?.errorKey === "USER_PLAN_NOT_ALLOWED") {
        setGridFallback(true);
        setNoRowsText(message?.description);
      }
      console.log(err);
    }
  };
  const handleExport = () => {
    if (!hasTrialFallback) {
      setDownloadCSV(true);
      setTimeout(() => {
        setDownloadCSV(false);
      }, 3000);
    }
  };
  const [breadCrumbs] = useState(false);
  useEffect(() => {
    if (acc) {
      setIsPageLoading(true);
      setGridLoading(true);
      getData();
    }
  }, [acc]);
  return (
    <>
      {expandModal && (
        <>
          <ExpandComponent
            columnDefs={columnDefs}
            rowData={gridData}
            gridFallback={gridFallback}
            handleExpand={handleExpand}
            gridStyle={{
              height: getGridHeight(gridData, window?.innerHeight, 30, 180),
              avoidAutoHeightOverflow: getAutoheightOverflow(gridData)
            }}
            gridLoading={gridLoading}
            noRowsText={noRowsText}
            downloadCSV={downloadCSV}
            tableName={"Clusters Inventory"}
            suppressColumnsSize={handleGridResolution(
              gridTotalWidth,
              window.innerWidth,
              96
            )}
            hasTrialFallback={hasTrialFallback}
            changeActiveModule={changeActiveModule}
          />
        </>
      )}

      <div className="cluster__inv-container overflow-auto">
        {!isPageLoading ? (
          <>
            <div className="header-container">
              <div className="header-block">
                {breadCrumbs ? (
                  <div className="breadcrumbs-container"></div>
                ) : null}
                <div className="header">
                  <span>Clusters Inventory</span>
                </div>
              </div>
              <div className="select-container filter-container">
                <Select
                  value={dateRange}
                  options={dateRangeOptions}
                  styles={customStyles}
                  classNamePrefix="selectcategory"
                  placeholder="select"
                  isSearchable={false}
                  components={{ DropdownIndicator }}
                  onChange={(e) => {
                    setDateRange(e);
                    setGridLoading(true);
                    getTableData(e, activeTab);
                  }}
                />
              </div>
            </div>
            <div className="tabs__container">{tabView}</div>
            <div className="stats__container">
              {totals && (
                <>
                  {totals?.active_clusters ? (
                    <DataCards
                      data={{
                        metric_name: "Active Clusters",
                        value: totals?.active_clusters
                      }}
                    />
                  ) : null}
                  {totals?.total_cost ? (
                    <DataCards
                      data={{
                        metric_name: "Total Cost($)",
                        value: totals?.total_cost,
                        unit_prefix: "$"
                      }}
                    />
                  ) : null}
                  {totals?.total_dbus ? (
                    <DataCards
                      data={{
                        metric_name: "Total DBUs",
                        value: totals?.total_dbus
                      }}
                    />
                  ) : null}
                </>
              )}
            </div>
            <div className="grid-block">
              <div className="grid-header-container">
                <div className="grid-filter-container">
                  <div className="grid-filter-wrapper-right w-100">
                    <Button
                      onClick={() => {
                        handleExpand(true);
                      }}
                      className={"qg-filter-button expand__button"}>
                      <img src={expand} alt="" />
                      <span>Expand</span>
                    </Button>
                    <div className="select-container export-container">
                      <Button
                        disabled={hasTrialFallback}
                        onClick={handleExport}
                        className={"qg-filter-button"}>
                        <img src={ExportCSV} alt="" />
                        <span>Export</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <GridComponent
                columnDefs={columnDefs}
                rowData={gridData}
                gridFallback={gridFallback}
                gridStyle={{
                  height: getGridHeight(
                    gridData,
                    window?.innerHeight - 42,
                    30,
                    180
                  ),
                  avoidAutoHeightOverflow: getAutoheightOverflow(gridData)
                }}
                gridLoading={gridLoading}
                noRowsText={noRowsText}
                downloadCSV={downloadCSV}
                hasTrialFallback={hasTrialFallback}
                changeActiveModule={changeActiveModule}
                tableName={"CLusters Inventory"}
                exportColumns={requiredCols}
                suppressColumnsSize={gridResolution}
              />
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
DBXClustersInventory.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  changeActiveModule: PropTypes.func
};
export default DBXClustersInventory;
