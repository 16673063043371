import React from "react";
import PropTypes from "prop-types";
const PaginationButtons = ({ currentPage, totalPages, handlePageClick }) => {
  const buttons = [];
  const maxVisibleButtons = 3;
  let startPage = Math.max(currentPage, 0);
  let endPage = Math.min(startPage + maxVisibleButtons - 1, totalPages - 1);

  if (endPage - startPage < maxVisibleButtons - 1) {
    startPage = Math.max(endPage - maxVisibleButtons + 1, 0);
  }

  for (let i = startPage; i <= endPage; i++) {
    buttons.push(
      <button
        className={+i === +currentPage ? "page-btn active" : "page-btn"}
        onClick={(e) => {
          // handleBtnClick(+e.target.innerHTML);
          handlePageClick(+e.target.innerHTML);
        }}
        key={i}>
        {i + 1}
      </button>
    );
  }

  return buttons;
};
PaginationButtons.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  handlePageClick: PropTypes.func
};
export default PaginationButtons;
