import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import back_arrow from "../../assets/images/common/back_arrow2.svg";
import warning from "../../assets/images/common/warning.svg";
import {
  validateCompanyEmail,
  validateEmail,
  validateEmailDomain
} from "../../utils/validateemail";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import dropdown_arrow from "../../assets/images/common/dropdown_arrow.svg";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { isBusinessEmail } from "../../utils/business-email-validator";
import { AuthContext } from "../../utils/authprovider";

const InviteMembers = (props) => {
  const { doInvite, goBack, changeIsLoading, onSkip } = props;
  const { userInfo } = useContext(AuthContext);
  const roles = [
    { value: "User", label: "User" },
    { value: "Admin", label: "Admin" }
  ];
  const [emails, setEmails] = useState([
    {
      email: "",
      roleOptions: roles?.map((obj, index) => {
        return (
          <MenuItem key={index} value={obj.value}>
            {obj.label}
          </MenuItem>
        );
      }),
      role: { value: "User", label: "User" }
    },
    {
      email: "",
      roleOptions: roles?.map((obj, index) => {
        return (
          <MenuItem key={index} value={obj.value}>
            {obj.label}
          </MenuItem>
        );
      }),
      role: { value: "User", label: "User" }
    }
  ]);
  const [infoError, setInfoError] = useState([
    { email: "", role: "" },
    { email: "", role: "" }
  ]);
  const scrollRef = useRef(null);
  const handleEmailChange = (e, index) => {
    const newEmails = [...emails];
    newEmails[index].email = e.target.value;
    setEmails(newEmails);

    const newInfoError = [...infoError];
    newInfoError[index].email = "";
    setInfoError(newInfoError);
  };

  const handleRoleChange = (e, index) => {
    const newEmails = [...emails];
    newEmails[index].role = e.target.value;
    setEmails(newEmails);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const filteredEmails = emails.filter(
      (email) => email?.email?.trim() !== ""
    );
    const newInfoError = [...infoError];
    if (filteredEmails.length === 0) {
      newInfoError[0].email = "Please add atleast one email";
      setInfoError(newInfoError);
      return;
    }
    let invalid = false;
    emails.forEach((email, index) => {
      if (email?.email?.trim() === "") return;
      if (!validateEmail(email?.email?.trim())) {
        newInfoError[index].email = "Please enter valid email";
        invalid = true;
      }
      if (validateCompanyEmail(email?.email?.trim())) {
        newInfoError[index].email = "Please provide company email id";
        invalid = true;
      }
      if (!isBusinessEmail(email?.email?.trim())) {
        newInfoError[index].email = "Please provide company email id";
      }
      if (!validateEmailDomain(email?.email?.trim(), userInfo?.info?.domain)) {
        newInfoError[index].email =
          "Enter Valid Email with same domain as user";
        invalid = true;
      }
    });
    if (invalid) {
      setInfoError(newInfoError);
      return;
    }
    doInvite(filteredEmails);
  };

  const addEmailBox = () => {
    const newEmails = [
      ...emails,
      {
        email: "",
        roleOptions: roles?.map((obj, index) => {
          return (
            <MenuItem key={index} value={obj.value}>
              {obj.label}
            </MenuItem>
          );
        }),
        role: { value: "User", label: "User" }
      }
    ];
    setEmails(newEmails);
    const offsetBottom =
      scrollRef.current.offsetTop + scrollRef.current.offsetHeight;
    window.scrollTo({ top: offsetBottom });
    setInfoError([...infoError, { email: "", role: "" }]);
  };

  const clickSkip = (e) => {
    e.preventDefault();
    changeIsLoading(true);
    onSkip();
  };

  return (
    <div className="signboard-invite-parent">
      <form className="signboard-list" onSubmit={handleSubmit}>
        <div className="signboard-scrollable" ref={scrollRef}>
          <div className="heading-back">
            <div className="back-btn" onClick={goBack}>
              <img src={back_arrow} />
              <span>Back</span>
            </div>
          </div>
          <div className="heading">
            <span>Invite Team Members</span>
          </div>
          <div className="signboard-emailboxes">
            {emails.map((email, index) => (
              <>
                <TextField
                  key={index}
                  placeholder="Email Address"
                  variant="outlined"
                  className="signboard-textbox"
                  fullWidth
                  FormHelperTextProps={{
                    className: "email-error",
                    sx: {
                      mt: infoError.email !== "" ? "6px" : "0px",
                      ml: 0
                    }
                  }}
                  autoComplete={`section-email${index} email`}
                  value={email?.email}
                  onChange={(e) => handleEmailChange(e, index)}
                  error={infoError[index].email !== ""}
                  helperText={
                    <>
                      {infoError[index].email !== "" && (
                        <img src={warning} alt="warning" />
                      )}
                      <span>{infoError[index].email}</span>
                    </>
                  }
                />
                <FormControl fullWidth className="signboard-role-dropdown">
                  <InputLabel>Role</InputLabel>
                  <Select
                    label="Role"
                    value={email?.role}
                    onChange={(e) => {
                      handleRoleChange(e, index);
                    }}
                    name="Role"
                    IconComponent={() => (
                      <span className="signboard-dropdown-arrow">
                        <img src={dropdown_arrow} />
                      </span>
                    )}>
                    {email?.roleOptions}
                  </Select>
                </FormControl>
              </>
            ))}
            <div className="signboard-add-email">
              <span className="signboard-add-email-text" onClick={addEmailBox}>
                + Add another
              </span>
            </div>
          </div>
        </div>
        <div className="signboard-invite-submit-div">
          <input
            type="submit"
            value="Send invites"
            className="btn btn-green-variant__login login-btn"
          />
        </div>
      </form>
      <div className="signboard-invite-footer">
        <span className="signboard-do-later" onClick={clickSkip}>
          Skip
        </span>
      </div>
    </div>
  );
};
InviteMembers.propTypes = {
  doInvite: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  changeIsLoading: PropTypes.func,
  onSkip: PropTypes.func
};

export default InviteMembers;
