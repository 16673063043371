import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

import chev_down from "../../assets/images/common/chev_down.svg";
import Button from "../customElements/Button";
import DeleteIcon from "../customElements/DeleteIcon";
import {
  getDatabaseDropdownsAPI,
  getRolesDropdownsAPI,
  getUserDropdownsAPI,
  getWHDropdownsAPI
} from "../../utils/chargeback-helper";
import Multiselects from "../customElements/MultiSelect";

function CreateResource({
  column,
  handleSwitch,
  index,
  deleteResource,
  handleChange,
  resources,
  setResources
}) {
  const [loading, setLoading] = useState(true);
  const [defaultDropdowns, setDefaultDropdowns] = useState({
    account: column.accountDropdowns,
    warehouse: [],
    user: [],
    database: [],
    role: []
  });

  useEffect(() => {
    setLoading(true);
    getallDropdowns(column?.account);
  }, [column?.account]);

  // Get WH & user dropdown Values
  const getallDropdowns = async (acc) => {
    try {
      const [userDropdown, whDropdown, databases, userRoles] =
        await Promise.all([
          getUserDropdownsAPI(acc.id),
          getWHDropdownsAPI(acc.id),
          getDatabaseDropdownsAPI(acc.id),
          getRolesDropdownsAPI(acc.id)
        ]);
      if (userDropdown && whDropdown && databases && userRoles) {
        setDefaultDropdowns({
          ...defaultDropdowns,
          user: userDropdown,
          warehouse: whDropdown,
          database: databases,
          account: column.accountDropdowns,
          role: userRoles
        });
        const newData = resources.map((item, ind) =>
          index === ind
            ? {
                ...item,
                whDropdowns: whDropdown,
                userDropdowns: userDropdown,
                dbDropdowns: databases
              }
            : item
        );
        setResources(newData);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // dropdown handler
  const handleSelectedValue = (
    e,
    key,
    index,
    dropdownInputValue,
    setSelectAllState
  ) => {
    if (key === "account") {
      handleChange(e, key, index);
      return;
    }

    const dropdowns = {
      user: "userDropdowns",
      database: "dbDropdowns",
      role: "roleDropdowns",
      warehouse: "whDropdowns"
    };

    const dropdown = dropdowns[key];
    const selectedItems = resources?.[index]?.[dropdown];

    const isAllSelected = selectedItems?.length === e.length;
    const filterDropdowns = dropdownInputValue
      ? selectedItems
          ?.filter((val) =>
            val.value.includes(dropdownInputValue.toUpperCase())
          )
          .map((val) => val.value)
      : [];

    const containsAllElements = filterDropdowns.every((item) =>
      e.includes(item)
    );

    setSelectAllState(isAllSelected || containsAllElements);

    const updatedResources = resources.map((item, ind) => {
      if (ind === index) {
        const update = { ...item, [key]: e, [`${key}Menu`]: false };
        return update;
      }
      return item;
    });

    setResources(updatedResources);
  };

  // select dropdown arrow
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img style={{ pointerEvents: "none" }} src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const customStyle = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
    })
  };
  return loading ? (
    <div className="main-wrapper">
      <div className="load">
        <div className="preload"></div>
      </div>
    </div>
  ) : (
    <div
      className={index % 2 == 0 ? "main-wrapper even-row-bg" : "main-wrapper"}>
      <div id="accountRow" className="account-row-wrapper">
        <div className="account-input">
          <input disabled={true} defaultValue={"Account"} type="text" />
        </div>
        <div className="operator-input">
          <input disabled={true} defaultValue={"Equals"} type="text" />
        </div>
        <div className="value-input">
          {column?.accountMenu ? (
            <Select
              value={column?.account}
              options={column?.accountDropdowns}
              styles={customStyle}
              classNamePrefix="selectcategory"
              components={{
                DropdownIndicator
              }}
              placeholder=""
              onChange={(e) => handleSelectedValue(e, "account", index)}
              onMenuClose={() => handleSwitch(index, "accountMenu")}
              onMenuOpen={() => handleSwitch(index, "accountMenu")}
              isSearchable={false}
              isMulti={false}
              menuPlacement="auto"
              menuIsOpen={true}
            />
          ) : (
            <Button
              onClick={() => handleSwitch(index, "accountMenu")}
              className={"filter-button"}>
              {column?.account?.value === "" ? (
                <>
                  <span>Select Value</span>
                  <img src={chev_down} alt="" />
                </>
              ) : (
                <>
                  <div className="chip-container">
                    <div className="chip-values">{column?.account?.value}</div>
                    <img src={chev_down} alt="" />
                  </div>
                </>
              )}
            </Button>
          )}
        </div>
        <div onClick={() => deleteResource(index)} className="delete-filter">
          <DeleteIcon />
        </div>
      </div>
      <div id="whWrapper" className="wearehouse-row-container">
        <div className="wearehouse-row-wrapper">
          <div className="account-input row-childrens">
            <input disabled={true} defaultValue={"Warehouse"} type="text" />
          </div>
          <div className="operator-input">
            <input disabled={true} defaultValue={"Equals"} type="text" />
          </div>
          <div className="value-input cost-value-container">
            {column?.warehouseMenu ? (
              <Multiselects
                value={column?.warehouse}
                options={defaultDropdowns?.warehouse}
                onChange={(
                  e,
                  dropdownInputValue,
                  setDropdownInputValue,
                  selectAllState,
                  setSelectAllState
                ) =>
                  handleSelectedValue(
                    e,
                    "warehouse",
                    index,
                    dropdownInputValue,
                    setSelectAllState
                  )
                }
                onHide={() => handleSwitch(index, "warehouseMenu")}
                footer={true}
              />
            ) : (
              <Button
                onClick={() => handleSwitch(index, "warehouseMenu")}
                className={"filter-button"}>
                {column?.warehouse?.length <= 0 ? (
                  <>
                    <span>Select Value</span>
                    <img src={chev_down} alt="" />
                  </>
                ) : (
                  <>
                    {column?.warehouse?.length > 1 ? (
                      <div className="chip-container">
                        <>
                          <div className="chip">{column?.warehouse?.[0]}</div>
                          {column?.warehouse?.length >= 2 && (
                            <div className="chip">{column?.warehouse?.[1]}</div>
                          )}
                          {column?.warehouse?.length >= 3 && (
                            <div className="chip2">
                              +{column?.warehouse?.length - 2}
                            </div>
                          )}
                        </>
                        <img src={chev_down} alt="" />
                      </div>
                    ) : (
                      <div className="chip-container">
                        <div className="chip">{column?.warehouse?.[0]}</div>
                        <img src={chev_down} alt="" />
                      </div>
                    )}
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="or-row">
        <p>OR</p>
      </div>
      <div id="whWrapper" className="wearehouse-row-container">
        <div className="wearehouse-row-wrapper">
          <div className="account-input row-childrens">
            <input disabled={true} defaultValue={"User"} type="text" />
          </div>
          <div className="operator-input">
            <input disabled={true} defaultValue={"Equals"} type="text" />
          </div>
          <div className="value-input cost-value-container">
            {column?.userMenu ? (
              <Multiselects
                value={column?.user}
                options={defaultDropdowns?.user}
                onChange={(
                  e,
                  dropdownInputValue,
                  setDropdownInputValue,
                  selectAllState,
                  setSelectAllState
                ) =>
                  handleSelectedValue(
                    e,
                    "user",
                    index,
                    dropdownInputValue,
                    setSelectAllState
                  )
                }
                onHide={() => handleSwitch(index, "userMenu")}
              />
            ) : (
              <Button
                onClick={() => handleSwitch(index, "userMenu")}
                className={"filter-button"}>
                {column.user?.length <= 0 ? (
                  <>
                    <span>Select Value</span>
                    <img src={chev_down} alt="" />
                  </>
                ) : (
                  <>
                    {column?.user?.length > 1 ? (
                      <div className="chip-container">
                        <>
                          <div className="chip">{column?.user?.[0]}</div>
                          {column?.user?.length >= 2 && (
                            <div className="chip">{column?.user?.[1]}</div>
                          )}
                          {column?.user?.length >= 3 && (
                            <div className="chip2">
                              +{column.user.length - 2}
                            </div>
                          )}
                        </>
                        <img src={chev_down} alt="" />
                      </div>
                    ) : (
                      <div className="chip-container">
                        <div className="chip">{column?.user?.[0]}</div>
                        <img src={chev_down} alt="" />
                      </div>
                    )}
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="or-row">
        <p>OR</p>
      </div>
      <div id="whWrapper" className="wearehouse-row-container">
        <div className="wearehouse-row-wrapper">
          <div className="account-input row-childrens">
            <input disabled={true} defaultValue={"Database"} type="text" />
          </div>
          <div className="operator-input">
            <input disabled={true} defaultValue={"Equals"} type="text" />
          </div>
          <div className="value-input cost-value-container">
            {column?.databaseMenu ? (
              <Multiselects
                value={column?.database}
                options={defaultDropdowns?.database}
                onChange={(
                  e,
                  dropdownInputValue,
                  setDropdownInputValue,
                  selectAllState,
                  setSelectAllState
                ) =>
                  handleSelectedValue(
                    e,
                    "database",
                    index,
                    dropdownInputValue,
                    setSelectAllState
                  )
                }
                onHide={() => handleSwitch(index, "databaseMenu")}
              />
            ) : (
              <Button
                onClick={() => handleSwitch(index, "databaseMenu")}
                className={"filter-button"}>
                {column.database?.length <= 0 ? (
                  <>
                    <span>Select Value</span>
                    <img src={chev_down} alt="" />
                  </>
                ) : (
                  <>
                    {column?.database?.length > 1 ? (
                      <div className="chip-container">
                        <>
                          <div className="chip">{column?.database?.[0]}</div>
                          {column?.database?.length >= 2 && (
                            <div className="chip">{column?.database?.[1]}</div>
                          )}
                          {column?.database?.length >= 3 && (
                            <div className="chip2">
                              +{column.database.length - 2}
                            </div>
                          )}
                        </>
                        <img src={chev_down} alt="" />
                      </div>
                    ) : (
                      <div className="chip-container">
                        <div className="chip">{column?.database?.[0]}</div>
                        <img src={chev_down} alt="" />
                      </div>
                    )}
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="or-row">
        <p>OR</p>
      </div>
      <div id="whWrapper" className="wearehouse-row-container">
        <div className="wearehouse-row-wrapper">
          <div className="account-input row-childrens">
            <input disabled={true} defaultValue={"Roles"} type="text" />
          </div>
          <div className="operator-input">
            <input disabled={true} defaultValue={"Equals"} type="text" />
          </div>
          <div className="value-input cost-value-container">
            {column?.roleMenu ? (
              <Multiselects
                value={column?.role}
                options={defaultDropdowns?.role}
                onChange={(
                  e,
                  dropdownInputValue,
                  setDropdownInputValue,
                  selectAllState,
                  setSelectAllState
                ) => {
                  handleSelectedValue(
                    e,
                    "role",
                    index,
                    dropdownInputValue,
                    setSelectAllState
                  );
                }}
                onHide={() => handleSwitch(index, "roleMenu")}
              />
            ) : (
              <Button
                onClick={() => handleSwitch(index, "roleMenu")}
                className={"filter-button"}>
                {column?.role?.length <= 0 ? (
                  <>
                    <span>Select Value</span>
                    <img src={chev_down} alt="" />
                  </>
                ) : (
                  <>
                    {column?.role?.length > 1 ? (
                      <div className="chip-container">
                        <>
                          <div className="chip">{column?.role?.[0]}</div>
                          {column?.role?.length >= 2 && (
                            <div className="chip">{column?.role?.[1]}</div>
                          )}
                          {column?.role?.length >= 3 && (
                            <div className="chip2">
                              +{column.role.length - 2}
                            </div>
                          )}
                        </>
                        <img src={chev_down} alt="" />
                      </div>
                    ) : (
                      <div className="chip-container">
                        <div className="chip">{column?.role?.[0]}</div>
                        <img src={chev_down} alt="" />
                      </div>
                    )}
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
CreateResource.propTypes = {
  column: PropTypes.object,
  handleSwitch: PropTypes.func,
  index: PropTypes.number,
  defaultDropdowns: PropTypes.object,
  deleteResource: PropTypes.func,
  handleChange: PropTypes.func,
  dwAccOptions: PropTypes.array,
  acc: PropTypes.object,
  setColumn: PropTypes.func,
  resources: PropTypes.array,
  setResources: PropTypes.func
};
export default CreateResource;
