import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import "./select.scss";
import chev_down from "../../../assets/images/common/chev_down.svg";

const CustomSelect = (props) => {
  const {
    options,
    value,
    onChange,
    customStyle,
    type = "normal",
    ...otherProps
  } = props;

  //   custom dropdown icons
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  DropdownIndicator.propTypes = {
    isDisabled: PropTypes.bool
  };

  // Custom Styles
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
    }),
    ...customStyle
  };

  //   custom elemets of dropdown
  const componentElements = { DropdownIndicator };
  return (
    <div
      className={
        type === "granularity" || type === "daterange"
          ? "custom-select-container calendar"
          : type === "groupBy"
          ? "custom-select-container groupBy"
          : "custom-select-container normal-dropdown"
      }>
      <Select
        value={
          type === "groupBy"
            ? (() => {
                return {
                  ...value,
                  label: `Group By • ${
                    value?.label === "None" || value == null
                      ? "Ungrouped"
                      : value?.label
                  }`
                };
              })()
            : value
        }
        options={options ?? []}
        onChange={onChange}
        components={componentElements}
        styles={customStyles}
        isSearchable={props?.isSearchable ?? false}
        isMulti={props?.isMulti ?? false}
        placeholder={props?.placeholder ?? "select"}
        classNamePrefix={props?.classNamePrefix ?? "selectcategory"}
        {...otherProps}
      />
    </div>
  );
};

CustomSelect.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  type: PropTypes.string,
  customStyle: PropTypes.object,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  classNamePrefix: PropTypes.string,
  isMulti: PropTypes.bool
};

export default CustomSelect;
