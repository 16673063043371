import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./top50queries.scss";
import { getSessionStorage } from "../../../utils/storage-helper";

import Select, { components } from "react-select";
import chev_down from "../../../assets/images/common/chev_down.svg";
import { exportCSVcolumns } from "../../../utils/filter-helper";
import Button from "../../../components/customElements/Button";
import {
  createColDefs,
  getAutoheightOverflow,
  getGridHeight,
  getSortingValueObj,
  handleGridResolution
} from "../../../utils/common-helper";
import { getException } from "../../../utils/exceptiondictionary";
import groupSvg from "../../../assets/images/dashboard/group.svg";
import ExpandComponent from "../../../components/ExpandComponent";
import ExportCSV from "../../../assets/images/grid/exportcsv.svg";
import expand from "../../../assets/images/common/expand.svg";
import GridComponent from "../../../components/GridComponent";
import { getTop50QueriesProps } from "./top50queriescoldefs";
import { getMonthRange, getMonthsArray } from "../../../utils/date-helper";
import { DateTime } from "luxon";
import {
  getDBXTop50QueriesDateRanges,
  getTop50QueriesData,
  getTop50QueriesSortingOptions
} from "../../../utils/query-helper";

const DBXTop50Queries = ({ acc, changeActiveModule }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };
  const [hasTrialFallback] = useState(
    getSessionStorage("plan_name").toLowerCase() === "trial" ? true : false
  );
  const customProps = getTop50QueriesProps(hasTrialFallback);
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const univerSalStyle = {
    cellStyle: (params) => {
      if (hasTrialFallback && params.rowIndex > 1) {
        return {
          userSelect: "none",
          "-webkit-user-select": "none"
        };
      }
      return { };
    }
  };
  const [gridData, setGridData] = useState(null); // Set gridData to
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [gridFallback, setGridFallback] = useState(false);
  const [expandModal, setExpandModal] = useState(false);
  const [gridTotalWidth, setGridTotalWidth] = useState(null);
  const [sorting, setSorting] = useState(null);
  const [sortingOptions, setSortingOptions] = useState([]);
  const [noRowsText, setNoRowsText] = useState(
    "Top 50 Queries for the selected period or account are currently unavailable. Coming soon..."
  );
  const [requiredCols, setRequiredCols] = useState([]);
  const [gridResolution, setGridResolution] = useState(true);

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([]);
  const [downloadCSV, setDownloadCSV] = useState(false);
  const handleExpand = (bool) => {
    setExpandModal(bool);
  };
  const getTableData = async (dateRange, sorting) => {
    try {
      if (
        sorting &&
        Object.keys(dateRange).length > 0 &&
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        acc &&
        Object.keys(acc).length > 0
      ) {
        const data = await getTop50QueriesData({
          acc: acc,
          start_date: dateRange?.value?.start_date,
          end_date: dateRange?.value?.end_date,
          sorting_option: sorting?.label,
          is_failed: false
        });
        if (data) {
          const colDefsArr = createColDefs(
            data?.columns,
            customProps,
            univerSalStyle,
            data?.column_display_name_mappping
          );
          if (colDefsArr && data?.data) {
            const newData = colDefsArr.map((colDef) => ({
              ...colDef,
              unSortIcon: true,
              sortable: colDef?.sortable,
              filter: false
            }));
            setColumnDefs(newData);
            const requiredColumns = exportCSVcolumns(newData);
            setRequiredCols(requiredColumns);
            setGridData(data?.data);
            let screenInnerWidth = window.innerWidth;
            let gridWidth = Object.values(newData).reduce((sum, prop) => {
              if (typeof prop.width === "number" && !isNaN(prop.width)) {
                return sum + prop.width;
              }
              return sum;
            }, 0);
            setGridResolution(
              handleGridResolution(gridWidth, screenInnerWidth, 360)
            );
            setGridTotalWidth(gridWidth);
          }
        } else {
          clearData();
        }
        setGridLoading(false);
        if (gridFallback) {
          setGridFallback(false);
        }
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      const message = getException(err?.response?.data?.errorKey);

      if (
        !gridFallback &&
        err?.response?.data?.errorKey !== "USER_PLAN_NOT_ALLOWED"
      ) {
        setNoRowsText(
          "Top 50 Queries for the selected period or account are currently unavailable. Coming soon..."
        );
        setGridFallback(true);
      } else if (err?.response?.data?.errorKey === "USER_PLAN_NOT_ALLOWED") {
        setGridFallback(true);
        setNoRowsText(message?.description);
      }
      console.log(err);
    }
  };
  const clearData = () => {
    setGridData([]);
  };

  const getData = async () => {
    let dateRangeArr = [];
    let sortingArr = [];
    try {
      const [dateRangeObj, sortingOptionsObj] = await Promise.all([
        getDBXTop50QueriesDateRanges(acc),
        getTop50QueriesSortingOptions()
      ]);
      if (dateRangeObj?.values) {
        dateRangeArr = getMonthsArray(
          dateRangeObj?.values?.start_date,
          dateRangeObj?.values?.end_date
        )
          .reverse()
          .map((item) => {
            let value = getMonthRange(item, dateRangeObj?.values?.end_date);
            return {
              label: value?.mtd
                ? `${DateTime.fromISO(value?.startDate).toFormat(
                    "dd"
                  )} - ${DateTime.fromISO(value?.endDate)
                    .minus({ days: 1 })
                    .toFormat("dd LLL yyyy")} (MTD)`
                : `${DateTime.fromISO(item).toFormat("LLL yyyy")}`,
              value: {
                start_date: DateTime.fromISO(value?.startDate).toFormat(
                  "yyyy-MM-dd"
                ),
                end_date: DateTime.fromISO(value?.endDate).toFormat(
                  "yyyy-MM-dd"
                )
              },
              mtd: value?.mtd
            };
          });
        dateRangeArr.push({
          label: "Last 3 Months",
          show_range: true,
          value: {
            start_date: DateTime.fromISO(dateRangeObj?.values?.end_date)
              .set({
                day: 1
              })
              .minus({ months: 3 })
              .toFormat("yyyy-MM-dd"),
            end_date: dateRangeObj?.values?.end_date
          },
          mtd: true
        });
        dateRangeArr.push({
          label: "Last 6 Months",
          show_range: true,
          value: {
            start_date: DateTime.fromISO(dateRangeObj?.values?.end_date)
              .set({
                day: 1
              })
              .minus({ months: 6 })
              .toFormat("yyyy-MM-dd"),
            end_date: dateRangeObj?.values?.end_date
          },
          mtd: true
        });
        setDateRangeOptions(dateRangeArr);
        setDateRange(dateRangeArr[0]);
      }
      if (sortingOptionsObj?.values) {
        sortingArr = getSortingValueObj(sortingOptionsObj?.values);
        setSortingOptions(sortingArr);
        setSorting(sortingArr[0]);
      }
      if (dateRangeArr && acc && sortingArr) {
        getTableData(dateRangeArr[0], sortingArr[0]);
      } else {
        clearData();
      }
      setGridLoading(false);
      setIsPageLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      console.log(err);
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      const message = getException(err?.response?.data?.errorKey);

      if (
        !gridFallback &&
        err?.response?.data?.errorKey !== "USER_PLAN_NOT_ALLOWED"
      ) {
        setNoRowsText(
          "Top 50 Queries for the selected period or account are currently unavailable. Coming soon..."
        );
        setGridFallback(true);
      } else if (err?.response?.data?.errorKey === "USER_PLAN_NOT_ALLOWED") {
        setGridFallback(true);
        setNoRowsText(message?.description);
      }
      console.log(err);
    }
  };
  const handleExport = () => {
    if (!hasTrialFallback) {
      setDownloadCSV(true);
      setTimeout(() => {
        setDownloadCSV(false);
      }, 3000);
    }
  };
  const [breadCrumbs] = useState(false);
  useEffect(() => {
    if (acc) {
      setIsPageLoading(true);
      setGridLoading(true);
      getData();
    }
  }, [acc]);
  return (
    <>
      {expandModal && (
        <>
          <ExpandComponent
            columnDefs={columnDefs}
            rowData={gridData}
            gridFallback={gridFallback}
            handleExpand={handleExpand}
            gridStyle={{
              height: getGridHeight(gridData, window?.innerHeight, 30, 180),
              avoidAutoHeightOverflow: getAutoheightOverflow(gridData)
            }}
            gridLoading={gridLoading}
            noRowsText={noRowsText}
            downloadCSV={downloadCSV}
            tableName={"Top 50 Query Groups"}
            suppressColumnsSize={handleGridResolution(
              gridTotalWidth,
              window.innerWidth,
              96
            )}
            hasTrialFallback={hasTrialFallback}
            changeActiveModule={changeActiveModule}
          />
        </>
      )}

      <div className="top50__queries-container overflow-auto">
        {!isPageLoading ? (
          <>
            <div className="header-container">
              <div className="header-block">
                {breadCrumbs ? (
                  <div className="breadcrumbs-container"></div>
                ) : null}
                <div className="header">
                  <span>Top 50 Query Groups</span>
                </div>
              </div>
              <div className="select-container filter-container">
                <Select
                  value={dateRange}
                  options={dateRangeOptions}
                  styles={customStyles}
                  classNamePrefix="selectcategory"
                  placeholder="select"
                  isSearchable={false}
                  components={{ DropdownIndicator }}
                  onChange={(e) => {
                    setDateRange(e);
                    setGridLoading(true);
                    getTableData(e, sorting);
                  }}
                />
              </div>
            </div>
            <div className="grid-block">
              <div className="grid-header-container">
                <div className="grid-filter-container">
                  <div className="grid-filter-wrapper-left">
                    <div className="select-container-groupby">
                      <Select
                        value={(() => {
                          return {
                            ...sorting,
                            label: `Sort By • ${
                              sorting?.label == "None" || sorting == null
                                ? "Unsorted"
                                : sorting?.label
                            }`
                          };
                        })()}
                        isSearchable={false}
                        classNamePrefix="selectcategory"
                        placeholder="select"
                        components={{ group: groupSvg, DropdownIndicator }}
                        styles={customStyles}
                        options={sortingOptions}
                        onChange={(e) => {
                          setSorting(e);
                          setGridLoading(true);
                          getTableData(dateRange, e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid-filter-wrapper-right w-100">
                    <Button
                      onClick={() => {
                        handleExpand(true);
                      }}
                      className={"qg-filter-button expand__button"}>
                      <img src={expand} alt="" />
                      <span>Expand</span>
                    </Button>
                    <div className="select-container export-container">
                      <Button
                        disabled={hasTrialFallback}
                        onClick={handleExport}
                        className={"qg-filter-button"}>
                        <img src={ExportCSV} alt="" />
                        <span>Export</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <GridComponent
                columnDefs={columnDefs}
                rowData={gridData}
                gridFallback={gridFallback}
                gridStyle={{
                  height: getGridHeight(
                    gridData,
                    window?.innerHeight - 28,
                    30,
                    180
                  ),
                  avoidAutoHeightOverflow: getAutoheightOverflow(gridData)
                }}
                gridLoading={gridLoading}
                noRowsText={noRowsText}
                downloadCSV={downloadCSV}
                hasTrialFallback={hasTrialFallback}
                changeActiveModule={changeActiveModule}
                tableName={"Top 50 Query Groups"}
                exportColumns={requiredCols}
                suppressColumnsSize={gridResolution}
              />
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
DBXTop50Queries.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  changeActiveModule: PropTypes.func
};
export default DBXTop50Queries;
