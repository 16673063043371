export const exportHTML = (chartData, treeData, rawData) => {
  const treeDataCopy = JSON.parse(JSON.stringify(treeData));
  treeDataCopy.columns.forEach((column) => {
    if (column.field === "dw_ids") {
      column.style = { ...column.style, width: "286px" };
      column.headerStyle = { ...column.headerStyle, width: "286px" };
    }
  });
  const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <title>Chart and Table Export</title>
        <link rel="stylesheet" href="https://unpkg.com/primereact/resources/themes/saga-blue/theme.css">
        <link rel="stylesheet" href="https://unpkg.com/primereact/resources/primereact.min.css">
        <link rel="stylesheet" href="https://unpkg.com/primeicons/primeicons.css">
        <script src="https://unpkg.com/react/umd/react.production.min.js"></script>
        <script src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"></script>
        <script src="https://unpkg.com/react-transition-group@4.4.2/dist/react-transition-group.min.js" crossorigin=""></script>
        <script src="https://unpkg.com/lodash@4.17.21/lodash.min.js"></script>
        <script src="https://unpkg.com/luxon@2.3.0/build/global/luxon.min.js"></script>
        <script src="https://unpkg.com/primereact@10.7.0/utils/utils.min.js"></script>
        <script src="https://unpkg.com/primereact@10.7.0/api/api.min.js"></script>
        <script src="https://unpkg.com/primereact@10.7.0/hooks/hooks.min.js"></script>
        <script src="https://unpkg.com/primereact/core/core.min.js"></script>
        <script src="https://unpkg.com/primereact/treetable/treetable.min.js"></script>
        <script src="https://unpkg.com/primereact/column/column.min.js"></script>
        <script src="https://code.highcharts.com/highcharts.js"></script>
         <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap" rel="stylesheet">
    <style>
        body {
            font-family: 'Inter', sans-serif;
        }
    </style>
    </head>
    <body>
    <div id="chart-container"></div>
    <div id="table-container"></div>

    <script>
    document.addEventListener("DOMContentLoaded", function () {
    // Highcharts data
     const getUniqueDate = function(data, key = "month_date") {
        return _.uniq(_.map(data, key));
      };
   const roundToSigDigits = function(
  number,
  sigDigits = 2,
  ignoreError = false,
  overrideSig = false
) {
  try {
    if (isNaN(number) || number === null) {
      return "-";
    }

    const absNum = Math.abs(number);
    let finalNum;
    if (absNum === 0) {
      finalNum = 0;
    } else if (absNum < 1) {
      finalNum = number.toFixed(
        overrideSig
          ? overrideSig
          : -Math.floor(Math.log10(Math.abs(number))) + (sigDigits - 1)
      );
    } else if (absNum < 10) {
      finalNum = number.toFixed(1);
    } else {
      finalNum = number.toFixed();
    }
    return parseFloat(finalNum);
  } catch (e) {
    if (!ignoreError) {
      console.log(e);
      throw e;
    } else {
      return number;
    }
  }
};
const customTooltipFormatter = (that) => {
  const name = that.series.name;
        const color = that.series.color;
        const value = costFormatter(roundToSigDigits(that.y, 2, false));
  return \`<div style="color:white; background-color: \${color}; border: 1px solid \${color}; padding: 10px;">
                  <span>Name - <b>\${name}</b></span><br/>
                  <span>Value - <b>\${value}</b></span>
                </div>\`;
};
      const customDateTimeFormat = function(date, format) {
        const dt = luxon.DateTime.fromISO(date);
        return dt.toFormat(format);
      };
     const costFormatter = function(cost) {
  const newCost = roundToSigDigits(cost, 2, false, 2).toLocaleString("en-US");

  if (cost < 0) {
    let data = newCost.charAt(0) + "$" + newCost.slice(1);
    return data;
  } else {
    let data = "$" + newCost;
    return data;
  }
};
    const rawData = ${JSON.stringify(rawData)};
    const chartData = ${JSON.stringify(chartData)};

    // PrimeReact TreeTable data
    const treeData = ${JSON.stringify(treeDataCopy)};
    const dateFormat =
    rawData?.date_aggregation === "monthly" ? "MMM yyyy" : "dd MMM yyyy";
  let x_axis_data = rawData?.data?.map((item) => ({
    ...item,
    month_date: customDateTimeFormat(item.date, dateFormat)
  }));
  const uniqueDates = getUniqueDate(x_axis_data);

  

const StackOptions = {
    chart: {
      type: "column"
    },
    title: {
      text: "Chargeback Report"
    },
    xAxis: {
      categories: uniqueDates
    },
    yAxis: {
      min: 0,
      title: {
        text: "Cost ($)"
      },
      stackLabels: {
        enabled: true,
        align: "center",
        style: {
          color: "#222"
        },
        x: 0,
        verticalAlign: "top",
        formatter: function () {
          return costFormatter(roundToSigDigits(this.total));
        }
      }
    },
    tooltip: {
      backgroundColor: "black",
      borderRadius: 5, // Add rounded corners to the tooltip
      formatter: function () {
        return ${`customTooltipFormatter(this)`};
      }
    },
    legend: {
      reversed: true,
      symbolRadius: 2,
      symbolHeight: 10
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        stacking: "normal",
        dataLabels: {
          enabled: false // Disable data labels
        },
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            enabled: false // Enable hover state for all series
          }
        },
        groupPadding: 0.1 // Adjust the groupPadding as needed to add a gap between stacks
      }
    },
    series: chartData?.reverse()
  };
  console.log(${JSON.stringify(treeData)});

    // Initialize Highcharts
    Highcharts.chart('chart-container', StackOptions);

    // Initialize PrimeReact TreeTable
    const columns = treeData.columns.map(col => React.createElement(primereact.column.Column, { key: col.field, field: col.field, header: col.name, expander: col.expander, frozen: col.frozen, style: col.style, headerStyle: col.headerStyle, body: col.body }));
    const TreeTableElement = React.createElement(primereact.treetable.TreeTable, { value: treeData.data, scrollable: treeData.tableStyle.scrollable, scrollHeight: treeData.tableStyle.scrollHeight, frozenWidth: treeData.tableStyle.frozenWidth, className:treeData.tableClass ? treeData.tableClass : "", resizableColumns: treeData.tableStyle.resizableColumns, showGridlines: treeData.tableStyle.showGridlines, tableStyle: {width: treeData.tableStyle.width}, children: columns, columns });
    ReactDOM.render(TreeTableElement, document.getElementById('table-container'));
});
    </script>
    </body>
    </html>
        `;
  const blob = new Blob([htmlContent], { type: "text/html" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "Chargeback Report.html";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
