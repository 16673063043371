import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  checkValue,
  filterTypes,
  groupTableData
} from "../../utils/filter-helper";
import Button from "../customElements/Button";
import "./customFilter.scss";
import { clearAllFilters } from "../../utils/filter-helper";
import { removeDropdown } from "../../utils/common-helper";
import FilterRow from "./FilterRow";

function CustomFilter({
  column,
  gridData,
  applyFilter,
  setApplyFilter,
  handleFilter,
  clearFilter,
  filterOperators = filterTypes,
  close,
  isMultiRowDisabled = false,
  maxSelectionLimit = { limit: 100, isActive: false }
}) {
  const elementRef = useRef(null);
  const [temp, setTemp] = useState([]);
  const applyNewFilter = async () => {
    if (temp?.length < column?.length && !isMultiRowDisabled) {
      const filteredColumns = await removeDropdown(
        column,
        temp,
        "column",
        "label"
      );
      setTemp([
        ...temp,
        {
          column: filteredColumns[0],
          condition: filterOperators?.[0],
          filteredColumns,
          previuosValue: [],
          previousMenuList: [],
          value: [],
          dropDown: false,
          defaultMenuList:
            filterOperators?.[0].value !== "in"
              ? ""
              : groupTableData(gridData, filteredColumns[0]?.value)
        }
      ]);
    }
  };

  // On column and operator dropdown Change
  const handleInputs = (...args) => {
    const [e, index, type = "", dropdownInputValue, , , setSelectAllState] =
      args;
    const new_data = temp.map((item) => ({ ...item, dropDown: false }));
    let newarr;
    if (type === "col") {
      const gropedData = groupTableData(gridData, e.value);
      newarr = new_data.map((item, ind) => {
        const filteredColumns = removeDropdown(
          column,
          [{ column: { label: e?.label } }],
          "column",
          "label"
        );
        return ind === index
          ? {
              ...item,
              column: e,
              previuosValue: item.value,
              filteredColumns,
              previousMenuList: item.defaultMenuList,
              value: [],
              dropDown: false,
              defaultMenuList: gropedData
            }
          : item;
      });
    } else if (type === "opp") {
      newarr = new_data.map((item, ind) =>
        ind === index
          ? {
              ...item,
              condition: e,
              value: e.value !== "in" ? "" : [],
              defaultMenuList:
                e.value !== "in"
                  ? ""
                  : groupTableData(gridData, item?.column.value)
            }
          : item
      );
    } else if (type === "val") {
      const containsAllElements = (e, list) => {
        return list.every((item) => e.includes(item));
      };

      if (temp[index].defaultMenuList?.length === e.length) {
        setSelectAllState(true);
        newarr = new_data.map((item, ind) =>
          ind === index ? { ...item, value: e, dropDown: false } : item
        );
      } else if (dropdownInputValue) {
        const newfilter = temp[index].defaultMenuList
          .filter((val) => val.value.includes(dropdownInputValue.toUpperCase()))
          .map((val) => val.value);

        if (containsAllElements(e, newfilter)) {
          setSelectAllState(true);
        } else {
          setSelectAllState(false);
        }
        newarr = new_data.map((item, ind) =>
          ind === index ? { ...item, value: e, dropDown: false } : item
        );
      } else {
        setSelectAllState(false);
        newarr = new_data.map((item, ind) =>
          ind === index ? { ...item, value: e, dropDown: false } : item
        );
      }
    } else if (type === "input") {
      newarr = new_data.map((item, ind) =>
        ind === index ? { ...item, value: e, dropDown: false } : item
      );
    }
    setTemp(newarr);
  };
  const removeFilterRow = (index) => {
    const updatedArr = temp.filter((item, ind) => ind !== index);
    //setApplyFilter(updatedArr);
    setTemp(updatedArr);
    updateOnRemove(updatedArr);
  };
  const updateOnRemove = (applyFilter) => {
    const updateOldState = applyFilter?.map((item) => {
      const groupedData =
        item?.condition?.value !== "in"
          ? ""
          : groupTableData(gridData, item?.column?.value);
      const filteredColumns = removeDropdown(
        column,
        applyFilter,
        "column",
        "label"
      );
      return {
        ...item,
        filteredColumns,
        defaultMenuList:
          item?.previousMenuList?.length > 0
            ? item?.previousMenuList
            : groupedData,
        value:
          item?.previuosValue?.length > 0 ? item?.previuosValue : item?.value
      };
    });
    setApplyFilter(updateOldState);
    setTemp(updateOldState);
  };

  const handleSwitch = (index) => {
    const new_arr = temp.map((item, ind) =>
      index === ind ? { ...item, dropDown: !item.dropDown } : item
    );
    setTemp(new_arr);
  };

  const applyGridFilter = (filterValue, gridData) => {
    setApplyFilter(filterValue);
    handleFilter(filterValue, gridData);
    close();
  };

  const handleClearFilter = () => {
    clearFilter();
    const filterData = clearAllFilters(temp);
    setTemp(filterData);
  };

  useEffect(() => {
    const updateOldState = applyFilter?.map((item) => {
      const groupedData =
        item?.condition?.value !== "in"
          ? ""
          : groupTableData(gridData, item?.column?.value);
      const filteredColumns = removeDropdown(
        column,
        applyFilter,
        "column",
        "label"
      );
      return {
        ...item,
        filteredColumns,
        defaultMenuList:
          item?.previousMenuList?.length > 0
            ? item?.previousMenuList
            : groupedData,
        value:
          item?.previuosValue?.length > 0 ? item?.previuosValue : item?.value
      };
    });
    setApplyFilter(updateOldState);
    setTemp(updateOldState);
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      if (
        !elementRef.current.contains(event.target) &&
        !(
          event.target.tagName === "SPAN" ||
          event.target.tagName === "INPUT" ||
          event.target.tagName === "BUTTON" ||
          event.target.tagName === "svg" ||
          Array.from(event.target.classList).includes(
            "selectcategory__option"
          ) ||
          Array.from(event.target.classList).includes(
            "selectcategory__single-value"
          )
        )
      ) {
        close();
      }
    };

    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <div className="filter-main-container" ref={elementRef}>
      <div
        className={
          temp?.length > 3
            ? "filter-row-container overflow-y"
            : "filter-row-container "
        }>
        {temp?.map((item, index) => {
          return (
            <FilterRow
              key={index}
              item={item}
              index={index}
              removeFilterRow={removeFilterRow}
              temp={temp}
              handleInputs={handleInputs}
              handleSwitch={handleSwitch}
              elementRef={elementRef}
              filterOperators={filterOperators}
              maxSelectionLimit={maxSelectionLimit}
            />
          );
        })}
      </div>
      <div
        className={
          temp?.length > 3 ? "filter-footer footer__stroke" : "filter-footer"
        }>
        <div>
          {column?.length > 1 && (
            <p
              className={
                temp?.length < column?.length && !isMultiRowDisabled
                  ? "enabled"
                  : "disabled"
              }
              onClick={() => {
                applyNewFilter();
              }}>
              + Add Filter
            </p>
          )}
        </div>
        <div className="filter-section">
          <Button onClick={handleClearFilter} className={"clear__button"}>
            Clear
          </Button>
          <Button
            disabled={checkValue(temp)}
            onClick={() => !checkValue(temp) && applyGridFilter(temp, gridData)}
            className={
              checkValue(temp)
                ? "button-primary filter-button-disabled"
                : "button-primary"
            }>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}
CustomFilter.propTypes = {
  close: PropTypes.func,
  gridData: PropTypes.array,
  column: PropTypes.array,
  handleFilter: PropTypes.func,
  clearFilter: PropTypes.func,
  applyFilter: PropTypes.array,
  setApplyFilter: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  isMultiRowDisabled: PropTypes.bool,
  filterOperators: PropTypes.array,
  maxSelectionLimit: PropTypes.object
};
export default CustomFilter;
