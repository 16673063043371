import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MultiSelect } from "primereact/multiselect";
import Button from "./Button";
import info from "../../assets/images/common/info__dark.svg";

const Multiselects = (props) => {
  const {
    options,
    value,
    footer = true,
    onChange,
    filterPlaceholder,
    isClearButtonVisible = true,
    isSaveButtonVisisble = true,
    clearButtonText = "Clear",
    saveButtonText = "OK",
    maxSelectionLimit = { isActive: false },
    ...otherProps
  } = props;
  const [selectAllState, setSelectAllState] = useState(false);
  const [dropdownInputValue, setDropdownInputValue] = useState("");
  const [searchKeys, setSearchKeys] = useState([]);
  const [labelClicked, setLabelClicked] = useState(false);
  const [initialRender, setInitialrender] = useState(false);
  const [tempValue, setTempValue] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);

  // reset when otions changes
  useEffect(() => {
    if (
      value?.length === options?.length &&
      value?.length !== 0 &&
      options?.length !== 0 &&
      value?.length !== 0
    ) {
      setSelectAllState(true);
    } else {
      if (selectAllState) {
        setSelectAllState(true);
      } else {
        setSelectAllState(false);
      }
    }
    setTempValue(value);
  }, [options, value]);

  const sendTheData = (
    value = tempValue,
    dropdownInputs = dropdownInputValue,
    setDropdownInput = setDropdownInputValue,
    selectAllValue = selectAllState,
    setSelectAllValue = setSelectAllState,
    searchKey_arr = searchKeys
  ) => {
    onChange(
      value,
      dropdownInputs,
      setDropdownInput,
      selectAllValue,
      setSelectAllValue,
      searchKey_arr,
      setSearchKeys
    );
  };

  // Handle search box
  const onInputChange = (e) => {
    setInitialrender(true);
    setDropdownInputValue(e.filter);
    if (tempValue?.length === options?.length) {
      setSelectAllState(true);
    } else {
      setSelectAllState(false);
    }

    //checking search history of select all
    if (searchKeys.includes(e.filter.toUpperCase())) {
      setSelectAllState(true);
    }
  };

  const handleOnChange = (e, triggerPoint) => {
    setInitialrender(true);
    let new_arr = [];

    const applyMaxSelectionLimit = (data) => {
      if (
        maxSelectionLimit?.isActive &&
        tempValue?.length < maxSelectionLimit?.limit
      ) {
        const difference = maxSelectionLimit?.limit - tempValue?.length;
        return data.slice(0, difference);
      }
      return data;
    };

    if (triggerPoint === "Select All") {
      if (!e.checked) {
        if (dropdownInputValue) {
          let filteredData = options
            ?.filter((item) =>
              item?.label
                .toUpperCase()
                .includes(dropdownInputValue.toUpperCase())
            )
            ?.map((item) => item.value);

          filteredData = applyMaxSelectionLimit(filteredData);
          new_arr = [...new Set([...tempValue, ...filteredData])];
          setTempValue(new_arr);
          setSearchKeys([...searchKeys, dropdownInputValue.toUpperCase()]);
        } else {
          if (maxSelectionLimit?.isActive) {
            new_arr = options
              ?.slice(0, maxSelectionLimit?.limit)
              ?.map((item) => item.value);
            if (
              tempValue?.length > 0 &&
              tempValue?.length < maxSelectionLimit?.limit
            ) {
              const filteredData = applyMaxSelectionLimit(new_arr);
              const arr = [...new Set([...tempValue, ...filteredData])];
              setTempValue(arr);
            } else if (tempValue.length >= 50) {
              setSelectAllState(true);
            } else {
              setTempValue(new_arr);
            }
          } else {
            new_arr = options?.map((item) => item.value);
            setTempValue(new_arr);
          }
        }
        setSelectAllState(true);
      } else {
        if (dropdownInputValue) {
          const filteredData = options
            ?.filter((item) =>
              item.label
                .toUpperCase()
                .includes(dropdownInputValue.toUpperCase())
            )
            ?.map((item) => item.value);

          new_arr = tempValue?.filter((item) => !filteredData.includes(item));
          setTempValue([...new Set(new_arr)]);

          const newSearchKeys = searchKeys.filter(
            (key) => key !== dropdownInputValue.toUpperCase()
          );
          setSearchKeys(newSearchKeys);
        } else {
          setTempValue([]);
          setSearchKeys([]);
        }
        setSelectAllState(false);
      }
    } else {
      new_arr = e?.value;
      if (maxSelectionLimit?.isActive) {
        if (new_arr?.length >= maxSelectionLimit?.limit) {
          new_arr = new_arr.slice(0, maxSelectionLimit.limit);
          setSelectAllState(true);
        } else {
          setSelectAllState(false);
        }
      } else {
        setSelectAllState(new_arr.length === options.length);
      }
      setTempValue(new_arr);
    }

    if (!isClearButtonVisible || !isSaveButtonVisisble) {
      sendTheData(new_arr);
    }
  };

  const handleApplyButton = () => {
    sendTheData(
      tempValue,
      dropdownInputValue,
      setDropdownInputValue,
      selectAllState,
      setSelectAllState,
      searchKeys,
      setSearchKeys
    );
  };
  const handleClearButton = () => {
    setTempValue([]);
    setSearchKeys([]);
    setSelectAllState(false);
    sendTheData([]);
  };

  // Default Footer of the Dropdown
  const panelFooterTemplate = () => {
    const length = tempValue ? tempValue.length : 0;
    return (
      <div className="select-footer-section">
        <div className="text-left">
          <span>
            <b>{length}</b> item{length > 1 ? "s" : ""} selected.
          </span>
        </div>
        <div className="footer-button-container">
          {isClearButtonVisible && (
            <Button onClick={handleClearButton} className={"secondary-button"}>
              {clearButtonText}
            </Button>
          )}
          {isSaveButtonVisisble && (
            <Button onClick={handleApplyButton} className={"primary-button"}>
              {saveButtonText}
            </Button>
          )}
        </div>
      </div>
    );
  };
  const onShow = () => {
    let selectAllCheckbox = document.querySelector(
      ".p-multiselect-header > .p-multiselect-select-all > .p-checkbox"
    );

    if (selectAllCheckbox) {
      let label = document.createElement("span");
      label.textContent = " Select All";
      selectAllCheckbox.parentNode.appendChild(label);
      label.onclick = () => {
        setInitialrender(true);
        setLabelClicked((prev) => !prev);
      };

      // Check if maxSelectionLimit.isActive is true
      if (maxSelectionLimit?.isActive) {
        // Create the infoIcon as an image
        let infoIcon = document.createElement("img");
        infoIcon.src = info;
        infoIcon.alt = "Info";
        infoIcon.className = "info-icon"; // Add a class for styling purposes

        // Append the icon next to the label
        selectAllCheckbox.parentNode.appendChild(infoIcon);

        // Show tooltip on hover
        infoIcon.onmouseover = () => {
          setShowTooltip(true);
        };

        // Hide tooltip when not hovering
        infoIcon.onmouseout = () => {
          setShowTooltip(false);
        };
      }
    }
  };

  useEffect(() => {
    if (initialRender) {
      if (!selectAllState && maxSelectionLimit?.isActive) {
        setShowTooltip(true);
        setTimeout(() => {
          setShowTooltip(false);
        }, 1000);
      }
      handleOnChange({ checked: selectAllState }, "Select All");
    }
  }, [labelClicked]);
  return (
    <div className="multiselect-Wrapper">
      <MultiSelect
        options={options}
        onShow={onShow}
        value={tempValue}
        filterPlaceholder={filterPlaceholder ?? "Search"}
        selectAll={selectAllState}
        display={props.display ?? "chip"}
        filter={props.filter ?? true}
        overlayVisible={props.overlayVisible ?? true}
        optionLabel={props.optionLabel ?? "label"}
        virtualScrollerOptions={{ itemSize: props.scrollSize ?? 50 }}
        onFilter={(e) => onInputChange(e)}
        onChange={(e) => {
          handleOnChange(e);
        }}
        panelFooterTemplate={
          footer && (props.panelFooterTemplate ?? panelFooterTemplate)
        }
        onSelectAll={(e) => {
          if (!e.checked) {
            setShowTooltip(true);
            setTimeout(() => {
              setShowTooltip(false);
            }, 1000);
          }
          handleOnChange(e, "Select All");
        }}
        {...otherProps}
      />
      {showTooltip && (
        <div
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          className="tooltip-wrapper">
          With EQUALS condition, you can select upto 50 values. Use CONTAINS or
          STARTS WITH or ENDS WITH to select more than 50 values
        </div>
      )}
    </div>
  );
};
Multiselects.propTypes = {
  options: PropTypes.array,
  value: PropTypes.array,
  display: PropTypes.string,
  filter: PropTypes.bool,
  overlayVisible: PropTypes.bool,
  optionLabel: PropTypes.string,
  scrollSize: PropTypes.number,
  footer: PropTypes.bool,
  panelFooterTemplate: PropTypes.func,
  onChange: PropTypes.func,
  filterPlaceholder: PropTypes.string,
  clearButtonText: PropTypes.string,
  saveButtonText: PropTypes.string,
  isClearButtonVisible: PropTypes.bool,
  isSaveButtonVisisble: PropTypes.bool,
  maxSelectionLimit: PropTypes.object
};
export default Multiselects;
