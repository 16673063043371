import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./dropdown.scss";
import SVG from "react-inlinesvg";
import { getSessionStorage } from "../../utils/storage-helper";
import { AuthContext } from "../../utils/authprovider";

const DropDown = ({ options, active, align, style, close }) => {
  const [alignmentClass] = useState(align ? align : "center");
  const userData = JSON.parse(getSessionStorage("me"));
  const { userInfo } = useContext(AuthContext);
  const drpstyle = style ? style : { width: "170px" };
  const options_view =
    options &&
    options.length &&
    options?.map((option) => {
      return (
        <div
          className={`option${
            active ? (active === option.label ? " active" : "") : ""
          }`}
          key={option.id}
          onClick={option.handler}>
          {option?.img && <SVG src={option?.img} />}
          <span style={option.style}>{option.label}</span>
        </div>
      );
    });
  useEffect(() => {
    window.addEventListener("click", close);
    return () => {
      window.removeEventListener("click", close);
    };
  }, []);
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`dropdown-parent ${alignmentClass}`}
      style={{ drpstyle }}>
      <div className="login-info">
        <p>Signed in as</p>
        <h5>{userData?.email ?? ""}</h5>
      </div>
      <div className="login-info">
        <p>Organization Name</p>
        <h5>{userInfo?.info?.acc_name ?? ""}</h5>
      </div>
      <div className="dropdown-block">{options_view}</div>
    </div>
  );
};
DropDown.propTypes = {
  options: PropTypes.array,
  active: PropTypes.string,
  align: PropTypes.string,
  style: PropTypes.object,
  close: PropTypes.func
};
export default DropDown;
