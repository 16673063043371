import React, { useEffect, useState } from "react";
import "./confirmmail.scss";
import OtpInput from "react-otp-input";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { getException } from "../../utils/exceptiondictionary";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { useAuth } from "../../utils/auth-helper";
import { useLocation, useNavigate } from "react-router-dom";
import { createOrg } from "../../utils/user-helper";
import { deleteToken, saveToken } from "../../utils/token-helper";
import back_arrow from "../../assets/images/common/back_arrow2.svg";

const ConfirmMail = ({
  username,
  pword,
  org,
  changeHidden,
  changeIsLoading,
  changeOtp,
  errorObj
}) => {
  const toast = useToast();
  const { onLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const [otp, setOtp] = useState("");
  const [infoError, setInfoError] = useState({
    otp: ""
  });
  const [timezero, setTimezero] = useState(false);

  const navigateTo = (path, attrs = {}) => {
    navigate(path, attrs);
  };

  const changeTimezero = (b) => {
    setTimezero(b);
  };

  useEffect(() => {
    if (errorObj) {
      setInfoError(errorObj);
    }
  }, [errorObj]);

  useEffect(() => {
    const timer = setTimeout(() => {
      changeTimezero(true);
    }, 60000);
    return () => clearTimeout(timer);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    let errObj = { ...infoError };
    const length = otp.toString().length;
    if (!otp || length < 6) {
      errObj["otp"] = "Please enter verification code";
    }
    setInfoError(errObj);
    if (errObj["otp"] === "") {
      if (location?.state?.signUpState === "resetpassword") {
        changeOtp(otp);
        changeHidden(true);
      } else {
        changeIsLoading(true);
        confirmSignUp();
      }
    }
  };

  const resendConfirmationMail = async () => {
    try {
      await Auth.resendSignUp(username.trim()?.toLowerCase());
      changeIsLoading(false);
    } catch (err) {
      customToast({ type: "error", ...getException("FailedToSendCode") });
      changeIsLoading(false);
    }
  };

  async function confirmSignUp() {
    try {
      await Auth.confirmSignUp(username?.trim()?.toLowerCase(), otp);
      const user = await Auth.signIn(
        username?.trim()?.toLowerCase(),
        pword.trim()
      );
      const { signInUserSession } = user;
      const { accessToken } = signInUserSession;
      const { jwtToken } = accessToken;
      if (jwtToken) {
        saveToken("accessToken", jwtToken);
      } else {
        deleteToken("accessToken");
      }
      await createOrg({
        email: username.trim()?.toLowerCase(),
        account_name: org.trim()
      });
      onLogin();
      changeIsLoading(false);
    } catch (error) {
      customToast({
        type: "error",
        ...getException(error.name, error.message)
      });
      changeIsLoading(false);
    }
  }
  const handleChange = (otp) => {
    if (infoError["otp"] !== "") {
      setInfoError({ ...infoError, otp: "" });
    }
    setOtp(otp);
  };

  return (
    <div className="confirmmail-parent">
      <div className="heading-back">
        <div className="back-btn" onClick={() => navigateTo("/signin")}>
          <img src={back_arrow} />
          <span>Back</span>
        </div>
      </div>
      <div className="heading">
        <span>Verify your email id</span>
      </div>
      <div className="subheading">
        <span>
          We have sent a 6-digit verification code to{" "}
          <span className="email-span">{username.trim()}</span>{" "}
        </span>
      </div>
      <form className="confirmmail-list" onSubmit={handleClick}>
        <div className="ver-code">
          <span>Verification code</span>
        </div>
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          isInputNum={true}
          containerStyle={
            "name-container " + (infoError.otp !== "" ? "error__otp" : "")
          }
          inputStyle={{
            width: "junk" // Junk to ignore forced inline style
            // react-otp-input forces 1em width.
          }}
        />
        {infoError.otp !== "" ? (
          <div className="connection__fail">
            <p>{infoError.otp}</p>
          </div>
        ) : null}
        {location?.state?.signUpState !== "resetpassword" &&
          (timezero ? (
            <div className="subheading">
              <span className="ver-code-text__desktop">
                Didn’t receive verification code?{" "}
              </span>
              <span className="ver-code-text__mobile">
                Didn’t receive code?{" "}
              </span>
              <span
                className="resend_mail"
                onClick={() => {
                  changeIsLoading(true);
                  resendConfirmationMail();
                }}>
                Resend
              </span>
            </div>
          ) : null)}

        <input
          type="submit"
          className="btn btn-green-variant__login w-100 login-btn"
          value="Submit"
        />
      </form>
    </div>
  );
};
ConfirmMail.propTypes = {
  username: PropTypes.string,
  changeHidden: PropTypes.func,
  changeIsLoading: PropTypes.func,
  pword: PropTypes.string,
  changeOtp: PropTypes.func,
  fname: PropTypes.string,
  lname: PropTypes.string,
  org: PropTypes.string,
  errorObj: PropTypes.object
};
export default ConfirmMail;
