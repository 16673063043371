import { attachParams } from "./url-helper";
import { getRequest } from "./http-helper";
import _ from "lodash";
import { DateTime } from "luxon";
import { stackGrahpcolors } from "./cost-reporting-helper";

const colors = stackGrahpcolors;
export const granularityArray = [
  {
    value: "daily",
    label: "Daily",
    chartLabel: "Day"
  },
  {
    value: "weekly",
    label: "Weekly",
    chartLabel: "Week"
  },
  {
    value: "monthly",
    label: "Monthly",
    chartLabel: "Month"
  }
];

export const getCostExplorerDateRanges = async (acc, currentKey) => {
  const params = {
    data_wh_id: acc.id
  };
  const URL = attachParams(
    `/v2/cost_explorer/${currentKey}/date_ranges`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getDBXCostExplorerDateRanges = async (acc, currentKey) => {
  const params = {
    data_wh_id: acc.data_warehouse_info_id
  };
  const URL = attachParams(
    `/v2/dbx_cost_explorer/${currentKey}/date_ranges`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getCostExplorerMetaData = async (currentKey) => {
  const URL = attachParams(`/v2/cost_explorer/${currentKey}/metadata`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getDBXCostExplorerMetaData = async (
  currentKey,
  acc,
  start_date,
  end_date
) => {
  const params = {
    data_wh_id: acc.data_warehouse_info_id,
    start_date: start_date,
    end_date: end_date
  };
  const URL = attachParams(
    `/v2/dbx_cost_explorer/${currentKey}/metadata`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getCostExplorerOverView = async (
  acc,
  start_date,
  end_date,
  group_by,
  currentKey,
  date_grouping = "daily"
) => {
  const params = {
    data_wh_id: acc.id,
    start_date: start_date,
    end_date: end_date,
    group_by: group_by,
    date_grouping: date_grouping
  };
  const URL = attachParams(`/v2/cost_explorer/${currentKey}`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getDBXCostExplorerTotals = async (
  acc,
  start_date,
  end_date,
  group_by,
  currentKey,
  date_grouping = "daily",
  tag_name = null
) => {
  const params = {
    data_wh_id: acc.id,
    start_date: start_date,
    end_date: end_date,
    group_by: group_by,
    date_grouping: date_grouping,
    tag_name: tag_name
    // ascending: false
  };
  const URL = attachParams(
    `/v2/dbx_cost_explorer/${currentKey}/totals`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getDBXCostExplorerOverView = async (
  acc,
  start_date,
  end_date,
  group_by,
  currentKey,
  date_grouping = "daily",
  tag_name = null,
  page_num = 0,
  page_size = 50
) => {
  let startDate = start_date;
  if (date_grouping === "weekly") {
    startDate = DateTime.fromISO(
      DateTime.fromFormat(start_date, "yyyy-MM-dd").toISO()
    )
      .minus({ weeks: 1 })
      .toFormat("yyyy-MM-dd");
  } else if (date_grouping === "monthly") {
    startDate = DateTime.fromISO(
      DateTime.fromFormat(start_date, "yyyy-MM-dd").toISO()
    )
      .minus({ months: 1 })
      .toFormat("yyyy-MM-dd");
  } else if (date_grouping === "daily") {
    startDate = DateTime.fromISO(
      DateTime.fromFormat(start_date, "yyyy-MM-dd").toISO()
    )
      .minus({ days: 1 })
      .toFormat("yyyy-MM-dd");
  }
  const params = {
    data_wh_id: acc.id,
    start_date: startDate,
    end_date: end_date,
    group_by: group_by,
    date_grouping: date_grouping,
    tag_name: tag_name,
    page_num: page_num + 1,
    page_size
  };
  const URL = attachParams(`/v2/dbx_cost_explorer/${currentKey}`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getQueryData = async (acc, start_date, end_date) => {
  const params = {
    data_wh_id: acc.id,
    start_date: start_date,
    end_date: end_date
  };
  const URL = attachParams(
    `/v2/cost_explorer/cloud_services/top_query_groups`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getForecastCost = async (acc, start_date, end_date) => {
  const params = {
    data_wh_id: acc.id,
    start_date: start_date,
    end_date: end_date
  };
  const URL = attachParams(
    `/v2/cost_explorer/overview/forecasted_cost`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

const defaults = {
  costexplorer: "usage_type",
  compute: "service_type",
  storage: "none", //no other grpby options
  data_transfer: "transfer_type",
  cloud_services: "warehouse_name",
  warehouse: "warehouse_name",
  replication: "source_account",
  autocluster: "table_name",
  pipe: "pipe_name",
  search_optimization: "table_name",
  materialized_view: "table_name",
  query_acceleration: "warehouse_name",
  serverless_tasks: "task_name",
  ai_services: "model_name",
  snowpark: "compute_pool_name"
};

export const getGroupbyID = (path) => {
  if (path) {
    return defaults[path] ? defaults[path] : "none";
  }
  return "none";
};

export const extractColumns = (data) => {
  // Extract the keys from the first element
  const firstItem = _.head(data);
  const baseKeys = _.keys(_.omit(firstItem, "date"));

  // Collect all unique usage_month_date values
  const uniqueDates = _.uniq(_.map(data, "date"));

  // Combine base keys with unique dates
  return [...baseKeys, ...uniqueDates];
};
export const linkStyle = {
  active: {
    color: "#60CA9A",
    textDecoration: "none",
    cursor: "pointer",
    fontSize: "16px",
    fontFamily: "Inter-Semibold",
    lineHeight: "22px" /* 150% */,
    letterSpacing: "0.2px"
  },
  inactive: {
    color: "#000000",
    textDecoration: "none",
    fontSize: "16px",
    fontFamily: "Inter-Medium",
    lineHeight: "22px" /* 150% */,
    letterSpacing: "0.2px"
  }
};

export const transformChartData = (data) => {
  // Extract xValue
  const xValue = Object.keys(data);
  let counter = 0;

  // Build yValue
  const yValue = _(data)
    .flatMap()
    .groupBy("type")
    .map((items, type) => {
      const dataValues = _.map(
        xValue,
        (date) => _.find(items, { date, type })?.cost || 0
      );
      return {
        name: type,
        data: dataValues,
        color: colors[counter++], // Use modulo to avoid index out of bounds
        sum: _.sum(dataValues)
      };
    })
    .value();

  return { y: yValue.reverse(), x: xValue };
};
export const groupData = (data, granularity, grouping) => {
  const groupedData = _.groupBy(data, (item) => {
    const date = DateTime.fromISO(item.date);
    switch (granularity) {
      case "monthly":
        return date.toFormat("yyyy-MM");
      case "weekly":
        return date.startOf("week").toISODate();
      case "daily":
        return item.date.substring(0, 10);
      default:
        return null;
    }
  });

  // Process each group
  _.forEach(groupedData, (items, key) => {
    if (!grouping) {
      groupedData[key] = _(items)
        .map((item) => ({
          date: key,
          type: "All",
          cost: item?.cost
        }))
        .sortBy("cost")
        .value();
    } else {
      groupedData[key] = _(items)
        .groupBy(grouping)
        .map((groupItems, type) => ({
          date: key,
          type,
          cost: _.sumBy(groupItems, "cost")
        }))
        .sortBy("cost")
        .value();
    }
  });
  const result = transformChartData(groupedData);
  return result;
};
export const getChartData = (data, granularity, grouping) => {
  return groupData(data, granularity?.value, grouping?.groupby_column);
};

export const getCurrentCostText = (date, granularity) => {
  if (date) {
    const label = granularity?.label?.toLowerCase();
    if (label === "daily")
      return DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("LLL dd yyyy");
    else if (label === "monthly")
      return `${DateTime.fromFormat(date, "yyyy-MM-dd")?.toFormat(
        "LLL yyyy"
      )} MTD`;
    else if (label === "weekly") {
      return `Week of ${DateTime.fromFormat(date, "yyyy-MM-dd")
        ?.startOf("week")
        .toFormat("LLL dd yyyy")}`;
    }
  }
  return "";
};

export const getChartFilteredData = (applyifilters, data, key) => {
  const filteredData = applyifilters?.flatMap((filterItem) =>
    filterItem?.value?.flatMap((filterValue) =>
      data?.filter((val) => _.get(val, key) === filterValue)
    )
  );

  return _.uniqBy(filteredData, (item) => _.get(item, "name"));
};
