import menuIcons from "../assets/images/dashboard/index";
import { getRequest } from "./http-helper";
import { attachParams } from "./url-helper";

const IconList = {
  Compute: {
    name: "Compute",
    inactive: menuIcons?.compute_img,
    active: menuIcons?.compute_img_active,
    module: "Compute"
  },
  "Compute Overview": {
    name: "Overview",
    inactive: "",
    active: "",
    module: "Compute"
  },
  Inventory: {
    name: "Inventory",
    inactive: "",
    active: "",
    module: "Compute"
  },
  Organization: {
    name: "Organization",
    inactive: menuIcons?.organization_img,
    active: menuIcons?.organization_img_active,
    module: "Organization"
  },
  Recommendations: {
    name: "Recommendations",
    inactive: menuIcons?.db_recommendation_inactive,
    active: menuIcons?.db_recommendation_active,
    module: "Recommendations"
  },
  Chargeback: {
    name: "Chargeback",
    inactive: menuIcons?.chargeback_inactive,
    active: menuIcons?.chargeback_active,
    module: "Chargeback"
  },
  "Cost Center": {
    name: "Cost Center",
    inactive: menuIcons?.chargeback_inactive,
    active: menuIcons?.chargeback_active,
    module: "Chargeback"
  },
  "Cost Reporting": {
    name: "Cost Reporting",
    inactive: menuIcons?.chargeback_inactive,
    active: menuIcons?.chargeback_active,
    module: "Chargeback"
  },

  "Cluster Recommendations": {
    name: "Recommendations",
    inactive: "",
    active: "",
    module: "Cluster"
  },
  "Search Optimization": {
    name: "Search Optimization",
    inactive: "",
    active: "",
    module: "Credits"
  },
  Workloads: {
    name: "Workloads",
    inactive: menuIcons?.workloads_img,
    active: menuIcons?.workloads_img_active,
    module: "Workloads"
  },
  "Query Groups": {
    name: "Query Groups",
    inactive: "",
    active: "",
    module: "Workloads"
  },
  "Failed Queries": {
    name: "Failed Queries",
    inactive: "",
    active: "",
    module: "Workloads"
  },
  "Query Recommendations": {
    name: "Recommendations",
    inactive: menuIcons?.active_rcs,
    active: menuIcons?.active_rcs_active,
    module: "Workloads"
  },
  "Query Overview": {
    name: "Overview",
    inactive: menuIcons.inventory,
    active: menuIcons.inventory_active,
    module: "Workloads"
  },
  Storage: {
    name: "Storage",
    inactive: menuIcons.storage_img,
    active: menuIcons.storage_img_active,
    module: "Storage"
  },
  "Tables Overview": {
    name: "Overview",
    inactive: menuIcons.inventory,
    active: menuIcons.inventory_active,
    module: "Storage"
  },
  "Table Groups": {
    name: "Table Groups",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Storage"
  },
  "Unused Tables": {
    name: "Unused Tables",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Storage"
  },
  "Dropped Tables": {
    name: "Dropped Tables",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Storage"
  },
  Governance: {
    name: "Governance",
    inactive: menuIcons.governance_img,
    active: menuIcons.governance_img_active,
    module: "Governance"
  },
  "User Overview": {
    name: "User Overview",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Governance"
  },
  Changelog: {
    name: "Changelog",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Governance"
  },
  // "Usage Insights": {
  //   name: "Usage Insights",
  //   inactive: menuIcons.dt_trnsfr,
  //   active: menuIcons.dt_trnsfr_active,
  //   module: "Users"
  // },
  "Credits Warehouse": {
    name: "Warehouse",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Cost Explorer"
  },

  "Cost Explorer": {
    name: "Cost Explorer",
    inactive: menuIcons.cost_exp,
    active: menuIcons.cost_exp_active,
    module: "Cost Explorer"
  },
  PIPE: {
    name: "PIPE",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Cost Explorer"
  },
  "Cloud Services": {
    name: "Cloud Services",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Cost Explorer"
  },
  Autocluster: {
    name: "Autocluster",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Cost Explorer"
  },
  "Overall Compute": {
    name: "Compute",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Cost Explorer"
  },
  "Data Transfer": {
    name: "Data Transfer",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Cost Explorer"
  },
  "Cost Explorer Storage": {
    name: "Storage",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Cost Explorer"
  },
  "Cost Explorer Overview": {
    name: "Overview",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Cost Explorer"
  },
  "Organization Settings": {
    name: "Organization Settings",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Settings"
  },
  "Alert Settings": {
    name: "Alert Settings",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Settings"
  },
  "SSO Configuration": {
    name: "SSO Configuration",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Settings"
  },
  "User Provisioning": {
    name: "User Provisioning",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Settings"
  },
  Billing: {
    name: "Billing",
    inactive: menuIcons.dt_trnsfr,
    active: menuIcons.dt_trnsfr_active,
    module: "Settings"
  },
  "Data Source": {
    name: "Data Source",
    inactive: menuIcons.dtsource_img,
    active: menuIcons.dtsource_img_active,
    module: "Data Source"
  },
  Home: {
    name: "Home",
    inactive: menuIcons.home_inactive,
    active: menuIcons.home_active,
    module: "Home"
  },
  "Select Connection": {
    name: "Select Connection",
    inactive: menuIcons.dtsource_img,
    active: menuIcons.dtsource_img_active,
    module: "Data Source"
  },
  "Connect Accounts": {
    name: "Connect Accounts",
    inactive: menuIcons.dtsource_img,
    active: menuIcons.dtsource_img_active,
    module: "Data Source"
  }
};
const HealthDashboardList = {
  Compute: {
    name: "Compute",
    id: 1,
    image: menuIcons?.health_compute,
    redirectionText: "View Recommendations",
    symbol: { pre: "", post: "%" },
    redirectTo: { module: "Compute", page: "Recommendations" }
  },
  "Cloud Services": {
    name: "Cloud Services",
    id: 3,
    image: menuIcons?.health_cost_exp,
    redirectionText: "View Cloud Services",
    redirectTo: { module: "Cost Explorer", page: "Cloud Services" },
    param: "costexplorer/cloud_services",
    symbol: { pre: "$", post: "/day" }
  },
  Workloads: {
    name: "WorkLoads",
    image: menuIcons?.health_workload,
    redirectionText: "View Recommendations",
    redirectTo: { module: "Workloads", page: "Recommendations" },
    symbol: { pre: "", post: "%" }
  },
  "Non-Active Storage": {
    name: "Non-Active Storage",
    id: 5,
    image: menuIcons?.health_storage,
    redirectionText: "View Non-Active Storage",
    redirectTo: { module: "Storage", page: "Overview" },
    symbol: { pre: "", post: "GB" }
  },
  "Failed Queries": {
    name: "Failed Queries",
    id: 2,
    image: menuIcons?.health_workload,
    redirectionText: "Fix Failed Queries",
    redirectTo: { module: "Workloads", page: "Failed Queries" },
    symbol: { pre: "$", post: "/day" }
  },
  "Unused Tables": {
    name: "Unused Tables",
    id: 4,
    image: menuIcons?.health_storage,
    redirectionText: "View Unused Tables",
    redirectTo: { module: "Storage", page: "Unused Tables" },
    symbol: { pre: "", post: "GB" }
  }
};
export const getHealthDashboardObj = (key) => {
  return HealthDashboardList[key];
};

export const getMetricSymbol = (metric_type) => {
  let obj = { pre: "", post: "" };
  if (metric_type) {
    switch (metric_type) {
      case "GBs": {
        obj = { pre: "", post: "GB" };
        break;
      }
      case "TBs": {
        obj = { pre: "", post: "TB" };
        break;
      }
      case "cost per day": {
        obj = { pre: "$", post: "/day" };
        break;
      }
      case "percentage": {
        obj = { pre: "", post: "%" };
        break;
      }
      case "default": {
        obj = { pre: "", post: "GB" };
        break;
      }
    }
  }
  return obj;
};

export const getMetricID = (name) => {
  return HealthDashboardList[name]?.id;
};

export const getIconFromModule = (key) => {
  return IconList[key];
};

const links = {
  compute: { name: "Compute", module: "Compute" },
  organization_summary: { name: "Organization", module: "Organization" },
  chargeback: { name: "Chargeback", module: "Chargeback" },
  "chargeback/costcenter": { name: "Cost Center", module: "Chargeback" },
  "chargeback/costreporting": { name: "Cost Reporting", module: "Chargeback" },

  datasource: { name: "Data Source", module: "Data Source" },
  home: { name: "Home", module: "Home" },
  selectconnection: { name: "Select Connection", module: "Data Source" },
  "/selectconnection/connectaccounts/:id": {
    name: "Connect Accounts",
    module: "Data Source"
  },
  "compute/overview": { name: "Overview", module: "Compute" },
  "compute/inventory": { name: "Inventory", module: "Compute" },
  "compute/utilization": { name: "Utilization", module: "Compute" },
  "compute/momperformance": {
    name: "M-o-M Performance",
    module: "Compute"
  },
  "compute/recommendations": {
    name: "Recommendations",
    module: "Compute"
  },
  workloads: { name: "Workloads", module: "Workloads" },
  "workloads/overview": { name: "Overview", module: "Workloads" },
  "workloads/groups": { name: "Query Groups", module: "Workloads" },
  "workloads/top50": { name: "Top 50 Queries by Cost", module: "Workloads" },
  "workloads/failed": { name: "Failed Queries", module: "Workloads" },
  "workloads/recommendations": { name: "Recommendations", module: "Workloads" },
  storage: { name: "Storage", module: "Storage" },
  "storage/overview": { name: "Overview", module: "Storage" },
  "storage/groups": { name: "Table Groups", module: "Storage" },
  "storage/unused": { name: "Unused Tables", module: "Storage" },
  "storage/dropped": { name: "Dropped Tables", module: "Storage" },
  governance: { name: "Governance", module: "Governance" },
  "governance/overview": { name: "User Overview", module: "Governance" },
  "governance/usageinsights": { name: "Usage Insights", module: "Governance" },
  "governance/changelog": { name: "Changelog", module: "Governance" },
  costexplorer: { name: "Cost Explorer", module: "Cost Explorer" },
  "costexplorer/overview": {
    name: "Overview",
    module: "Cost Explorer"
  },
  "settings/organization": {
    name: "Organization Settings",
    module: "Settings"
  },
  "settings/alerts": {
    name: "Alert Settings",
    module: "Settings"
  },
  "settings/billing": {
    name: "Billing",
    module: "Settings"
  },
  "settings/alerts/slack": {
    name: "Alert Settings",
    module: "Settings"
  },
  "settings/sso_configuration": {
    name: "SSO Configuration",
    module: "Settings"
  },
  "settings/user_provisioning": {
    name: "User Provisioning",
    module: "Settings"
  }
};

const submodule_link = {
  selectconnection: { name: "Select Connection", module: "Data Source" },
  "/selectconnection/connectaccounts/:id": {
    name: "Connect Accounts",
    module: "Data Source"
  },
  // costexplorer: { name: "Cost Explorer", module: "Cost Explorer" },
  datasource: { name: "Data Source", module: "Data Source" },
  storage: { name: "Storage", module: "Storage" },
  "storage/mostusedtables": { name: "Most Used Tables", module: "Storage" },
  workloads: { name: "Workloads", module: "Workloads" },
  "workloads/jobs/overview": {
    name: "Overview",
    module: "Workloads",
    submodule: "Jobs"
  },
  "workloads/jobs/top50": {
    name: "Top 50 Jobs",
    module: "Workloads",
    submodule: "Jobs"
  },
  "workloads/jobs/failed": {
    name: "Failed Jobs",
    module: "Workloads",
    submodule: "Jobs"
  },
  "workloads/jobs/top50failed": {
    name: "Top 50 Failed Jobs",
    module: "Workloads",
    submodule: "Jobs"
  },
  "workloads/notebook/overview": {
    name: "Overview",
    module: "Workloads",
    submodule: "Notebooks"
  },
  "workloads/dbsqlqueries/overview": {
    name: "Overview",
    module: "Workloads",
    submodule: "Queries"
  },
  "workloads/dbsqlqueries/top50": {
    name: "Top 50 Queries",
    module: "Workloads",
    submodule: "Queries"
  },
  "workloads/dbsqlqueries/failed": {
    name: "Failed Queries",
    module: "Workloads",
    submodule: "Queries"
  },
  costexplorer: {
    name: "Overview",
    module: "Cost Explorer"
  },
  "workloads/dbsqlqueries/top50failed": {
    name: "Top 50 Failed Queries",
    module: "Workloads",
    submodule: "Queries"
  },
  compute: { name: "Compute", module: "Compute" },
  "compute/clusters/overview": {
    name: "Overview",
    module: "Compute",
    submodule: "Clusters"
  },
  "compute/clusters/inventory": {
    name: "Inventory",
    module: "Compute",
    submodule: "Clusters"
  },
  "compute/dbsqlwarehouse/overview": {
    name: "Overview",
    module: "Compute",
    submodule: "Warehouses"
  },
  "compute/dbsqlwarehouse/inventory": {
    name: "Inventory",
    module: "Compute",
    submodule: "Warehouses"
  },
  "settings/organization": {
    name: "Organization Settings",
    module: "Settings"
  },
  "settings/alerts": {
    name: "Alert Settings",
    module: "Settings"
  },
  "settings/billing": {
    name: "Billing",
    module: "Settings"
  },
  "settings/alerts/slack": {
    name: "Alert Settings",
    module: "Settings"
  },
  "settings/sso_configuration": {
    name: "SSO Configuration",
    module: "Settings"
  },
  "settings/user_provisioning": {
    name: "User Provisioning",
    module: "Settings"
  },
  "recommendations/cluster": {
    name: "Clusters",
    module: "Recommendations"
  },
  "recommendations/dbsql": {
    name: "DB SQL",
    module: "Recommendations"
  },
  "recommendations/jobs": {
    name: "Jobs",
    module: "Recommendations"
  }
};

export const getDashboardKeys = (key) => {
  const keys = Object.keys(links);
  let nearestMatch = null;
  let minDistance = Infinity;

  for (const objKey of keys) {
    const regex = new RegExp(`^${objKey.replace(/\//g, "\\/")}`);
    if (regex.test(key)) {
      const distance = key.length - objKey.length;
      if (distance < minDistance) {
        minDistance = distance;
        nearestMatch = links[objKey];
      }
    }
  }

  return {
    name: nearestMatch?.name,
    module: nearestMatch?.module,
    submodule: nearestMatch?.submodule ? nearestMatch?.submodule : undefined
  };
};

export const getDashboardKeysDBX = (key) => {
  const keys = Object.keys(submodule_link);
  let nearestMatch = null;
  let minDistance = Infinity;

  for (const objKey of keys) {
    const regex = new RegExp(`^${objKey.replace(/\//g, "\\/")}`);
    if (regex.test(key)) {
      const distance = key.length - objKey.length;
      if (distance < minDistance) {
        minDistance = distance;
        nearestMatch = submodule_link[objKey];
      }
    }
  }

  return {
    name: nearestMatch?.name,
    module: nearestMatch?.module,
    submodule: nearestMatch?.submodule ? nearestMatch?.submodule : undefined
  };
};

export const getDashboardLink = (key, page, submodule) => {
  let res = "";
  const module = page ? page : key;
  res = Object.keys(links).find((link) => {
    return submodule
      ? links[`${link}`].name === key &&
          links[`${link}`].module === module &&
          links[`${link}`].submodule === submodule
      : links[`${link}`].name === key && links[`${link}`].module === module;
  });
  return res;
};
export const getDashboardLinkWithSubmodule = (key, page, submodule) => {
  let res = "";
  const module = page ? page : key;
  res = Object.keys(submodule_link).find((link) => {
    return submodule
      ? submodule_link[`${link}`].name === key &&
          submodule_link[`${link}`].module === module &&
          submodule_link[`${link}`].submodule === submodule
      : submodule_link[`${link}`].name === key &&
          submodule_link[`${link}`].module === module;
  });
  return res;
};
export const getIcons = (key, page) => {
  let res = "";
  const module = page ? page : key;
  res = Object.keys(IconList).find((link) => {
    return (
      IconList[`${link}`].name === key && IconList[`${link}`].module === module
    );
  });
  return res;
};
export const addDashboardIcons = (arr) => {
  let newArr = arr.map((item) => {
    return {
      ...item,
      link: getDashboardLink(item?.page_display_name, item?.module_name),
      img: getIcons(item?.page_display_name, item?.module_name)
    };
  });
  return newArr;
};

export const getDashboardHeader = async () => {
  const params = {};
  const URL = attachParams(`/v1/streamlit/sidebar`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};
export const getDashboardHeaderNew = async () => {
  const params = {};
  const URL = attachParams(`/v2/sidebar`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getHealthDashboardDateRangeAPI = async (accID) => {
  const params = { data_wh_id: accID };
  const URL = attachParams(
    `/v1/analytics/warehouses/overview/date_ranges`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getHealthDashboardDateRange = async (accID) => {
  const params = { data_wh_id: accID };
  const URL = attachParams(`/v2/dashboard/metrics/date_ranges`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getMetricsDataAPI = async (acc, dateRange) => {
  const params = {
    data_wh_id: acc?.id,
    date_range: dateRange?.value
  };
  const URL = attachParams(`/v2/dashboard/metrics`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
