import { CustomTooltip } from "../../../utils/tooltip-helper";
import {
  PrettyFormat,
  roundToSigDigits,
  secondsConverter
} from "../../../utils/common-helper";

export const getTop50QueriesProps = (hasTrialFallback) => {
  const customProps = {
    "S No.": {
      type: "rightAligned",
      headerClass: "text-align-right",
      width: 75,
      pinned: "left",
      cellRenderer: (params) => {
        return params?.data["S No."];
      }
    },
    "Query Group": {
      width: 247,
      filter: true,
      pinned: "left",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(PrettyFormat(params.value), params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    Warehouses: {
      width: 200,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    Users: {
      width: 200,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Total Runs": {
      width: 170,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Total Failed Runs": {
      width: 170,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Total Execution Time": {
      width: 200,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params?.data["Total Execution Time"];
        }
        return secondsConverter(
          roundToSigDigits(
            params?.data["Total Execution Time"],
            2,
            false,
            false
          )
        );
      }
    },
    "Avg. Execution Time": {
      width: 200,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params?.data["Avg. Execution Time"];
        }
        return secondsConverter(
          roundToSigDigits(params?.data["Avg. Execution Time"], 2, false, false)
        );
      }
    },
    "Total Disk Spillage (GB)": {
      width: 200,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Avg. Disk Spillage (GB)": {
      width: 200,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(
          params?.data["Avg. Disk Spillage (GB)"],
          2,
          false,
          false
        );
      }
    },
    "Total Partitions Read": {
      width: 200,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Avg. Partitions Read": {
      width: 200,
      type: "rightAligned",
      headerClass: "text-align-right",
      resizable: false,
      sortable: false,
      cellRenderer: (params) => {
        return roundToSigDigits(
          params?.data["Avg. Partitions Read"],
          2,
          false,
          false
        );
      }
    },

    "Job Id": {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      resizable: false,
      sortable: false,
      //pinned: "right",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    }
  };

  return customProps;
};
