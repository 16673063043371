import React from "react";
import { roundToSigDigits } from "../../utils/common-helper";
import HTMLRenderrer from "../../utils/htmlRenderrer";
import { CustomListForKeyValue } from "../../utils/changelog-helper";
import { CustomTooltip } from "../../utils/tooltip-helper";

export const getWHRecommendationProps = (hasTrialFallback) => {
  const customProps = {
    index: {
      type: "rightAligned",
      width: 75,
      pinned: "left",
      headerName: "S No.",
      suppressSizeToFit: true,
      headerClass: "text-align-right"
    },
    warehouse_name: {
      //width: 200,
      filter: true,
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 10);
      },
      cellClass: "grid-cell-left-aligned",
      suppressSizeToFit: true
    },
    warehouse_config: {
      valueGetter: (params) => {
        return {
          size: params.data.warehouse_size,
          cluster_size: params.data.warehouse_cluster_size_min_max,
          scaling_policy: params.data.warehouse_scaling_policy,
          auto_suspend: params.data.warehouse_auto_suspend,
          query_acceleration_enabled: params.data.enable_query_acceleration,
          query_acceleration_max_scale_factor:
            params.data.query_acceleration_max_scale_factor
        };
      },
      cellRenderer: (params) => {
        return CustomListForKeyValue(JSON.stringify(params.value));
      },
      hasCustomListforKeys: true,
      cellClass: "grid-cell-left-aligned",
      sortable: false,
      wrapText: true,
      autoHeight: true,
      width: 250
    },
    observations: {
      cellClass: "grid-cell-left-aligned ",
      autoHeight: true,
      wrapText: true,
      width: 200,
      cellRenderer: (params) => {
        return <HTMLRenderrer value={params?.value} />;
      }
    },
    warehouse_recommendations: {
      cellClass: "grid-cell-left-aligned ",
      autoHeight: true,
      wrapText: true,
      width: 200,
      cellRenderer: (params) => {
        return <HTMLRenderrer value={params?.value} />;
      }
    },
    credits_used: {
      width: 150,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      },
      headerClass: "text-align-right"
    },
    warehouse_cost: {
      width: 135,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      },
      pinned: "right"
    },
    wh_utilization: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      suppressSizeToFit: true,
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    warehouse_idle_creds: {
      width: 104,
      type: "rightAligned",
      suppressSizeToFit: true,
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      },
      pinned: "right",
      headerClass: "text-align-right"
    },
    failed_queries_cost: {
      width: 140,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_daily_bytes_spilled_remote_gb: {
      width: 170,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_queued_overload_time: {
      minWidth: 187,
      type: "rightAligned",
      headerClass: "text-align-right",
      resizable: false,
      flex: 1,
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    }
  };
  return customProps;
};
