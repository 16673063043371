import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./datasource.scss";
import GridComponent from "../../components/GridComponent";
import { createColDefs } from "../../utils/common-helper";
import { formatDateTime } from "../../utils/date-helper";
import { getCardItem } from "../../utils/cards-helper";
import { useNavigate } from "react-router-dom";
import plus_add from "../../assets/images/dashboard/plus_add.svg";
import { getSessionStorage } from "../../utils/storage-helper";
import delete_red from "../../assets/images/dashboard/delete_red.svg";
import org_edit from "../../assets/images/common/org_edit.svg";
import ConnectionEdit from "../../components/ConnectionForm/ConnectionEdit";
import { deleteDW, patchUpDW } from "../../utils/user-helper";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { getException } from "../../utils/exceptiondictionary";

const DataSource = ({
  activeModule,
  dwAccOptions,
  dwAccOptionsChanged,
  acc
}) => {
  const plan = getSessionStorage("plan_name").toLowerCase();
  const [hasTrialFallback] = useState(
    plan === "trial" || plan === "free" ? true : false
  );
  const [connection, setConnection] = useState("");
  const univerSalStyle = {
    cellStyle: { borderRight: "1px solid #eaeaeb" }
  };
  const navigate = useNavigate();
  const gotoSelectConn = () => {
    navigate("../selectconnection");
  };
  const toast = useToast();

  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const [customProps] = useState({
    display_name: {
      //width: 280,
      headerName: "Data Connection Name",
      cellClass: "grid-cell-left-aligned no-border"
    },
    setup_done: {
      width: 120,
      headerName: "Status",
      cellClass: "grid-cell-left-aligned no-border",
      cellRenderer: (params) => {
        return params?.value ? (
          <span style={{ color: "#60ca9a" }}>Live</span>
        ) : (
          <span style={{ color: "#fac06b" }}>Pending</span>
        );
      }
    },
    connection_type: {
      //width: 220,
      cellClass: "grid-cell-left-aligned no-border",
      headerName: "Data Source Type",
      cellRenderer: (params) => {
        const item = getCardItem(params?.value, true);
        return (
          <div className="dt-img-text-container">
            <img src={item?.image} />
            <span>{params?.value}</span>
          </div>
        );
      }
    },
    last_sync_on: {
      //width: 280,
      headerName: "Last Synced",
      cellClass: "grid-cell-left-aligned no-border",
      cellRenderer: (params) => {
        const formattedDate = formatDateTime(params?.value, true, false);
        return <span>{formattedDate === "-" ? "" : formattedDate}</span>;
      }
    },
    created_on: {
      // width: 280,
      headerName: "Created On",
      cellClass: "grid-cell-left-aligned no-border",
      cellRenderer: (params) => {
        return <span>{formatDateTime(params?.value, true, false)}</span>;
      }
    },
    action: {
      width: 100,
      cellClass: "grid-cell-left-aligned ",
      headerName: "Action",
      field: "action",
      pinned: "right",
      cellRenderer: (params) => {
        return (
          <div className="update__field">
            <button
              data-id="2"
              onClick={() => {
                setConnection({
                  name: params?.data?.connection_type,
                  id: params?.data?.data_warehouse_info_id
                });
                changeModalOpen({ bool: true, status: "edit" });
                setEditFields(params);
              }}
              className="delete_user">
              <img src={org_edit} />
            </button>

            <button
              data-id="2"
              onClick={() => {
                setConnection({
                  name: params?.data?.connection_type,
                  id: params?.data?.data_warehouse_info_id
                });
                changeModalOpen({ bool: true, status: "delete" });
                setEditFields(params);
              }}
              className="delete_user">
              <img src={delete_red} />
            </button>
          </div>
        );
      }
    }
  });
  //   const univerSalStyle = {
  //     cellStyle: { borderRight: "1px solid #eaeaeb" }
  //   };

  const [rowData, setRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [gridFallback, setGridFallback] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const [editFields, setEditFields] = useState(null);

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([]);
  const column_display_name_mappping = {
    display_name: "Data Connection Name",
    setup_done: "Status",
    connection_type: "Data Source Type",
    last_sync_on: "Last Synced",
    created_on: "Created On",
    action: "Action"
  };
  const changeModalOpen = (obj) => {
    setStatus(obj?.status);
    setIsModalOpen(obj?.bool);
  };
  const setupConnection = async (fields) => {
    try {
      const dw = await patchUpDW(fields, connection);
      console.log(dw);
      changeIsLoading(true);
      dwAccOptionsChanged(
        connection?.id.toString() === acc?.data_warehouse_info_id.toString()
          ? acc?.data_warehouse_info_id
          : undefined
      );
    } catch (error) {
      console.log(error);
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorKey,
          error?.response?.data?.message
        )
      });
      changeIsLoading(true);
      dwAccOptionsChanged();
    }
  };
  const getTableData = async (optionsArr) => {
    try {
      if (optionsArr && optionsArr?.length) {
        if (optionsArr) {
          const columns = [
            "display_name",
            "setup_done",
            "connection_type",
            "last_sync_on",
            "created_on",
            "action"
          ];
          const colDefsArr = createColDefs(
            columns,
            customProps,
            univerSalStyle,
            column_display_name_mappping
          );
          if (colDefsArr && optionsArr) {
            setColumnDefs(colDefsArr);
            setRowData(optionsArr);
          }
        } else {
          clearData();
        }
        setGridLoading(false);
        if (gridFallback) {
          setGridFallback(false);
        }
      }
    } catch (err) {
      console.log(err);
      setGridLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
    }
  };

  const clearData = () => {
    setRowData([]);
  };

  const getData = async () => {
    try {
      if (dwAccOptions && dwAccOptions?.length) {
        getTableData(dwAccOptions);
        setGridLoading(false);
        setIsPageLoading(false);
      } else {
        clearData();
        setGridLoading(false);
        setIsPageLoading(false);
      }
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
      console.log(err);
    }
  };
  const changeIsLoading = (bool) => {
    setGridLoading(bool);
    setIsPageLoading(bool);
  };
  const deleteConnection = async (field) => {
    try {
      const dw = await deleteDW(field);
      console.log(dw);
      changeIsLoading(true);
      dwAccOptionsChanged(
        connection?.id.toString() === acc?.data_warehouse_info_id.toString()
          ? undefined
          : acc?.data_warehouse_info_id
      );
    } catch (e) {
      console.error(e);
      customToast({
        type: "error",
        ...getException(e?.response?.data?.errorKey, e?.response?.data?.message)
      });
      changeIsLoading(true);
      dwAccOptionsChanged();
    }
  };
  useEffect(() => {
    setIsPageLoading(true);
    setGridLoading(true);
    getData();
  }, [dwAccOptions]);
  const closeModal = () => {
    setEditFields(null);
    setStatus(null);
    setIsModalOpen(false);
    setConnection(null);
  };
  return (
    <>
      {isModalOpen && (
        <ConnectionEdit
          connection={connection}
          closeModal={closeModal}
          status={status}
          setupConnection={setupConnection}
          deleteConnection={deleteConnection}
          changeIsLoading={changeIsLoading}
          editFields={editFields}
        />
      )}
      <div className="view-ctrl-container">
        <div className="viewctrl-header-container">
          <div className="header-block">
            <span>{activeModule}</span>
          </div>

          <button
            className={`btn btn-green-variant add__dt__src ${
              hasTrialFallback ? "disabled" : ""
            }`}
            disabled={hasTrialFallback}
            onClick={() => {
              gotoSelectConn();
            }}>
            {hasTrialFallback ? null : <img src={plus_add} />}
            <span>Add Data Source</span>
          </button>
        </div>
        <div className="ctrl-main-block">
          <div className="datasource-container">
            {!isPageLoading ? (
              <>
                <div className="grid-block">
                  <GridComponent
                    columnDefs={columnDefs}
                    rowData={rowData}
                    gridLoading={gridLoading}
                    gridFallback={gridFallback}
                    gridStyle={{
                      height: rowData && rowData.length ? 500 : 450,
                      avoidAutoHeightOverflow:
                        rowData?.length >= 0 && rowData?.length <= 14
                          ? false
                          : true,
                      noborder: false,
                      rowHeight: 46,
                      additionalClass: "cell-flex text-ellipse"
                    }}
                    suppressColumnsSize={true}
                    noRowsText={"No Data Sources Found"}
                  />
                </div>
              </>
            ) : null}
            {isPageLoading && (
              <div className="load">
                <div className="preload"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
DataSource.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  dwAccOptions: PropTypes.array,
  activeModule: PropTypes.string,
  dwAccOptionsChanged: PropTypes?.func
};
export default DataSource;
