import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import StackGraph from "../ChartComponent/StackGraph";
import { roundToSigDigits } from "../../utils/common-helper";
import { getUniqueDate } from "../../utils/chargeback-helper";
import { costFormatter } from "../../utils/organization-helper";
import Highcharts from "highcharts";
import addExportingModule from "highcharts/modules/exporting";
import addOfflineExportingModule from "highcharts/modules/offline-exporting";
import { customDateTimeFormat } from "../../utils/date-helper";
addExportingModule(Highcharts);
addOfflineExportingModule(Highcharts);
function StackGraphContainer({
  chartTitle,
  activeTab,
  rawData,
  graphData,
  chartRef,
  dateRange
}) {
  const [stackOption, setStackOption] = useState(null);
  useEffect(() => {
    if (graphData && graphData?.length) {
      setStackOption({ ...StackOptions, series: graphData?.reverse() });
    }
  }, [graphData]);
  const dateFormat =
    rawData?.date_aggregation === "monthly" ? "MMM yyyy" : "dd MMM yyyy";
  let x_axis_data = rawData?.data?.map((item) => ({
    ...item,
    month_date: customDateTimeFormat(item.date, dateFormat)
  }));
  const uniqueDates = !/MTD/.test(dateRange?.label)
    ? getUniqueDate(x_axis_data).slice(1)
    : getUniqueDate(x_axis_data); //removec T - 1 by slicing
  const StackOptions = {
    chart: {
      type: "column"
    },
    title: {
      text: chartTitle ? chartTitle : ""
    },
    xAxis: {
      categories: uniqueDates
    },
    yAxis: {
      min: 0,
      title: {
        text: "Cost ($)"
      },
      stackLabels: {
        enabled: true,
        align: "center",
        style: {
          color: "#222"
        },
        x: 0,
        verticalAlign: "top",
        formatter: function () {
          return costFormatter(roundToSigDigits(this.total));
        }
      }
    },
    tooltip: {
      backgroundColor: "black",
      borderRadius: 5, // Add rounded corners to the tooltip
      formatter: function () {
        return `<div style="color:white; background-color: ${
          this.series.color
        }; border: 1px solid ${this.series.color}; padding: 10px;">
        <span> Name - <b>${
          this.series.name
        }</b><br/><span>Value - <b>${costFormatter(
          roundToSigDigits(this.y, 2, false)
        )}</b></span></span>
       
      </div>`;
      }
    },
    legend: {
      reversed: true,
      symbolRadius: 2,
      symbolHeight: 10
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        stacking: "normal",
        dataLabels: {
          enabled: false // Disable data labels
        },
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            enabled: false // Enable hover state for all series
          }
        },
        groupPadding: 0.1 // Adjust the groupPadding as needed to add a gap between stacks
      }
    },
    series: graphData?.reverse()
  };

  //
  //
  // Line Chart Data
  const SPlineOptions = {
    chart: {
      type: "line"
    },

    title: {
      text: " "
    },

    xAxis: {
      type: "datetime",
      tickPixelInterval: 150,
      maxPadding: 0.1
    },

    yAxis: {
      title: {
        text: "Cost ($)"
      }
    },
    plotOptions: {
      series: {
        marker: {
          symbol: "circle", // Set the marker symbol to 'circle'
          enabled: false // Disable individual markers for all series
        },
        states: {
          inactive: {
            opacity: 1
          }
        }
      },
      stickyTracking: false
    },
    tooltip: {
      backgroundColor: "black",
      borderRadius: 5, // Add rounded corners to the tooltip
      formatter: function () {
        return `<div style="color:white; background-color: ${
          this.series.color
        }; border: 1px solid ${this.series.color}; padding: 10px;">
        <span> Name - <b>${
          this.series.name
        }</b><br/><span>Value - <b>$${roundToSigDigits(
          this.y,
          2,
          false
        )}</b></span></span>
       
      </div>`;
      }
    },

    legend: {
      enabled: true
    },
    series: graphData
  };

  return (
    <StackGraph
      options={activeTab === "1" ? stackOption : SPlineOptions}
      chartRef={chartRef}
    />
  );
}
StackGraphContainer.propTypes = {
  activeTab: PropTypes.string,
  rawData: PropTypes.object,
  graphData: PropTypes.array,
  chartRef: PropTypes.object,
  dateRange: PropTypes.object,
  chartTitle: PropTypes?.string
};
export default StackGraphContainer;
