import React from "react";
import PropTypes from "prop-types";
import "./pagination.scss";
import Button from "../Button";
import prev from "../../../assets/images/common/paginationPrev.svg";
import next from "../../../assets/images/common/paginationNext.svg";

const Pagination = (props) => {
  const {
    currentPage = 1,
    previousPage = null,
    nextPage = null,
    totalPages = 1,
    pageSize = 10,
    totalRecords = 0,
    onPageChange
  } = props;

  const handlePageChange = (val, type = "number") => {
    if (type === "size") {
      onPageChange(1, val, "pageSize");
    } else {
      if (val <= totalPages && val > 0) {
        onPageChange(val, pageSize, "pageNumber");
      }
    }
  };

  const validCurrentPage = isNaN(currentPage) ? 1 : currentPage;
  const validPageSize = isNaN(pageSize) ? 10 : pageSize;
  const validTotalRecords = isNaN(totalRecords) ? 0 : totalRecords;

  const startRecord =
    validTotalRecords > 0 ? (validCurrentPage - 1) * validPageSize + 1 : 0;
  const endRecord = Math.min(
    validCurrentPage * validPageSize,
    validTotalRecords
  );

  const pageNumbers = [];
  if (!nextPage && previousPage && previousPage - 1 > 0) {
    pageNumbers.push(previousPage - 1);
  }
  if (previousPage) {
    pageNumbers.push(previousPage);
  }
  pageNumbers.push(validCurrentPage);
  if (nextPage) {
    pageNumbers.push(nextPage);
  }

  if (!previousPage && nextPage && nextPage + 1 <= totalPages) {
    pageNumbers.push(nextPage + 1);
  }

  return (
    <div className="pagination-container">
      <span className="p-size-title">No. of rows per page: </span>

      <select
        className="p-size-dropdown"
        value={validPageSize}
        onChange={(e) => handlePageChange(e.target.value, "size")}>
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
      </select>

      <span className="p-size-title">
        <span>{startRecord}</span>-<span>{endRecord} </span> of{" "}
        <span>{validTotalRecords}</span>
      </span>

      <Button
        className={"p-nav-buttons"}
        onClick={() => handlePageChange(validCurrentPage - 1)}
        disabled={!previousPage}>
        <img src={prev} alt="previous Icon" />
      </Button>

      {pageNumbers.map((pageNumber, index) => (
        <Button
          key={index}
          onClick={() => handlePageChange(pageNumber)}
          className={
            pageNumber === validCurrentPage
              ? "p-number-buttons p-number-button-active"
              : "p-number-buttons"
          }>
          {pageNumber}
        </Button>
      ))}

      <Button
        className={"p-nav-buttons"}
        onClick={() => handlePageChange(validCurrentPage + 1)}
        disabled={!nextPage}>
        <img src={next} alt="next Icon" />
      </Button>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  previousPage: PropTypes.any,
  nextPage: PropTypes.any,
  onPageChange: PropTypes.func,
  totalPages: PropTypes.number,
  pageSize: PropTypes.number,
  totalRecords: PropTypes.number
};

export default Pagination;
