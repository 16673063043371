import React from "react";
import "./App.css";
import "./assets/styles/common.scss";
import AuthProvider from "./utils/authprovider";
import AppRoutes from "./routes";
import ChatwootWidget from "./components/ChatwootWidget";
import { PrimeReactProvider } from "primereact/api";

// Import PrimeReact themes
import "primereact/resources/themes/saga-blue/theme.css"; // or other theme of your choice
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
// Import PrimeReact core styles
import "primereact/resources/primereact.min.css";

function App() {
  const value = {
    appendTo: "self"
  };
  const router = (
    <PrimeReactProvider value={value}>
      <AuthProvider>
        <ChatwootWidget />
        <AppRoutes />
      </AuthProvider>
    </PrimeReactProvider>
  );
  return <div className="App">{router}</div>;
}

export default App;
