import React, { useEffect, useState } from "react";
import StackGraph from "../ChartComponent/StackGraph";
import {
  pretty_print_number_with_suffix,
  roundToSigDigits
} from "../../utils/common-helper";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { costFormatter } from "../../utils/organization-helper";
import Highcharts from "highcharts";
import addExportingModule from "highcharts/modules/exporting";
import addOfflineExportingModule from "highcharts/modules/offline-exporting";
import _ from "lodash";
import { stackGrahpcolors } from "../../utils/cost-reporting-helper";
import { customFormatDate } from "../../utils/date-helper";
addExportingModule(Highcharts);
addOfflineExportingModule(Highcharts);

function GraphContainer({
  chartRef,
  chartData,
  granularity,
  forecastCost,
  chartTitle,
  filterApplied,
  enableLegends = true,
  legendDetails = { align: "right", verticalAlign: "top", layout: "vertical" },
  totals,
  yLabel,
  useCostFormatter = true
}) {
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (chartData && Object?.keys(chartData)?.length) {
      const colors = stackGrahpcolors;
      let finalChartData = JSON.parse(JSON.stringify(chartData));
      let categories = finalChartData?.x;
      if (granularity?.value) {
        const currentDate = DateTime.local();
        switch (granularity?.value) {
          case "monthly": {
            categories = categories?.map((date) => {
              const dateTime = DateTime.fromISO(date);
              const formattedDate = dateTime.toFormat("LLL yyyy");
              const isCurrentMonth = dateTime.hasSame(currentDate, "month");
              return isCurrentMonth ? `${formattedDate} (MTD)` : formattedDate;
            });
            break;
          }
          case "daily":
          case "weekly": {
            const formatString = "LLL dd, yyyy";
            categories = categories.map((date) =>
              DateTime.fromISO(date).toFormat(formatString)
            );
            break;
          }

          default:
            break;
        }
      }

      if (
        !filterApplied &&
        forecastCost &&
        Object.keys(forecastCost)?.length &&
        forecastCost?.values
      ) {
        finalChartData.y = _.map(finalChartData.y, (item) => ({
          ...item,
          data: [...item.data, 0]
        }));
        finalChartData?.x?.push(
          `Forecasted Cost (${DateTime.fromISO(
            forecastCost?.values?.month_name
          ).toFormat("LLL yyyy")})`
        );
        const forecastedData = {
          name: `Forecasted Cost`,
          data: new Array(finalChartData?.x?.length)?.fill(0),
          color: "#7a7a7a"
        };
        forecastedData.data[forecastedData.data.length - 1] =
          forecastCost?.values?.cost;
        const checkisFCPresent = finalChartData?.y?.find(
          (item) => !item?.name?.includes("Forecasted Cost")
        );
        if (checkisFCPresent) {
          categories?.push(
            `Forecasted Cost (${DateTime.fromISO(
              forecastCost?.values?.month_name
            ).toFormat("LLL yyyy")})`
          );
          finalChartData?.y?.push(forecastedData);
        }
      }
      if (finalChartData?.y?.length > 10 && !filterApplied) {
        const sortedData = finalChartData?.y?.sort((a, b) => b.sum - a.sum);
        let differences = [];
        let top10 = sortedData.slice(0, 10);
        let others = sortedData.slice(10, finalChartData?.y?.length);
        const totalColumnsTop10 = _(top10)
          .flatMap((d) =>
            d.data.map((value, i) => ({
              month: finalChartData?.x?.[i],
              value
            }))
          )
          .groupBy("month")
          .map((values, month) => ({
            [month]: {
              date: month,
              value: _.sumBy(values, "value")
            }
          }))
          .value();
        const formattedTotals = _(totals?.granular)
          .map((item) => {
            const formattedDate = customFormatDate(item.date, granularity);
            return {
              [formattedDate]: {
                date: formattedDate,
                usage_quantity: item.usage_quantity,
                cost: item.cost
              }
            };
          })
          .value();
        differences = _.map(formattedTotals, (obj2, index) => {
          const dateKey = _.keys(obj2)[0];
          const obj1 = totalColumnsTop10[index];
          const value1 = obj1[dateKey].value;
          const value2 =
            chartData?.activeTab && chartData?.activeTab === "1"
              ? obj2[dateKey]?.["cost"]
              : obj2[dateKey]?.["usage_quantity"];

          return value2 - value1 > 0 ? value2 - value1 : 0;
        });

        const hasOthers = top10.findIndex((item) => item.name === "Others"); // check if others already present
        let result = {
          name: "Others",
          data:
            totals && Object.keys(totals) && totals?.granular?.length
              ? differences
              : _.reduce(
                  others,
                  (acc, obj) => {
                    _.forEach(obj.data, (value, index) => {
                      acc[index] = (acc[index] || 0) + value;
                    });
                    return acc;
                  },
                  []
                )
        };
        if (hasOthers > -1) {
          const [item] = _.pullAt(top10, hasOthers);
          top10.push(item);
          top10[hasOthers].data = _.map(
            result?.data,
            (item, index) => item + top10[hasOthers]?.data[index]
          );
        } else {
          top10.push(result);
        }
        top10 = top10.map((item, index) => ({ ...item, color: colors[index] }));
        finalChartData.y = top10.reverse();
      } else {
        const new_data = finalChartData?.y.map((item, index) => ({
          ...item,
          color: colors[index]
        }));
        finalChartData.y = new_data.reverse();
      }
      setOptions({
        ...StackOptions,
        series: finalChartData?.y,
        xAxis: {
          categories: categories,
          title: {
            text: "Date",
            margin: 20,
            style: {
              color: "#222",
              fontSize: "14px"
            }
          },
          labels: {
            formatter: function () {
              return this?.value;
            },
            style: {
              color: "#7A7A7A"
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: yLabel
              ? yLabel
              : chartData?.activeTab && chartData?.activeTab !== "1"
              ? "DBUs"
              : "Cost ($)",
            x: -10,
            style: {
              color: "#222",
              fontSize: "14px"
            }
          },
          labels: {
            formatter: function () {
              return pretty_print_number_with_suffix(Number(this.value));
            }
          },
          stackLabels: {
            enabled: true,
            align: "center",
            style: {
              color: "#222"
            },
            x: 0,
            verticalAlign: "top",
            formatter: function () {
              return chartData?.activeTab && chartData?.activeTab !== "1"
                ? roundToSigDigits(this.total, 2, false, false).toLocaleString(
                    "en-US"
                  )
                : useCostFormatter
                ? costFormatter(roundToSigDigits(this.total, 2, false))
                : roundToSigDigits(this.total, 2, false, false).toLocaleString(
                    "en-US"
                  );
            }
          }
        }
      });
    }
  }, [chartData]);

  const StackOptions = {
    chart: {
      type: "column",
      events: {
        render: function () {
          var chart = this,
            legend = chart.legend,
            series = chart.series,
            group = legend.group;
          for (var i = 0, len = legend?.allItems?.length; i < len; i++) {
            (function (i) {
              var item = legend?.allItems[i]?.legendItem;
              const text = item?.label;
              text
                ?.on("mouseover", function () {
                  item.legendTooltip = chart?.renderer
                    .label(series[series.length - 1 - i]?.name, 0, 0)
                    .css({
                      width: 500,
                      color: "white"
                    })
                    .attr({
                      fill: "black",
                      stroke: "none",
                      padding: 10,
                      zIndex: 99,
                      r: 5
                    })
                    .add(group);
                  item.legendTooltip.attr({
                    x:
                      item.x > Number(window?.innerWidth) / 2
                        ? item?.x -
                          Number(item?.textWidth?.replace("px", "")) +
                          30
                        : item?.x,
                    y: item?.y + 20
                  });
                })
                .on("mouseout", function () {
                  if (item.legendTooltip) {
                    item.legendTooltip.destroy();
                    item.legendTooltip = undefined;
                  }
                })
                .on("click", function () {
                  series[series.length - 1 - i].setVisible(
                    !series[series.length - 1 - i].visible
                  );

                  if (item.legendTooltip) {
                    item.legendTooltip.destroy();
                    item.legendTooltip = undefined;
                  }
                });
            })(i);
          }
        }
      }
    },
    title: {
      text: chartTitle ? chartTitle : ""
    },
    lang: {
      noData: "No Data Available"
    },
    tooltip: {
      backgroundColor: "black",
      shared: true,
      useHTML: true,
      borderColor: "#000000",
      borderRadius: 5, // Add rounded corners to the tooltip
      formatter: function () {
        let str = "";
        let data = this?.points;
        data?.forEach((point) => {
          if (parseInt(roundToSigDigits(point?.y, 2, false)) !== 0) {
            if (
              !point?.key?.includes("Forecasted Cost") &&
              !this?.x?.includes("Forecasted Cost")
            ) {
              str += `<div style='display:flex; align-items: center; margin-bottom: 10px;'><span style='height: 11px;
          width: 11px;
          background-color: ${point?.color};
          border-radius: 50%;
          display: inline-block;
        '></span> &nbsp;<span>${
          point?.series?.name
        }</span>&nbsp; -&nbsp; <span>${
                chartData?.activeTab && chartData?.activeTab !== "1"
                  ? roundToSigDigits(point?.y, 2, false, false).toLocaleString(
                      "en-US"
                    )
                  : useCostFormatter
                  ? costFormatter(roundToSigDigits(point?.y, 2, false))
                  : roundToSigDigits(point?.y, 2, false, false).toLocaleString(
                      "en-US"
                    )
              }</span></div>`;
            }
          }
        });
        if (this?.x?.includes("Forecasted")) {
          str += `<div style='display:flex; align-items: center;'><span style='height: 11px;
          width: 11px;
          background-color: #7a7a7a;
          border-radius: 50%;
          display: inline-block;
        '></span> &nbsp;${this?.x}</span> &nbsp;-&nbsp; <span>${
            chartData?.activeTab && chartData?.activeTab !== "1"
              ? roundToSigDigits(
                  this?.points[this?.points?.length - 1],
                  2,
                  false,
                  false
                ).toLocaleString("en-US")
              : costFormatter(
                  roundToSigDigits(
                    this?.points?.[this?.points?.length - 1]?.y,
                    2,
                    false
                  )
                )
          }</span></div>`;
        }
        return `<div style="color: white;" >${str}<br/>&nbsp;<span style='font-weight: bold; color: white;'>Total: ${
          chartData?.activeTab && chartData?.activeTab !== "1"
            ? roundToSigDigits(
                this?.points?.[0]?.total,
                2,
                false,
                false
              ).toLocaleString("en-US")
            : useCostFormatter
            ? costFormatter(
                roundToSigDigits(this?.points?.[0]?.total, 2, false)
              )
            : roundToSigDigits(
                this?.points?.[0]?.total,
                2,
                false,
                false
              ).toLocaleString("en-US")
        }</span></div>`;
      }
    },
    legend: {
      enabled: enableLegends,
      reversed: true,
      symbolRadius: 6,
      align: legendDetails?.align,
      verticalAlign: legendDetails?.verticalAlign,
      floating: false,
      useHTML: true,
      layout: legendDetails?.layout,
      margin: 32,
      symbolHeight: 12,
      symbolWidth: 12,
      itemStyle: {
        fontWeight: 400
      },
      labelFormatter: function () {
        return this?.name?.length > 25
          ? `<span data-id=${this?.name}>${this?.name?.slice(0, 24)}...</span>`
          : this?.name;
      }
    },
    plotOptions: {
      series: {
        animation: false
      },
      column: {
        borderWidth: 0,
        stacking: "normal",
        dataLabels: {
          enabled: false
        },
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            enabled: false
          }
        },
        groupPadding: 0.1
      }
    },
    credits: {
      enabled: false
    }
  };

  return <StackGraph chartRef={chartRef} options={options} />;
}

GraphContainer.propTypes = {
  chartRef: PropTypes.object,
  chartData: PropTypes.object,
  granularity: PropTypes.object,
  groupBy: PropTypes.object,
  forecastCost: PropTypes?.object,
  chartTitle: PropTypes?.string,
  filterApplied: PropTypes.bool,
  legendDetails: PropTypes?.object,
  totals: PropTypes.object,
  enableLegends: PropTypes?.bool,
  yLabel: PropTypes?.string,
  useCostFormatter: PropTypes?.bool
};
export default GraphContainer;
