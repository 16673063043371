import { DateTime } from "luxon";
import { roundToSigDigits } from "./common-helper";
import { getRequest } from "./http-helper";
import { CustomTooltip } from "./tooltip-helper";
import { attachParams } from "./url-helper";
import _ from "lodash";

export const timeIntervalOptions = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" }
];
export const getCRdateRangeDropdowns = async (id) => {
  const params = { data_wh_id: id };
  const URL = attachParams(`/v2/cost_center/reporting/date_ranges`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getCRDataAPI = async (id, start_date, end_date, date_grouping) => {
  let startDate = start_date;
  if (date_grouping === "weekly") {
    startDate = DateTime.fromISO(
      DateTime.fromFormat(start_date, "yyyy-MM-dd").toISO()
    )
      .minus({ weeks: 1 })
      .toFormat("yyyy-MM-dd");
  } else if (date_grouping === "monthly") {
    startDate = DateTime.fromISO(
      DateTime.fromFormat(start_date, "yyyy-MM-dd").toISO()
    )
      .minus({ months: 1 })
      .toFormat("yyyy-MM-dd");
  } else if (date_grouping === "daily") {
    startDate = DateTime.fromISO(
      DateTime.fromFormat(start_date, "yyyy-MM-dd").toISO()
    )
      .minus({ days: 1 })
      .toFormat("yyyy-MM-dd");
  }
  const params = {
    data_wh_id: id,
    start_date: startDate,
    end_date,
    date_grouping
  };
  const URL = attachParams(
    `/v2/cost_center/reporting/cost_center_drilldown`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const formatPageData = (totalCostCenter, tree) => {
  const newData = tree?.map((item, index) => {
    const getName = totalCostCenter.map((val) => {
      if (item?.data?.id?.toString() === val?.id?.toString()) {
        return {
          ...item?.data,
          Total: item?.data?.cost,
          "S No": index + 1,
          name: val?.["Cost Center Name"]
        };
      }
    });
    const finalData = getName.filter((item) => item !== undefined);
    return finalData;
  });
  const formattedData = _.concat(...newData);
  return formattedData;
};

const addDollerToValue = (params) => {
  const currency = `$${roundToSigDigits(params?.value, 2, false)}`;
  return currency;
};
export const createTableColumn = (tableColumn) => {
  let column = tableColumn?.columns?.map((item) => {
    return item !== "cost_center_id"
      ? {
          width: 180,
          cellClass: "grid-cell-middle-aligned cell-border-right",
          field: item,
          filter: false,
          headerName: item,
          sortable: true,
          unSortIcon: true,
          cellRenderer: (params) => {
            if (item !== "cost_center_id") {
              return addDollerToValue(params);
            } else {
              return roundToSigDigits(params?.value, 2, false);
            }
          }
        }
      : {
          width: 180,
          cellClass: "grid-cell-left-aligned cell-border-right",
          field: "name",
          filter: false,
          headerName: "Cost Center Name",
          sortable: true,
          unSortIcon: true,
          cellRenderer: (params) => {
            return CustomTooltip(params.value, params.value, 10);
          }
        };
  });
  column.unshift({
    width: 80,
    cellClass: "grid-cell-middle-aligned cell-border-right",
    field: "S No",
    filter: false,
    headerName: "S No",
    sortable: true,
    unSortIcon: true,
    cellRenderer: (params) => {
      return params.value;
    }
  });
  return column;
};

export const flattenTreeData = (
  nodes,
  parentKey = "",
  columnsToExclude = []
) => {
  let rows = [];
  nodes.forEach((node) => {
    const row = {
      ...node.data,
      key: parentKey ? `${parentKey}-${node.key}` : node.key
    };
    columnsToExclude.forEach((column) => delete row[column]); // Remove the excluded columns
    rows.push(row);
    if (node.children) {
      rows = rows.concat(
        flattenTreeData(node.children, row.key, columnsToExclude)
      );
    }
  });
  return rows;
};

export const stackGrahpcolors = [
  "#3182BD",
  "#4398CB",
  "#6BAED6",
  "#8BC0DF",
  "#A9D0E4",
  "#C6DBEF",
  "#C64201",
  "#E6550D",
  "#F0883D",
  "#FD9F4F",
  "#FDAF6D",
  "#FDC58C",
  "#1C713C",
  "#157F3C",
  "#16A249",
  "#1AC057",
  "#4ADE80",
  "#85EFAC",
  "#5A21B5",
  "#6B26D9",
  "#8444F3",
  "#9354FF",
  "#A689FA",
  "#C3B4FD",
  "#334256",
  "#424E62",
  "#636D7D",
  "#787F8E",
  "#9AA2B1",
  "#CCD1D8",
  "#9B174C",
  "#BF185D",
  "#DB2979",
  "#EC4699",
  "#F471B5",
  "#F9A9D5",
  "#864E0E",
  "#A26107",
  "#CA8511",
  "#E7B008",
  "#FACC14",
  "#FFE771",
  "#115F5A",
  "#0F756D",
  "#0D968B",
  "#14B8A5",
  "#2BD4BD",
  "#5DEAD5",
  "#981B1B",
  "#BA1C1C",
  "#DC2828",
  "#EF4343",
  "#FF6565",
  "#FFA0A0",
  "#406312",
  "#49790B",
  "#609C0C",
  "#7BC111",
  "#96DB29",
  "#BBEF5F",
  "#85198F",
  "#A31DAF",
  "#BF27D3",
  "#D948EF",
  "#E87BF9",
  "#F0ABFC",
  "#066046",
  "#047656",
  "#059467",
  "#10B77F",
  "#36D399",
  "#6EE7B7",
  "#372FA2",
  "#463ACB",
  "#5048E5",
  "#6B6EF8",
  "#8C96FC",
  "#AAB8FF",
  "#155F75",
  "#0E7490",
  "#088EAF",
  "#07B4D3",
  "#20D3EE",
  "#67E8F9",
  "#27272A",
  "#3F3F46",
  "#52525B",
  "#7A7A83",
  "#A1A1AA",
  "#D0D0D4",
  "#6A21A6",
  "#7E22CE",
  "#9234EA",
  "#A855F7",
  "#BF83FC",
  "#D8B4FE",
  "#075783",
  "#0369A0",
  "#0284C5",
  "#1BADF0",
  "#3ABFF8",
  "#7ED4FC"
];
export const createGraphData = (data, date_label) => {
  const graphData = data?.map((item, index) => {
    let values = [];
    Object.keys(item).map((key) => {
      if (
        key !== "cost_center_id" &&
        key !== "name" &&
        key !== "Total" &&
        key !== "S No" &&
        key !== "cost" &&
        key !== "s_no" &&
        key !== "id" &&
        key !== "dw_ids" &&
        key !== "resource_subtypes"
      ) {
        values.push(item[key]);
      }
    });
    if (!/MTD/.test(date_label)) {
      values = values.slice(1);
    }
    return {
      name: item.name,
      data: values,
      color: stackGrahpcolors[index],
      dw_ids: item.dw_ids
    };
  });
  return graphData;
};
