import React from "react";
import "./pagination.scss";
import PropTypes from "prop-types";
import PaginationButtons from "./paginationbuttons";
const Pagination = ({
  paginationInfo,
  handleNextPage,
  handlePreviousPage,
  handlePageClick
}) => {
  return (
    <div className="pagination-container">
      <div className="pageinfo-container">
        <div className="pgbtns-container">
          <span className="page-info">
            <span className="bold">{paginationInfo?.start + 1}</span> to{" "}
            <span className="bold">{`${
              paginationInfo?.end <= paginationInfo?.total_records
                ? paginationInfo?.end
                : paginationInfo?.total_records
            }`}</span>{" "}
            of <span className="bold">{paginationInfo?.total_records}</span>
          </span>
          <button
            disabled={paginationInfo?.page_num === 0}
            className="button-chevron"
            onClick={handlePreviousPage}>
            {"<"}
          </button>
          <PaginationButtons
            currentPage={paginationInfo?.page_num}
            totalPages={paginationInfo?.total_pages}
            handlePageClick={handlePageClick}
          />
          <button className="button-chevron" onClick={handleNextPage}>
            {">"}
          </button>
        </div>
      </div>
    </div>
  );
};
Pagination.propTypes = {
  paginationInfo: PropTypes.object,
  handlePreviousPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handlePageClick: PropTypes.func
};
export default Pagination;
