import React from "react";
import { CustomTooltip } from "../../../utils/tooltip-helper";
import { roundToSigDigits } from "../../../utils/common-helper";
import { costFormatter } from "../../../utils/organization-helper";

export const getClusterOverviewProps = (hasTrialFallback, totalValues) => {
  const customProps = {
    cluster_name: {
      filter: false,
      width: 300,
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    sku_name: {
      width: 300,
      filter: false,
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    worker_node_type: {
      width: 300,
      filter: false,
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    driver_node_type: {
      width: 300,
      filter: false,
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    workspace_id: {
      width: 300,
      filter: false,
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    cost: {
      width: 180,
      suppressSizeToFit: true,
      filter: false,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        } else {
          return (
            <span>
              {costFormatter(roundToSigDigits(params.value, 2, false, false))}{" "}
              <span className="percentage">
                {"(" +
                  roundToSigDigits(
                    (params.value / totalValues?.cost) * 100,
                    2,
                    false,
                    false
                  ) +
                  "%)"}
              </span>
            </span>
          );
        }
      }
    },
    usage_quantity: {
      width: 200,
      suppressSizeToFit: true,
      filter: false,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        } else {
          return (
            <span>
              {roundToSigDigits(params.value, 2, false, false)}{" "}
              <span className="percentage">
                {"(" +
                  roundToSigDigits(
                    (params.value / totalValues?.dbus) * 100,
                    2,
                    false,
                    false
                  ) +
                  "%)"}
              </span>
            </span>
          );
        }
      }
    },

    active_cluster_count: {
      width: 180,
      suppressSizeToFit: true,
      filter: false,
      cellClass: "grid-cell-left-aligned",
      resizable: false,
      sortable: false,
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        } else {
          return (
            <span>
              {roundToSigDigits(params.value, 2, false, false)}{" "}
              <span className="percentage">
                {"(" +
                  roundToSigDigits(
                    (params.value / totalValues?.active_clusters) * 100,
                    2,
                    false,
                    false
                  ) +
                  "%)"}
              </span>
            </span>
          );
        }
      }
    },
    runtime: {
      width: 170,
      suppressSizeToFit: true,
      filter: false,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        } else {
          return (
            <span>
              {roundToSigDigits(params.value, 2, false, false)}{" "}
              <span className="percentage">
                {"(" +
                  roundToSigDigits(
                    (params.value / totalValues?.runtime_hrs) * 100,
                    2,
                    false,
                    false
                  ) +
                  "%)"}
              </span>
            </span>
          );
        }
      }
    }
  };

  return customProps;
};
