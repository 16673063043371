import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./skeleton.scss";

const SkeletonLoader = (props) => {
  const {
    count = 1,
    direction = "horizontal",
    width = "100%",
    height = "100%"
  } = props;

  const skeletonArray = Array.from({ length: count });

  return (
    <div
      className={`skeleton-container ${
        direction === "horizontal"
          ? "skeleton-container-horizontal"
          : "skeleton-container-vertical"
      }`}
      style={{ width: width, height: height }}>
      {skeletonArray.map((_, index) => (
        <Skeleton
          key={index}
          className="skeleton-item"
          width={direction === "horizontal" ? "100%" : width}
          height={direction === "vertical" ? height : "100%"}
        />
      ))}
    </div>
  );
};

SkeletonLoader.propTypes = {
  count: PropTypes.number,
  direction: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default SkeletonLoader;
