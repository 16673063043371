// import { stackGrahpcolors } from "./cost-reporting-helper";
import { getRequest, postRequest } from "./http-helper";
import { attachParams } from "./url-helper";
import _ from "lodash";
import { DateTime } from "luxon";

export const pageTabOptions = [
  { label: "Overall", value: "overall" },
  { label: "All Purpose Clusters", value: "all_purpose" },
  { label: "Job Clusters", value: "job" }
];

export const createGroupByOptions = (groupBy) => {
  const options = groupBy?.map((item) => ({
    ...item,
    label: item.groupby_title,
    value: item.groupby_id
  }));
  return options;
};
export const createAllWorkspaceOptions = (list) => {
  const options = list?.map((item) => ({
    ...item,
    label: item.workspace_name,
    value: item.workspace_id
  }));
  return options;
};

export const getClusterOverviewDateRangeAPI = async (acc) => {
  const params = {
    data_wh_id: acc?.id
  };
  const URL = attachParams(`/v2/dbx_cluster/overview/date_ranges`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getAllWorkspaceListAPI = async (acc) => {
  const params = {
    data_wh_id: acc?.id
  };
  const URL = attachParams(`/v2/dbx_workspaces`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getClusterOverviewMetaAPI = async (acc, start_date, end_date, cluster_type, workspace_filter) => {
  const params = {
    data_wh_id: acc?.id,
    start_date,
    end_date,
    cluster_type,
    workspace_filter
  };
  const URL = attachParams(`/v2/dbx_cluster/overview/metadata`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getClusterOverviewDataAPI = async (
  acc,
  start_date,
  end_date,
  group_by,
  date_grouping,
  cluster_type,
  page_num,
  page_size,
  filter_values,
  workspace_filter
) => {
  const params = {
    data_wh_id: acc?.id,
    start_date,
    end_date,
    group_by,
    date_grouping,
    cluster_type,
    page_num,
    page_size,
    filters: filter_values,
    workspace_filter
  };
  const URL = attachParams(`/v2/dbx_cluster/overview`, { data_wh_id: acc?.id });
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(params)
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getTotalDisplayDataAPI = async (
  acc,
  start_date,
  end_date,
  group_by,
  date_grouping,
  cluster_type
) => {
  const params = {
    data_wh_id: acc?.id,
    start_date,
    end_date,
    group_by,
    date_grouping,
    cluster_type
  };
  const URL = attachParams(`/v2/dbx_cluster/overview/totals`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const createClusterGraphdata = (data, groupby, key) => {
  let result = [];
  let x_axis = _.sortBy(
    _.uniq(
      _.map(data, (item) => DateTime.fromISO(item.date).toFormat("yyyy-MM-dd"))
    )
  );
  let groupedData = _.groupBy(data, groupby);
  let uniqueDates = _.sortBy(_.uniq(_.map(data, "date")));
  _.forEach(groupedData, (items, clusterName) => {
    let clusterData = {
      data: _.map(uniqueDates, (date) => {
        let item = _.find(items, { date });
        return item ? item[key] : 0;
      }),
      name: clusterName,
      sum: _.sumBy(items, "cost")
    };
    result.push(clusterData);
  });
  return { x: x_axis, y: result };
};
