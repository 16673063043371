import cost_exp from "./cost_exp.svg";
import fallback_icon from "./fallback_icon.svg";
import add_white_outline from "./add_white_outline.svg";
import compute_img from "./compute_img.svg";
import workloads_img from "./workloads_img.svg";
import storage_img from "./storage_img.svg";
import governance_img from "./governance_img.svg";
import dtsource_img from "./dtsource_img.svg";
import cost_exp_active from "./cost_exp_active.svg";
import compute_img_active from "./compute_img_active.svg";
import workloads_img_active from "./workloads_img_active.svg";
import storage_img_active from "./storage_img_active.svg";
import governance_img_active from "./governance_img_active.svg";
import dtsource_img_active from "./dtsource_img_active.svg";
import health_cloud_services from "./health_cloud_services.svg";
import health_compute from "./health_compute.svg";
import health_failed_queries from "./health_failed_queries.svg";
import health_storage from "./health_storage.svg";
import health_workload from "./health_workload.svg";
import home_active from "./home_active.svg";
import home_inactive from "./home_inactive.svg";
import health_cost_exp from "./health_cost_exp.svg";
import organization_img from "./organization_img.svg";
import organization_img_active from "./organization_img_active.svg";
import chargeback_active from "./chargeback_active.svg";
import chargeback_inactive from "./chargeback_inactive.svg";
import db_recommendation_active from "./db_recommendation_active.svg";
import db_recommendation_inactive from "./db_recommendation_inactive.svg";
import dt_trnsfr_active from "./dt_trnsfr_active.svg";

export default {
  cost_exp,
  fallback_icon,
  add_white_outline,
  compute_img,
  workloads_img,
  storage_img,
  governance_img,
  dtsource_img,
  organization_img,
  cost_exp_active,
  compute_img_active,
  workloads_img_active,
  storage_img_active,
  governance_img_active,
  dtsource_img_active,
  organization_img_active,
  health_cloud_services,
  health_compute,
  health_failed_queries,
  health_storage,
  health_workload,
  home_active,
  home_inactive,
  health_cost_exp,
  chargeback_active,
  chargeback_inactive,
  db_recommendation_active,
  db_recommendation_inactive,
  dt_trnsfr_active
};
