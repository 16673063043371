import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./qrecommendations.scss";
import { CustomTooltip } from "../../utils/tooltip-helper";
import chev_down from "../../assets/images/common/chev_down.svg";
import qry_det_img from "../../assets/images/dashboard/qry_det_img.png";
import {
  createColDefs,
  getAutoheightOverflow,
  getGridHeight,
  getValueObj,
  handleGridResolution,
  PrettyFormat,
  roundToSigDigits
} from "../../utils/common-helper";
import GridComponent from "../../components/GridComponent";
import Select, { components } from "react-select";
import { getQueryRecommendations } from "../../utils/user-helper";
import HTMLRenderrer from "../../utils/htmlRenderrer";
import {
  DownloadLinkButton,
  getQueryRecommendationsDateRange,
  requestRec
} from "../../utils/query-helper";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { getException } from "../../utils/exceptiondictionary";
import menuIcons from "../../assets/images/dashboard/index";
import external_link from "../../assets/images/common/external_link.svg";
import { useNavigate, useParams } from "react-router-dom";
import expand from "../../assets/images/common/expand.svg";
import Button from "../../components/customElements/Button";
import ExpandComponent from "../../components/ExpandComponent";
import ExportCSV from "../../assets/images/grid/exportcsv.svg";

const QRecommendations = ({ acc }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };
  const navigate = useNavigate();
  const { date_range } = useParams();
  const [customProps, setCustomProps] = useState({
    index: {
      type: "rightAligned",
      width: 75,
      headerClass: "text-align-right"
    },
    regex_id: {
      hide: true
    },
    query_text_regex: {
      width: 250,
      filter: true,
      pinned: "left",
      cellRenderer: (params) => {
        return CustomTooltip(
          PrettyFormat(params.value),
          params.value,
          35,
          undefined,
          undefined,
          <button
            className="qry-detail-btn"
            onClick={() => {
              gotoQryDetail(params, dateRange, acc);
            }}>
            <div className="img-container">
              <img src={qry_det_img} />
            </div>
            Debug Query
          </button>
        );
      },
      cellClass: "grid-cell-left-aligned"
    },
    problems_with_query: {
      cellClass: "grid-cell-left-aligned pre-line",
      autoHeight: true,
      wrapText: true,
      width: 300,
      cellRenderer: (params) => {
        return <HTMLRenderrer value={params?.value} />;
      }
    },
    recommendations: {
      cellClass: "grid-cell-left-aligned pre-line",
      autoHeight: true,
      wrapText: true,
      width: 300,
      cellRenderer: (params) => {
        return <HTMLRenderrer value={params?.value} />;
      }
    },
    warehouses: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    users: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    count: {
      width: 100,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_query_cost: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_query_cost: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    max_query_cost: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    median_query_cost: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    stddev_query_cost: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_total_elapsed_time: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_execution_time: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_execution_time: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    max_execution_time: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    stddev_execution_time: {
      width: 160,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    ratio_non_execution_time: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_queued_overload_time: {
      width: 187,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_queued_overload_time: {
      width: 187,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_bytes_spilled_to_remote_storage: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_gb_spilled_to_remote_storage: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_bytes_spilled_to_local_storage: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    ratio_partitions_scanned: {
      width: 175,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    percentage_total_execution_time: {
      width: 155,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_gb_spilled_to_local_storage: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_gb_spilled_to_remote_storage: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    "Query Ids": {
      width: 90,
      pinned: "right",
      headerClass: "text-align-center",
      cellRenderer: (params) => {
        return DownloadLinkButton(params.value);
      }
    }
  });
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };

  const [rowData, setRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [gridFallback, setGridFallback] = useState(false);
  const [downloadCSV, setDownloadCSV] = useState(false);
  const [gridResolution, setGridResolution] = useState(true);
  const [gridTotalWidth, setGridTotalWidth] = useState(null);
  const [noRowsText, setNoRowsText] = useState(
    "Query recommendations for the selected period or account are currently unavailable. Coming soon..."
  );
  const [expandModal, setExpandModal] = useState(false);
  const toast = useToast();
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([]);
  const gotoQryDetail = (params, dateRange, acc) => {
    navigate(
      `../workloads/recommendations/${dateRange?.value}/querydetails/queryprofile/${acc?.id}/${params?.data?.regex_id}`
    );
  };

  const handleExport = () => {
    setDownloadCSV(true);
    setTimeout(() => {
      setDownloadCSV(false);
    }, 3000);
  };
  const handleExpand = (bool) => {
    setExpandModal(bool);
  };
  const getTableData = async (dateRange, customProps) => {
    try {
      if (
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        acc &&
        Object.keys(acc).length > 0
      ) {
        const data = await getQueryRecommendations({
          data_wh_id: acc?.id,
          date_range: dateRange?.label
        });
        const downloadColName = "Query Ids";
        data?.data?.forEach((item) => {
          item[downloadColName] = {
            data_wh_id: acc?.id,
            date_range: dateRange?.label,
            regex_id: item.regex_id,
            filter_type: "all"
          };
        });
        data?.columns?.push?.(downloadColName);
        if (data) {
          const columns = [
            "index",
            "query_text_regex",
            "regex_id",
            "problems_with_query",
            "recommendations",
            "warehouses",
            "users",
            // "count",
            // "sum_query_cost",
            "Query Ids"
          ];
          const colDefsArr = createColDefs(
            columns,
            customProps,
            null,
            data?.column_display_name_mappping
          );
          if (colDefsArr && data?.data) {
            setColumnDefs(colDefsArr);
            setRowData(data?.data);
            let screenInnerWidth = window.innerWidth;
            let gridWidth = Object.values(colDefsArr).reduce((sum, prop) => {
              if (typeof prop.width === "number" && !isNaN(prop.width)) {
                return sum + prop.width;
              }
              return sum;
            }, 0);
            setGridResolution(
              handleGridResolution(gridWidth, screenInnerWidth, 360)
            );
            setGridTotalWidth(gridWidth);
          }
        }
        setGridLoading(false);
        if (gridFallback) {
          setGridFallback(false);
        }
        setIsPageLoading(false);
      }
    } catch (err) {
      clearData();
      const message = getException(err?.response?.data?.errorKey);
      setNoRowsText(message?.description);

      if (
        !gridFallback &&
        err?.response?.data?.errorKey !== "USER_PLAN_NOT_ALLOWED"
      ) {
        setGridFallback(true);
      } else if (err?.response?.data?.errorKey === "USER_PLAN_NOT_ALLOWED") {
        setGridFallback(false);
      }
      setGridLoading(false);
      setIsPageLoading(false);
    }
  };

  const clearData = () => {
    setRowData([]);
  };

  const getData = async () => {
    let dateRangeArr = [];
    let tempDtRange = null;
    try {
      const dateRangeObj = await getQueryRecommendationsDateRange(
        acc ? acc?.id : ""
      );
      if (dateRangeObj?.values) {
        dateRangeArr = getValueObj(dateRangeObj?.values);
        setDateRangeOptions(dateRangeArr);
        if (date_range) {
          const dtObj = dateRangeArr.find((date) => {
            return date?.label === date_range;
          });
          if (dtObj && Object.keys(dtObj).length) {
            tempDtRange = dtObj;
            setDateRange(dtObj);
          } else {
            setDateRange(dateRangeArr[0]);
            tempDtRange = dateRangeArr[0];
          }
        } else if (dateRange) {
          const dtObj = dateRangeArr.find((date) => {
            return date?.label === dateRange?.label;
          });
          if (dtObj && Object.keys(dtObj).length) {
            tempDtRange = dtObj;
            setDateRange(dtObj);
          } else {
            setDateRange(dateRangeArr[0]);
            tempDtRange = dateRangeArr[0];
          }
        } else {
          setDateRange(dateRangeArr[0]);
          tempDtRange = dateRangeArr[0];
        }
      }
      if (tempDtRange && acc) {
        getTableData(tempDtRange, {
          ...customProps,
          query_text_regex: {
            width: 250,
            filter: true,
            cellRenderer: (params) => {
              return CustomTooltip(
                PrettyFormat(params.value),
                params.value,
                35,
                undefined,
                undefined,
                <button
                  className="qry-detail-btn"
                  onClick={() => {
                    gotoQryDetail(params, tempDtRange, acc);
                  }}>
                  <div className="img-container">
                    <img src={qry_det_img} />
                  </div>
                  Debug Query
                </button>
              );
            },
            cellClass: "grid-cell-left-aligned"
          }
        });
      }
    } catch (err) {
      clearData();
      const message = getException(err?.response?.data?.errorKey);
      setNoRowsText(message?.description);
      if (
        !gridFallback &&
        err?.response?.data?.errorKey !== "USER_PLAN_NOT_ALLOWED"
      ) {
        setGridFallback(true);
      } else if (err?.response?.data?.errorKey === "USER_PLAN_NOT_ALLOWED") {
        setGridFallback(false);
      }
      setGridLoading(false);
      setIsPageLoading(false);
    }
  };
  const [breadCrumbs] = useState(false);
  useEffect(() => {
    if (acc) {
      clearData();
      setIsPageLoading(true);
      setGridLoading(true);
      getData();
    }
  }, [acc]);

  const handleReqRec = async () => {
    try {
      if (acc && acc?.id && dateRange && dateRange?.value) {
        const status = await requestRec({
          data_wh_id: acc?.id,
          date_range: dateRange?.value
        });
        if (status) {
          customToast({ type: "success", ...getException("REQRECSUCCESS") });
          setGridLoading(false);
        } else {
          customToast({ type: "error", ...getException("REQRECFAILED") });
          setGridLoading(false);
        }
      } else {
        customToast({ type: "error", ...getException("REQRECFAILED") });
        setGridLoading(false);
      }
    } catch (err) {
      console.log(err);
      customToast({ type: "error", ...getException("REQRECFAILED") });
      setGridLoading(false);
    }
  };

  const getFallbackHTML = (gridFallback) => {
    return gridFallback ? (
      <>
        <img src={menuIcons?.fallback_icon} />
        <span className="fallback-text-q-rec err-text">
          Recommendations not available for selected period
        </span>
        <div className="req-div">
          <span className="fallback-text-q-rec err-cta">
            Get Query Recommendation by enabling Query Profile Access. See{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://documentation.chaosgenius.io/get-started/#step-4---optional-additional-permissions-for-query-recommendations">
              Documentation <img src={external_link} />
            </a>
          </span>
          <button
            onClick={() => {
              setGridLoading(true);
              handleReqRec();
            }}
            className="btn btn-dark req-btn">
            Request Recommendation
          </button>
        </div>
      </>
    ) : null;
  };

  return (
    <>
      {expandModal && (
        <>
          <ExpandComponent
            columnDefs={columnDefs}
            rowData={rowData}
            gridFallback={gridFallback}
            handleExpand={handleExpand}
            gridStyle={{
              height: getGridHeight(rowData, window?.innerHeight, 250, 200),
              avoidAutoHeightOverflow: getAutoheightOverflow(rowData)
            }}
            customRowHeight={250}
            gridLoading={gridLoading}
            noRowsText={noRowsText}
            suppressColumnsSize={handleGridResolution(
              gridTotalWidth,
              window.innerWidth,
              96
            )}
            fallbackHTML={getFallbackHTML(gridFallback)}
            tableName={"Query Recommendations"}
          />
        </>
      )}
      <div className="qrec-container overflow-auto">
        {!isPageLoading ? (
          <>
            <div className="header-container">
              <div className="header-block">
                {breadCrumbs ? (
                  <div className="breadcrumbs-container"></div>
                ) : null}
                <div className="header">
                  <span>Query Recommendations</span>
                </div>
              </div>
              <div className="top-filter">
                <div className="select-container">
                  <Select
                    value={dateRange}
                    options={dateRangeOptions}
                    styles={customStyles}
                    classNamePrefix="selectcategory"
                    placeholder="select"
                    isSearchable={false}
                    components={{ DropdownIndicator }}
                    onChange={(e) => {
                      setDateRange(e);
                      setCustomProps({
                        ...customProps,
                        query_text_regex: {
                          width: 250,
                          filter: true,
                          cellRenderer: (params) => {
                            return CustomTooltip(
                              PrettyFormat(params.value),
                              params.value,
                              35,
                              undefined,
                              undefined,
                              <button
                                className="qry-detail-btn"
                                onClick={() => {
                                  gotoQryDetail(params, e, acc);
                                }}>
                                <div className="img-container">
                                  <img src={qry_det_img} />
                                </div>
                                Debug Query
                              </button>
                            );
                          },
                          cellClass: "grid-cell-left-aligned"
                        }
                      });
                      setGridLoading(true);
                      getTableData(e, {
                        ...customProps,
                        query_text_regex: {
                          width: 250,
                          filter: true,
                          cellRenderer: (params) => {
                            return CustomTooltip(
                              PrettyFormat(params.value),
                              params.value,
                              35,
                              undefined,
                              undefined,
                              <button
                                className="qry-detail-btn"
                                onClick={() => {
                                  gotoQryDetail(params, e, acc);
                                }}>
                                <div className="img-container">
                                  <img src={qry_det_img} />
                                </div>
                                Debug Query
                              </button>
                            );
                          },
                          cellClass: "grid-cell-left-aligned"
                        }
                      });
                      navigate(`../workloads/recommendations/${e?.value}`);
                    }}
                  />
                </div>
                {/* {!gridLoading && rowData && !rowData?.length && (
                 
                )} */}
              </div>
            </div>
            <div className="grid-block">
              <div className="mod__block">
                <Button
                  onClick={() => {
                    handleExpand(true);
                  }}
                  className={"qg-filter-button expand__button align-self-end"}>
                  <img src={expand} alt="" />
                  <span>Expand</span>
                </Button>
                <div className="select-container export-container">
                  <Button
                    // disabled={hasTrialFallback}
                    onClick={handleExport}
                    className={"qg-filter-button"}>
                    <img src={ExportCSV} alt="" />
                    <span>Export</span>
                  </Button>
                </div>
              </div>
              <GridComponent
                columnDefs={columnDefs}
                rowData={rowData}
                gridLoading={gridLoading}
                gridFallback={gridFallback}
                customRowHeight={250}
                downloadCSV={downloadCSV}
                suppressColumnsSize={gridResolution}
                gridStyle={{
                  height: getGridHeight(
                    rowData,
                    window?.innerHeight - 250,
                    250,
                    250
                  ),
                  avoidAutoHeightOverflow: getAutoheightOverflow(rowData)
                }}
                fallbackHTML={getFallbackHTML(gridFallback)}
                noRowsText={noRowsText}
              />
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
QRecommendations.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object
};
export default QRecommendations;
