import React from "react";
import "./expandcomponent.scss";
import PropTypes from "prop-types";
import GridComponent from "../GridComponent";
import TreeComponent from "../TreeComponent";

const ExpandComponent = ({
  handleExpand,
  columnDefs,
  rowData,
  gridFallback,
  customRowHeight,
  hasTrialFallback,
  changeActiveModule,
  customHeaderHeight,
  fallbackHTML,
  exportColumns,
  suppressColumnsSize,
  gridStyle,
  tableName,
  gridLoading,
  noRowsText,
  downloadCSV,
  altTable,
  treeData,
  tableClass,
  columnView,
  tableStyle,
  paginationInfo,
  handlePreviousPage,
  handleNextPage,
  handlePageClick
}) => {
  return (
    <>
      <div className="modal__wrapper display-flex justify-flex-center align-flex-center"></div>
      <div
        className="modal__chart__container display-flex justify-flex-center align-flex-center"
        onClick={() => {
          handleExpand(false);
        }}>
        <div
          className="modal__chart__block display-flex justify-flex-center align-flex-center"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <span className="title cg-absolute-position">{tableName}</span>
          <button
            className="close__div"
            onClick={(e) => {
              e.stopPropagation();
              handleExpand(false);
            }}>
            <span>&#xD7;</span>
          </button>
          {altTable ? (
            <TreeComponent
              data={treeData}
              tableClass={tableClass}
              columnView={columnView}
              tableStyle={tableStyle}
            />
          ) : (
            <GridComponent
              columnDefs={columnDefs}
              rowData={rowData}
              gridFallback={gridFallback}
              suppressColumnsSize={suppressColumnsSize}
              gridStyle={gridStyle}
              gridLoading={gridLoading}
              noRowsText={noRowsText}
              downloadCSV={downloadCSV}
              paginationInfo={paginationInfo}
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
              handlePageClick={handlePageClick}
              tableName={tableName}
              customRowHeight={customRowHeight}
              customHeaderHeight={customHeaderHeight}
              hasTrialFallback={hasTrialFallback}
              changeActiveModule={changeActiveModule}
              fallbackHTML={fallbackHTML}
              exportColumns={exportColumns}
            />
          )}
        </div>
      </div>
    </>
  );
};
ExpandComponent.propTypes = {
  handleExpand: PropTypes.func,
  columnDefs: PropTypes.array,
  rowData: PropTypes?.array,
  title: PropTypes.string,
  gridLoading: PropTypes.bool,
  noRowsText: PropTypes.string,
  fallbackHTML: PropTypes.element,
  suppressColumnsSize: PropTypes.bool,
  downloadCSV: PropTypes.bool,
  hasTrialFallback: PropTypes.bool,
  changeActiveModule: PropTypes.func,
  tableName: PropTypes.string,
  customRowHeight: PropTypes.number,
  customHeaderHeight: PropTypes.number,
  exportColumns: PropTypes.array,
  additionalClass: PropTypes.string,
  rowHeight: PropTypes.number,
  gridFallback: PropTypes.bool,
  altTable: PropTypes?.any,
  gridStyle: PropTypes.object,
  treeData: PropTypes?.array,
  tableClass: PropTypes?.string,
  columnView: PropTypes?.array,
  tableStyle: PropTypes?.object,
  paginationInfo: PropTypes?.object,
  handlePreviousPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handlePageClick: PropTypes.func
};
export default ExpandComponent;
