import React, { useState } from "react";
import PropTypes from "prop-types";
import info_img from "../../assets/images/common/info.svg";
import eyeCon from "../../assets/images/common/eye.svg";
import eyeConSlash from "../../assets/images/common/eye-slash.svg";
import Select from "@mui/material/Select";
import dropdown_arrow from "../../assets/images/common/dropdown_arrow.svg";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import "./databricksform.scss";
import { CustomTooltip } from "../../utils/tooltip-helper";
const DatabricksForm = ({ closeModal, changeIsLoading, setupConnection }) => {
  const [eyeSlash, setEyeSlash] = useState(false);
  const [pwdType, setPwdType] = useState("password");
  const [fields, setFields] = useState({
    acc_id: "",
    accname: "",
    conname: "",
    role: "",
    warehouse: "",
    database: "",
    username: "",
    pword: "",
    connection_type: "DATABRICKS",
    connection_cloud: { value: "AWS", label: "AWS" }
  });
  const [infoError, setInfoError] = useState({
    acc_id: "",
    accname: "",
    conname: "",
    role: "",
    warehouse: "",
    database: "",
    username: "",
    pword: "",
    connection_type: "",
    connection_cloud: ""
  });
  const handleClick = () => {
    let errorObj = { ...infoError };
    if (fields.acc_id === "") {
      errorObj["acc_id"] = "Please enter account identifier";
    }
    if (fields.conname === "") {
      errorObj["conname"] = "Please enter connection name";
    }
    if (fields.username === "") {
      errorObj["username"] = "Please enter client ID";
    }
    if (fields.role === "") {
      errorObj["role"] = "Please enter Service Principal ID";
    }
    if (fields.pword === "") {
      errorObj["pword"] = "Please enter the Service Principal Client Secret";
    }
    if (fields.database === "") {
      errorObj["database"] = "Please enter catalog name";
    }
    if (fields.warehouse === "") {
      errorObj["warehouse"] = "Please enter DBSQL Warehouse ID";
    }
    if (fields.accname === "") {
      errorObj["accname"] = "Please enter Warehouse Workspace URL";
    }

    setInfoError(errorObj);
    if (
      errorObj.acc_id === "" &&
      errorObj?.accname === "" &&
      errorObj?.conname === "" &&
      errorObj.role === "" &&
      errorObj.pword === "" &&
      errorObj.database === "" &&
      errorObj.warehouse === ""
    ) {
      changeIsLoading(true);
      setupConnection(fields);
      closeModal();
    }
  };
  const toggleSlash = () => {
    setEyeSlash(!eyeSlash);
    if (pwdType && pwdType === "password") {
      setPwdType("text");
    } else if (pwdType && pwdType === "text") {
      setPwdType("password");
    }
  };
  const fieldList = [
    {
      id: "1",
      name: "acc_id",
      label: "Databricks Account ID",
      type: "text",
      placeholder: "Enter Databricks Account ID",
      value: fields.acc_id,
      isDisabled: false,
      desc: "",
      onChange: (e) => {
        if (infoError["acc_id"] !== "") {
          setInfoError({ ...infoError, acc_id: "" });
        }
        setFields({ ...fields, acc_id: e.target.value });
      }
    },
    {
      id: "2",
      name: "conname",
      label: "Connection Name",
      type: "text",
      placeholder: "Enter Connection Name",
      value: fields.conname,
      isDisabled: false,
      desc: "",
      onChange: (e) => {
        if (infoError["conname"] !== "") {
          setInfoError({ ...infoError, conname: "" });
        }
        setFields({ ...fields, conname: e.target.value });
      }
    },
    {
      id: "3",
      name: "username",
      label: "Service Principal Client ID",
      type: "text",
      placeholder: "Enter Service Principal Client ID",
      value: fields.username,
      isDisabled: false,
      desc: "",
      onChange: (e) => {
        if (infoError["username"] !== "") {
          setInfoError({ ...infoError, username: "" });
        }
        setFields({ ...fields, username: e.target.value });
      }
    },
    {
      id: "4",
      name: "role",
      label: "Service Principal ID",
      type: "text",
      placeholder: "Enter Service Principal ID",
      value: fields.role,
      isDisabled: false,
      desc: "",
      //   additionalInfo: (
      //     <a
      //       target="_blank"
      //       rel="noreferrer"
      //       href="https://docs.snowflake.com/en/user-guide/admin-account-identifier.html">
      //       Read More <img src={external_link} />
      //     </a>
      //   ),
      onChange: (e) => {
        if (infoError["role"] !== "") {
          setInfoError({ ...infoError, role: "" });
        }
        setFields({ ...fields, role: e.target.value });
      }
    },
    {
      id: "5",
      name: "pword",
      label: "Service Principal Client Secret",
      type: "password",
      placeholder: "Enter Service Pricipal Client Secret",
      isDisabled: false,
      desc: "",
      value: fields.pword,
      onChange: (e) => {
        if (infoError["pword"] !== "") {
          setInfoError({ ...infoError, pword: "" });
        }
        setFields({ ...fields, pword: e.target.value });
      }
    },
    {
      id: "6",
      name: "database",
      label: "Catalog Name",
      type: "text",
      placeholder: "Enter Catalog Name",
      value: fields.database,
      isDisabled: false,
      desc: "",
      onChange: (e) => {
        if (infoError["database"] !== "") {
          setInfoError({ ...infoError, database: "" });
        }
        setFields({ ...fields, database: e.target.value });
      }
    },
    {
      id: "7",
      name: "warehouse",
      label: "DBSQL Warehouse ID",
      type: "text",
      placeholder: "Enter DBSQL Warehouse ID",
      value: fields.warehouse,
      isDisabled: false,
      desc: "",
      onChange: (e) => {
        if (infoError["warehouse"] !== "") {
          setInfoError({ ...infoError, warehouse: "" });
        }
        setFields({ ...fields, warehouse: e.target.value });
      }
    },
    {
      id: "8",
      name: "accname",
      label: "Workspace URL Where the DBSQL Warehouse is created",
      type: "text",
      placeholder: "Enter Workspace URL",
      value: fields.accname,
      isDisabled: false,
      desc: "",
      onChange: (e) => {
        if (infoError["accname"] !== "") {
          setInfoError({ ...infoError, accname: "" });
        }
        setFields({ ...fields, accname: e.target.value });
      }
    },
    {
      id: "9",
      name: "connection_cloud",
      label: "Connection Cloud",
      options: [
        { value: "AZURE", label: "AZURE" },
        { value: "AWS", label: "AWS" },
        { value: "GCP", label: "GCP" }
      ].map((obj, index) => {
        return (
          <MenuItem
            className={`cloud_con__menu_item ${
              obj?.value === fields?.connection_cloud?.value ? "selected" : ""
            }`}
            key={index}
            value={obj.value}>
            {obj.label}
          </MenuItem>
        );
      }),
      type: "dropdown",
      placeholder: "Enter Connection Cloud",
      value: fields?.connection_cloud?.value,
      isDisabled: false,
      desc: "",
      onChange: (e) => {
        if (infoError["connection_cloud"] !== "") {
          setInfoError({ ...infoError, connection_cloud: "" });
        }
        setFields({ ...fields, connection_cloud: e.target });
      }
    }
  ];
  const fieldView = fieldList?.map((field) => {
    return (
      <div className="form-group" key={field.id}>
        <div className="label-parent">
          <div className="label-container">
            <label htmlFor={field.name}>{field.label}</label>
            {field?.desc ? (
              <div className="img-container">
                {CustomTooltip(
                  field?.desc,
                  <img className="info" src={info_img} />,
                  10,
                  "info-tooltip",
                  "right"
                )}
              </div>
            ) : null}
          </div>
          {field?.additionalInfo ? field?.additionalInfo : null}
        </div>

        {field.type === "password" ? (
          <div className="cg-relative-position">
            <input
              type={pwdType}
              className="form-control"
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              // aria-describedby="emailHelp"
              placeholder={field.placeholder}
            />
            {eyeSlash ? (
              <img
                className="cg-eye cg-absolute-position"
                src={eyeCon}
                aria-hidden="true"
                onClick={() => {
                  toggleSlash();
                }}
              />
            ) : (
              <img
                className="cg-eye cg-absolute-position"
                src={eyeConSlash}
                aria-hidden="true"
                onClick={() => {
                  toggleSlash();
                }}
              />
            )}
          </div>
        ) : field?.type === "dropdown" ? (
          <FormControl fullWidth className="signboard-role-dropdown">
            <Select
              //label={field?.label}
              className="height-48"
              value={field?.value}
              onChange={field?.onChange}
              name={field?.label}
              IconComponent={() => (
                <span className="signboard-dropdown-arrow">
                  <img src={dropdown_arrow} />
                </span>
              )}>
              {field?.options}
            </Select>
          </FormControl>
        ) : (
          <input
            type={field.type}
            className="form-control"
            id={field.name}
            onChange={field.onChange}
            value={field.value}
            disabled={field?.isDisabled}
            // aria-describedby="emailHelp"
            placeholder={field.placeholder}
          />
        )}
        {infoError[`${field.name}`] !== "" ? (
          <div className="connection__fail">
            <p>{infoError[`${field.name}`]}</p>
          </div>
        ) : null}
      </div>
    );
  });

  return (
    <>
      <div className="form-container dbt_form_container">
        <div className="form-parent">
          <div className="field-container dbx__field__container">
            {fieldView}
          </div>
          <div className="button-container dbx__btn__container">
            <button className="btn btn btn-dark" onClick={handleClick}>
              Connect Account
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
DatabricksForm.propTypes = {
  onSubmitForm: PropTypes.func,
  closeModal: PropTypes.func,
  changeIsLoading: PropTypes?.func,
  setupConnection: PropTypes?.func
};
export default DatabricksForm;
