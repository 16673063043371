import React from "react";
import PropTypes from "prop-types";
import DeleteIcon from "../customElements/DeleteIcon";
import Select, { components } from "react-select";
import chev_down from "../../assets/images/common/chev_down.svg";

import Button from "../customElements/Button";
import Multiselects from "../customElements/MultiSelect";

const filterOperator = [
  { label: "AND", value: "AND" }
  // { label: "OR", value: "OR" }
];

const FilterRow = (props) => {
  const {
    item,
    index,
    removeFilterRow,
    temp,
    handleInputs,
    handleSwitch,
    filterOperators,
    maxSelectionLimit
  } = props;
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img style={{ pointerEvents: "none" }} src={chev_down} />
      </components.DropdownIndicator>
    );
  };

  return (
    <div key={index} className="filter-row-sub-container">
      <div className="filter__row">
        <div className="filter__section">
          <div className="grid-column-select">
            <Select
              value={item?.column}
              options={item?.filteredColumns}
              name="column"
              classNamePrefix="selectcategory"
              components={{ DropdownIndicator }}
              isDisabled={index === temp?.length - 1 ? false : true}
              placeholder="select"
              isSearchable={false}
              onChange={(e) => handleInputs(e, index, "col")}
            />
          </div>
          <div className="grid-operator-select">
            <Select
              value={item?.condition}
              options={filterOperators}
              name="column"
              classNamePrefix="selectcategory"
              components={{ DropdownIndicator }}
              placeholder="select"
              isSearchable={false}
              onChange={(e) => handleInputs(e, index, "opp")}
            />
          </div>
          {item?.condition?.value === "in" ? (
            <div className="grid-value-select">
              {item?.dropDown ? (
                <Multiselects
                  value={item?.value}
                  options={item?.defaultMenuList}
                  onChange={(
                    e,
                    dropdownInputValue,
                    setDropdownInputValue,
                    selectAllState,
                    setSelectAllState,
                    tempArray,
                    setTempArray
                  ) => {
                    handleInputs(
                      e,
                      index,
                      "val",
                      dropdownInputValue,
                      setDropdownInputValue,
                      selectAllState,
                      setSelectAllState,
                      tempArray,
                      setTempArray
                    );
                  }}
                  maxSelectionLimit={maxSelectionLimit}
                  footer={true}
                  onHide={() => handleSwitch(index)}
                />
              ) : (
                <Button
                  id=""
                  onClick={() => handleSwitch(index)}
                  className="filter-button">
                  {item.value?.length <= 0 ? (
                    <span className="placeholder-container">
                      <span className="placeholder-text">Select Value</span>
                      <img className="dropdown-arrow" src={chev_down} alt="" />
                    </span>
                  ) : (
                    <>
                      {item?.value?.length <= 2 ? (
                        <span className="chip-container">
                          <span className="chip-mid">{item?.value?.[0]}</span>
                          {item?.value?.length >= 2 && (
                            <span className="chip-mid">{item?.value?.[1]}</span>
                          )}
                          <img
                            className="dropdown-arrow"
                            src={chev_down}
                            alt=""
                          />
                        </span>
                      ) : (
                        <span className="chip-container">
                          <span className="chip">{item?.value?.[0]}</span>
                          <span className="chip">{item?.value?.[1]}</span>
                          <span className="chip-count">
                            +{item?.value?.length - 2}
                          </span>
                          <img
                            className="dropdown-arrow"
                            src={chev_down}
                            alt=""
                          />
                        </span>
                      )}
                    </>
                  )}
                </Button>
              )}
            </div>
          ) : (
            <div className="grid-value-select">
              <input
                type="text"
                placeholder="Enter Value"
                onChange={(e) => handleInputs(e.target.value, index, "input")}
                value={item?.value}
              />
            </div>
          )}
        </div>
        {temp.length > 1 && (
          <div onClick={() => removeFilterRow(index)} className="delete-filter">
            <DeleteIcon />
          </div>
        )}
      </div>
      {temp.length > 1 && index < temp.length - 1 && (
        <div className="filter-type-container">
          <Select
            value={filterOperator[0]}
            options={filterOperator}
            // styles={customStyles}
            classNamePrefix="selectcategory"
            components={{ DropdownIndicator }}
            placeholder="select"
            isSearchable={false}
          />
        </div>
      )}
    </div>
  );
};

FilterRow.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  handleSwitch: PropTypes.func,
  removeFilterRow: PropTypes.func,
  temp: PropTypes.array,
  handleInputs: PropTypes.func,
  handleSelectAll: PropTypes.func,
  filterOperators: PropTypes.array,
  maxSelectionLimit: PropTypes.object,
};

export default FilterRow;
