import React from "react";
import { getUniqueDate } from "../../utils/chargeback-helper";
import { costFormatter } from "../../utils/organization-helper";
import { CustomTooltip } from "../../utils/tooltip-helper";
import { customDateTimeFormat } from "../../utils/date-helper";
import { roundToSigDigits } from "../../utils/common-helper";

export const getColumns = (data, dateFormat = "MMM yyyy", date_label) => {
  data = data?.map((item) => ({
    ...item,
    month_date: customDateTimeFormat(item.date, dateFormat)
  }));
  const uniqueDatesArr = getUniqueDate(data);
  const uniqueDatesObj =
    uniqueDatesArr &&
    uniqueDatesArr?.length &&
    uniqueDatesArr?.map((date, index) => {
      return {
        field: date,
        name: date,
        expander: false,
        frozen: false,
        hidden: index === 0 && !/MTD/.test(date_label) ? true : false,
        body: costTemplate(date),
        style: {
          width: "120px",
          height: "40px",
          fontFamily: "Inter",
          fontSize: "12px",
          fontWeight: "400",
          padding: "8px",
          textAlign: "right",
          borderBottom: "1px solid #eaeaeb"
        },
        headerStyle: {
          textAlign: "right",
          width: "120px",
          height: "40px",
          padding: "8px",
          fontFamily: "Inter",
          backgroundColor: "#000",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "500",
          borderRight: "0.5px solid #fff"
        }
      };
    });
  uniqueDatesObj.unshift({
    field: "dw_ids",
    name: "Account(s)",
    expander: false,
    frozen: false,
    body: nameTemplate("dw_ids", {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "150px",
      display: "inline-block",
      verticalAlign: "top"
    }),
    style: {
      width: "150px",
      height: "40px",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: "400",
      padding: "8px",
      textAlign: "left",
      borderBottom: "1px solid #eaeaeb"
    },
    headerStyle: {
      textAlign: "left",
      width: "150px",
      height: "40px",
      padding: "8px",
      backgroundColor: "#000",
      color: "#fff",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Inter",
      borderRight: "0.5px solid #fff"
    }
  });
  uniqueDatesObj.unshift({
    field: "resource_subtypes",
    name: "Type(s)",
    expander: false,
    frozen: false,
    body: nameTemplate("resource_subtypes", {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "150px",
      display: "inline-block",
      verticalAlign: "top"
    }),
    style: {
      width: "100px",
      height: "40px",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: "400",
      padding: "8px",
      textAlign: "left",
      borderBottom: "1px solid #eaeaeb"
    },
    headerStyle: {
      textAlign: "left",
      width: "100px",
      height: "40px",
      padding: "8px",
      backgroundColor: "#000",
      color: "#fff",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Inter",
      borderRight: "0.5px solid #fff"
    }
  });
  uniqueDatesObj.unshift({
    field: "name",
    name: "Name",
    expander: true,
    body: nameTemplate("name", {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "295px",
      display: "inline-block",
      verticalAlign: "top"
    }),
    frozen: false,
    style: {
      width: "370px",
      height: "40px",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: "400",
      padding: "8px",
      textAlign: "left",
      borderBottom: "1px solid #eaeaeb"
    },
    headerStyle: {
      textAlign: "left",
      width: "370px",
      height: "40px",
      padding: "8px",
      backgroundColor: "#000",
      color: "#fff",
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Inter",
      borderRight: "0.5px solid #fff"
    }
  });

  return uniqueDatesObj;
};
export const nameTemplate = (field, style) => {
  const template = (node) => (
    <span style={style}>
      {CustomTooltip(node?.data[field], node?.data[field], 10)}
    </span>
  );
  template.displayName = `BodyTemplate(${field})`;
  return template;
};
export const costTemplate = (field) => {
  const template = (node) => {
    const keys = Object.keys(node.data);
    const nodeData = keys?.findIndex((key) => key === field);
    if (nodeData === -1) {
      return <span>{`$0.00`}</span>;
    }
    const currentCost = +node.data[field];
    const previousCost = +node?.data[keys[nodeData - 1]];
    const difference = +currentCost - +previousCost;

    const percentageDifference = +((difference / previousCost) * 100);
    const formattedPercentageDifference = !isNaN(percentageDifference)
      ? (percentageDifference >= 0 ? "+" : "") +
        roundToSigDigits(percentageDifference, 0, false, false)
      : "";
    return (
      <span>
        {`${costFormatter(+node.data[field])}`}
        <span className="percentage-grey">
          {formattedPercentageDifference &&
          !isNaN(percentageDifference) &&
          previousCost !== 0 &&
          formattedPercentageDifference !== "Infinity"
            ? "(" + formattedPercentageDifference + "%)"
            : ""}
        </span>
      </span>
    );
  };
  template.displayName = `BodyTemplate(${field})`;
  return template;
};
