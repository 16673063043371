import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./changelog.scss";
import chev_down from "../../assets/images/common/chev_down.svg";
import {
  createColDefs,
  getAutoheightOverflow,
  getGridHeight,
  getValueObj,
  handleGridResolution
} from "../../utils/common-helper";
import GridComponent from "../../components/GridComponent";
import Select, { components } from "react-select";
import { getDateRange } from "../../utils/user-helper";
import {
  getChangeLogUserRole,
  getChangeLogWarehouse
  // getChangeLogFilters
} from "../../utils/changelog-helper";
import Button from "../../components/customElements/Button";
import FilterIcon from "../../assets/images/grid/Filter.svg";
import ExportCSV from "../../assets/images/grid/exportcsv.svg";
import CustomFilter from "../../components/CustomFilter/CustomFilter";
import { getChangeLogUserProps, getChangeLogWhProps } from "./tablecoldefs";
import expand from "../../assets/images/common/expand.svg";
import {
  CGURFilterColumns,
  CGWHFilterColumns,
  applyFilterToGrid,
  clearAllFilters,
  filterTypes,
  postFilter
} from "../../utils/filter-helper";
import ExpandComponent from "../../components/ExpandComponent";

const customStyles = {
  control: (base) => ({
    ...base,
    background: "#FFFFFF",
    borderColor: "#E3E3E8"
  }),
  container: (provided) => ({
    ...provided
    //width: 180
  })
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={chev_down} />
    </components.DropdownIndicator>
  );
};

const ChangeLog = ({ acc }) => {
  const customPropsWh = getChangeLogWhProps();
  const customPropsUser = getChangeLogUserProps();
  const [gridResolution, setGridResolution] = useState(true);

  const [pageData, setPageData] = useState(null);
  const [colDef, setColDef] = useState(null);
  const [gridFallback, setGridFallback] = useState(false);
  const [gridData, setGridData] = useState(null);
  const [gridLoading, setGridLoading] = useState(false);

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);

  const [dateRange, setDateRange] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [showFilters, setShowFilters] = useState(false);
  const [downloadCSV, setDownloadCSV] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);

  const [applyFilter, setApplyFilter] = useState([]);
  const [expandModal, setExpandModal] = useState(false);
  const [gridTotalWidth, setGridTotalWidth] = useState(null);
  const tabs = [
    { id: "1", label: "Warehouse Management" },
    { id: "2", label: "User and Role Management" }
  ];
  const handleExpand = (bool) => {
    setExpandModal(bool);
  };
  const tabView =
    tabs &&
    tabs.map((tab) => {
      return (
        <div
          className={`tab ${tab?.id === activeTab ? "active" : "normal-btn"} ${
            gridLoading ? "disabled" : ""
          }`}
          key={tab?.id}
          onClick={() => {
            setActiveTab(tab?.id);
            if (tab?.id === "1") {
              setApplyFilter([
                {
                  column: CGWHFilterColumns?.[0],
                  condition: filterTypes?.[0],
                  value: [],
                  dropDown: false,
                  previuosValue: [],
                  previousMenuList: []
                }
              ]);
            } else {
              setApplyFilter([
                {
                  column: CGURFilterColumns?.[0],
                  condition: filterTypes?.[0],
                  value: [],
                  dropDown: false,
                  previuosValue: [],
                  previousMenuList: []
                }
              ]);
            }
            setDateRange(dateRangeOptions[0]);
            setFilterApplied(false);
          }}>
          <span className="title">{tab?.label}</span>
        </div>
      );
    });

  const loadPageData = async (acc, dateRangeVal) => {
    setGridLoading(true);
    let response;
    let colDefsArr;
    try {
      if (activeTab === "1") {
        response = await getChangeLogWarehouse(acc, dateRangeVal);
        response.columns.unshift("index");
        response.column_display_name_mappping.index = "S No.";
        colDefsArr = createColDefs(
          response?.columns,
          customPropsWh,
          null,
          response?.column_display_name_mappping
        );
      } else {
        response = await getChangeLogUserRole(acc, dateRangeVal);
        response.columns.unshift("index");
        response.column_display_name_mappping.index = "S No.";
        colDefsArr = createColDefs(
          response?.columns,
          customPropsUser,
          null,
          response?.column_display_name_mappping
        );
      }

      if (response) {
        if (colDefsArr && response?.data) {
          response.data.forEach((value, index) => {
            value.index = index + 1;
          });
          const newData = colDefsArr.map((colDef) => ({
            ...colDef,
            unSortIcon: true,
            sortable: true,
            filter: false
          }));
          setColDef(newData);
          setGridData(response?.data);
          setPageData(response?.data);
          let screenInnerWidth = window.innerWidth;
          let gridWidth = Object.values(newData).reduce((sum, prop) => {
            if (typeof prop.width === "number" && !isNaN(prop.width)) {
              return sum + prop.width;
            }
            return sum;
          }, 0);
          setGridTotalWidth(gridWidth);
          setGridResolution(
            handleGridResolution(gridWidth, screenInnerWidth, 360)
          );
        }
        if (gridFallback) {
          setGridFallback(false);
        }
      } else {
        setGridData([]);
        setPageData([]);
      }
      setIsPageLoading(false);
      setGridLoading(false);
    } catch (err) {
      setGridData([]);
      setPageData([]);
      setIsPageLoading(false);
      setGridLoading(false);
    }
  };

  const getData = async () => {
    try {
      let dateRangeArr = [];
      const dateRangeObj = await getDateRange(acc?.id);
      if (dateRangeObj?.values) {
        dateRangeArr = getValueObj(dateRangeObj?.values);
        setDateRangeOptions(dateRangeArr);
        setDateRange(dateRangeArr[0]);
      }
    } catch (err) {
      setGridData([]);
      setPageData([]);
      setIsPageLoading(false);
    }
  };
  // Filter and popover Section

  const handlePopover = (e) => {
    e?.stopPropagation();
    setShowFilters(!showFilters);
  };

  const handleExport = () => {
    setDownloadCSV(true);
    setTimeout(() => {
      setDownloadCSV(false);
    }, 3000);
  };
  const clearGridFilter = () => {
    setGridData(pageData);
    const filterData = clearAllFilters(applyFilter);
    setApplyFilter(filterData);
    setFilterApplied(false);
  };
  const handleGridFilter = (applyifilters, gridRowData) => {
    const filteredData = applyFilterToGrid(applyifilters, gridRowData);
    setGridData(filteredData);
    setFilterApplied(true);
    const handleFilterValue = postFilter(applyifilters);
    setApplyFilter(handleFilterValue);
  };
  const [breadCrumbs] = useState(false);

  useEffect(() => {
    setIsPageLoading(true);
    getData();
  }, []);
  useEffect(() => {
    setGridLoading(true);
    if (dateRange?.label) {
      loadPageData(acc, dateRange.label);
    }

    if (activeTab === "1") {
      setApplyFilter([
        {
          column: CGWHFilterColumns?.[0],
          condition: filterTypes?.[0],
          value: [],
          dropDown: false,
          previuosValue: [],
          previousMenuList: []
        }
      ]);
    } else {
      setApplyFilter([
        {
          column: CGURFilterColumns?.[0],
          condition: filterTypes?.[0],
          value: [],
          dropDown: false,
          previuosValue: [],
          previousMenuList: []
        }
      ]);
    }
  }, [acc, dateRange, activeTab]);
  return (
    <>
      {expandModal && (
        <>
          <ExpandComponent
            columnDefs={colDef}
            rowData={gridData}
            gridFallback={gridFallback}
            handleExpand={handleExpand}
            gridStyle={{
              height: getGridHeight(gridData, window.innerHeight, 200, 180),
              avoidAutoHeightOverflow: getAutoheightOverflow(gridData)
            }}
            gridLoading={gridLoading}
            suppressColumnsSize={handleGridResolution(
              gridTotalWidth,
              window.innerWidth,
              96
            )}
            noRowsText={
              activeTab === "1"
                ? gridData?.length == 0
                  ? "No warehouse changelog for the selected period."
                  : "No data for this filter"
                : "No User or User role changelog for the selected period."
            }
            downloadCSV={downloadCSV}
            tableName={
              activeTab === "1"
                ? "Warehouse Management"
                : "User and Role Management"
            }
          />
        </>
      )}
      <div className="changelog-container overflow-auto">
        {!isPageLoading ? (
          <>
            <div className="header-container">
              <div className="header-block">
                {breadCrumbs ? (
                  <div className="breadcrumbs-container"></div>
                ) : null}
                <div className="header">
                  <div className="tabs__container">{tabView}</div>
                </div>
              </div>
              <div className="select-container date__filter">
                <Select
                  value={dateRange}
                  options={dateRangeOptions}
                  styles={customStyles}
                  classNamePrefix="selectcategory"
                  placeholder="select"
                  isSearchable={false}
                  components={{ DropdownIndicator }}
                  onChange={(e) => {
                    // setGridLoading(true);
                    setDateRange(e);
                  }}
                />
              </div>
            </div>
            <div className="changelog-grid-wrapper">
              <div className="header-container">
                <div className="filter-container">
                  <div className="top-filter">
                    <div className="select-container filter__container">
                      <Button
                        onClick={handlePopover}
                        className={
                          filterApplied
                            ? "qg-filter-button-active "
                            : showFilters
                            ? "qg-filter-active"
                            : "qg-filter-button"
                        }>
                        <img src={FilterIcon} alt="" />
                        <span>Filter</span>
                      </Button>
                      {showFilters && (
                        <CustomFilter
                          column={
                            activeTab === "1"
                              ? CGWHFilterColumns
                              : CGURFilterColumns
                          }
                          gridData={pageData}
                          applyFilter={applyFilter}
                          setApplyFilter={setApplyFilter}
                          close={handlePopover}
                          handleFilter={handleGridFilter}
                          clearFilter={clearGridFilter}
                          isMenuOpen={showFilters}
                        />
                      )}
                    </div>
                  </div>
                  <div className="top-filter">
                    <Button
                      onClick={() => {
                        handleExpand(true);
                      }}
                      className={"qg-filter-button expand__button"}>
                      <img src={expand} alt="" />
                      <span>Expand</span>
                    </Button>
                    <div className="select-container export-container">
                      <Button
                        onClick={handleExport}
                        className={"qg-filter-button"}>
                        <img src={ExportCSV} alt="" />
                        <span>Export</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-block">
                {!gridLoading ? (
                  <GridComponent
                    columnDefs={colDef}
                    rowData={gridData}
                    gridFallback={gridFallback}
                    gridStyle={{
                      height: getGridHeight(
                        gridData,
                        window?.innerHeight - 246,
                        100,
                        180
                      ),
                      avoidAutoHeightOverflow: getAutoheightOverflow(gridData)
                    }}
                    gridLoading={gridLoading}
                    suppressColumnsSize={gridResolution}
                    noRowsText={
                      activeTab === "1"
                        ? gridData?.length == 0
                          ? "No warehouse changelog for the selected period."
                          : "No data for this filter"
                        : "No User or User role changelog for the selected period."
                    }
                    downloadCSV={downloadCSV}
                    tableName={
                      activeTab === "1"
                        ? "Warehouse Management"
                        : "User and Role Management"
                    }
                  />
                ) : (
                  <div className="load">
                    <div className="preload"></div>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
ChangeLog.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object
};
export default ChangeLog;
