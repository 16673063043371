import React from "react";
import PropTypes from "prop-types";
import "./connectioncard.scss";
import connection_checked from "../../assets/images/connection/connection_checked.svg";
import coming_soon from "../../assets/images/connection/coming_soon.svg";
const ConnectionCard = ({ item, changeSelected, selected }) => {
  const handleClick = (item) => {
    changeSelected(item);
  };
  return (
    <div
      className={`card-container ${
        item.available?.toString() === "true" && selected === item.id
          ? "selected"
          : ""
      }`}
      key={item.id}
      onClick={
        item?.available?.toString() === "true"
          ? () => {
              handleClick(item);
            }
          : null
      }>
      <div className="card-block">
        <div className="image-container">
          <img src={item.image} />
        </div>
        <div className="header-container">
          <div className="header">
            <span>{item.label}</span>
          </div>
          <div className="sub-header">
            <span>Connect now</span>
          </div>
        </div>
      </div>
      <div className="card-block">
        <div className="selection-container">
          {item.available?.toString() === "true" ? (
            item.id === selected ? (
              <div className="selected-container">
                <div className="selected-img">
                  <img src={connection_checked} />
                </div>
              </div>
            ) : null
          ) : (
            <div className="coming-soon">
              <div className="coming-soon-img">
                <img src={coming_soon} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ConnectionCard.propTypes = {
  selected: PropTypes.string,
  item: PropTypes.object,
  changeSelected: PropTypes.func
};

export default ConnectionCard;
