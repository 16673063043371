import { attachParams } from "./url-helper";
import { getRequest } from "./http-helper";

export const getTop50JobsDateRanges = async (acc) => {
  const params = {
    data_wh_id: acc?.data_warehouse_info_id
  };
  const URL = attachParams(`/v2/dbx_jobs/top50_jobs/date_ranges`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getTop50JobsData = async (fields) => {
  const params = {
    data_wh_id: fields?.acc?.data_warehouse_info_id,
    start_date: fields?.start_date,
    end_date: fields?.end_date,
    cluster_type: fields?.cluster_type,
    sorting_option: fields?.sorting_option,
    is_failed: fields?.is_failed
  };
  const URL = attachParams(`/v2/dbx_jobs/top50_jobs/table_data`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getTop50JobsSortingOptions = async (fields) => {
  const params = {
    cluster_type: fields?.cluster_type
  };
  const URL = attachParams(`/v2/dbx_jobs/top50_jobs/sorting_options`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
