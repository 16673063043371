import { attachParams } from "./url-helper";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest
} from "./http-helper";
import { getException } from "./exceptiondictionary";
import ping__id from "../assets/images/common/ping__id.svg";
import _ from "lodash";

export const getOrgSettingsUsersData = async () => {
  //const params = { data_wh_id: wh_id ? wh_id : "" };
  const URL = attachParams(`/v1/user`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getOrgSettingsGroupData = async () => {
  //const params = { data_wh_id: wh_id ? wh_id : "" };
  const URL = attachParams(`/v1/group`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getSlackAlert = async () => {
  const URL = attachParams(`/v1/alerts/slack`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getTeamsAlert = async () => {
  const URL = attachParams(`/v1/alerts/microsoftteams`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getAlertsConfigData = async () => {
  const URL = attachParams(`v1/alerts/email`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const setSlackAlertAPI = async (_params, customToast) => {
  const params = {
    url: _params?.url,
    channel_name: _params?.channel_name,
    disabled: false
  };
  const URL = attachParams(`/v1/alerts/slack`);
  const { data, error } =
    _params?.mode === "add"
      ? await postRequest({
          url: URL,
          data: JSON.stringify(params)
        })
      : await putRequest({
          url: URL,
          data: JSON.stringify(params)
        });
  if (error) {
    if (customToast) {
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorKey,
          error?.response?.data?.message
        )
      });
    }
    return null;
  } else if (data) {
    return data;
  }
};

export const setTeamsAlertAPI = async (_params, customToast) => {
  const params = {
    url: _params?.url,
    channel_name: _params?.channel_name,
    disabled: false
  };
  const URL = attachParams(`/v1/alerts/microsoftteams`);
  const { data, error } =
    _params?.mode === "add"
      ? await postRequest({
          url: URL,
          data: JSON.stringify(params)
        })
      : await putRequest({
          url: URL,
          data: JSON.stringify(params)
        });
  if (error) {
    if (customToast) {
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorKey,
          error?.response?.data?.message
        )
      });
    }
    return null;
  } else if (data) {
    return data;
  }
};

export const testSlackAlertAPI = async (_params) => {
  const params = {
    url: _params?.url
  };
  const URL = attachParams(`/v1/alerts/slack/test`);
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(params)
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const testTeamsAlertAPI = async (_params) => {
  const params = {
    url: _params?.url
  };
  const URL = attachParams(`/v1/alerts/microsoftteams/test`);
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(params)
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const deleteSlackAlertAPI = async () => {
  const URL = attachParams(`/v1/alerts/slack`);
  const { data, error } = await deleteRequest({
    url: URL
    //data: JSON.stringify(params),
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const disableSCIMAPI = async () => {
  const URL = attachParams(`/v1/account/scim-token`);
  const { data, error } = await deleteRequest({
    url: URL
    //data: JSON.stringify(params),
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const deleteUser = async (id, customToast) => {
  const params = { user_id: id };
  const URL = attachParams(`/v1/user`, params);
  const { data, error } = await deleteRequest({
    url: URL
  });
  if (error) {
    if (customToast) {
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorKey,
          error?.response?.data?.message
        )
      });
    }
    return null;
  } else if (data) {
    if (customToast) {
      customToast({
        type: "success",
        ...getException("USERDELETIONSUCCESS")
      });
    }
    return data;
  }
};

export const deleteGroupAPI = async (id, customToast) => {
  const URL = attachParams(`/v1/group/${id}`);
  const { data, error } = await deleteRequest({
    url: URL
  });
  if (error) {
    if (customToast) {
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorKey,
          error?.response?.data?.message
        )
      });
    }
    return null;
  } else {
    if (customToast) {
      customToast({
        type: "success",
        ...getException("GROUPDELETIONSUCCESS")
      });
    }
    return data;
  }
};
export const editUser = async (id, role, dwaccoptions, rowData) => {
  let params = { role: role?.value?.toLowerCase() };
  const id_list = rowData?.find((o) => {
    return id?.toString() === o?.user_id?.toString();
  })?.access_list?.dw_id_list;
  const hasAll = dwaccoptions?.findIndex((o) => {
    return o?.label === "Select All";
  });
  if (hasAll >= 0) {
    if (id_list?.length === 0) {
      params = {
        role: role?.value?.toLowerCase()
      };
    } else {
      params = {
        role: role?.value?.toLowerCase(),
        dw_access_list: []
      };
    }
  } else {
    const is_equal = _.isEqual(
      id_list?.sort(),
      dwaccoptions
        ?.filter((o) => {
          return o !== "Select All";
        })
        ?.map((o) => {
          return o;
        })
        .sort()
    );
    if (is_equal) {
      params = { role: role?.value?.toLowerCase() };
    } else {
      params = {
        role: role?.value?.toLowerCase(),
        dw_access_list: dwaccoptions?.length
          ? dwaccoptions
              ?.filter((obj) => {
                return obj !== "Select All";
              })
              ?.map((obj) => {
                return obj;
              })
          : []
      };
    }
  }

  const URL = attachParams(`/v1/user/${id}`);
  const { data, error } = await patchRequest({
    url: URL,
    data: params
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const editGroupAPI = async (id, role, users, name, synced) => {
  const params =
    synced?.toString() === "true"
      ? {
          role: role?.value?.toLowerCase()
        }
      : {
          role: role?.value?.toLowerCase(),
          name: name,
          users: users?.length
            ? users
                ?.filter((obj) => {
                  return obj !== "Select All";
                })
                ?.map((obj) => {
                  return obj;
                })
            : []
        };
  const URL = attachParams(`/v1/group/${id}`);
  const { data, error } = await patchRequest({
    url: URL,
    data: params
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const addUser = async (fields, customToast, dwAccOptions) => {
  const params = {
    first_name: fields?.fname,
    last_name: fields?.lname,
    email: fields?.email?.trim()?.toLowerCase(),
    organization: fields?.org,
    password: fields?.pword,
    autogenerate_password: fields?.auto_generate_pword,
    role: fields?.role?.value?.toLowerCase(),
    ...(fields?.dwaccoptions?.length === dwAccOptions?.length
      ? {
          dw_access_list: []
        }
      : {
          dw_access_list:
            fields?.dwaccoptions?.length === 0
              ? []
              : fields?.dwaccoptions
                  ?.filter((obj) => {
                    return obj !== "Select All";
                  })
                  ?.map((obj) => {
                    return obj;
                  })
        })
  };
  const URL = attachParams(`v1/user`);
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(params)
  });
  if (error) {
    if (customToast) {
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorKey,
          error?.response?.data?.message
        )
      });
    }
    return null;
  } else if (data) {
    return data;
  }
};

export const addGroupAPI = async (fields, customToast) => {
  const params = {
    name: fields?.name,
    role: fields?.role?.value?.toLowerCase(),
    users:
      fields?.users?.length === 0
        ? []
        : fields?.users
            ?.filter((obj) => {
              return obj !== "Select All";
            })
            ?.map((obj) => {
              return obj;
            })
  };
  const URL = attachParams(`v1/group`);
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(params)
  });
  if (error) {
    if (customToast) {
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorKey,
          error?.response?.data?.message
        )
      });
    }
    return null;
  } else if (data) {
    return data;
  }
};

export const saveAlertConfig = async ({
  user_id,
  daily_report,
  warehouse_recommendation_report,
  long_running_queries_report
}) => {
  const params = {
    user_id: user_id,
    reports: {
      daily_report: daily_report,
      warehouse_recommendation_report: warehouse_recommendation_report,
      long_running_queries_report: long_running_queries_report
    }
  };
  const URL = attachParams(`/v1/alerts/email`);
  const { data, error } = await patchRequest({
    url: URL,
    data: params
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getSSODetailsAPI = async () => {
  const URL = attachParams(`v1/sso`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getSCIMData = async () => {
  const URL = attachParams(`/v1/account/scim-token`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getAutoDeleteUserData = async () => {
  const URL = attachParams(`/v1/account/scim_config`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const postAutoDeleteUserData = async (payload) => {
  const URL = attachParams(`/v1/account/scim_config`);
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(payload)
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const setupSCIMToken = async () => {
  const URL = attachParams(`/v1/account/scim-token`);
  const { data, error } = await postRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getIDPListAPI = async () => {
  const URL = attachParams(`v1/sso/idp-list`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const ssoPatchAPI = async (disablePwd) => {
  const params = {
    password_login_enabled: !disablePwd
  };
  const URL = attachParams(`/v1/sso`);
  const { data, error } = await patchRequest({
    url: URL,
    data: params
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const ssoPatchIDPAPI = async (idp_id, meta, cert, enable) => {
  let params = null;
  if (!meta && !cert) {
    params = {
      enable
    };
  } else {
    params = {
      file_order:
        !meta && cert ? "cert" : !cert && meta ? "config" : "config,cert",
      enable
    };
  }
  const formData = new FormData();
  if (meta) {
    formData.append("files", meta);
  }
  if (cert) {
    formData.append("files", cert);
  }
  if (!meta && !cert) {
    formData.append("files", null);
  }
  const URL = attachParams(`/v1/sso/idp/${idp_id}`, params);
  const { data, error } = await patchRequest({
    url: URL,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const disableSSOAPI = async () => {
  const URL = attachParams(`/v1/sso/disable`);
  const { data, error } = await postRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const ssoPostIDPAPI = async (meta, cert, display_name) => {
  const params = {
    idp_type: display_name,
    file_order: "config,cert"
  };
  const formData = new FormData();
  formData.append("files", meta);
  formData.append("files", cert);
  const URL = attachParams(`/v1/sso/idp`, params);
  const { data, error } = await postRequest({
    url: URL,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const enableSSOAPI = async () => {
  const URL = attachParams(`/v1/sso/enable`);
  const { data, error } = await postRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const IDPProviders = () => {
  return {
    "PingIdentity (SAML)": {
      label: "Ping Identity",
      img: ping__id,
      idp_type: "PingIdentity (SAML)"
    }
  };
};
