import CoinDCX from "./Logo_CoinDCX.svg";
import Tapp from "./Logo_Tapp.svg";
import Kangaroo from "./Logo_Kangaroo.svg";
import Wildlife from "./Logo_Wildlife.svg";
import Klub from "./Logo_Klub.svg";
import Hunters from "./Logo_Hunters.svg";
import Meriters from "./Logo_Meriters2.svg";
import Aura from "./Logo_Aura2.svg";
import CoinSwitch from "./Logo_Coinswitch2.svg";
import Blend360 from "./Logo_Blend360.svg";
import Accenture from "./Logo_Accenture.svg";
import MCH from "./Logo_MCH.svg";
import Bigeye from "./Logo_Bigeye.svg";

export default {
  CoinDCX,
  Tapp,
  Kangaroo,
  Wildlife,
  Klub,
  Hunters,
  Meriters,
  Aura,
  CoinSwitch,
  Blend360,
  Accenture,
  MCH,
  Bigeye
};
