import { attachParams } from "./url-helper";
import { getRequest } from "./http-helper";

export const getCIDateRanges = async (acc) => {
  const params = {
    data_wh_id: acc?.data_warehouse_info_id
  };
  const URL = attachParams(`/v2/dbx_cluster/inventory/date_ranges`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getCIData = async (fields) => {
  const params = {
    data_wh_id: fields?.acc?.data_warehouse_info_id,
    start_date: fields?.start_date,
    end_date: fields?.end_date,
    cluster_type: fields?.cluster_type
  };
  const URL = attachParams(`/v2/dbx_cluster/inventory/table_data`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getCITotals = async (fields) => {
  const params = {
    data_wh_id: fields?.acc?.data_warehouse_info_id,
    start_date: fields?.start_date,
    end_date: fields?.end_date,
    cluster_type: fields?.cluster_type
  };
  const URL = attachParams(`/v2/dbx_cluster/inventory/totals`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
