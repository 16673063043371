import React from "react";
import PropTypes from "prop-types";
import "./cards.scss";

const DisplayCards = (props) => {
  const { label, value, align = "normal" } = props;
  return (
    <div className="display-card-wrapper">
      {align === "normal" && <p className="card-label">{label??"-"}</p>}
      <p className="card-value">{value??"-"}</p>
      {align === "reverse" && <p className="card-label">{label??"-"}</p>}
    </div>
  );
};
DisplayCards.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  align: PropTypes.string
};
export default DisplayCards;
