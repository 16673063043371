import { CustomTooltip } from "../../../utils/tooltip-helper";
import {
  roundToSigDigits,
  secondsConverter
} from "../../../utils/common-helper";
import { costFormatter } from "../../../utils/organization-helper";

export const getTop50jobsProps = (hasTrialFallback) => {
  const customProps = {
    "S No.": {
      type: "rightAligned",
      headerClass: "text-align-right",
      width: 75,
      pinned: "left",
      cellRenderer: (params) => {
        return params?.data["S No."];
      }
    },
    "Job Name/Job Id": {
      width: 247,
      filter: true,
      pinned: "left",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    "Total Duration": {
      width: 200,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return secondsConverter(
          roundToSigDigits(params?.value, 2, false, false)
        );
      }
    },
    "Non-Execution Time": {
      width: 200,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return secondsConverter(
          roundToSigDigits(params?.value, 2, false, false)
        );
      }
    },
    "Total Runs": {
      width: 170,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Avg. Cost per Run ($)": {
      width: 200,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params?.data["Avg. Cost per Run ($)"];
        }
        return costFormatter(
          roundToSigDigits(
            params?.data["Avg. Cost per Run ($)"],
            2,
            false,
            false
          )
        );
      }
    },
    "Avg. Duration per Run": {
      width: 200,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params?.data["Avg. Duration per Run"];
        }
        return secondsConverter(
          roundToSigDigits(
            params?.data["Avg. Duration per Run"],
            2,
            false,
            false
          )
        );
      }
    },
    "% of Failed Runs": {
      width: 180,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Total Cost ($)": {
      width: 180,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Total DBUs": {
      width: 180,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Latest Run Start Time": {
      width: 200,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Latest Run Status": {
      width: 180,
      cellClass: "grid-cell-left-aligned"
    },
    "Total Tasks": {
      width: 170,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "# of Successful Tasks": {
      width: 200,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "% of Failed Tasks": {
      width: 180,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false, false);
      }
    },
    Users: {
      width: 200,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    SKUs: {
      width: 200,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Cluster/Warehouse/Dashboard ID": {
      width: 270,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Warehouse ID": {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      resizable: false,
      sortable: false,
      //pinned: "right",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Job Id": {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      resizable: false,
      sortable: false,
      //pinned: "right",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    }
  };

  return customProps;
};
