// URL CONSTANTS
import { env } from "../env";
export const BASE_URL = env.REACT_APP_BASE_URL || "";

export const attachParams = (relativeUrl, params) => {
  let baseUrl = BASE_URL;

  if (baseUrl === "") {
    baseUrl = window.location.origin;
  }
  const url = new URL(relativeUrl, baseUrl);
  url.search = new URLSearchParams(params).toString();
  return url;
};

export const extractParamsFromURL = (search) => {
  let newSearchParams = new URLSearchParams(search);
  let paramsArray = Array.from(newSearchParams.entries()).map(
    ([paramKey, value]) => ({ key: paramKey, value })
  );
  return paramsArray;
};

export const checkSingleParamKey = (search, key) => {
  let flag = false;
  let paramsArray = extractParamsFromURL(search);
  paramsArray.forEach((element) => {
    if (element.key === key) {
      flag = true;
    }
  });

  return flag;
};
