import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "../../components/customElements/Button";
import FilterIcon from "../../assets/images/grid/Filter.svg";
import chev_down from "../../assets/images/common/chev_down.svg";
import Select, { components } from "react-select";
import "./dbxcostexplorer.scss";
import GraphContainer from "../../components/CostExplorer/GraphContainer";
import GridComponent from "../../components/GridComponent";
import _ from "lodash";
import { DateTime } from "luxon";
import { useLocation, useNavigate } from "react-router-dom";
import jpeg_export from "../../assets/images/common/jpeg_export.svg";
import ExportCSV from "../../assets/images/grid/exportcsv.svg";
import {
  extractColumns,
  getDBXCostExplorerDateRanges,
  getDBXCostExplorerMetaData,
  getDBXCostExplorerOverView,
  getDBXCostExplorerTotals,
  granularityArray
} from "../../utils/cost-explorer-helper";
import { getMonthRange, getMonthsArray } from "../../utils/date-helper";
import groupSvg from "../../assets/images/dashboard/group.svg";
import {
  getAutoheightOverflow,
  getGridHeight,
  getMaxDate,
  getValueObj,
  handleGridResolution,
  isValidDate,
  roundToSigDigits
} from "../../utils/common-helper";
import { costFormatter } from "../../utils/organization-helper";
import { getSessionStorage } from "../../utils/storage-helper";
import CustomFilter from "../../components/CustomFilter/CustomFilter";
import expand from "../../assets/images/common/expand.svg";
import {
  applyFilterToGrid,
  clearAllFilters,
  //deleteFromURL,
  filterTypes,
  postFilter
  // restoreFromUrlParams,
  // updateUrlWithParams
} from "../../utils/filter-helper";
import ExpandComponent from "../../components/ExpandComponent";
import { stackGrahpcolors } from "../../utils/cost-reporting-helper";
import { CustomTooltip } from "../../utils/tooltip-helper";
function DBXCostExplorer({ acc }) {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const colors = stackGrahpcolors;
  const getValuesFromUrl = (key, navigationState) => {
    if (
      navigationState &&
      navigationState?.length &&
      navigationState[navigationState?.length - 1][key]
    ) {
      return navigationState[navigationState?.length - 1][key];
    } else {
      return new URLSearchParams(location.search).get(key);
    }
  };
  const [gridData, setGridData] = useState({
    rowData: [],
    columnDefs: []
  });
  const [navigationState, setNavigationState] = useState([]);
  const tagurl = getValuesFromUrl("tag", navigationState);
  const [granularity, setGranularity] = useState(null);
  const currentKey = location.pathname.split("/").pop();
  const [granularityOptions] = useState(granularityArray);
  const [dateRange, setDateRange] = useState(null);
  const [groupByOptions, setGroupByOptions] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    page_size: 50,
    page_num: 0,
    pagination: true,
    start: 0,
    end: 50
  });

  const [downloadCSV, setDownloadCSV] = useState(false);
  const [groupBy, setGroupBy] = useState(null);
  const [tagVisible, setTagVisible] = useState(
    tagurl && tagurl.toString() !== "null" ? true : false
  );
  const [memoizeData, setMemoizeData] = useState(null);
  const [totals, setTotals] = useState(null);
  const [tag, setTag] = useState(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [copyChartData, setCopyChartData] = useState(null);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [costSpent, setCostSpent] = useState(null);
  const [dbuSpent, setDbuSpent] = useState(null);
  const [currentCostSpent, setCurrentCostSpent] = useState({});
  const [filterDisabled, setFilterDisabled] = useState(false);
  const [expandModal, setExpandModal] = useState(false);
  const handleExpand = (bool) => {
    setExpandModal(bool);
  };
  const [showFilters, setShowFilters] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterColumns, setFilterColumns] = useState([
    { label: "SKU", value: "sku_name" }
  ]);
  const [activeTab, setActiveTab] = useState(
    getValuesFromUrl("activeTab", navigationState) || "1"
  );
  const [gridfallback, setGridfallback] = useState(false);
  const [gridLoading, setGridLoading] = useState(true);
  const [chartLoading, setChartLoading] = useState(true);
  const [applyFilter, setApplyFilter] = useState([
    {
      column: filterColumns?.[0],
      condition: filterTypes?.[0],
      value: [],
      dropDown: false,
      previuosValue: [],
      previousMenuList: []
    }
  ]);
  const [hasTrialFallback] = useState(
    getSessionStorage("plan_name").toLowerCase() === "trial" ? true : false
  );

  const tabOptions = [
    { value: "1", label: `$`, id: "1" },
    { value: "2", label: `DBUs`, id: "2" }
  ];
  const tabView = tabOptions?.map((tab) => {
    const activeTab = getValuesFromUrl("activeTab", navigationState) || "1";
    return (
      <div
        key={tab?.id}
        className={`tab ${tab?.id === activeTab ? "active" : "normal-btn"}`}
        onClick={() => {
          setActiveTab(tab?.id);
          let url = new URLSearchParams(location.search || "");
          url.set("activeTab", tab?.id);
          const copyObj = { ...navigationState?.[navigationState?.length - 1] };
          copyObj.activeTab = tab?.id;
          setNavigationState([copyObj]);
          navigate({
            pathname: location.pathname,
            search: url.toString()
          });
        }}>
        <span>{tab?.label}</span>
      </div>
    );
  });

  const chartRef = useRef(null);
  const [metaData, setMetaData] = useState(null);
  const [analysisData, setAnalysisData] = useState(null);

  const clearGridFilter = async () => {
    const groupByArr = metaData?.groupby_drilldowns[groupBy?.groupby_id];
    const rowData_ = createRowData(
      analysisData,
      groupBy,
      groupByArr,
      activeTab
    );
    const updatedData = {
      rowData: rowData_,
      columnDefs: gridData?.columnDefs
    };
    setChartData(copyChartData);
    setGridData(updatedData);
    const filterData = clearAllFilters(applyFilter);
    setApplyFilter(filterData);
    setFilterApplied(false);
    setGridLoading(false);
    // const newURL = deleteFromURL(window.location.href);
    // changeLoading(false, newURL.toString());
    changeLoading(false);
  };

  const changeLoading = (bool, url = null) => {
    if (url) {
      navigate(window.location.pathname + "?" + url);
    }
    setChartLoading(bool);
    setIsPageLoading(bool);
  };

  const handleCostExpGridFilter = async (applyifilters, _gridData) => {
    // const groupByArr = metaData?.groupby_drilldowns[groupBy?.groupby_id];
    // let rowData_ = createRowData(analysisData, groupBy, groupByArr, activeTab);
    const filteredData = await applyFilterToGrid(applyifilters, _gridData);
    setGridData({
      ...gridData,
      rowData: [...filteredData]
    });
    const handleFilterValue = postFilter(applyifilters);
    const chartFilter = getChartFilteredData(
      applyifilters,
      copyChartData?.y,
      "name"
    );
    setChartData({ ...chartData, x: copyChartData?.x, y: chartFilter });
    setApplyFilter(handleFilterValue);
    setFilterApplied(true);
    changeLoading(false);
  };
  const getChartFilteredData = (applyifilters, data, key) => {
    const filteredData = applyifilters?.flatMap((filterItem) =>
      filterItem.value.flatMap((filterValue) =>
        data?.filter((val) => _.get(val, key) === filterValue)
      )
    );

    return _.uniqBy(filteredData, (item) => _.get(item, "name"));
  };

  const createRowData = (data, grpObj, groupByArr, activeTab) => {
    let rowData = [];
    const group = grpObj?.groupby_column;
    const grp = _.groupBy(data?.analysis_data?.data, group);
    let nonNumericalUniqueKeys = [];
    rowData = _.map(_.keys(grp), function (e) {
      const grpByobj = groupByArr?.find((item) => {
        return item?.option_id === e;
      });
      const formattedData = grp[e]?.map((obj) => ({
        ...obj,
        _rawdate: DateTime.fromISO(obj?.date).toFormat("yyyy-MM-dd")
      }));
      const latestDate = _.maxBy(formattedData, "_rawdate");
      return _.reduce(
        grp[e],
        function (r, o) {
          _.forEach(_.keys(o), function (key) {
            if (!isNaN(o[key]) && !key?.toLowerCase().includes("id")) {
              if (key?.toLowerCase() === "cost") {
                r["current_cost"] =
                  (r["current_cost"] || 0) +
                  DateTime.fromFormat(
                    DateTime.fromISO(o?.date).toFormat("yyyy-MM-dd"),
                    "yyyy-MM-dd"
                  ).equals(
                    DateTime.fromFormat(latestDate?._rawdate, "yyyy-MM-dd")
                  )
                    ? o?.cost
                    : 0;
                r["current_date"] = DateTime.fromFormat(
                  DateTime.fromISO(o?.date).toFormat("yyyy-MM-dd"),
                  "yyyy-MM-dd"
                ).equals(
                  DateTime.fromFormat(latestDate?._rawdate, "yyyy-MM-dd")
                )
                  ? latestDate?._rawdate
                  : null;
              }
              if (key?.toLowerCase() === "usage_quantity") {
                r["current_usage_quantity"] =
                  (r["current_usage_quantity"] || 0) +
                  DateTime.fromFormat(
                    DateTime.fromISO(o?.date).toFormat("yyyy-MM-dd"),
                    "yyyy-MM-dd"
                  ).equals(
                    DateTime.fromFormat(latestDate?._rawdate, "yyyy-MM-dd")
                  )
                    ? o?.usage_quantity
                    : 0;
                r["current_date"] = DateTime.fromFormat(
                  DateTime.fromISO(o?.date).toFormat("yyyy-MM-dd"),
                  "yyyy-MM-dd"
                ).equals(
                  DateTime.fromFormat(latestDate?._rawdate, "yyyy-MM-dd")
                )
                  ? latestDate?._rawdate
                  : null;
              }
              r[key] =
                (r[key] || 0) +
                (o["date"] !== data?.metadata?.["t_sub_1_date"] ? +o[key] : 0);
            } else {
              nonNumericalUniqueKeys.push(key);
            }
          });
          return {
            ...r,
            [o["date"]]: activeTab === "1" ? o["cost"] : o["usage_quantity"],
            drilldown_possible: grpByobj?.drilldown_possible,
            drilldown_key: grpByobj?.drilldown_key
          };
        },
        { [group]: e }
      );
    });
    //mapValuesToFilter(data, _.uniq(nonNumericalUniqueKeys));
    rowData?.forEach((item, index) => {
      item["S No"] = index + 1;
    });
    return rowData ? rowData : [];
  };
  const appendGridData = async (
    acc,
    dateRange,
    page_num,
    page_size,
    gridLoading
  ) => {
    try {
      if (acc && dateRange && groupBy && granularity) {
        const currentKey_ =
          currentKey == "costexplorer" ? "overview" : currentKey;
        const overviewData = await getDBXCostExplorerOverView(
          acc,
          dateRange?.value?.start_date,
          dateRange?.value?.end_date,
          groupBy?.groupby_id,
          currentKey_,
          granularity?.value,
          tagVisible ? tag?.value : null,
          page_num,
          page_size
        );
        // setAnalysisData({
        //   ...analysisData,
        //   analysis_data: {
        //     ...(analysisData?.analysis_data || {}),
        //     data: [
        //       ...(analysisData?.analysis_data?.data || []),
        //       ...(overviewData?.analysis_data?.data || [])
        //     ]
        //   }
        // });
        const groupByArr = metaData?.groupby_drilldowns[groupBy?.groupby_id];
        let rowData_ = createRowData(
          overviewData,
          groupBy,
          groupByArr,
          activeTab
        );
        const chartUpdate = getChartData(
          overviewData?.analysis_data?.data,
          granularity,
          groupBy,
          activeTab
        );
        setMemoizeData({ ...memoizeData, [page_num]: rowData_ });
        setCopyChartData({
          ...copyChartData,
          y: [...copyChartData.y, ...chartUpdate.y]
        });
        if (gridfallback) {
          setGridfallback(false);
        }
        if (gridLoading) {
          setGridLoading(false);
        }
        if (isPageLoading) {
          setIsPageLoading(false);
        }
      }
    } catch (er) {
      setGridLoading(false);
    }
    if (gridfallback) {
      setGridfallback(false);
    }
    if (gridLoading) {
      setGridLoading(false);
    }
    if (isPageLoading) {
      setIsPageLoading(false);
    }
  };

  const getOverviewData = async (acc, dateRange, page_num, page_size) => {
    try {
      if (acc && dateRange && groupBy && granularity) {
        const currentKey_ =
          currentKey == "costexplorer" ? "overview" : currentKey;

        const [overviewData, totalsData] = await Promise.all([
          getDBXCostExplorerOverView(
            acc,
            dateRange?.value?.start_date,
            dateRange?.value?.end_date,
            groupBy?.groupby_id,
            currentKey_,
            granularity?.value,
            tagVisible ? tag?.value : null,
            page_num,
            page_size
          ),
          getDBXCostExplorerTotals(
            acc,
            dateRange?.value?.start_date,
            dateRange?.value?.end_date,
            groupBy?.groupby_id,
            currentKey_,
            granularity?.value,
            tagVisible ? tag?.value : null
          )
        ]);
        setPaginationInfo({
          ...paginationInfo,
          total_records: overviewData?.pagination?.total_records,
          total_pages: overviewData?.pagination?.total_pages
        });
        setAnalysisData(overviewData);
        setCostSpent(
          costFormatter(
            roundToSigDigits(totalsData?.totals?.["cost"], 2, false)
          )
        );
        setDbuSpent(
          roundToSigDigits(
            totalsData?.totals?.["usage_quantity"],
            2,
            false
          ).toLocaleString("en-US")
        );
        setMemoizeData({
          ...memoizeData,
          [page_num]: createRowData(
            overviewData,
            groupBy,
            metaData?.groupby_drilldowns[groupBy?.groupby_id],
            activeTab
          )
        });
        const chartUpdate = getChartData(
          overviewData?.analysis_data?.data,
          granularity,
          groupBy,
          activeTab
        );
        setCopyChartData(JSON.parse(JSON.stringify(chartUpdate)));
        setTotals(totalsData);
        if (gridfallback) {
          setGridfallback(false);
        }
        if (gridLoading) {
          setGridLoading(false);
        }
        if (chartLoading) {
          setChartLoading(false);
        }
        if (isPageLoading) {
          setIsPageLoading(false);
        }
      }
    } catch (er) {
      setAnalysisData(null);
      setChartData(null);
      setTotals(null);
      setCopyChartData(null);
      if (!gridfallback) {
        setGridfallback(true);
      }
      if (chartLoading) {
        setChartLoading(false);
      }
      if (gridLoading) {
        setGridLoading(false);
      }
      if (isPageLoading) {
        setIsPageLoading(false);
      }
      navigate({
        pathname: location.pathname,
        search: ""
      });
    }
  };
  const resetPageData = (page_num, newPaginationInfo) => {
    if (memoizeData?.[page_num]) {
      setPaginationInfo(newPaginationInfo);
    } else {
      setGridLoading(true);
      appendGridData(
        acc,
        dateRange,
        page_num,
        paginationInfo?.page_size,
        activeTab,
        true
      );
      setPaginationInfo(newPaginationInfo);
    }
  };
  const handlePageClick = (page) => {
    if (page > -1) {
      const newPageNum = page - 1;
      const start = newPageNum * paginationInfo?.page_size;
      const end =
        start + paginationInfo?.page_size < paginationInfo?.total_records
          ? start + paginationInfo?.page_size
          : paginationInfo?.total_records;
      const newPaginationInfo = {
        ...paginationInfo,
        page_num: newPageNum,
        start,
        end
      };
      if (start < end) {
        resetPageData(newPageNum, newPaginationInfo);
      }
    }
  };

  const handlePreviousPage = () => {
    if (paginationInfo?.page_num >= 1) {
      const newPageNum = paginationInfo?.page_num - 1;
      const start = newPageNum * paginationInfo?.page_size;
      const end =
        start + paginationInfo?.page_size < paginationInfo?.total_records
          ? start + paginationInfo?.page_size
          : paginationInfo?.total_records;
      const newPaginationInfo = {
        ...paginationInfo,
        page_num: newPageNum,
        start,
        end
      };
      resetPageData(newPageNum, newPaginationInfo);
    }
  };
  const handleNextPage = () => {
    if (paginationInfo?.page_num > -1) {
      const newPageNum = paginationInfo?.page_num + 1;
      const start = newPageNum * paginationInfo?.page_size;
      const end =
        start + paginationInfo?.page_size < paginationInfo?.total_records
          ? start + paginationInfo?.page_size
          : paginationInfo?.total_records;
      const newPaginationInfo = {
        ...paginationInfo,
        page_num: newPageNum,
        start,
        end
      };
      if (start < end) {
        resetPageData(newPageNum, newPaginationInfo);
      }
    }
  };

  const getDateRangeAndMetaData = async (acc) => {
    try {
      let currentKey_ = currentKey == "costexplorer" ? "overview" : currentKey;
      const dateRanges = await getDBXCostExplorerDateRanges(acc, currentKey_);
      if (dateRanges) {
        let dateRangesArray = getMonthsArray(
          dateRanges?.values?.start_date,
          dateRanges?.values?.end_date
        )
          .reverse()
          .map((item) => {
            let value = getMonthRange(item, dateRanges?.values?.end_date);
            return {
              label: value?.mtd
                ? `${DateTime.fromISO(value?.startDate).toFormat(
                    "dd"
                  )} - ${DateTime.fromISO(value?.endDate)
                    .minus({ days: 1 })
                    .toFormat("dd LLL yyyy")} (MTD)`
                : `${DateTime.fromISO(item).toFormat("LLL yyyy")}`,
              value: {
                start_date: DateTime.fromISO(value?.startDate).toFormat(
                  "yyyy-MM-dd"
                ),
                end_date: DateTime.fromISO(value?.endDate).toFormat(
                  "yyyy-MM-dd"
                )
              },
              mtd: value?.mtd
            };
          });
        dateRangesArray.push({
          label: "Last 3 Months",
          show_range: true,
          value: {
            start_date: DateTime.fromISO(dateRanges?.values?.end_date)
              .set({
                day: 1
              })
              .minus({ months: 3 })
              .toFormat("yyyy-MM-dd"),
            end_date: dateRanges?.values?.end_date
          },
          mtd: true
        });
        dateRangesArray.push({
          label: "Last 6 Months",
          show_range: true,
          value: {
            start_date: DateTime.fromISO(dateRanges?.values?.end_date)
              .set({
                day: 1
              })
              .minus({ months: 6 })
              .toFormat("yyyy-MM-dd"),
            end_date: dateRanges?.values?.end_date
          },
          mtd: true
        });
        setDateRangeOptions(dateRangesArray);
        if (
          getValuesFromUrl("startDate", navigationState) &&
          getValuesFromUrl("endDate", navigationState) &&
          getValuesFromUrl("granularity", navigationState) &&
          getValuesFromUrl("groupby", navigationState)
        ) {
          noramlizeUrl(granularityOptions, dateRangesArray, currentKey_);
        } else {
          let groupByOptionsArray = [];
          let grpByObj = {};
          const currentPath = location?.pathname;
          const currentPathArr = currentPath?.split("/");
          const copyObj = [...navigationState];
          let metaData = undefined;
          const obj = {};
          let url = new URLSearchParams(location.search || "");
          const defaultGroupById = "sku_name";

          const index = dateRangesArray?.findIndex((date) => {
            return date?.label?.toLowerCase() === "last 6 months";
          });
          if (
            !(
              getValuesFromUrl("startDate", navigationState) &&
              getValuesFromUrl("endDate", navigationState)
            )
          ) {
            if (index >= 0) {
              setDateRange(dateRangesArray[index]);
              url.set("startDate", dateRangesArray[index]?.value?.start_date);
              url.set("endDate", dateRangesArray[index]?.value?.end_date);

              const metadata = await getDBXCostExplorerMetaData(
                currentKey_,
                acc,
                dateRangesArray[index]?.value?.start_date,
                dateRangesArray[index]?.value?.end_date
              );
              if (metadata) {
                setMetaData(metadata);
                metaData = metadata;
                setTagOptions(getValueObj(metadata?.tag_values));
                groupByOptionsArray = metadata?.groupby?.map((item) => {
                  return {
                    ...item,
                    label:
                      item?.groupby_title?.toLowerCase() === "none"
                        ? "Ungrouped"
                        : item?.groupby_title,
                    value: item?.groupby_id
                  };
                });
                setGroupByOptions(groupByOptionsArray);
                grpByObj = groupByOptionsArray?.find((item) => {
                  return item?.groupby_id === defaultGroupById;
                });
                if (!getValuesFromUrl("groupby", navigationState)) {
                  setGroupBy(
                    Object.keys(grpByObj)?.length
                      ? grpByObj
                      : groupByOptionsArray[1]
                  );
                  setTag(
                    Object.keys(grpByObj)?.length
                      ? grpByObj?.groupby_id === "tag"
                        ? getValueObj(metadata?.tag_values)[0]
                        : "null"
                      : "null"
                  );
                  url.set(
                    "tag",
                    Object.keys(grpByObj)?.length
                      ? grpByObj?.groupby_id === "tag"
                        ? getValueObj(metadata?.tag_values)[0]?.value
                        : "null"
                      : "null"
                  );
                  url.set(
                    "groupby",
                    Object.keys(grpByObj)?.length
                      ? grpByObj?.groupby_id
                      : groupByOptionsArray[0]?.groupby_id
                  );
                }
              }
            } else {
              setDateRange(dateRangesArray[0]);
              url.set("startDate", dateRangesArray[0]?.value?.start_date);
              url.set("endDate", dateRangesArray[0]?.value?.end_date);
              const metadata = await getDBXCostExplorerMetaData(
                currentKey_,
                acc,
                dateRangesArray[0]?.value?.start_date,
                dateRangesArray[0]?.value?.end_date
              );
              if (metadata) {
                setMetaData(metadata);
                metaData = metadata;
                setTagOptions(getValueObj(metadata?.tag_values));
                groupByOptionsArray = metadata?.groupby?.map((item) => {
                  return {
                    ...item,
                    label:
                      item?.groupby_title?.toLowerCase() === "none"
                        ? "Ungrouped"
                        : item?.groupby_title,
                    value: item?.groupby_id
                  };
                });
                setGroupByOptions(groupByOptionsArray);
                grpByObj = groupByOptionsArray?.find((item) => {
                  return item?.groupby_id === defaultGroupById;
                });
                if (!getValuesFromUrl("groupby", navigationState)) {
                  setGroupBy(
                    Object.keys(grpByObj)?.length
                      ? grpByObj
                      : groupByOptionsArray[1]
                  );
                  setTag(
                    Object.keys(grpByObj)?.length
                      ? grpByObj?.groupby_id === "tag"
                        ? getValueObj(metadata?.tag_values)[0]
                        : "null"
                      : "null"
                  );
                  url.set(
                    "tag",
                    Object.keys(grpByObj)?.length
                      ? grpByObj?.groupby_id === "tag"
                        ? getValueObj(metadata?.tag_values)[0]?.value
                        : "null"
                      : "null"
                  );
                  url.set(
                    "groupby",
                    Object.keys(grpByObj)?.length
                      ? grpByObj?.groupby_id
                      : groupByOptionsArray[0]?.groupby_id
                  );
                }
              }
            }
          }
          if (!getValuesFromUrl("granularity", navigationState)) {
            setGranularity(granularityOptions[2]);
            url.set("granularity", granularityOptions[2]?.value);
          }

          obj.startDate =
            index >= 0
              ? dateRangesArray[index]?.value?.start_date
              : dateRangesArray[0]?.value?.start_date;
          obj.endDate =
            index >= 0
              ? dateRangesArray[index]?.value?.end_date
              : dateRangesArray[0]?.value?.end_date;
          obj.granularity = granularityOptions[2]?.value;

          copyObj?.push({
            ...obj,
            groupby: Object.keys(grpByObj)?.length
              ? grpByObj?.groupby_id
              : groupByOptionsArray[0]?.groupby_id,
            tag: Object.keys(grpByObj)?.length
              ? grpByObj?.groupby_id === "tag"
                ? getValueObj(metaData?.tag_values)[0]?.value
                : "null"
              : "null",
            path: currentPathArr[currentPathArr?.length - 1].toLowerCase()
          });
          setNavigationState(copyObj);
          navigate({
            pathname: location.pathname,
            search: url.toString()
          });
        }
      }
    } catch (er) {
      setAnalysisData(null);
      setChartData(null);
      setTotals(null);
      setCopyChartData(null);
      setMetaData(null);
      setCostSpent(null);
      setDbuSpent(null);
      setGroupByOptions([]);
      setDateRangeOptions([]);
      setGranularity(null);
      setDateRange(null);
      setGroupBy(null);
      if (!gridfallback) {
        setGridfallback(true);
      }
      if (gridLoading) {
        setGridLoading(false);
      }
      if (chartLoading) {
        setChartLoading(false);
      }
      if (isPageLoading) {
        setIsPageLoading(false);
      }
    }
  };

  const transformChartData = (data) => {
    // Extract xValue
    const xValue = Object.keys(data);
    let counter = 0;
    // Build yValue
    const yValue = _(data)
      .flatMap()
      .groupBy("type")
      .map((items, type) => {
        const dataValues = _.map(
          xValue,
          (date) => _.find(items, { date, type })?.cost || 0
        );
        return {
          name: type,
          data: dataValues?.slice(1), //remove T-1
          color: colors[counter++], // Use modulo to avoid index out of bounds
          sum: _.sum(dataValues)
        };
      })
      .value();

    return { y: yValue.reverse(), x: xValue?.slice(1), activeTab }; // remove T-1 date
  };
  const groupData = (data, granularity, grouping, activeTab) => {
    const groupedData = _.groupBy(data, (item) => {
      const date = DateTime.fromISO(item.date);
      switch (granularity) {
        case "monthly":
          return date.toFormat("yyyy-MM");
        case "weekly":
          return date.startOf("week").toISODate();
        case "daily":
          return item.date.substring(0, 10);
        default:
          return null;
      }
    });

    // Process each group
    _.forEach(groupedData, (items, key) => {
      if (!grouping) {
        groupedData[key] = _(items)
          .map((item) => ({
            date: key,
            type: "All",
            cost: activeTab === "1" ? item?.["cost"] : item?.["usage_quantity"]
          }))
          .sortBy(activeTab === "1" ? "cost" : "usage_quantity")
          .value();
      } else {
        groupedData[key] = _(items)
          .groupBy(grouping)
          .map((groupItems, type) => ({
            date: key,
            type,
            cost: _.sumBy(
              groupItems,
              activeTab === "1" ? "cost" : "usage_quantity"
            )
          }))
          .sortBy(activeTab === "1" ? "cost" : "usage_quantity")
          .value();
      }
    });
    const result = transformChartData(groupedData, activeTab);
    return result;
  };
  const getChartData = (data, granularity, grouping, activeTab) => {
    return groupData(
      data,
      granularity?.value,
      grouping?.groupby_column,
      activeTab
    );
  };

  const getPercentageChange = (params, dateString, granularity) => {
    const validDateEntries = _.pickBy(params?.data, (value, key) =>
      isValidDate(key)
    );
    const keys = _.keys(validDateEntries);
    const index = _.indexOf(keys, dateString);
    if (index === -1) {
      return "";
    }
    if (index === 0) {
      return "";
    }

    const currentValue = validDateEntries[keys[index]];
    const previousDate = DateTime.fromISO(keys[index])
      .minus(
        ...(granularity?.value === "weekly"
          ? [{ days: 7 }]
          : granularity?.value === "daily"
          ? [{ days: 1 }]
          : [{ months: 1 }])
      )
      .toFormat("yyyy-MM-dd'T'HH:mm:ss");
    const previousValue = validDateEntries[previousDate];
    const difference = +currentValue - +previousValue;

    const percentageDifference = +((difference / previousValue) * 100);
    const formattedPercentageDifference = !isNaN(percentageDifference)
      ? (percentageDifference >= 0 ? "+" : "") +
        roundToSigDigits(percentageDifference, 0, false, false)
      : "";

    return formattedPercentageDifference;
  };
  const getHeaderText = (dateString, granularity) => {
    const date = DateTime.fromISO(dateString);
    switch (granularity?.value) {
      case "monthly":
        return date.toFormat("LLL yyyy");
      case "weekly":
        return date.startOf("week").toFormat("dd LLL yyyy");
      case "daily":
        return date.toFormat("dd LLL yyyy");
      default:
        return null;
    }
  };
  useEffect(() => {
    const activeTab = getValuesFromUrl("activeTab", navigationState) || "1";
    if (analysisData && metaData && totals && Object.keys(memoizeData).length) {
      let screenInnerWidth = window.innerWidth;
      let __rawcurrentCost = 0;
      const groupByArr = metaData?.groupby_drilldowns[groupBy?.groupby_id];
      //let validCostExpProps = getDBXCostExpProps();
      let columns = analysisData?.metadata?.columns;
      columns = extractColumns(analysisData?.analysis_data?.data);
      let copygridWidth = Object.values(columns).reduce(
        (sum, prop) => {
          if (typeof prop?.width === "number" && !isNaN(prop.width)) {
            return sum + prop.width;
          } else {
            if (isNaN(prop?.width)) {
              return sum + 100;
            }
          }
          return sum;
        },
        groupBy?.value?.toLowerCase() === "none" ? 370 : 70
      );

      const copyResolution = handleGridResolution(
        copygridWidth,
        screenInnerWidth,
        360
      );
      let columnDefs = columns?.map((item, index) => {
        const colObj = analysisData?.metadata?.columns?.find((value) => {
          return value?.id === item;
        });
        return {
          headerName: isValidDate(item)
            ? getHeaderText(item, granularity)
            : colObj?.title,
          field: item,

          headerClass:
            !colObj?.metric_column && !isValidDate(item)
              ? ""
              : "text-align-right",
          sortable: true,
          filter: false,
          unSortIcon: true,
          cellClass: () => {
            return !colObj?.metric_column && !isValidDate(item)
              ? "grid-cell-left-aligned cell-border-right"
              : "ag-right-aligned-cell cell-border-right";
          },
          useValueFormatterForExport: true,
          ...(groupBy?.value?.toLowerCase() !== "none" &&
            groupBy?.value?.toLowerCase() === item && {
              width: 300,
              suppressSizeToFit: true
            }),
          resizable: index === columns?.length - 1 ? false : true,
          pinned:
            index === columns?.length - 1 && !copyResolution
              ? "right"
              : item === groupBy?.value?.toLowerCase()
              ? "left"
              : false,
          hide:
            (activeTab === "2" && item === "cost") ||
            item === analysisData?.metadata?.["t_sub_1_date"] ||
            (activeTab === "1" && item === "usage_quantity") ||
            item === analysisData?.metadata?.["t_sub_1_date"],
          // hide: (activeTab === "2" && item === "usage_quantity") || false,
          cellRenderer: (params) => {
            const validDate = isValidDate(item);
            let percChange = "";
            if (activeTab === "1") {
              if (colObj?.metric_column || validDate) {
                if (validDate) {
                  percChange = getPercentageChange(
                    params,
                    params?.column?.colId,
                    granularity
                  );
                } else {
                  percChange = "";
                }
                return (
                  <span>
                    {costFormatter(roundToSigDigits(params?.value, 2, false))}
                    <span className="percentage-grey">
                      {validDate && percChange ? ` (${percChange}%)` : ""}
                    </span>
                  </span>
                );
              } else {
                return CustomTooltip(params?.value, params?.value, 10);
              }
            } else {
              if (colObj?.metric_column || validDate) {
                if (validDate) {
                  percChange = getPercentageChange(
                    params,
                    params?.column?.colId,
                    granularity
                  );
                } else {
                  percChange = "";
                }
                return (
                  <span>
                    {roundToSigDigits(
                      params?.value,
                      2,
                      false,
                      false
                    ).toLocaleString("en-US")}
                    <span className="percentage-grey">
                      {validDate && percChange ? ` (${percChange}%)` : ""}
                    </span>
                  </span>
                );
              } else {
                return CustomTooltip(params?.value, params?.value, 10);
              }
            }
          }
        };
      });

      if (columnDefs) {
        if (groupBy?.value?.toLowerCase() === "none") {
          columnDefs?.unshift({
            headerName: "Cost Explorer",
            width: 300,
            suppressSizeToFit: true,
            pinned: "left",
            cellClass: "grid-cell-left-aligned  cell-border-right",
            cellRenderer: () => <span>All</span>
          });
        }
        columnDefs?.unshift({
          headerName: "S No.",
          field: "S No",
          width: 70,
          pinned: "left",
          suppressSizeToFit: true,
          cellClass: "ag-right-aligned-cell cell-border-right",
          cellRenderer: (params) => {
            return params?.value;
          }
        });
        const __rowData = createRowData(
          analysisData,
          groupBy,
          groupByArr,
          activeTab
        );
        if (__rowData && __rowData?.length) {
          const maxDate = getMaxDate(__rowData);
          __rawcurrentCost = roundToSigDigits(
            _.sumBy(__rowData, `${maxDate}`),
            2,
            false
          );
          const o = {
            ...currentCostSpent,
            value:
              activeTab === "1"
                ? costFormatter(__rawcurrentCost)
                : __rawcurrentCost.toLocaleString("en-US"),
            rawValue: __rawcurrentCost,
            date: maxDate
          };
          setCurrentCostSpent(o);
        }
        setGridData({
          rowData: __rowData,
          columnDefs
        });
      }
      const chartUpdate = getChartData(
        analysisData?.analysis_data?.data,
        granularity,
        groupBy,
        activeTab
      );
      setChartData(JSON.parse(JSON.stringify(chartUpdate)));
      // setCopyChartData(JSON.parse(JSON.stringify(chartUpdate)));
    } else {
      setGridData({
        rowData: [],
        columnDefs: []
      });
      setChartData(null);
      setMemoizeData(null);
      setCopyChartData(null);
    }
  }, [
    groupBy,
    granularity,
    totals,
    dateRange,
    analysisData,
    activeTab,
    location.pathname,
    //location.search,
    memoizeData
  ]);
  useEffect(() => {
    if (
      dateRange &&
      dateRangeOptions &&
      dateRangeOptions?.length > 0 &&
      metaData
    ) {
      getOverviewData(
        acc,
        dateRange,
        paginationInfo?.page_num,
        paginationInfo?.page_size
      );
    }
  }, [dateRange, granularity, groupBy, dateRangeOptions, tag]);
  const noramlizeUrl = async (
    granularityOptions,
    dateRangeOptions,
    currentKey_
  ) => {
    let groupByOptionsArray = [];
    let url = new URLSearchParams(location.search || "");
    const copyObj = [...navigationState];
    const currentPath = location?.pathname;
    const currentPathArr = currentPath?.split("/");
    const defaultGroupById = "sku_name";
    const obj = {};
    if (
      getValuesFromUrl("groupby", navigationState) ||
      getValuesFromUrl("granularity", navigationState) ||
      getValuesFromUrl("startDate", navigationState) ||
      getValuesFromUrl("endDate", navigationState)
    ) {
      let granularityObj = granularityOptions.find((item) => {
        return item?.value === getValuesFromUrl("granularity", navigationState);
      });

      if (granularityObj && getValuesFromUrl("granularity", navigationState)) {
        setGranularity(granularityObj);
        obj.granularity = granularityObj?.value;
        url.set("granularity", granularityObj?.value);
      } else {
        setGranularity(granularityOptions[2]);
        obj.granularity = granularityOptions[2]?.value;
        url.set("granularity", granularityOptions[2]?.value);
      }

      let startDate = getValuesFromUrl("startDate", navigationState);
      let endDate = getValuesFromUrl("endDate", navigationState);
      const index = dateRangeOptions?.findIndex((date) => {
        return date?.label?.toLowerCase() === "last 6 months";
      });
      if (startDate && endDate) {
        let dateRangeObj = dateRangeOptions.find((item) => {
          return (
            item?.value?.start_date === startDate &&
            item?.value?.end_date === endDate
          );
        });
        if (dateRangeObj) {
          setDateRange(dateRangeObj);
          obj.startDate = dateRangeObj?.value?.start_date;
          obj.endDate = dateRangeObj?.value?.end_date;
          url.set("startDate", dateRangeObj?.value?.start_date);
          url.set("endDate", dateRangeObj?.value?.end_date);

          const metadata = await getDBXCostExplorerMetaData(
            currentKey_,
            acc,
            dateRangeObj?.value?.start_date,
            dateRangeObj?.value?.end_date
          );
          if (metadata) {
            setMetaData(metadata);
            const tagoptions = getValueObj(metadata?.tag_values);
            setTagOptions(tagoptions);
            const tagValue = getValuesFromUrl("tag", navigationState);
            const tagObj = tagoptions?.find((item) => {
              return item?.value === tagValue;
            });
            groupByOptionsArray = metadata?.groupby?.map((item) => {
              return {
                ...item,
                label:
                  item?.groupby_title?.toLowerCase() === "none"
                    ? "Ungrouped"
                    : item?.groupby_title,
                value: item?.groupby_id
              };
            });
            setGroupByOptions(groupByOptionsArray);
            const groupByObj = groupByOptionsArray?.find((item) => {
              return (
                item?.groupby_id ===
                getValuesFromUrl("groupby", navigationState)
              );
            });
            if (groupByObj && getValuesFromUrl("groupby", navigationState)) {
              setTag(
                groupByObj?.groupby_id === "tag"
                  ? tagObj
                    ? tagObj
                    : tagoptions[0]
                  : "null"
              );
              setGroupBy(groupByObj);
              obj.groupby = groupByObj?.groupby_id;
              obj.tag =
                groupByObj?.groupby_id === "tag"
                  ? tagObj
                    ? tagObj?.value
                    : tagoptions[0]?.value
                  : "null";
              url.set(
                "tag",
                groupByObj?.groupby_id === "tag"
                  ? tagObj
                    ? tagObj?.value
                    : tagoptions[0]?.value
                  : "null"
              );
              url.set("groupby", groupByObj?.groupby_id);

              setFilterColumns([
                {
                  label: groupByObj?.groupby_title,
                  value: groupByObj?.groupby_id
                }
              ]);
              setApplyFilter([
                {
                  column: {
                    label: groupByObj?.groupby_title,
                    value: groupByObj?.groupby_id
                  },
                  condition: filterTypes[0],
                  value: [],
                  dropDown: false,
                  previuosValue: [],
                  previousMenuList: []
                }
              ]);
            } else {
              const grpByObj = groupByOptionsArray?.find((item) => {
                return item?.groupby_id === defaultGroupById;
              });
              setGroupBy(
                Object.keys(grpByObj)?.length
                  ? grpByObj
                  : groupByOptionsArray[1]
              );
              obj.groupby = Object.keys(grpByObj)?.length
                ? grpByObj?.groupby_id
                : groupByOptionsArray[0]?.groupby_id;
              obj.tag =
                grpByObj?.groupby_id === "tag"
                  ? tagObj
                    ? tagObj?.value
                    : tagoptions[0]?.value
                  : "null";
              setTag(
                grpByObj?.groupby_id === "tag"
                  ? tagObj
                    ? tagObj
                    : tagoptions[0]
                  : "null"
              );
              url.set(
                "tag",
                grpByObj?.groupby_id === "tag"
                  ? tagObj
                    ? tagObj?.value
                    : tagoptions[0]?.value
                  : "null"
              );
              url.set(
                "groupby",
                Object.keys(grpByObj)?.length
                  ? grpByObj?.groupby_id
                  : groupByOptionsArray[0]?.groupby_id
              );
              setFilterColumns([
                {
                  label: groupByObj?.groupby_title,
                  value: groupByObj?.groupby_id
                }
              ]);
              setApplyFilter([
                {
                  column: {
                    label: groupByObj?.groupby_title,
                    value: groupByObj?.groupby_id
                  },
                  condition: filterTypes[0],
                  value: [],
                  dropDown: false,
                  previuosValue: [],
                  previousMenuList: []
                }
              ]);
            }
          }
        } else {
          setDateRange(
            index >= 0 ? dateRangeOptions[index] : dateRangeOptions[0]
          );

          const metadata = await getDBXCostExplorerMetaData(
            currentKey_,
            acc,
            index >= 0
              ? dateRangeOptions[index]?.value?.start_date
              : dateRangeOptions[0]?.value?.start_date,
            index >= 0
              ? dateRangeOptions[index]?.value?.end_date
              : dateRangeOptions[0]?.value?.end_date
          );
          if (metadata) {
            setMetaData(metadata);
            const tagoptions = getValueObj(metadata?.tag_values);
            setTagOptions(tagoptions);
            const tagValue = getValuesFromUrl("tag", navigationState);
            const tagObj = tagoptions?.find((item) => {
              return item?.value === tagValue;
            });
            groupByOptionsArray = metadata?.groupby?.map((item) => {
              return {
                ...item,
                label:
                  item?.groupby_title?.toLowerCase() === "none"
                    ? "Ungrouped"
                    : item?.groupby_title,
                value: item?.groupby_id
              };
            });
            setGroupByOptions(groupByOptionsArray);
            const groupByObj = groupByOptionsArray?.find((item) => {
              return (
                item?.groupby_id ===
                getValuesFromUrl("groupby", navigationState)
              );
            });
            if (groupByObj && getValuesFromUrl("groupby", navigationState)) {
              setGroupBy(groupByObj);
              obj.groupby = groupByObj?.groupby_id;
              obj.tag =
                groupByObj?.groupby_id === "tag"
                  ? tagObj
                    ? tagObj?.value
                    : tagoptions[0]?.value
                  : "null";
              setTag(
                groupByObj?.groupby_id === "tag"
                  ? tagObj
                    ? tagObj
                    : tagoptions[0]
                  : "null"
              );
              url.set(
                "tag",
                groupByObj?.groupby_id === "tag"
                  ? tagObj
                    ? tagObj?.value
                    : tagoptions[0]?.value
                  : "null"
              );
              url.set("groupby", groupByObj?.groupby_id);
            } else {
              const grpByObj = groupByOptionsArray?.find((item) => {
                return item?.groupby_id === defaultGroupById;
              });
              setGroupBy(
                Object.keys(grpByObj)?.length
                  ? grpByObj
                  : groupByOptionsArray[1]
              );
              obj.groupby = Object.keys(grpByObj)?.length
                ? grpByObj?.groupby_id
                : groupByOptionsArray[0]?.groupby_id;
              obj.tag =
                grpByObj?.groupby_id === "tag"
                  ? tagObj
                    ? tagObj?.value
                    : tagoptions[0]?.value
                  : "null";
              setTag(
                grpByObj?.groupby_id === "tag"
                  ? tagObj
                    ? tagObj
                    : tagoptions[0]
                  : "null"
              );
              url.set(
                "tag",
                grpByObj?.groupby_id === "tag"
                  ? tagObj
                    ? tagObj?.value
                    : tagoptions[0]?.value
                  : "null"
              );
              url.set(
                "groupby",
                Object.keys(grpByObj)?.length
                  ? grpByObj?.groupby_id
                  : groupByOptionsArray[0]?.groupby_id
              );
            }
          }

          obj.startDate =
            index >= 0
              ? dateRangeOptions[index]?.value?.start_date
              : dateRangeOptions[0]?.value?.start_date;
          obj.endDate =
            index >= 0
              ? dateRangeOptions[index]?.value?.end_date
              : dateRangeOptions[0]?.value?.end_date;
          url.set(
            "startDate",
            index >= 0
              ? dateRangeOptions[index]?.value?.start_date
              : dateRangeOptions[0]?.value?.start_date
          );
          url.set(
            "endDate",
            index >= 0
              ? dateRangeOptions[index]?.value?.end_date
              : dateRangeOptions[0]?.value?.end_date
          );
        }
      } else {
        setDateRange(
          index >= 0 ? dateRangeOptions[index] : dateRangeOptions[0]
        );
        const metadata = await getDBXCostExplorerMetaData(
          currentKey_,
          acc,
          index >= 0
            ? dateRangeOptions[index]?.value?.start_date
            : dateRangeOptions[0]?.value?.start_date,
          index >= 0
            ? dateRangeOptions[index]?.value?.end_date
            : dateRangeOptions[0]?.value?.end_date
        );
        if (metadata) {
          setMetaData(metadata);
          const tagoptions = getValueObj(metadata?.tag_values);
          setTagOptions(tagoptions);
          const tagValue = getValuesFromUrl("tag", navigationState);
          const tagObj = tagoptions?.find((item) => {
            return item?.value === tagValue;
          });
          groupByOptionsArray = metadata?.groupby?.map((item) => {
            return {
              ...item,
              label:
                item?.groupby_title?.toLowerCase() === "none"
                  ? "Ungrouped"
                  : item?.groupby_title,
              value: item?.groupby_id
            };
          });
          setGroupByOptions(groupByOptionsArray);
          const groupByObj = groupByOptionsArray?.find((item) => {
            return (
              item?.groupby_id === getValuesFromUrl("groupby", navigationState)
            );
          });
          if (groupByObj && getValuesFromUrl("groupby", navigationState)) {
            setGroupBy(groupByObj);
            obj.groupby = groupByObj?.groupby_id;
            obj.tag =
              groupByObj?.groupby_id === "tag"
                ? tagObj
                  ? tagObj?.value
                  : tagoptions[0]?.value
                : "null";
            setTag(
              groupByObj?.groupby_id === "tag"
                ? tagObj
                  ? tagObj
                  : tagoptions[0]
                : "null"
            );
            url.set(
              "tag",
              groupByObj?.groupby_id === "tag"
                ? tagObj
                  ? tagObj?.value
                  : tagoptions[0]?.value
                : "null"
            );
            url.set("groupby", groupByObj?.groupby_id);
          } else {
            const grpByObj = groupByOptionsArray?.find((item) => {
              return item?.groupby_id === defaultGroupById;
            });
            setGroupBy(
              Object.keys(grpByObj)?.length ? grpByObj : groupByOptionsArray[1]
            );
            obj.groupby = Object.keys(grpByObj)?.length
              ? grpByObj?.groupby_id
              : groupByOptionsArray[0]?.groupby_id;
            obj.tag =
              grpByObj?.groupby_id === "tag"
                ? tagObj
                  ? tagObj?.value
                  : tagoptions[0]?.value
                : "null";
            setTag(
              grpByObj?.groupby_id === "tag"
                ? tagObj
                  ? tagObj
                  : tagoptions[0]
                : "null"
            );
            url.set(
              "tag",
              grpByObj?.groupby_id === "tag"
                ? tagObj
                  ? tagObj?.value
                  : tagoptions[0]?.value
                : "null"
            );
            url.set(
              "groupby",
              Object.keys(grpByObj)?.length
                ? grpByObj?.groupby_id
                : groupByOptionsArray[0]?.groupby_id
            );
          }
        }
        obj.startDate =
          index >= 0
            ? dateRangeOptions[index]?.value?.start_date
            : dateRangeOptions[0]?.value?.start_date;
        obj.endDate =
          index >= 0
            ? dateRangeOptions[index]?.value?.end_date
            : dateRangeOptions[0]?.value?.end_date;
        url.set(
          "startDate",
          index >= 0
            ? dateRangeOptions[index]?.value?.start_date
            : dateRangeOptions[0]?.value?.start_date
        );
        url.set(
          "endDate",
          index >= 0
            ? dateRangeOptions[index]?.value?.end_date
            : dateRangeOptions[0]?.value?.end_date
        );
      }
      obj.path = currentPathArr[currentPathArr?.length - 1]?.toLowerCase();
      copyObj[copyObj?.length - 1] = obj;

      setNavigationState(copyObj);
      navigate({
        pathname: location.pathname,
        search: url.toString()
      });
    } else {
      if (granularity) {
        url.set("granularity", granularity?.value);
        navigate({
          pathname: location.pathname,
          search: url.toString()
        });
      }
      if (groupBy) {
        url.set("groupby", groupBy?.groupby_id);
        navigate({
          pathname: location.pathname,
          search: url.toString()
        });
      }
      if (dateRange) {
        url.set("startDate", dateRange?.value?.start_date);
        url.set("endDate", dateRange?.value?.end_date);
        navigate({
          pathname: location.pathname,
          search: url.toString()
        });
      }
    }
  };
  const getCurrentCostText = (date, granularity) => {
    if (date) {
      const label = granularity?.label?.toLowerCase();
      if (label === "daily")
        return DateTime.fromISO(date).toFormat("LLL dd yyyy");
      else if (label === "monthly")
        return `${DateTime.fromISO(date)?.toFormat("LLL yyyy")} MTD`;
      else if (label === "weekly") {
        return `Week of ${DateTime.fromISO(date)
          ?.startOf("week")
          .toFormat("LLL dd yyyy")}`;
      }
    }
    return "";
  };
  useEffect(() => {
    setIsPageLoading(true);
    setGridLoading(true);
    setChartLoading(true);
    setDateRangeOptions([]);
    setDateRange(null);
    setTotals(null);
    setMemoizeData(null);
    setCostSpent(null);
    setDbuSpent(null);
    setCurrentCostSpent(null);
    setMetaData(null);
    setTagOptions([]);
    setTag(null);
    (async () => {
      await getDateRangeAndMetaData(acc);
    })();
  }, [acc, location.pathname]);

  const setAndNavigateParams = (arr) => {
    let url = new URLSearchParams(location.search || "");
    arr.forEach((item) => {
      url.set(item.key, item.value);
    });
    navigate({
      pathname: location.pathname,
      search: url.toString()
    });
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const handlePopover = (e) => {
    if (!hasTrialFallback && groupBy?.groupby_column) {
      e?.stopPropagation();
      setShowFilters(!showFilters);
    }
  };
  DropdownIndicator.propTypes = {
    isDisabled: PropTypes.bool
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
    })
  };
  const handleExport = () => {
    if (!hasTrialFallback) {
      setDownloadCSV(true);
      setTimeout(() => {
        setDownloadCSV(false);
      }, 3000);
    }
  };
  const getColumnDefs = (gridData) => {
    return gridData?.columnDefs?.length ? gridData?.columnDefs : [];
  };
  const getOrderedRowData = (
    rowData,
    paginationInfo,
    filterApplied,
    memoizeData
  ) => {
    const orderedData = _.orderBy(
      filterApplied ? rowData : memoizeData?.[paginationInfo?.page_num],
      activeTab === "1" ? ["cost"] : ["usage_quantity"],
      ["desc"]
    );
    let count = paginationInfo?.start;
    const indexedData = orderedData?.map((item) => {
      return {
        ...item,
        "S No": ++count
      };
    });
    return indexedData;
  };
  const getRowData = (
    gridData,
    costSpent,
    paginationInfo,
    filterApplied,
    memoizeData
  ) => {
    return gridData?.rowData?.length && costSpent?.toString() !== "$0"
      ? getOrderedRowData(
          gridData?.rowData,
          paginationInfo,
          filterApplied,
          memoizeData
        )
      : [];
  };
  const getGridFallback = (costSpent, gridfallback) => {
    return costSpent?.toString() !== "$0" ? gridfallback : true;
  };
  const getExportColumns = (gridData) => {
    return gridData?.columnDefs?.length
      ? gridData?.columnDefs
          ?.filter((item) => {
            return (
              item?.field?.toLowerCase() !== "action" &&
              item?.field?.toLowerCase() !== "date" &&
              !item?.hide
            );
          })
          .map((item) => item?.field)
      : [];
  };
  const getGridStyle = (
    gridData,
    rowHeight,
    innerHeight,
    minHeight,
    memoizeData,
    filterApplied,
    paginationInfo
  ) => {
    return {
      height: getGridHeight(
        filterApplied
          ? gridData?.rowData
          : memoizeData?.[paginationInfo?.page_num],
        innerHeight,
        rowHeight,
        minHeight
      ),
      avoidAutoHeightOverflow: getAutoheightOverflow(gridData?.rowData)
    };
  };
  const createFilterGridData = (memoizeData) => {
    let result = [];
    for (let key in memoizeData) {
      result.push(...memoizeData[key]);
    }
    return result;
  };
  return (
    <>
      {expandModal && (
        <>
          <ExpandComponent
            columnDefs={getColumnDefs(gridData)}
            rowData={getRowData(
              gridData,
              costSpent,
              paginationInfo,
              filterApplied,
              memoizeData
            )}
            gridFallback={getGridFallback(costSpent, gridfallback)}
            suppressColumnsSize={true}
            paginationInfo={
              !filterApplied &&
              paginationInfo?.total_records > 50 &&
              paginationInfo
            }
            handlePageClick={handlePageClick}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            exportColumns={getExportColumns(gridData)}
            handleExpand={handleExpand}
            downloadCSV={downloadCSV}
            gridStyle={getGridStyle(
              gridData,
              40,
              window?.innerHeight,
              180,
              memoizeData,
              filterApplied,
              paginationInfo
            )}
            gridLoading={gridLoading}
            customRowHeight={40}
            noRowsText={"No data Found"}
            tableName={"Cost Explorer"}
            hasTrialFallback={hasTrialFallback}
          />
        </>
      )}
      <div className="ce-main-container">
        {isPageLoading ? (
          <div className="load">
            <div className="preload"></div>
          </div>
        ) : (
          <>
            <div className="header-container">
              <span>Cost Explorer</span>
            </div>
            <div className="ce-page-container">
              <div className="filter-container">
                <div className="filter-row">
                  <div className="filter-section">
                    <div className="select-container">
                      <Button
                        onClick={handlePopover}
                        disabled={
                          hasTrialFallback ||
                          !groupBy?.groupby_column ||
                          filterDisabled
                        }
                        className={
                          showFilters
                            ? "qg-filter-button-active "
                            : filterApplied
                            ? "qg-filter-active"
                            : "qg-filter-button"
                        }>
                        <img src={FilterIcon} alt="" />
                        <span>Filter</span>
                      </Button>

                      {showFilters && !hasTrialFallback && !filterDisabled && (
                        <CustomFilter
                          column={filterColumns}
                          gridData={createFilterGridData(memoizeData)}
                          applyFilter={applyFilter}
                          setApplyFilter={setApplyFilter}
                          close={handlePopover}
                          handleFilter={(filterValue, gridData) => {
                            changeLoading(true);
                            handleCostExpGridFilter(filterValue, gridData);
                          }}
                          clearFilter={() => {
                            changeLoading(true);
                            clearGridFilter();
                          }}
                          isMenuOpen={showFilters}
                        />
                      )}
                    </div>
                  </div>
                  <div className="select-container-groupby">
                    <Select
                      value={(() => {
                        return {
                          ...groupBy,
                          label: `Group By • ${
                            groupBy?.groupby_title == "None" || groupBy == null
                              ? "Ungrouped"
                              : groupBy?.groupby_title
                          }`
                        };
                      })()}
                      isSearchable={false}
                      classNamePrefix="selectcategory"
                      placeholder="select"
                      components={{ group: groupSvg, DropdownIndicator }}
                      styles={customStyles}
                      options={groupByOptions}
                      onChange={(item) => {
                        setPaginationInfo({
                          ...paginationInfo,
                          page_num: 0,
                          start: 0,
                          end: 50
                        });
                        setGridLoading(true);
                        setChartLoading(true);
                        setTotals(null);
                        setMemoizeData(null);
                        setAnalysisData(null);
                        setGridData({
                          rowData: [],
                          columnDefs: []
                        });
                        setFilterColumns([
                          {
                            label: item?.groupby_title,
                            value: item?.groupby_id
                          }
                        ]);
                        setFilterApplied(false);
                        setShowFilters(false);
                        setApplyFilter([
                          {
                            column: {
                              label: item?.groupby_title,
                              value: item?.groupby_id
                            },
                            condition: filterTypes?.[0],
                            value: [],
                            dropDown: false,
                            previuosValue: [],
                            previousMenuList: []
                          }
                        ]);
                        setFilterDisabled(false);
                        if (item?.value?.toString() === "tag") {
                          setTagVisible(true);

                          const objArr = [...navigationState];
                          const obj = {
                            ...navigationState[navigationState?.length - 1]
                          };
                          obj.groupby = item?.value;
                          obj.tag = tagOptions?.[0]?.value;
                          objArr[objArr?.length - 1] = obj;
                          setNavigationState(objArr);
                          setGroupBy(item);
                          setTag(tagOptions?.[0]);
                          setAndNavigateParams([
                            {
                              key: "groupby",
                              value: item?.groupby_id
                            },
                            { key: "tag", value: tagOptions?.[0]?.value },
                            { key: "filter", value: "false" }
                          ]);
                        } else {
                          const objArr = [...navigationState];
                          const obj = {
                            ...navigationState[navigationState?.length - 1]
                          };
                          obj.groupby = item?.value;
                          setTagVisible(false);
                          setTag(null);
                          obj.tag = "null";
                          objArr[objArr?.length - 1] = obj;
                          setNavigationState(objArr);
                          setGroupBy(item);
                          setAndNavigateParams([
                            {
                              key: "groupby",
                              value: item?.groupby_id
                            },
                            { key: "tag", value: "null" },
                            { key: "filter", value: "false" }
                          ]);
                        }
                      }}
                    />
                  </div>
                  {tagVisible ? (
                    <div className="select-container-groupby">
                      <Select
                        value={tag}
                        isSearchable={false}
                        classNamePrefix="selectcategory"
                        placeholder="select"
                        components={{ group: groupSvg, DropdownIndicator }}
                        styles={customStyles}
                        options={tagOptions}
                        onChange={(item) => {
                          setPaginationInfo({
                            ...paginationInfo,
                            page_num: 0,
                            start: 0,
                            end: 50
                          });
                          setTotals(null);
                          setMemoizeData(null);
                          setAnalysisData(null);
                          setGridData({
                            rowData: [],
                            columnDefs: []
                          });
                          setFilterDisabled(false);
                          if (
                            item?.value?.toString() !== tag?.value?.toString()
                          ) {
                            setChartLoading(true);
                            setGridLoading(true);
                          }
                          setTag(item);
                          const objArr = [...navigationState];
                          const obj = {
                            ...navigationState[navigationState?.length - 1]
                          };
                          obj.tag = item?.value;
                          objArr[objArr?.length - 1] = obj;
                          setNavigationState(objArr);
                          setAndNavigateParams([
                            {
                              key: "tag",
                              value: item?.value
                            }
                          ]);
                        }}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="date-range-container">
                  <div className="select-container">
                    <div>
                      <Select
                        styles={customStyles}
                        options={dateRangeOptions}
                        value={dateRange}
                        classNamePrefix="selectcategory"
                        placeholder="Select Date"
                        isSearchable={false}
                        className="date-range-select"
                        components={{ DropdownIndicator }}
                        onChange={(item) => {
                          setPaginationInfo({
                            ...paginationInfo,
                            page_num: 0,
                            start: 0,
                            end: 50
                          });
                          setFilterDisabled(false);
                          if (
                            item?.label?.toString() !==
                            dateRange?.label?.toString()
                          ) {
                            setChartLoading(true);
                            setGridLoading(true);
                          }
                          setTotals(null);
                          setMemoizeData(null);
                          setAnalysisData(null);
                          setGridData({
                            rowData: [],
                            columnDefs: []
                          });
                          const objArr = [...navigationState];
                          objArr?.forEach((nav) => {
                            nav.startDate = item?.value?.start_date;
                            nav.endDate = item?.value?.end_date;
                          });
                          setNavigationState(objArr);
                          setDateRange(item);
                          let url = new URLSearchParams(location.search || "");
                          url.set("startDate", item?.value?.start_date);
                          url.set("endDate", item?.value?.end_date);
                          navigate({
                            pathname: location.pathname,
                            search: url.toString()
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="month-container">
                    <Select
                      styles={customStyles}
                      classNamePrefix="selectcategory"
                      placeholder="Select"
                      className="granularity-select"
                      options={granularityOptions}
                      isSearchable={false}
                      components={{ DropdownIndicator }}
                      onChange={(item) => {
                        setPaginationInfo({
                          ...paginationInfo,
                          page_num: 0,
                          start: 0,
                          end: 50
                        });
                        setFilterDisabled(false);
                        if (
                          item?.value?.toString() !==
                          granularity?.value?.toString()
                        ) {
                          setChartLoading(true);
                          setGridLoading(true);
                        }
                        setTotals(null);
                        setMemoizeData(null);
                        setAnalysisData(null);
                        setGridData({
                          rowData: [],
                          columnDefs: []
                        });
                        const objArr = [...navigationState];
                        objArr?.forEach((nav) => {
                          nav.granularity = item?.value;
                        });
                        setNavigationState(objArr);
                        setGranularity(item);
                        setAndNavigateParams([
                          {
                            key: "granularity",
                            value: item?.value
                          }
                        ]);
                      }}
                      value={granularity}
                    />
                  </div>
                  <div className="select-container export-container">
                    <Button
                      onClick={() => {
                        // chart to image
                        const chart = chartRef?.current?.chart;
                        chart?.exportChart({ type: "image/jpeg" });
                      }}
                      className={"qg-filter-button ce-filter-button"}>
                      <img src={jpeg_export} alt="" />
                      <span>Export Chart</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="overall-value-container">
                <div>
                  <p className="overall-value">{costSpent ? costSpent : "⸻"}</p>
                  <p className="value-title">{`Accrued Cost (${
                    dateRange
                      ? dateRange?.show_range
                        ? `${DateTime.fromISO(
                            dateRange?.value?.start_date?.trim()
                          ).toFormat("LLL yyyy")} - ${DateTime.fromISO(
                            dateRange?.value?.end_date?.trim()
                          ).toFormat("LLL yyyy")}`
                        : dateRange?.label?.trim().replace("(MTD)", "MTD")
                      : ""
                  })`}</p>
                </div>
                <div>
                  <p className="overall-value">{dbuSpent ? dbuSpent : "⸻"}</p>
                  <p className="value-title">{`Accrued DBUs (${
                    dateRange
                      ? dateRange?.show_range
                        ? `${DateTime.fromISO(
                            dateRange?.value?.start_date?.trim()
                          ).toFormat("LLL yyyy")} - ${DateTime.fromISO(
                            dateRange?.value?.end_date?.trim()
                          ).toFormat("LLL yyyy")}`
                        : dateRange?.label?.trim().replace("(MTD)", "MTD")
                      : ""
                  })`}</p>
                </div>
                {currentCostSpent &&
                currentCostSpent?.rawValue &&
                currentCostSpent?.value ? (
                  <div>
                    <p className="overall-value">{currentCostSpent?.value}</p>
                    <p className="value-title">{`Current ${
                      activeTab === "1" ? "Cost" : "DBUs"
                    } (${getCurrentCostText(
                      currentCostSpent?.date,
                      granularity
                    )})`}</p>
                  </div>
                ) : null}
                {tabView ? (
                  <div className="tabs__container mar-left-auto">{tabView}</div>
                ) : null}
              </div>

              <div className="graph-container">
                {!chartLoading ? (
                  <>
                    {analysisData &&
                    chartData &&
                    costSpent &&
                    costSpent.toString() !== "$0" ? (
                      <GraphContainer
                        chartRef={chartRef}
                        chartData={chartData ? chartData : {}}
                        chartTitle={`${
                          currentKey.slice(0, 1).toUpperCase() +
                          currentKey.slice(1)
                        } by ${groupBy?.label} (${dateRange?.label})`}
                        granularity={granularity}
                        filterApplied={filterApplied}
                        totals={totals}
                      />
                    ) : (
                      <div className="chart-placeholder">No Data Found</div>
                    )}
                  </>
                ) : (
                  <div className="load">
                    <div className="preload"></div>
                  </div>
                )}
              </div>

              <div className="row-container justify-flex-end">
                <div className="row-end">
                  <Button
                    onClick={() => {
                      handleExpand(true);
                    }}
                    className={
                      "qg-filter-button expand__button align-self-end"
                    }>
                    <img src={expand} alt="" />
                    <span>Expand</span>
                  </Button>
                  <div className="select-container export-container">
                    <Button
                      onClick={handleExport}
                      className={"qg-filter-button"}>
                      <img src={ExportCSV} alt="" />
                      <span>Export</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="grid-container">
                <GridComponent
                  columnDefs={getColumnDefs(gridData)}
                  rowData={getRowData(
                    gridData,
                    costSpent,
                    paginationInfo,
                    filterApplied,
                    memoizeData
                  )}
                  gridFallback={getGridFallback(costSpent, gridfallback)}
                  suppressColumnsSize={true}
                  noRowsText={"No Data Found"}
                  tableName={"Cost Explorer"}
                  exportColumns={getExportColumns(gridData)}
                  downloadCSV={downloadCSV}
                  paginationInfo={
                    !filterApplied &&
                    paginationInfo?.total_records > 50 &&
                    paginationInfo
                  }
                  handlePreviousPage={handlePreviousPage}
                  handleNextPage={handleNextPage}
                  gridStyle={getGridStyle(
                    gridData,
                    40,
                    window?.innerHeight,
                    180,
                    memoizeData,
                    filterApplied,
                    paginationInfo
                  )}
                  handlePageClick={handlePageClick}
                  gridLoading={gridLoading}
                  customRowHeight={40}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
DBXCostExplorer.propTypes = {
  acc: PropTypes.object
};

export default DBXCostExplorer;
