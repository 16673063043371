import { roundToSigDigits } from "./common-helper";
import { customDateTimeFormat } from "./date-helper";
import { deleteRequest, getRequest, postRequest } from "./http-helper";
import { attachParams } from "./url-helper";
import _ from "lodash";

export const getOptions = (arr) => {
  const options = arr?.map((opt) => {
    return {
      value: opt,
      label: opt
    };
  });
  return options;
};

export const validateButton = (data) => {
  let flag = false;

  for (let i = 0; i < data?.length; i++) {
    if (data?.[i]?.user.length <= 0 || data?.[i]?.warehouse?.length <= 0) {
      flag = true;
      break;
    }
  }
  return flag;
};

export const checkResources = (resources) => {
  let finalData = [];
  resources?.map((item) => {
    if (
      item?.user?.length === 0 &&
      item?.warehouse.length === 0 &&
      item?.database?.length === 0 &&
      item?.role?.length === 0
    ) {
      finalData.push({
        data_wh_id: item?.account?.data_warehouse_info_id,
        resource_type: "sf_account",
        resource_list: []
      });
    } else {
      if (
        item.user.length === item.userDropdowns.length ||
        item.warehouse.length === item.whDropdowns.length ||
        item.database.length === item.dbDropdowns.length ||
        item.role.length === item.roleDropdowns.length
      ) {
        finalData.push({
          data_wh_id: item?.account?.data_warehouse_info_id,
          resource_type: "sf_account",
          resource_list: []
        });
      } else {
        if (item.user.length > 0) {
          finalData.push({
            data_wh_id: item?.account?.data_warehouse_info_id,
            resource_type: "user",
            resource_list: item?.user?.filter(
              (element) => element !== "Select All"
            )
          });
        }
        if (item.warehouse.length > 0) {
          finalData.push({
            data_wh_id: item?.account?.data_warehouse_info_id,
            resource_type: "warehouse",
            resource_list: item?.warehouse?.filter(
              (element) => element !== "Select All"
            )
          });
        }
        if (item.database.length > 0) {
          finalData.push({
            data_wh_id: item?.account?.data_warehouse_info_id,
            resource_type: "database",
            resource_list: item?.database?.filter(
              (element) => element !== "Select All"
            )
          });
        }
        if (item.role.length > 0) {
          finalData.push({
            data_wh_id: item?.account?.data_warehouse_info_id,
            resource_type: "role",
            resource_list: item?.role?.filter(
              (element) => element !== "Select All"
            )
          });
        }
      }
    }
  });
  const uniqueObjects = _.uniqWith(finalData, _.isEqual);
  return uniqueObjects;
};

const convertToTableFormat = (data) => {
  const tableFormat = data?.map((item, index) => {
    let whNames;
    if (item?.data_wh_names.length > 0) {
      whNames = _.map(item?.data_wh_names, (name) => name).join(", ");
    } else {
      whNames = "-";
    }
    let usersList = item?.resources.filter(
      (val) => val?.resource_type === "user"
    );
    const concatenatedResourceList = _.chain(usersList)
      .map("resource_list")
      .flatten()
      .uniq()
      .join(", ")
      .value();
    let whList = item?.resources.filter(
      (val) => val?.resource_type === "warehouse"
    );
    const concatenatedWHList = _.chain(whList)
      .map("resource_list")
      .flatten()
      .uniq()
      .join(", ")
      .value();
    let databaseList = item?.resources.filter(
      (val) => val?.resource_type === "database"
    );
    const concatenatedDatabaseList = _.chain(databaseList)
      .map("resource_list")
      .flatten()
      .uniq()
      .join(", ")
      .value();
    let roleList = item?.resources.filter(
      (val) => val?.resource_type === "role"
    );
    const concatenatedRoleList = _.chain(roleList)
      .map("resource_list")
      .flatten()
      .uniq()
      .join(", ")
      .value();
    return {
      id: item?.cost_center_id,
      S_No: index + 1,
      "Cost Center Name": item?.name,
      Account: whNames,
      User: concatenatedResourceList ?? "-",
      Warehouse: concatenatedWHList ?? "-",
      Database: concatenatedDatabaseList ?? "-",
      Owner: item.owner,
      Role: concatenatedRoleList ?? "-",
      Status: item.cost_center_ready
    };
  });
  return tableFormat;
};

export const getAccountDropdowns = async () => {
  const URL = attachParams(`/v2/cost_center/config/data_wh_info`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    const options = getAccOptions(data);
    return options;
  }
};

export const getcostcentres = async () => {
  const URL = attachParams(`/v2/cost_center/config`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    const convertedData = convertToTableFormat(data?.cost_centers);
    return convertedData;
  }
};

export const getUserDropdownsAPI = async (accID) => {
  const params = { data_wh_id: accID, date_range: "September 2023" };
  const URL = attachParams(
    `/v2/cost_center/config/org_resources/users`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    const options = getOptions(data?.resource_names);
    return options;
  }
};

export const getWHDropdownsAPI = async (accID) => {
  const params = { data_wh_id: accID, date_range: "September 2023" };
  const URL = attachParams(
    `/v2/cost_center/config/org_resources/warehouses`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    const options = getOptions(data?.resource_names);
    return options;
  }
};

export const getDatabaseDropdownsAPI = async (accID) => {
  const params = { data_wh_id: accID, date_range: "September 2023" };
  const URL = attachParams(
    `/v2/cost_center/config/org_resources/databases`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    const options = getOptions(data?.resource_names);
    return options;
  }
};

export const getRolesDropdownsAPI = async (accID) => {
  const params = { data_wh_id: accID, date_range: "September 2023" };
  const URL = attachParams(
    `/v2/cost_center/config/org_resources/roles`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    const options = getOptions(data?.resource_names);
    return options;
  }
};

export const postNewCostCentre = async (payload) => {
  const URL = attachParams(`/v2/cost_center/config`);
  const { data, error, status } = await postRequest({
    url: URL,
    data: JSON.stringify(payload)
  });
  if (error) {
    throw error;
  } else if (data || status) {
    return { data, status };
  }
};

export const deleteCostCentre = async (id) => {
  const URL = attachParams(`/v2/cost_center/config/${id}`);
  const { data, error, status } = await deleteRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data || status) {
    return { data, status };
  }
};

export const getSearchResult = (data, searchTerm) => {
  const searchResult = data.filter(
    (item) =>
      item?.Owner?.toUpperCase().includes(searchTerm.toUpperCase()) ||
      item?.["Cost Center Name"]
        ?.toUpperCase()
        .includes(searchTerm.toUpperCase())
  );

  return searchResult;
};

const getAccOptions = (arr) => {
  const options = arr?.map((opt) => {
    return {
      id: opt?.data_warehouse_info_id,
      value: opt?.display_name,
      label: opt?.display_name,
      ...opt
    };
  });
  return options;
};

export const getUniqueDate = (data, key = "month_date") => {
  return _.uniq(_.map(data, key));
};
export const createTreeData = (
  data,
  costCenterNames,
  dwAccOptions,
  dateFormat = "MMM yyyy"
) => {
  data = data?.map((item) => ({
    ...item,
    month_date: customDateTimeFormat(item.date, dateFormat)
  }));
  const uniqueMonthDates = getUniqueDate(data);

  const transformData = (data) => {
    const groupedByCcId = _.groupBy(data, "cc_id");
    let index = 0;
    const transformedData = _.map(groupedByCcId, (ccItems, ccId) => {
      const ccTotalCost = _.sumBy(ccItems, "cost");

      const groupedByResourceType = _.groupBy(ccItems, "resource_type");
      const validResourceTypes = _.omit(groupedByResourceType, null, "");

      if (_.isEmpty(validResourceTypes)) return null;

      const ccDwIds = getDWNames(
        _.uniq(_.map(ccItems, "dw_id")).join(","),
        dwAccOptions
      );

      const ccData = {
        key: ccId.toString(),
        data: {
          s_no: (++index).toString(),
          id: ccId.toString(),
          name: _.get(
            _.find(costCenterNames, { id: Number(ccId) }),
            "Cost Center Name",
            null
          ),
          cost: roundToSigDigits(ccTotalCost, 2, false),
          dw_ids: ccDwIds,
          resource_subtypes: "COST CENTER"
        },
        children: _.map(validResourceTypes, (resourceItems, resourceType) => {
          const resourceTotalCost = roundToSigDigits(
            _.sumBy(resourceItems, "cost"),
            2,
            false
          );
          const groupedById = _.groupBy(resourceItems, "resource_id");

          const resourceData = {
            key: `${ccId}-${resourceType}`,
            data: {
              name: resourceType,
              cost: roundToSigDigits(resourceTotalCost, 2, false),
              dw_ids: getDWNames(
                _.uniq(_.map(resourceItems, "dw_id")).join(","),
                dwAccOptions
              ),
              resource_subtypes: "COST TYPE"
            },
            children: _.flatMap(groupedById, (idItems, resourceId) => {
              const dwIds = getDWNames(
                _.get(_.first(idItems), "dw_id", null).toString(),
                dwAccOptions
              );
              const name =
                resourceId && resourceId?.toString() !== "null"
                  ? resourceId
                  : dwIds;
              const idTotalCost = _.sumBy(idItems, "cost");
              const idData = {
                key: `${ccId}-${resourceType}-${resourceId}`,
                data: {
                  name: name,
                  cost: roundToSigDigits(idTotalCost, 2, false),
                  dw_ids: dwIds,
                  resource_subtypes:
                    _.get(_.first(idItems), "resource_subtype") === null
                      ? "ACCOUNT"
                      : _.get(_.first(idItems), "resource_subtype")
                }
              };
              _.each(uniqueMonthDates, (month) => {
                const monthTotalCost = _.sumBy(idItems, (item) =>
                  item.month_date === month ? item.cost : 0
                );
                idData.data[month] = roundToSigDigits(monthTotalCost, 2, false);
              });

              return idData;
            })
          };
          _.each(uniqueMonthDates, (month) => {
            const monthTotalCost = _.sumBy(resourceItems, (item) =>
              item.month_date === month ? item.cost : 0
            );
            resourceData.data[month] = roundToSigDigits(
              monthTotalCost,
              2,
              false
            );
          });

          return resourceData;
        })
      };
      _.each(uniqueMonthDates, (month) => {
        const monthTotalCost = _.sumBy(ccItems, (item) =>
          item.month_date === month ? item.cost : 0
        );
        ccData.data[month] = roundToSigDigits(monthTotalCost, 2, false);
      });

      return ccData;
    });

    return _.filter(
      transformedData,
      (ccData) => ccData && !_.isEmpty(ccData.children)
    );
  };

  const transformedData = transformData(data);
  return transformedData;
};

export const getDWNames = (commaSeparatedIds, objectsArray) => {
  const idArray = _.map(
    commaSeparatedIds.split(","),
    _.method("trim", _.parseInt)
  );
  const matchingDisplayNames = _(objectsArray)
    .filter((obj) =>
      _.includes(idArray, obj?.data_warehouse_info_id?.toString())
    )
    .map("display_name")
    .join(", ");
  return matchingDisplayNames;
};
