import { CustomTooltip } from "../../../utils/tooltip-helper";
import { roundToSigDigits } from "../../../utils/common-helper";

export const getSQLInventoryProps = (hasTrialFallback) => {
  const customProps = {
    "S No.": {
      type: "rightAligned",
      headerClass: "text-align-right",
      width: 75,
      pinned: "left",
      cellRenderer: (params) => {
        return params?.data["S No."];
      }
    },
    "Warehouse Name": {
      width: 247,
      filter: true,
      pinned: "left",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    "Cost ($)": {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    DBUs: {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Auto Stop (min)": {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Warehouse Type": {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Cluster Size": {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    Creator: {
      width: 160,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Photon Enabled": {
      width: 180,
      cellClass: "grid-cell-left-aligned"
    },
    Serverless: {
      width: 150,
      cellClass: "grid-cell-left-aligned"
    },
    "Min Clusters": {
      width: 170,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params.value, 2, false, false);
      }
    },
    "Max Clusters": {
      width: 170,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false, false);
      }
    },
    "Spot Policy": {
      width: 170,
      cellClass: "grid-cell-left-aligned"
    },
    Tags: {
      width: 200,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Channel Name": {
      width: 180,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    },
    "Workspace ID": {
      width: 150,
      type: "rightAligned",
      headerClass: "right-aligned"
    },
    "Warehouse ID": {
      width: 150,
      type: "rightAligned",
      headerClass: "text-align-right",
      resizable: false,
      sortable: false,
      //pinned: "right",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 35);
      }
    }
  };

  return customProps;
};
