import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Drawerwrapper from "../customElements/Drawerwrapper";

import closeIcon from "../../assets/images/grid/closeIcon.svg";
import Button from "../customElements/Button";
import CreateResource from "./CreateResource";
import {
  checkResources,
  getAccountDropdowns,
  postNewCostCentre
} from "../../utils/chargeback-helper";
import { removeDropdown } from "../../utils/common-helper";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "../../utils/toast-helper";
const initialState = {
  account: [],
  accountDropdowns: [],
  warehouse: [],
  user: [],
  database: [],
  role: [],
  whDropdowns: [],
  userDropdowns: [],
  dbDropdowns: [],
  roleDropdowns: [],
  accountMenu: false,
  warehouseMenu: false,
  userMenu: false,
  databaseMenu: false,
  roleMenu: false
};
function CreateModal({ open, close, acc }) {
  const toast = useToast();
  const [resources, setResources] = useState([]);
  const [costCenterName, setCostCenterName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [error, setError] = useState({ owner: null, CCname: null });
  const [accountDropdowns, setAccountDropdowns] = useState(null);
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };

  useEffect(() => {
    fetchAccountDropdowns();
  }, []);

  // Handle dropdown otion change
  const handleChange = (value, key, index) => {
    let updatedValue;
    if (key === "account") {
      updatedValue = resources.map((item, ind) =>
        ind === index
          ? {
              ...item,
              account: value,
              warehouse: [],
              user: [],
              database: [],
              role: [],
              accountMenu: false
            }
          : item
      );
      updateOtherAccounts(updatedValue);
    } else {
      updatedValue = resources.map((item, ind) =>
        ind === index ? { ...item, [key]: value } : item
      );
      setResources(updatedValue);
    }
  };

  // get all Account details API call
  const fetchAccountDropdowns = async () => {
    let response;
    try {
      response = await getAccountDropdowns(acc.id);
      if (response) {
        setAccountDropdowns(response);
      }
    } catch (error) {
      console.log("error", error);
    }
    setResources([
      { ...initialState, account: acc, accountDropdowns: response }
    ]);
  };

  // handle Dropdown Menu opn & close
  const handleSwitch = (ind, key) => {
    const updatedValue = resources.map((item, index) =>
      index === ind ? { ...item, [key]: !item[key] } : item
    );
    setResources(updatedValue);
  };

  // Delete single resource
  const deleteResource = (ind) => {
    if (resources.length > 1) {
      const updatedValue = resources.filter((item, index) => index !== ind);
      updateOtherAccounts(updatedValue);
    }
  };

  // Create new resource
  const handleResources = () => {
    const account_dropdowns = removeDropdown(
      accountDropdowns,
      resources,
      "account",
      "display_name"
    );
    if (account_dropdowns?.length > 0) {
      const payload = [
        ...resources,
        {
          ...initialState,
          account: account_dropdowns?.[0],
          accountDropdowns: account_dropdowns
        }
      ];
      updateOtherAccounts(payload);
    }
  };

  // set all the data once account changed
  const updateOtherAccounts = (payload) => {
    const account_dropdowns = removeDropdown(
      accountDropdowns,
      payload,
      "account",
      "display_name"
    );
    const updatedData = payload.map((item) => ({
      ...item,
      accountDropdowns: [item.account, ...account_dropdowns]
    }));
    setResources([...updatedData]);
  };

  // clear all data
  const resetData = () => {
    setResources([{ ...initialState, account: acc }]);
    setCostCenterName("");
    setOwnerName("");
    setError({ owner: null, CCname: null });
  };

  // Create cost center resource API call
  const createCostCenterResource = async () => {
    let CCerror = "";
    let OWerror = "";

    if (costCenterName.trim() === "") {
      CCerror = "This field cannot be empty";
    }
    if (ownerName.trim() === "") {
      OWerror = "This field cannot be empty";
    }
    if (ownerName && costCenterName) {
      const accountType = await checkResources(resources);
      const payload = {
        name: costCenterName,
        owner: ownerName,
        resources: accountType
      };
      try {
        const response = await postNewCostCentre(payload);
        if (response.status < 300) {
          customToast({
            type: "success",
            header: "Cost Center created successfully."
          });
          resetData();
          close();
        } else {
          customToast({
            type: "error",
            header: `Something went wrong.`
          });
        }
      } catch (error) {
        console.log(error);
        resetData();
        customToast({
          type: "error",
          header: "Failed to create Cost Center."
        });
        close();
      }
    } else {
      setError({ owner: OWerror, CCname: CCerror });
    }
  };

  return (
    <Drawerwrapper
      open={open}
      handleClose={close}
      anchor={"right"}
      className={"cost-center-modal-container"}>
      <div className="drawer-title ">
        <p>Create Cost Center</p>
        <img onClick={close} src={closeIcon} alt="" />
      </div>
      <div className="step-one-container">
        <div className="title-container">
          <p className="title">Step 1: Update Details</p>
          <p className="description">Fill cost center name and owner name</p>
        </div>
        <div className="inputs-container">
          <div>
            <label>Cost Center Name</label>
            <input
              placeholder=" Enter cost center name"
              value={costCenterName}
              onChange={(e) => {
                setCostCenterName(e.target.value);
                setError({ ...error, CCname: null });
              }}
              type="text"
            />
            {error.CCname && <span className="error-msg">{error.CCname}</span>}
          </div>
          <div>
            <label>Owner Name</label>
            <input
              placeholder=" Enter owner name"
              value={ownerName}
              onChange={(e) => {
                setOwnerName(e.target.value);
                setError({ ...error, owner: null });
              }}
              type="text"
            />
            {error?.owner && <span className="error-msg">{error.owner}</span>}
          </div>
        </div>
      </div>

      <div className="divider-container">
        <div className="divider"></div>
      </div>

      <div className="step-one-container">
        <div className="title-container">
          <p className="title">Step 2: Select Resources</p>
          <p className="description">
            A resource once used in a cost center definition cannot be reused.
            This is to prevent double-counting of the cost resources
          </p>
        </div>
      </div>
      <div className="cost-resource-main-container">
        {resources?.length &&
          resources?.map((item, ind) => (
            <CreateResource
              key={ind}
              acc={acc}
              column={item}
              index={ind}
              handleSwitch={handleSwitch}
              deleteResource={deleteResource}
              handleChange={handleChange}
              resources={resources}
              setResources={setResources}
            />
          ))}
      </div>
      <div className="add-condition-container">
        <p onClick={handleResources}>+ Add Condition</p>
      </div>

      {!resources?.[resources.length - 1]?.accountDropdown &&
        !resources?.[resources.length - 1]?.userDropdown &&
        !resources?.[resources.length - 1]?.warehouseDropdown && (
          <div
            className={
              resources.length > 1
                ? "drawer-footer position-sticky"
                : "drawer-footer"
            }>
            <div>
              <Button
                onClick={createCostCenterResource}
                className={"create-button"}>
                {"Create"}
              </Button>
            </div>
          </div>
        )}
    </Drawerwrapper>
  );
}
CreateModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  acc: PropTypes.object,
  dwAccOptions: PropTypes.array
};
export default CreateModal;
