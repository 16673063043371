import React, { useState } from "react";
import PropTypes from "prop-types";
import "./sidebar.scss";
import { getIconFromModule } from "../../utils/dashboard-helper";
import menuIcons from "../../assets/images/dashboard/index";
import {
  getSessionStorage,
  setSessinStorage
} from "../../utils/storage-helper";
import CG_logo_img from "../../assets/images/common/CG_logo_imgonly.svg";
import sidebr_drp_cllps from "../../assets/images/common/sidebr_drp_cllps.svg";
import sidbr_menu_opn from "../../assets/images/common/sidbr_menu_opn.svg";
import sidebr_drp_opn from "../../assets/images/common/sidebr_drp_opn.svg";
import logout_dark from "../../assets/images/common/logout_dark.svg";
import { SignOutUser } from "../../utils/user-helper";
//import gear_settings from "../../assets/images/common/gear_settings.svg";
import doc_dark from "../../assets/images/common/doc_dark.svg";
import support_dark from "../../assets/images/common/support_dark.svg";
import DropDown from "../DropDown";
import { useAuth } from "../../utils/auth-helper";
import prfl__img from "../../assets/images/common/prfl__img.svg";
import { useNavigate } from "react-router-dom";
import sttngs_dark from "../../assets/images/common/sttngs_dark.svg";

import Select, { components } from "react-select";
import chev_down from "../../assets/images/common/chev_down.svg";
import _ from "lodash";
import { getCardItem } from "../../utils/cards-helper";
import SVG from "react-inlinesvg";

const Sidebar = ({
  activeModule,
  changeActiveModule,
  headerMenu,
  isMenuLoading,
  profileMenu,
  //disableversionmargin,
  changeActivePage,
  changeActiveSubmodule,
  activeSubmodule,
  userInfo,
  changeAcc,
  visible,
  acc,
  dwAccOptions,
  enableLogout = true
}) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SVG className="chev__down" src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const onSignOut = () => {
    setIsLoading(true);
    handleModal();
    signOut();
  };
  const dropdown_options = [
    {
      id: "2",
      name: "Documentation",
      label: "Documentation",
      img: doc_dark,
      handler: () => {
        window.open(
          "https://documentation.chaosgenius.io/",
          "_blank",
          "rel=noopener noreferrer"
        );
        handleModal();
      }
    },
    {
      id: "3",
      name: "Support",
      label: "Support",
      img: support_dark,
      handler: () => {
        window.open(
          "https://join.slack.com/t/chaosgenius/shared_invite/zt-140042uac-rrm~xbx9o_aydi6PTmp_Mg",
          "_blank",
          "rel=noopener noreferrer"
        );
        handleModal();
      }
    },
    {
      id: "4",
      name: "Logout",
      label: "Logout",
      img: logout_dark,
      handler: onSignOut
    }
  ];
  const singleValue = ({ ...props }) => {
    // eslint-disable-next-line
    const { data } = props;
    // eslint-disable-next-line
    const card = getCardItem(data?.connection_type, true);
    // eslint-disable-next-line
    return (
      <components.SingleValue {...props}>
        {/* eslint-disable-next-line */}
        <img
          src={card?.image}
          // eslint-disable-next-line
          alt={data?.display_name}
          style={{ marginRight: "8px", width: "20px", height: "20px" }}
        />
        {/* eslint-disable-next-line */}
        {data?.display_name}
      </components.SingleValue>
    );
  };

  const Option = (props) => {
    // eslint-disable-next-line
    const { data } = props;
    // eslint-disable-next-line
    return (
      <components.Option
        {...props}
        innerProps={{
          // eslint-disable-next-line
          ...props.innerProps,
          // eslint-disable-next-line
          onMouseEnter: () => {
            handlePopup(false);
          }
        }}>
        {/* eslint-disable-next-line */}
        {props.children}
      </components.Option>
    );
  };
  const onOrgSettings = () => {
    if (changeActiveModule) {
      changeActiveModule("Settings", "", "Organization Settings");
    } else {
      navigate("/dashboard/settings/billing");
    }
  };
  const handleModal = (e) => {
    e?.stopPropagation();
    setShowModal(!showModal);
  };

  const formatOptionLabel = (params, { context }) => {
    const card = getCardItem(params?.connection_type, true);
    return (
      <div>
        {context === "menu" ? (
          <img
            src={card?.image}
            alt={params?.value}
            style={{ marginRight: "8px", width: "20px", height: "20px" }}
          />
        ) : null}
        {params?.label}
      </div>
    );
  };

  const [drpClose, setDrpClose] = useState(false);
  const versionInfo = getSessionStorage("version");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const navigate = useNavigate();
  const { onLogout } = useAuth();
  const { version } = versionInfo ? JSON.parse(versionInfo) : { version: "" };

  let pages = undefined;

  let module = headerMenu?.find((menu) => {
    return (
      menu.page_display_name === activeModule &&
      menu?.dw_type === acc?.connection_type
    );
  });
  if (module) {
    pages = module?.children;
  } else {
    module = profileMenu?.find((menu) => {
      return menu.page_display_name === activeModule;
    });
    pages = module?.children;
  }
  const transformedArray = _(pages)
    .groupBy("submodule_name")
    .map((value, key) => ({ submodule_name: key, children: value }))
    .value();
  const menuView = (
    <div className="item-block">
      {headerMenu &&
        headerMenu
          ?.filter((module) => {
            return (
              module?.dw_type?.toString() ===
                acc?.connection_type?.toString() ||
              module?.module_name === "Data Source"
            );
          })
          ?.map((module, index) => {
            const filterChildren =
              module?.children &&
              module?.children?.length &&
              module?.children?.filter((child) => {
                return child?.page_display_name !== module?.page_display_name;
              });
            const img = getIconFromModule(module?.page_display_name);
            return (
              <div
                className={`item__parent display-flex flex-column ${
                  module?.page_display_name === "Data Source"
                    ? "mar-top-auto"
                    : ""
                }`}
                key={index}>
                <div
                  onClick={() => {
                    if (module?.page_display_name !== activeModule) {
                      setDrpClose(false);
                      if (
                        userInfo?.info?.redirect_to_billing?.toString() ===
                        "false"
                      ) {
                        changeActiveSubmodule(
                          module?.children[0]?.submodule_name
                        );
                        changeActiveModule(
                          module?.page_display_name,
                          module?.noUrl ? "" : module?.page_url,
                          module?.children && !module?.dontDisplayChildren
                            ? module?.children[0]?.page_display_name
                            : undefined,
                          undefined,
                          "",
                          module?.children && !module?.dontDisplayChildren
                            ? module?.children[0]?.submodule_name
                            : undefined
                        );
                      } else {
                        changeActiveModule("Settings", "", "Billing");
                      }
                    } else {
                      setDrpClose(!drpClose);
                    }
                  }}
                  className={`item ${
                    activeModule === module?.page_display_name ? "active" : ""
                  }`}>
                  <img
                    src={
                      activeModule === img?.module ? img?.active : img?.inactive
                    }
                  />
                  <span>{module?.page_display_name}</span>
                  {filterChildren &&
                  filterChildren?.length &&
                  !filterChildren?.find(
                    (c) =>
                      !c?.submodule_name ||
                      c?.submodule_name?.toString() === "undefined" ||
                      c?.submodule_name?.toString() === "null"
                  ) &&
                  !module?.dontDisplayChildren ? (
                    <div className="mar-left-auto img-size-14">
                      <img
                        src={
                          activeModule === module?.page_display_name &&
                          !drpClose
                            ? sidebr_drp_opn
                            : sidebr_drp_cllps
                        }
                      />
                    </div>
                  ) : null}
                </div>
                {activeModule === module?.page_display_name &&
                transformedArray &&
                transformedArray?.length &&
                !module?.dontDisplayChildren &&
                !drpClose ? (
                  transformedArray?.length === 1 &&
                  transformedArray[0]?.submodule_name
                    ?.toString()
                    ?.toLowerCase() !== "undefined" &&
                  transformedArray[0]?.submodule_name !== "" ? (
                    <div className="child__page__container display-flex flex-column">
                      {transformedArray?.map((page, index) => {
                        return !page?.submodule_name ||
                          page?.submodule_name?.toString() === "undefined" ||
                          page?.submodule_name?.toString() === "null" ? null : (
                          <div
                            onClick={() => {
                              if (
                                userInfo?.info?.redirect_to_billing?.toString() ===
                                  "false" ||
                                (page?.children[0]?.module_name ===
                                  "Settings" &&
                                  page?.children[0]?.page_display_name ===
                                    "Organization Settings")
                              ) {
                                changeActiveSubmodule(page?.submodule_name);
                                changeActivePage(
                                  page?.children[0]?.page_display_name,
                                  page?.children[0]?.noUrl
                                    ? ""
                                    : page?.children[0]?.page_url,
                                  page?.children[0]?.module_name,
                                  page?.submodule_name
                                );
                              } else {
                                changeActivePage("Billing", "", "Settings");
                              }
                            }}
                            key={index}
                            className={`sidebar__page__container display-flex mar-left-auto cursor-pointer ${
                              page?.submodule_name === activeSubmodule
                                ? "active"
                                : ""
                            }`}>
                            <span>{page?.submodule_name}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : transformedArray?.length > 1 ? (
                    <div className="child__page__container display-flex flex-column">
                      {transformedArray?.map((page, index) => {
                        return !page?.submodule_name ||
                          page?.submodule_name?.toString() === "undefined" ||
                          page?.submodule_name?.toString() === "null" ? null : (
                          <div
                            onClick={() => {
                              if (
                                userInfo?.info?.redirect_to_billing?.toString() ===
                                  "false" ||
                                (page?.children[0]?.module_name ===
                                  "Settings" &&
                                  page?.children[0]?.page_display_name ===
                                    "Organization Settings")
                              ) {
                                changeActiveSubmodule(page?.submodule_name);
                                changeActivePage(
                                  page?.children[0]?.page_display_name,
                                  page?.children[0]?.noUrl
                                    ? ""
                                    : page?.children[0]?.page_url,
                                  page?.children[0]?.module_name,
                                  page?.submodule_name
                                );
                              } else {
                                changeActivePage("Billing", "", "Settings");
                              }
                            }}
                            key={index}
                            className={`sidebar__page__container display-flex mar-left-auto cursor-pointer ${
                              page?.submodule_name === activeSubmodule
                                ? "active"
                                : ""
                            }`}>
                            <span>{page?.submodule_name}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : null
                ) : null}
              </div>
            );
          })}
      <div
        className={`settings__parent display-flex flex-column
            }`}
        onClick={onOrgSettings}>
        <div className={`item ${activeModule === "Settings" ? "active" : ""}`}>
          <img
            src={
              activeModule === "Settings"
                ? menuIcons?.dt_trnsfr_active
                : sttngs_dark
            }
          />
          <span>Settings</span>
        </div>
      </div>
      <div className="user" onClick={handleModal}>
        <div className="user-details">
          <img src={prfl__img} />
          <span className="user__profile">Profile</span>
          {showModal && (
            <DropDown
              options={dropdown_options}
              align="left"
              close={handleModal}
              style={{ top: "-213px", left: "0px", width: "170px" }}
            />
          )}
        </div>
        <div className="profile-dropdown mar-left-auto">
          <img src={sidbr_menu_opn} />
        </div>
      </div>
    </div>
  );
  const signOut = async () => {
    try {
      await SignOutUser(onLogout);
      setIsLoading(false);
    } catch (error) {
      console.log("error signing out: ", error);
      setIsLoading(false);
    }
  };

  const handlePopup = (value = false) => {
    setPopupVisible(value);
  };

  return visible ? (
    <>
      <div className="sidebar-container">
        <div className="logo__block">
          <img src={CG_logo_img} />
          <span>Chaos Genius</span>
        </div>
        {dwAccOptions && dwAccOptions?.length ? (
          <div
            className="acc-select-container"
            onMouseEnter={() => handlePopup(true)}
            onMouseLeave={() => handlePopup(false)}>
            <Select
              value={acc}
              options={dwAccOptions?.map((obj) => {
                return {
                  ...obj,
                  isDisabled: obj?.user_access ? false : true
                };
              })}
              onMenuOpen={() => handlePopup(false)}
              onMenuClose={() => handlePopup(false)}
              styles={customStyles}
              classNamePrefix="selectcategory"
              placeholder="select"
              isSearchable={true}
              formatOptionLabel={formatOptionLabel}
              components={{
                DropdownIndicator,
                Option,
                SingleValue: singleValue
              }}
              onChange={(e) => {
                changeAcc(e);
                setSessinStorage("dw_acc_id", e?.id);
              }}
            />
            {popupVisible && (
              <div className="dw-popup">
                <p>{acc?.label}</p>
              </div>
            )}
          </div>
        ) : null}
        {!isMenuLoading ? (
          headerMenu && headerMenu.length ? (
            menuView
          ) : enableLogout ? (
            <div className="pre-onbording-container">
              <div className="user" onClick={handleModal}>
                <div className="user-details">
                  <img src={prfl__img} />
                  <span className="user__profile">Profile</span>
                  {showModal && (
                    <DropDown
                      options={dropdown_options}
                      align="left"
                      close={handleModal}
                      style={{ top: "-213px", left: "0px", width: "170px" }}
                    />
                  )}
                </div>
                <div className="profile-dropdown mar-left-auto">
                  <img src={sidbr_menu_opn} />
                </div>
              </div>
              {version && (
                <span className={`app-version`}>Version {version}</span>
              )}
            </div>
          ) : null
        ) : null}

        {version && !enableLogout && (
          <span className={`app-version`}>Version {version}</span>
        )}
      </div>
      {isLoading && (
        <div className="load">
          <div className="preload"></div>
        </div>
      )}
    </>
  ) : null;
};
Sidebar.propTypes = {
  activeModule: PropTypes.string,
  changeActiveModule: PropTypes.func,
  headerMenu: PropTypes.array,
  isMenuLoading: PropTypes.bool,
  userInfo: PropTypes.object,
  visible: PropTypes.bool,
  acc: PropTypes.object,
  profileMenu: PropTypes?.array,
  activePage: PropTypes?.string,
  changeActivePage: PropTypes?.func,
  changeActiveSubmodule: PropTypes?.func,
  activeSubmodule: PropTypes?.string,
  dwAccOptions: PropTypes?.array,
  changeAcc: PropTypes?.func,
  disableversionmargin: PropTypes?.bool,
  enableLogout: PropTypes?.bool
};
export default Sidebar;
