import React, { useState } from "react";
import PropTypes from "prop-types";
import eyeCon from "../../assets/images/common/eye.svg";
import eyeConSlash from "../../assets/images/common/eye-slash.svg";
import user_add_success from "../../assets/images/dashboard/user_add_success.svg";
import warning_delete from "../../assets/images/dashboard/warning_delete.svg";
import Select, { components } from "react-select";
import drpdwn from "../../assets/images/common/drpdwn_indicator.svg";
import "./adduserform.scss";
import {
  validateCompanyEmail,
  validateEmail,
  validateEmailDomain
} from "../../utils/validateemail";
import { validatePassword } from "../../utils/common-helper";
import { useAuth } from "../../utils/auth-helper";
import { isBusinessEmail } from "../../utils/business-email-validator";
import Multiselects from "../customElements/MultiSelect";
const AddUserForm = ({
  closeModal,
  isModalOpen,
  addUserSettings,
  deleteUserSettings,
  editUserSettings,
  isLoading,
  changeIsLoading,
  dwAccOptions,
  userData
}) => {
  const usersArr =
    userData && userData?.length
      ? userData?.map((obj) => {
          return {
            id: obj?.user_id,
            value: obj?.user_id,
            label: obj?.email
          };
        })
      : [];
  const { userInfo } = useAuth();
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    multiValue: (styles, { data }) => {
      if (data?.label === "Select All") {
        return {
          ...styles,
          display: "none"
        };
      } else {
        return {
          ...styles
        };
      }
    },
    container: (provided) => {
      return {
        ...provided
        //width: 180
      };
    }
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={drpdwn} />
      </components.DropdownIndicator>
    );
  };
  const getOnlyValue = (valArr) => {
    //[{label: "User", value: "User"}]
    let result = [];
    result = valArr?.map((val) => val.value);
    return result; //["User"]
  };
  const [roleOptions] = useState(
    userInfo?.info?.role === "admin"
      ? [
          { label: "User", value: "User" },
          { label: "Admin", value: "Admin" }
        ]
      : [{ label: "User", value: "User" }]
  );
  const mappedOptions = isModalOpen?.access_list?.dw_id_list?.map((id) => {
    const obj = dwAccOptions?.find((o) => {
      return o?.id?.toString() === id?.toString();
    });
    return obj;
  });
  const mappedGrpOptions = isModalOpen?.users?.map((id) => {
    const obj = usersArr?.find((o) => {
      return o?.id?.toString() === id?.toString();
    });
    return obj;
  });
  const [fields, setFields] = useState({
    fname: "",
    lname: "",
    email: "",
    pword: "",
    role:
      isModalOpen?.action === "edit"
        ? roleOptions?.find((option) => {
            return (
              option?.value?.toLowerCase() === isModalOpen?.role?.toLowerCase()
            );
          })
        : { label: "User", value: "User" },
    auto_generate_pword: true,
    dwaccoptions:
      isModalOpen?.action === "edit"
        ? isModalOpen?.access_list?.dw_id_list &&
          isModalOpen?.access_list.dw_id_list?.length
          ? isModalOpen?.access_list?.dw_id_list?.length ===
            dwAccOptions?.length
            ? getOnlyValue([...mappedOptions])
            : getOnlyValue(mappedOptions)
          : getOnlyValue([...dwAccOptions])
        : []
  });
  const [grpFields, setGrpFields] = useState({
    name: isModalOpen?.action === "edit" ? isModalOpen?.name : "",
    role:
      isModalOpen?.action === "edit"
        ? roleOptions?.find((option) => {
            return (
              option?.value?.toLowerCase() === isModalOpen?.role?.toLowerCase()
            );
          })
        : { label: "User", value: "User" },
    users:
      isModalOpen?.action === "edit"
        ? isModalOpen?.users && isModalOpen?.users?.length
          ? isModalOpen?.users?.length === usersArr?.length
            ? getOnlyValue([...mappedGrpOptions])
            : getOnlyValue([...mappedGrpOptions])
          : mappedGrpOptions
        : []
  });
  const [infoError, setInfoError] = useState({
    fname: "",
    lname: "",
    email: "",
    pword: ""
  });
  const [grpInfoError, setGrpInfoError] = useState({
    name: "",
    role: ""
  });
  const [eyeSlash, setEyeSlash] = useState(false);
  const [pwdType, setPwdType] = useState("password");
  const clearFields = () => {
    setFields({
      fname: "",
      lname: "",
      email: "",
      pword: "",
      role: { label: "User", value: "User" },
      dwaccoptions: [],
      auto_generate_pword: true
    });
  };
  const clearGrpFields = () => {
    setGrpFields({
      name: "",
      role: { label: "User", value: "User" },
      users: []
    });
  };
  const toggleSlash = () => {
    setEyeSlash(!eyeSlash);
    if (pwdType && pwdType === "password") {
      setPwdType("text");
    } else if (pwdType && pwdType === "text") {
      setPwdType("password");
    }
  };
  const handleOkayClick = () => {
    if (isModalOpen?.tab?.toLowerCase() === "groups") {
      clearGrpFields();
    } else {
      clearFields();
    }
    closeModal();
  };
  const handleDeleteClick = async (id) => {
    if (isModalOpen && isModalOpen?.action === "delete") {
      deleteUserSettings(id);
    }
  };
  const handleEditClick = async (id, role, arr, name, synced) => {
    let errorObj = { ...grpInfoError };
    if (name?.trim() === "") {
      errorObj["name"] = "Please enter name";
    }
    setGrpInfoError(errorObj);
    if (
      isModalOpen &&
      isModalOpen?.action === "edit" &&
      errorObj?.name === ""
    ) {
      changeIsLoading(true);
      editUserSettings(id, role, arr, name, synced);
    }
  };
  const handleClick = () => {
    let errorObj = { ...infoError };
    if (fields.fname.trim() === "") {
      errorObj["fname"] = "Please enter first name";
    }
    if (fields.lname.trim() === "") {
      errorObj["lname"] = "Please enter last name";
    }
    if (fields.email.trim() === "") {
      errorObj["email"] = "Please add email";
    }
    if (fields.email.trim() !== "" && !validateEmail(fields?.email?.trim())) {
      errorObj["email"] = "Enter Valid Email";
    }
    if (
      fields.email.trim() !== "" &&
      validateCompanyEmail(fields.email.trim())
    ) {
      errorObj["email"] = "Please provide company email id";
    }
    if (!isBusinessEmail(fields?.email?.trim())) {
      errorObj["email"] = "Please provide company email id";
    }
    if (
      fields.email.trim() !== "" &&
      !validateEmailDomain(fields.email.trim(), userInfo?.info?.domain)
    ) {
      errorObj["email"] = "Enter Valid Email with same domain as user";
    }
    if (fields.pword.trim() === "" && fields?.auto_generate_pword === false) {
      errorObj["pword"] = "Please enter password";
    }
    if (
      !validatePassword(fields.pword.trim()) &&
      fields?.auto_generate_pword === false
    ) {
      errorObj["pword"] =
        "Password should be 6 - 50 characters with atleast one digit,one uppercase and one special character";
    }
    setInfoError(errorObj);
    if (
      errorObj.fname === "" &&
      errorObj.lname === "" &&
      errorObj.email === "" &&
      errorObj.pword === ""
    ) {
      changeIsLoading(true);
      addUserSettings(fields);
    }
  };

  const handleGroupClick = () => {
    let errorObj = { ...grpInfoError };
    if (grpFields.name.trim() === "") {
      errorObj["name"] = "Please enter name";
    }
    setGrpInfoError(errorObj);
    if (errorObj.name === "") {
      changeIsLoading(true);
      addUserSettings(grpFields);
    }
  };

  const fieldList = [
    {
      id: "1",
      name: "fname",
      label: "First Name",
      type: "text",
      placeholder: "Enter First Name",
      value: fields.fname,
      isDisabled: false,
      onChange: (e) => {
        if (infoError["fname"] !== "") {
          setInfoError({ ...infoError, fname: "" });
        }
        setFields({ ...fields, fname: e.target.value });
      }
    },

    {
      id: "2",
      name: "lname",
      label: "Last Name",
      type: "text",
      placeholder: "Enter Last Name",
      isDisabled: false,
      value: fields.lname,
      onChange: (e) => {
        if (infoError["lname"] !== "") {
          setInfoError({ ...infoError, lname: "" });
        }
        setFields({ ...fields, lname: e.target.value });
      }
    },
    {
      id: "3",
      name: "role",
      label: "Role",
      type: "dropdown",
      placeholder: "Select Role",
      options: roleOptions,
      value: fields.role,
      isDisabled: false,
      onChange: (e) => {
        setFields({
          ...fields,
          role: e
        });
      }
    },
    {
      id: "4",
      name: "email",
      label: "Email",
      type: "text",
      placeholder: "Enter Email",
      value: fields.email,
      isDisabled: false,
      onChange: (e) => {
        if (infoError["email"] !== "") {
          setInfoError({ ...infoError, email: "" });
        }
        setFields({ ...fields, email: e.target.value });
      }
    },
    {
      id: "5",
      name: "pword",
      label: "Password",
      type: "password",
      placeholder: "Enter Password",
      hidden: true,
      value: fields.pword,
      isDisabled: false,
      onChange: (e) => {
        if (infoError["pword"] !== "") {
          setInfoError({ ...infoError, pword: "" });
        }
        setFields({ ...fields, pword: e.target.value });
      }
    },
    {
      id: "6",
      name: "dwaccoptions",
      label: "Accounts",
      type: "multidropdown",
      placeholder: "Enter Warehouses",
      options: [...dwAccOptions],
      value: fields.dwaccoptions,
      isDisabled: false,
      multiselect: true,
      onChange: (e) => {
        setFields({
          ...fields,
          dwaccoptions: e
        });
      }
    },
    {
      id: "7",
      name: "auto_generate_pword",
      label: "Auto Generate Password",
      type: "checkbox",
      value: fields.auto_generate_pword,
      isDisabled: true,
      onChange: (e) => {
        setFields({
          ...fields,
          auto_generate_pword: e.target.checked
        });
      }
    }
  ];

  const grpFieldList = [
    {
      id: "1",
      name: "name",
      label: "Name",
      type: "text",
      placeholder: "Enter Group Name",
      value: grpFields.name,
      isDisabled: false,
      onChange: (e) => {
        if (grpInfoError["name"] !== "") {
          setGrpInfoError({ ...grpInfoError, name: "" });
        }
        setGrpFields({ ...grpFields, name: e.target.value });
      }
    },
    {
      id: "2",
      name: "role",
      label: "Role",
      type: "dropdown",
      placeholder: "Select Role",
      options: roleOptions,
      value: grpFields.role,
      isDisabled: false,
      onChange: (e) => {
        setGrpFields({
          ...grpFields,
          role: e
        });
      }
    },
    {
      id: "3",
      name: "users",
      label: "Users",
      type: "multidropdown",
      placeholder: "Enter Users",
      options: [...usersArr],
      value: grpFields.users,
      isDisabled: false,
      multiselect: true,
      onChange: (e) => {
        setGrpFields({
          ...grpFields,
          users: e
        });
      }
    }
  ];

  const editFields = [
    {
      id: "1",
      name: "name",
      label: "User Name",
      type: "text",
      placeholder: "Enter First Name",
      value: isModalOpen?.name,
      isDisabled: true
    },

    {
      id: "2",
      name: "email",
      label: "Email ID",
      type: "text",
      placeholder: "Enter Email",
      isDisabled: true,
      value: isModalOpen?.email
    },
    {
      id: "3",
      name: "role",
      label: "Role",
      type: "dropdown",
      options: roleOptions,
      placeholder: "Select Role",
      isDisabled: false,
      value: fields?.role,
      onChange: (e) => {
        setFields({
          ...fields,
          role: e
        });
      }
    },
    {
      id: "4",
      name: "dwaccoptions",
      label: "Accounts",
      type: "multidropdown",
      placeholder: "Select Warehouses",
      options: [...dwAccOptions],
      value: fields.dwaccoptions,
      isDisabled: false,
      multiselect: true,
      onChange: (e) => {
        setFields({
          ...fields,
          dwaccoptions: e
        });
      }
    }
  ];

  const editGrpFields = [
    {
      id: "1",
      name: "name",
      label: "Group Name",
      type: "text",
      placeholder: "Enter Group Name",
      value: grpFields.name,
      isDisabled: isModalOpen?.synced?.toString() === "true" ? true : false,
      onChange: (e) => {
        if (grpInfoError["name"] !== "") {
          setGrpInfoError({ ...grpInfoError, name: "" });
        }
        setGrpFields({ ...grpFields, name: e.target.value });
      }
    },
    {
      id: "2",
      name: "role",
      label: "Role",
      type: "dropdown",
      options: roleOptions,
      placeholder: "Select Role",
      isDisabled: false,
      value: grpFields?.role,
      onChange: (e) => {
        setGrpFields({
          ...grpFields,
          role: e
        });
      }
    },
    {
      id: "3",
      name: "users",
      label: "Users",
      type: "multidropdown",
      placeholder: "Select Users",
      options: usersArr,
      value: grpFields.users,
      isDisabled: isModalOpen?.synced?.toString() === "true" ? true : false,
      multiselect: true,
      onChange: (e) => {
        setGrpFields({
          ...grpFields,
          users: e
        });
      }
    }
  ];
  const editFieldView = editFields?.map((field) => {
    return (
      !field?.hidden && (
        <div className="form-group edit__form__group" key={field.id}>
          <div className="label-parent">
            {field?.type !== "checkbox" && !field?.hidden && (
              <div className="label-container edit__label">
                <label htmlFor={field.name}>{field.label}</label>
              </div>
            )}
            {field?.additionalInfo ? field?.additionalInfo : null}
          </div>

          {field.name === "pword" && !field?.hidden ? (
            <div className="cg-relative-position">
              <input
                type={pwdType}
                className="form-control"
                id={field.name}
                onChange={field.onChange}
                value={field.value}
                placeholder={field.placeholder}
              />
              {eyeSlash ? (
                <img
                  className="cg-eye cg-absolute-position"
                  src={eyeCon}
                  aria-hidden="true"
                  onClick={() => {
                    toggleSlash();
                  }}
                />
              ) : (
                <img
                  className="cg-eye cg-absolute-position"
                  src={eyeConSlash}
                  aria-hidden="true"
                  onClick={() => {
                    toggleSlash();
                  }}
                />
              )}
            </div>
          ) : field?.type === "checkbox" ? (
            <div className="checkbox-container">
              <input
                type={field.type}
                className="form-check-input"
                id={field.name}
                onChange={field.onChange}
                disabled={field?.isDisabled}
                value={field?.value}
                checked={field?.value}
              />
              <label className="form-check-label" htmlFor={field?.name}>
                {field?.label}
              </label>
            </div>
          ) : field?.type === "dropdown" ? (
            <div className="select-container edit__select">
              <Select
                value={field?.value}
                options={field?.options}
                styles={customStyles}
                isDisabled={field?.isDisabled}
                classNamePrefix="selectcategory"
                placeholder="select"
                isSearchable={false}
                components={{ DropdownIndicator }}
                onChange={field?.onChange}
              />
            </div>
          ) : field?.type === "multidropdown" ? (
            <div className="select-container edit__select">
              <Multiselects
                value={field?.value}
                placeholder={"Select Warehouses"}
                options={field?.options}
                onChange={field?.onChange}
                overlayVisible={false}
                isClearButtonVisible={false}
                isSaveButtonVisisble={false}
                scrollHeight={"180px"}
              />
            </div>
          ) : (
            <input
              type={field.type}
              className={`form-control edit__field ${
                field?.isDisabled ? "disabled" : ""
              }`}
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              disabled={field?.isDisabled}
              placeholder={field.placeholder}
            />
          )}

          {infoError[`${field.name}`] !== "" ? (
            <div className="connection__fail">
              <p>{infoError[`${field.name}`]}</p>
            </div>
          ) : null}
        </div>
      )
    );
  });

  const editGrpFieldView = editGrpFields?.map((field) => {
    return (
      !field?.hidden && (
        <div className="form-group edit__form__group" key={field.id}>
          <div className="label-parent">
            {field?.type !== "checkbox" && !field?.hidden && (
              <div className="label-container edit__label">
                <label htmlFor={field.name}>{field.label}</label>
              </div>
            )}
            {field?.additionalInfo ? field?.additionalInfo : null}
          </div>

          {field.name === "pword" && !field?.hidden ? (
            <div className="cg-relative-position">
              <input
                type={pwdType}
                className="form-control"
                id={field.name}
                onChange={field.onChange}
                value={field.value}
                placeholder={field.placeholder}
              />
              {eyeSlash ? (
                <img
                  className="cg-eye cg-absolute-position"
                  src={eyeCon}
                  aria-hidden="true"
                  onClick={() => {
                    toggleSlash();
                  }}
                />
              ) : (
                <img
                  className="cg-eye cg-absolute-position"
                  src={eyeConSlash}
                  aria-hidden="true"
                  onClick={() => {
                    toggleSlash();
                  }}
                />
              )}
            </div>
          ) : field?.type === "checkbox" ? (
            <div className="checkbox-container">
              <input
                type={field.type}
                className="form-check-input"
                id={field.name}
                onChange={field.onChange}
                disabled={field?.isDisabled}
                value={field?.value}
                checked={field?.value}
              />
              <label className="form-check-label" htmlFor={field?.name}>
                {field?.label}
              </label>
            </div>
          ) : field?.type === "dropdown" ? (
            <div className="select-container edit__select">
              <Select
                value={field?.value}
                options={field?.options}
                styles={customStyles}
                classNamePrefix="selectcategory"
                placeholder="select"
                isSearchable={false}
                isDisabled={field?.isDisabled}
                components={{ DropdownIndicator }}
                onChange={field?.onChange}
              />
            </div>
          ) : field?.type === "multidropdown" ? (
            <div
              className={`select-container edit__select ${
                field?.isDisabled ? "disabled cursor-not-allowed" : ""
              }`}>
              <Multiselects
                value={field?.value}
                options={field?.options}
                onChange={field?.onChange}
                overlayVisible={false}
                isClearButtonVisible={false}
                placeholder={"Select Users"}
                isSaveButtonVisisble={false}
                scrollHeight={"180px"}
              />
            </div>
          ) : (
            <input
              type={field.type}
              className={`form-control edit__field ${
                field?.isDisabled ? "disabled cursor-not-allowed" : ""
              }`}
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              disabled={field?.isDisabled}
              placeholder={field.placeholder}
            />
          )}

          {grpInfoError[`${field.name}`] !== "" ? (
            <div className="connection__fail">
              <p>{grpInfoError[`${field.name}`]}</p>
            </div>
          ) : null}
        </div>
      )
    );
  });
  const fieldView = fieldList?.map((field) => {
    return (
      !field?.hidden && (
        <div className="form-group" key={field.id}>
          <div className="label-parent">
            {field?.type !== "checkbox" && !field?.hidden && (
              <div className="label-container">
                <label htmlFor={field.name}>{field.label}</label>
              </div>
            )}
            {field?.additionalInfo ? field?.additionalInfo : null}
          </div>

          {field.name === "pword" && !field?.hidden ? (
            <div className="cg-relative-position">
              <input
                type={pwdType}
                className="form-control"
                id={field.name}
                onChange={field.onChange}
                value={field.value}
                placeholder={field.placeholder}
              />
              {eyeSlash ? (
                <img
                  className="cg-eye cg-absolute-position"
                  src={eyeCon}
                  aria-hidden="true"
                  onClick={() => {
                    toggleSlash();
                  }}
                />
              ) : (
                <img
                  className="cg-eye cg-absolute-position"
                  src={eyeConSlash}
                  aria-hidden="true"
                  onClick={() => {
                    toggleSlash();
                  }}
                />
              )}
            </div>
          ) : field?.type === "checkbox" ? (
            <div className="checkbox-container">
              <input
                type={field.type}
                className="form-check-input"
                id={field.name}
                onChange={field.onChange}
                disabled={field?.isDisabled}
                value={field?.value}
                checked={field?.value}
              />
              <label className="form-check-label" htmlFor={field?.name}>
                {field?.label}
              </label>
            </div>
          ) : field?.type === "dropdown" ? (
            <div className="select-container">
              <Select
                value={field?.value}
                options={field?.options}
                styles={customStyles}
                classNamePrefix="selectcategory"
                placeholder="select"
                isSearchable={false}
                components={{ DropdownIndicator }}
                onChange={field?.onChange}
              />
            </div>
          ) : field?.type === "multidropdown" ? (
            <div className="select-container edit__select">
              <Multiselects
                value={field?.value}
                options={field?.options}
                onChange={field?.onChange}
                overlayVisible={false}
                isClearButtonVisible={false}
                placeholder={"Select Warehouses"}
                isSaveButtonVisisble={false}
                scrollHeight={"180px"}
              />
            </div>
          ) : (
            <input
              type={field.type}
              className="form-control"
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              disabled={field?.isDisabled}
              placeholder={field.placeholder}
            />
          )}

          {infoError[`${field.name}`] !== "" ? (
            <div className="connection__fail">
              <p>{infoError[`${field.name}`]}</p>
            </div>
          ) : null}
        </div>
      )
    );
  });

  const grpFieldView = grpFieldList?.map((field) => {
    return (
      !field?.hidden && (
        <div className="form-group" key={field.id}>
          <div className="label-parent">
            {field?.type !== "checkbox" && !field?.hidden && (
              <div className="label-container">
                <label htmlFor={field.name}>{field.label}</label>
              </div>
            )}
            {field?.additionalInfo ? field?.additionalInfo : null}
          </div>

          {field.name === "pword" && !field?.hidden ? (
            <div className="cg-relative-position">
              <input
                type={pwdType}
                className="form-control"
                id={field.name}
                onChange={field.onChange}
                value={field.value}
                placeholder={field.placeholder}
              />
              {eyeSlash ? (
                <img
                  className="cg-eye cg-absolute-position"
                  src={eyeCon}
                  aria-hidden="true"
                  onClick={() => {
                    toggleSlash();
                  }}
                />
              ) : (
                <img
                  className="cg-eye cg-absolute-position"
                  src={eyeConSlash}
                  aria-hidden="true"
                  onClick={() => {
                    toggleSlash();
                  }}
                />
              )}
            </div>
          ) : field?.type === "checkbox" ? (
            <div className="checkbox-container">
              <input
                type={field.type}
                className="form-check-input"
                id={field.name}
                onChange={field.onChange}
                disabled={field?.isDisabled}
                value={field?.value}
                checked={field?.value}
              />
              <label className="form-check-label" htmlFor={field?.name}>
                {field?.label}
              </label>
            </div>
          ) : field?.type === "dropdown" ? (
            <div className="select-container">
              <Select
                value={field?.value}
                options={field?.options}
                styles={customStyles}
                classNamePrefix="selectcategory"
                placeholder="select"
                isSearchable={false}
                components={{ DropdownIndicator }}
                onChange={field?.onChange}
              />
            </div>
          ) : field?.type === "multidropdown" ? (
            <div className="select-container edit__select">
              <Multiselects
                value={field?.value}
                options={field?.options}
                onChange={field?.onChange}
                placeholder={"Select Users"}
                overlayVisible={false}
                isClearButtonVisible={false}
                isSaveButtonVisisble={false}
                scrollHeight={"180px"}
              />
            </div>
          ) : (
            <input
              type={field.type}
              className="form-control"
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              disabled={field?.isDisabled}
              placeholder={field.placeholder}
            />
          )}

          {grpInfoError[`${field.name}`] !== "" ? (
            <div className="connection__fail">
              <p>{grpInfoError[`${field.name}`]}</p>
            </div>
          ) : null}
        </div>
      )
    );
  });

  return (
    <>
      <div className="modal-container add-user-modal"></div>
      <div className="form-container add-user-container">
        {isModalOpen &&
        isModalOpen?.status === true &&
        isModalOpen?.action === "delete" ? (
          <div className="form-parent">
            <div className="form-header no-border">
              <div className="header">
                <span></span>
              </div>
              <div
                className="close-img padding-right-16"
                onClick={() => {
                  if (isModalOpen?.tab?.toLowerCase === "users") {
                    clearFields();
                  } else {
                    clearGrpFields();
                  }
                  closeModal(true);
                }}>
                <span>✕</span>
              </div>
            </div>
            <div className="delete-field-container">
              <div className="img-container">
                <img src={warning_delete} />
              </div>
              <div className="success-header-container">
                <div className="success-header">
                  <span>
                    {`Do you want to remove ${isModalOpen?.name} permanently?`}{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="button-container center-align button-delete">
              <button
                className="btn btn btn-dark medium-style"
                onClick={() => {
                  changeIsLoading(true);
                  handleDeleteClick(isModalOpen?.id);
                }}>
                Remove
              </button>
              <button
                onClick={() => {
                  if (isModalOpen?.tab?.toLowerCase === "users") {
                    clearFields();
                  } else {
                    clearGrpFields();
                  }
                  closeModal(true);
                }}
                className="btn btn-outline-dark">
                Cancel
              </button>
            </div>
          </div>
        ) : isModalOpen && isModalOpen?.action === "edit" ? (
          <div className="form-parent">
            <div className="form-header">
              <div className="header">
                <span>{`Edit ${
                  isModalOpen?.tab?.toLowerCase() === "users" ? "User" : "Group"
                }`}</span>
              </div>
              <div
                className="close-img"
                onClick={() => {
                  if (isModalOpen?.tab?.toLowerCase === "users") {
                    clearFields();
                  } else {
                    clearGrpFields();
                  }
                  closeModal(true);
                }}>
                <span>✕</span>
              </div>
            </div>
            <div className="field-container edit__field__container">
              {isModalOpen?.tab?.toLowerCase() === "users"
                ? editFieldView
                : editGrpFieldView}
            </div>
            <div className="button-container">
              <button
                className="btn btn btn-dark medium-style"
                onClick={() => {
                  //changeIsLoading(true);
                  if (isModalOpen?.tab?.toLowerCase() === "users") {
                    handleEditClick(
                      isModalOpen?.id,
                      fields?.role,
                      fields?.dwaccoptions
                    );
                  } else {
                    handleEditClick(
                      isModalOpen?.id,
                      grpFields?.role,
                      grpFields?.users,
                      grpFields?.name,
                      isModalOpen?.synced
                    );
                  }
                }}>
                Save
              </button>
              <button
                onClick={() => {
                  if (isModalOpen?.tab?.toLowerCase === "users") {
                    clearFields();
                  } else {
                    clearGrpFields();
                  }
                  closeModal(true);
                }}
                className="btn btn-outline-dark">
                Cancel
              </button>
            </div>
          </div>
        ) : isModalOpen && isModalOpen?.action === "add-user" ? (
          <div className="form-parent">
            <div className="form-header">
              <div className="header">
                <span>{`Add New ${
                  isModalOpen?.tab?.toLowerCase() === "users" ? "User" : "Group"
                }`}</span>
              </div>
              <div
                className="close-img"
                onClick={() => {
                  if (isModalOpen?.tab?.toLowerCase === "users") {
                    clearFields();
                  } else {
                    clearGrpFields();
                  }
                  closeModal(true);
                }}>
                <span>✕</span>
              </div>
            </div>
            <div className="field-container">
              {isModalOpen?.tab?.toLowerCase() === "users"
                ? fieldView
                : grpFieldView}
            </div>
            <div className="button-container">
              <button
                className="btn btn btn-dark medium-style"
                onClick={
                  isModalOpen?.tab?.toLowerCase() === "groups"
                    ? handleGroupClick
                    : handleClick
                }>
                {`Add ${
                  isModalOpen?.tab?.toLowerCase() === "users" ? "User" : "Group"
                }`}
              </button>
              <button
                onClick={() => {
                  if (isModalOpen?.tab?.toLowerCase === "users") {
                    clearFields();
                  } else {
                    clearGrpFields();
                  }
                  closeModal(true);
                }}
                className="btn btn-outline-dark">
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="form-parent">
            <div className="form-header no-border">
              <div className="header">
                <span></span>
              </div>
              <div
                className="close-img"
                onClick={() => {
                  if (isModalOpen?.tab?.toLowerCase === "users") {
                    clearFields();
                  } else {
                    clearGrpFields();
                  }
                  closeModal(true);
                }}>
                <span>✕</span>
              </div>
            </div>
            <div className="success-container">
              <div className="img-container">
                <img src={user_add_success} />
              </div>
              <div className="success-header-container">
                <div className="success-header">
                  <span>{`${
                    isModalOpen?.tab?.toLowerCase() === "users"
                      ? "User"
                      : "Group"
                  } Added Successfully`}</span>
                </div>
                <div className="success-subheader">
                  {isModalOpen?.tab?.toLowerCase() === "users" ? (
                    <span>
                      An email has been sent to the user with login details
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="button-container center-align">
              <button className="btn btn btn-dark" onClick={handleOkayClick}>
                Okay
              </button>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
AddUserForm.propTypes = {
  closeModal: PropTypes.func,
  changeIsLoading: PropTypes.func,
  addUserSettings: PropTypes.func,
  deleteUserSettings: PropTypes.func,
  editUserSettings: PropTypes?.func,
  isModalOpen: PropTypes.object,
  isLoading: PropTypes.bool,
  dwAccOptions: PropTypes?.array,
  userData: PropTypes?.array
};
export default AddUserForm;
