import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./alertsettings.scss";
import coming_soon_new from "../../assets/images/dashboard/coming_soon_new.png";
import alert_settings_slack from "../../assets/images/dashboard/alert_settings_slack.png";
import alert_settings_team from "../../assets/images/dashboard/alert_settings_team.svg";
import green_tick from "../../assets/images/dashboard/green_tick.svg";
// import SettingsRoutes from "../../routes/SettingsRoutes";
import { useNavigate } from "react-router-dom";
import GridComponent from "../../components/GridComponent";
import { getAlertConfigProps } from "./alertsettingscolsdef";
import {
  getAlertsConfigData,
  getSlackAlert,
  getTeamsAlert,
  saveAlertConfig
} from "../../utils/settings-helper";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { getException } from "../../utils/exceptiondictionary";
import { AuthContext } from "../../utils/authprovider";

const AlertSettings = ({
  isSlackAlertSetup,
  isTeamAlertSetup,
  changeSlackAlert,
  changeTeamAlert,
  changeTeamsAlertSetup,
  changeSlackAlertSetup
}) => {
  const toast = useToast();
  const { userInfo } = useContext(AuthContext);
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };
  const customProps = getAlertConfigProps();
  const univerSalStyle = {};
  const [breadCrumbs] = useState(false);
  const [gridLoading, setGridLoading] = useState(true);
  const [rowData, setRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [gridFallback, setGridFallback] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [slackAlertLoading, setSlackAlertLoading] = useState(true);
  const [teamAlertLoading, setTeamAlertLoading] = useState(true);
  const [modifiedSettings, setModifiedSettings] = useState([]);

  const clearSlackAlert = () => {
    changeSlackAlertSetup(false);
    setSlackAlertLoading(false);
  };

  const clearTeamsAlert = () => {
    changeTeamsAlertSetup(false);
    setTeamAlertLoading(false);
  };

  const getAlertStatus = async () => {
    try {
      const [slackAlertStatus, teamsAlertStatus] = await Promise.all([
        getSlackAlert(),
        getTeamsAlert()
      ]);

      if (!slackAlertStatus || !Object.keys(slackAlertStatus)?.length) {
        clearSlackAlert();
      } else {
        changeSlackAlertSetup(true);
        setSlackAlertLoading(false);
        changeSlackAlert(slackAlertStatus);
      }

      if (!teamsAlertStatus || !Object.keys(teamsAlertStatus)?.length) {
        clearTeamsAlert();
      } else {
        changeTeamsAlertSetup(true);
        setTeamAlertLoading(false);
        changeTeamAlert(teamsAlertStatus);
      }
    } catch (er) {
      console.log(er);
      clearTeamsAlert();
      clearSlackAlert();
    }
  };

  useEffect(() => {
    setSlackAlertLoading(true);
    setTeamAlertLoading(true);
    getAlertStatus();
  }, []);

  const column_display_name_mappping = {
    name: "Name",
    user_email: "Email",
    daily_report: "Daily Alerts",
    warehouse_recommendation_report: "Warehouse Recommendations"
  };
  const clearData = () => {
    setRowData([]);
  };
  const createSettingsColDefs = (
    data,
    customProps,
    univerSalStyle,
    column_display_name_mappping
  ) => {
    let res = [];
    if (data) {
      res = data?.map((item) => {
        let obj = {
          field: String(item)
        };
        if (item)
          if (customProps[item]) {
            obj = {
              ...obj,
              headerName: column_display_name_mappping
                ? column_display_name_mappping[`${item ? item : ""}`]
                : "",
              ...customProps[item]
            };
          }
        if (univerSalStyle) {
          obj = { ...obj, ...univerSalStyle };
        }
        return obj;
      });
      res.push({
        headerName: "Daily Alerts",
        ...customProps["daily_report"],
        headerClass: "text-align-center",
        field: "daily_report",
        cellRenderer: (params) => {
          return (
            <div className="checkbox-container">
              <input
                type="checkbox"
                className="form-check-input"
                id={params?.data?.user_id}
                // onChange={() => {
                //   params.data.buttonDisabled = false;
                //   params.setValue(!params?.value);
                //   params.api.redrawRows();
                // }}
                onChange={() => {
                  handleAlertSettings(params, "DLR");
                }}
                checked={params?.value}
              />
            </div>
          );
        }
      });
      res.push({
        headerName: "Warehouse Recommendations",
        ...customProps["warehouse_recommendation_report"],
        field: "warehouse_recommendation_report",
        headerClass: "text-align-center",
        cellRenderer: (params) => {
          return (
            <div className="checkbox-container">
              <input
                type="checkbox"
                className="form-check-input"
                id={params?.data?.user_id}
                onChange={() => {
                  handleAlertSettings(params, "WHR");
                }}
                checked={params?.value}
              />
            </div>
          );
        }
      });
      res.push({
        headerName: "Long Running Queries",
        ...customProps["long_running_queries_report"],
        field: "long_running_queries_report",
        headerClass: "text-align-center",
        resizable: false,
        cellRenderer: (params) => {
          return (
            <div className="checkbox-container">
              <input
                type="checkbox"
                className="form-check-input"
                id={params?.data?.user_id}
                onChange={() => {
                  handleAlertSettings(params, "LRQ");
                }}
                checked={params?.value}
              />
            </div>
          );
        }
      });
    }
    return res;
  };
  const handleAlertSettings = (params, rec) => {
    params.data.buttonDisabled = false;
    params.setValue(!params?.value);

    if (rec === "WHR") {
      let data = modifiedSettings ?? [];
      const findDuplicate = data.filter(
        (item) => item.user_id === params.data.user_id
      );

      if (findDuplicate.length > 0) {
        data = data.map((item) =>
          item.user_id === params.data.user_id
            ? {
                ...item,
                warehouse_recommendation_report: !params?.value
              }
            : item
        );
        setModifiedSettings(data);
      } else {
        data.push(params.data);
        const mod = [...data];
        setModifiedSettings(mod);
      }
    } else if (rec === "DLR") {
      let data = modifiedSettings ?? [];
      const findDuplicate = data.filter(
        (item) => item.user_id === params.data.user_id
      );

      if (findDuplicate.length > 0) {
        data = data.map((item) =>
          item.user_id === params.data.user_id
            ? {
                ...item,
                daily_report: !params?.value
              }
            : item
        );
        setModifiedSettings(data);
      } else {
        data.push(params.data);
        const mod = [...data];
        setModifiedSettings(mod);
      }
    } else if (rec === "LRQ") {
      let data = modifiedSettings ?? [];
      const findDuplicate = data.filter(
        (item) => item.user_id === params.data.user_id
      );

      if (findDuplicate.length > 0) {
        data = data.map((item) =>
          item.user_id === params.data.user_id
            ? {
                ...item,
                long_running_queries_report: !params?.value
              }
            : item
        );
        setModifiedSettings(data);
      } else {
        data.push(params.data);
        const mod = [...data];
        setModifiedSettings(mod);
      }
    }
  };

  const saveRow = async () => {
    try {
      modifiedSettings.map(async (item) => {
        await saveAlertConfig(item);
      });
      customToast({
        type: "success",
        ...getException("AlertPreferenceSaved")
      });
    } catch (err) {
      customToast({
        type: "error",
        ...getException(
          "",
          "Something went wrong while saving your preferences. Please reload the page and try again."
        )
      });
    }
    setModifiedSettings([]);
  };

  const getTableData = async () => {
    try {
      const data = await getAlertsConfigData();
      if (data) {
        const field_names = ["name", "user_email"];
        data?.forEach((element, index) => {
          element.index = index + 1;
          element.buttonDisabled = true;
          element.saveInProgress = false;
        });
        const colDefsArr = createSettingsColDefs(
          field_names,
          customProps,
          univerSalStyle,
          column_display_name_mappping
        );
        if (colDefsArr && data) {
          setColumnDefs(colDefsArr);
          setRowData(data);
        }
      } else {
        clearData();
      }
      setGridLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      console.log(err);
      setGridLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
    }
  };

  const getData = async () => {
    try {
      await getTableData();
      setGridLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
      console.log(err);
    }
  };
  useEffect(() => {
    setGridLoading(true);
    getData();
  }, []);

  const handleSlackClick = () => {
    navigateTo("/dashboard/settings/alerts/slack");
  };
  const handleEditSlackAlertClick = () => {
    navigateTo("/dashboard/settings/alerts/slack");
  };
  const handleTeamsClick = () => {
    navigateTo("/dashboard/settings/alerts/teams");
  };
  const handleEditTeamsAlertClick = () => {
    navigateTo("/dashboard/settings/alerts/teams");
  };
  //const handleEmailClick = () => {};
  const blocks = [
    {
      id: "1",
      name: "Slack",
      img: alert_settings_slack,
      heading: "Slack",
      subheading:
        "Update Slack Incoming Webhook URL to receive usage and anomaly alerts",
      onClick: !isSlackAlertSetup
        ? handleSlackClick
        : handleEditSlackAlertClick,
      btnClass: "btn btn-dark",
      btnText: !isSlackAlertSetup ? "Connect Slack" : "Edit Slack Connection",
      disabled: userInfo?.info?.role?.toString() !== "admin",
      isSetup: isSlackAlertSetup
    },
    {
      id: "2",
      name: "Microsoft Teams",
      img: alert_settings_team,
      heading: "Microsoft Teams",
      subheading:
        "Update Teams Incoming Webhook URL to receive usage and anomaly alerts",
      onClick: !isTeamAlertSetup ? handleTeamsClick : handleEditTeamsAlertClick,
      btnClass: "btn btn-dark",
      btnText: !isTeamAlertSetup ? "Connect Teams" : "Edit Teams Connection",
      disabled: userInfo?.info?.role?.toString() !== "admin",
      isSetup: isTeamAlertSetup
    }
  ];

  const blockView = blocks?.map((block) => {
    return (
      <div className="block" key={block?.id}>
        {block.isComingSoon && (
          <>
            <div className="coming-soon-img-container">
              <img src={coming_soon_new} />
            </div>
          </>
        )}
        <div className="img-container">
          <img src={block?.img} />
        </div>
        <div className="alert-heading-container">
          <div className="heading-parent">
            <h4 className="alert-heading">{block?.heading}</h4>
            {block?.isSetup && <img src={green_tick} />}
          </div>
          <div className="alert-desc">
            <span>{block.subheading}</span>
          </div>
        </div>
        <button
          className={block?.btnClass + (block?.disabled ? " disabled" : "")}
          disabled={block?.disabled}
          onClick={block?.onClick}>
          {block?.btnText}
        </button>
      </div>
    );
  });

  return (
    <>
      {(slackAlertLoading || teamAlertLoading) && (
        <div className="load">
          <div className="preload"></div>
        </div>
      )}
      {!slackAlertLoading && !teamAlertLoading ? (
        <div className="alert-settings-container">
          <div className="header-container">
            <div className="header-block">
              {breadCrumbs ? (
                <div className="breadcrumbs-container"></div>
              ) : null}
              <div className="header">
                <span>Channel Configuration</span>
              </div>
            </div>
          </div>
          <div className="block-container">{blockView}</div>
          <div className="grid-block">
            <div className="grid-header-container">
              <div className="grid-header-block">
                <div className="grid-header">
                  <span>Alerts Configuration</span>
                  <button
                    disabled={
                      modifiedSettings.length > 0 &&
                      userInfo?.info?.role === "admin"
                        ? false
                        : true
                    }
                    onClick={saveRow}
                    className={`btn btn-green-variant alert__settings-btn ${
                      modifiedSettings.length > 0 &&
                      userInfo?.info?.role === "admin"
                        ? ""
                        : "disabled"
                    }`}>
                    Save
                  </button>
                </div>
              </div>
            </div>
            <GridComponent
              columnDefs={columnDefs}
              rowData={rowData}
              gridFallback={gridFallback}
              gridStyle={{ height: 400 }}
              gridLoading={gridLoading}
              noRowsText={"No configs available"}
              //suppressColumnsSize={true}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
AlertSettings.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  isSlackAlertSetup: PropTypes.bool,
  isTeamAlertSetup: PropTypes.bool,
  changeSlackAlert: PropTypes.func,
  changeTeamAlert: PropTypes.func,
  changeSlackAlertSetup: PropTypes.func,
  changeTeamsAlertSetup: PropTypes.func
};
export default AlertSettings;
