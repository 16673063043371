const customProps = {
  name: {
    width: 255,
    cellClass: "grid-cell-left-aligned",
    flex: 1
  },
  // last_name: {
  //   width: 272,
  //   cellClass: "grid-cell-left-aligned"
  // },
  user_email: {
    width: 246,
    cellClass: "grid-cell-left-aligned",
    flex: 1
  },
  daily_report: {
    width: 200,
    cellClass: "grid-cell-middle-aligned flex-center cell-border-right",
    headerClass: "text-align-center"
  },
  warehouse_recommendation_report: {
    width: 200,
    cellClass: "grid-cell-middle-aligned flex-center cell-border-right",
    headerClass: "text-align-center"
  },
  long_running_queries_report: {
    width: 200,
    cellClass: "grid-cell-middle-aligned flex-center cell-border-right",
    headerClass: "text-align-center"
  }
  // action: {
  //   width: 200,
  //   cellClass: "grid-cell-middle-aligned flex-center"
  // }
};

export const getAlertConfigProps = () => {
  return customProps;
};
