import React, { useContext, useEffect, useState } from "react";
import "./userprovisioning.scss";
import { AuthContext } from "../../utils/authprovider";
import billing__fallback from "../../assets/images/common/billing__fallback.svg";
import { getException } from "../../utils/exceptiondictionary";
import {
  disableSCIMAPI,
  getAutoDeleteUserData,
  getSCIMData,
  postAutoDeleteUserData,
  setupSCIMToken
} from "../../utils/settings-helper";
import copy from "../../assets/images/connection/copy1.svg";
import copied from "../../assets/images/connection/copied.svg";
import { Button } from "@mui/material";
import { DateTime } from "luxon";
import { getUserAPI } from "../../utils/user-helper";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "../../utils/toast-helper";
import ToggleSwitch from "../../components/customElements/ToggleSwitch";
import ConfirmationPopup from "../../components/customElements/ConfirmationPopup";

const UserProvisioning = () => {
  const [isSetupModalOpen, setIsSetupmodalOpen] = useState(false);
  const [isRegenModalOpen, setIsRegenModalOpen] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const [token, setToken] = useState("");
  const [scimURL, setScimURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSetup, setIsSetup] = useState(false);
  const [isDisableModal, setIsDisableModal] = useState(false);
  const [generatedDate, setGeneratedDate] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [textCopied, setTextCopied] = useState(false);
  const [tokenCopied, setTokenCopied] = useState(false);
  const [urlCopied, setUrlCopied] = useState(false);
  const [autoDeleteUsers, setAutoDeleteUsers] = useState(false);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (userInfo?.info?.role === "admin") {
      getData();
      getAutoDeleteUserStatus();
    }
  }, []);
  const toast = useToast();
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };

  const getAutoDeleteUserStatus = async () => {
    const data = await getAutoDeleteUserData();
    if (data) {
      setAutoDeleteUsers(data?.auto_delete_user);
    }
  };
  const copyInstruction = (text, setInstruction) => {
    navigator.clipboard.writeText(text);
    setInstruction(true);
    setInterval(function () {
      setInstruction(false);
    }, 3000);
    customToast({ type: "success", ...getException("TextCopySuccessful") });
  };
  const disableSCIM = async () => {
    try {
      const data = await disableSCIMAPI();
      console.log(data);
      setIsSetup(false);
      setIsLoading(false);
    } catch (er) {
      console.log(er);
      setIsLoading(false);
    }
  };
  const getData = async () => {
    try {
      setIsLoading(true);
      const scimData = await getSCIMData();
      if (scimData) {
        const { url, created_by, created_on } = scimData;
        setScimURL(url);
        const user = await getUserAPI(created_by);
        setCreatedBy(user?.email);
        setGeneratedDate(
          DateTime.fromISO(created_on).toFormat("MMMM dd, yyyy hh:mm a ZZZZ")
        );
        setIsSetup(true);
        setIsLoading(false);
      } else {
        setIsSetup(false);
        setIsLoading(false);
      }
    } catch (err) {
      setIsSetup(false);
      setIsLoading(false);
      console.log(err);
    }
  };
  const setupSCIM = async () => {
    try {
      const data = await setupSCIMToken();
      if (data) {
        const { token, scim_url } = data;
        if (token) {
          setToken(token);
        }
        if (scim_url) {
          setScimURL(scim_url);
        }
      }
      setIsSetup(true);
      setIsSetupmodalOpen(true);
      setIsLoading(false);
    } catch (er) {
      console.log(er);
      setIsLoading(false);
      setIsSetupmodalOpen(false);
    }
  };

  const handleSwitch = (e) => {
    setShowModal(e.value);
    if (!e.value) {
      handleAutoDeleteUsers({ auto_delete_user: false });
    }
  };
  const handleReject = () => {
    setShowModal(false);
  };
  const handleAccept = () => {
    handleAutoDeleteUsers({ auto_delete_user: true });
  };
  const handleAutoDeleteUsers = async (payload) => {
    try {
      setIsLoading(true);
      const userData = await postAutoDeleteUserData(payload);
      if (userData) {
        setAutoDeleteUsers(userData?.auto_delete_user);
        handleReject();
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setAutoDeleteUsers(false);
      console.log(err);
    }
  };
  return (
    <>
      {userInfo?.info?.role === "admin" ? (
        <>
          {isLoading && (
            <div className="load">
              <div className="preload"></div>
            </div>
          )}
          <div
            className={`prov-modal-wrapper ${
              isSetupModalOpen ? "show" : ""
            }`}></div>
          <div
            className={`modal-container prov-modal ${
              isSetupModalOpen ? "show" : ""
            }`}>
            <div
              className={`prov-modal-dialog ${isSetupModalOpen ? "show" : ""}`}>
              <div className="modal-content">
                <div className="modal-header">
                  <span className="modal-title small-font">
                    Generate SCIM Token
                  </span>
                  <button
                    className="close"
                    onClick={() => {
                      setIsSetupmodalOpen(false);
                    }}>
                    <span>&#xD7;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    className="prov-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setIsSetupmodalOpen(false);
                      getData();
                    }}>
                    <div className="form-group">
                      <span className="sub__header">
                        Use the SCIM token and account SCIM URL to set up
                        integration in your identity provider.
                      </span>
                    </div>
                    <div className="form-group">
                      <label>SCIM Token</label>
                      <div className="scim_form">
                        <input
                          type="text"
                          className="form-control token_input"
                          id="redeem_code"
                          placeholder=""
                          value={token}
                          disabled={true}
                        />
                        {!tokenCopied ? (
                          <img
                            className="copy_img"
                            src={copy}
                            onClick={() => {
                              copyInstruction(token, setTokenCopied);
                            }}
                          />
                        ) : (
                          <img className="copy_img" src={copied} />
                        )}
                      </div>
                      <span className="sub__header">
                        Make sure to copy the token. You won’t be able to see it
                        again.
                      </span>
                    </div>
                    <div className="form-group">
                      <label>Account SCIM URL</label>
                      <div className="scim_form">
                        <input
                          type="text"
                          className="form-control token_input"
                          id="redeem_code"
                          placeholder=""
                          value={scimURL}
                          disabled={true}
                        />
                        {!urlCopied ? (
                          <img
                            className="copy_img"
                            src={copy}
                            onClick={() => {
                              copyInstruction(scimURL, setUrlCopied);
                            }}
                          />
                        ) : (
                          <img className="copy_img" src={copied} />
                        )}
                      </div>
                    </div>
                    <input
                      type="submit"
                      //disabled={redeemCode ? false : true}
                      className="btn btn-green-variant done-btn"
                      value="Done"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`prov-modal-wrapper ${
              isRegenModalOpen ? "show" : ""
            }`}></div>
          <div
            className={`modal-container prov-modal ${
              isRegenModalOpen ? "show" : ""
            }`}>
            <div
              className={`prov-modal-dialog ${isRegenModalOpen ? "show" : ""}`}>
              <div className="modal-content">
                <div className="modal-header">
                  <span className="modal-title small-font">
                    Regenerate Token
                  </span>
                  <button
                    className="close"
                    onClick={() => {
                      setIsRegenModalOpen(false);
                    }}>
                    <span>&#xD7;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    className="prov-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setIsRegenModalOpen(false);
                      setIsLoading(true);
                      setupSCIM();
                    }}>
                    <div className="form-group">
                      <span className="sub__header">
                        Are you sure that you want to regenerate the token?{" "}
                        <b>
                          The user provisioning will continue to work with the
                          existing token for 24 hours.
                        </b>{" "}
                        Replace the token in your identity provider as soon as
                        possible.
                      </span>
                    </div>
                    <input
                      type="submit"
                      //disabled={redeemCode ? false : true}
                      className="btn btn-green-variant done-btn"
                      value="Regenerate Token"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`prov-modal-wrapper ${
              isDisableModal ? "show" : ""
            }`}></div>
          <div
            className={`modal-container prov-modal ${
              isDisableModal ? "show" : ""
            }`}>
            <div
              className={`prov-modal-dialog ${isDisableModal ? "show" : ""}`}>
              <div className="modal-content">
                <div className="modal-header">
                  <span className="modal-title small-font">
                    Disable User Provisioning
                  </span>
                  <button
                    className="close"
                    onClick={() => {
                      setIsDisableModal(false);
                    }}>
                    <span>&#xD7;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    className="prov-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      setIsDisableModal(false);
                      setIsLoading(true);
                      disableSCIM();
                    }}>
                    <div className="form-group">
                      <span className="sub__header">
                        Disabling user provisioning invalidates all active SCIM
                        tokens immediately and disables user provisioning, Are
                        you sure?
                      </span>
                    </div>
                    <input
                      type="submit"
                      //disabled={redeemCode ? false : true}
                      className="btn btn-green-variant done-btn"
                      value="Disable"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          {!isSetup ? (
            <div className="user_prov_container">
              <div className="setup_container">
                <span className="scim__heading">User Provisioning</span>
                <span>
                  After you set up User Provisioning, you can manage user
                  attributes and group membership from your identity provider.
                </span>
                <div className={`no-border btn__parent flex-row`}>
                  <button
                    className="btn btn-primary setup__btn"
                    onClick={() => {
                      setIsLoading(true);
                      setupSCIM();
                    }}>
                    Setup User Provisioning
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="user_prov_container">
              <div className="setup__details_container">
                <span className="scim__heading">User Provisioning</span>
                <span className="sub__header">
                  After you set up User Provisioning, you can manage user
                  attributes and group membership from your identity provider.
                </span>
                <div className="det__">
                  <span className="det__header">SCIM Token</span>
                  <span className="det__subheader">
                    {`Last generated on ${generatedDate} by
                    ${createdBy}`}
                  </span>
                  <span
                    className="token__cta"
                    onClick={() => {
                      setIsRegenModalOpen(true);
                    }}>
                    Generate Token
                  </span>
                </div>

                <div className="det__">
                  <span className="det__header">Account SCIM URL</span>
                  <div className="scim_form">
                    <input
                      type="text"
                      className="form-control url_input"
                      id="redeem_code"
                      placeholder=""
                      value={scimURL}
                      disabled={true}
                    />
                    {!textCopied ? (
                      <img
                        className="copy_img"
                        src={copy}
                        onClick={() => {
                          copyInstruction(scimURL, setTextCopied);
                        }}
                      />
                    ) : (
                      <img className="copy_img" src={copied} />
                    )}
                  </div>
                  <div className="auto-delete-container">
                    <p className="auto-delete-label">
                      Automatically Delete Unassigned Users
                    </p>
                    <ToggleSwitch
                      checked={autoDeleteUsers}
                      onChange={handleSwitch}
                      className={"toggle-switch"}
                    />
                    <ConfirmationPopup
                      open={showModal}
                      msg={
                        "Are you sure you wish to proceed with automatic deletion of users who are not assigned to any group?"
                      }
                      title={"Delete User Automatically"}
                      acceptLabel={"Confirm"}
                      onAccept={handleAccept}
                      onCancel={handleReject}
                      rejectClassName={"rejectButton"}
                      acceptClassName={"acceptButton"}
                      rejectLabel={"Cancel"}
                      className={"user-delete-confirmation"}
                    />
                  </div>
                </div>
                <Button
                  variant="outlined"
                  color="error"
                  className="btn__disable"
                  onClick={() => {
                    setIsDisableModal(true);
                  }}>
                  Disable User Provisioning
                </Button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="billing__fallback">
            <div className="fallback__block">
              <div className="img__container">
                <img src={billing__fallback} />
              </div>
              <div className="fallback__heading">
                <div className="header">
                  <span>Access only available for administrators</span>
                </div>
                <div className="sub__header">
                  <span>
                    Please contact your admin to fix this issue or contact{`  `}
                    <a href="mailto:support@chaosgenius.io">
                      support@chaosgenius.io
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
UserProvisioning.propTypes = {};
export default UserProvisioning;
