import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { stackGrahpcolors } from "../../../utils/cost-reporting-helper";
import { CustomTooltip } from "../../../utils/tooltip-helper";

const CustomLegends = (props) => {
  const {
    data = [],
    filterApplied = false,
    handleLegendClick,
    disabledLegends
  } = props;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const renderLegends = (startIndex, endIndex) => {
    return data.slice(startIndex, endIndex).map((item, index) => (
      <div
        onClick={() => handleLegendClick(item, index)}
        key={startIndex + index}
        className={
          disabledLegends?.includes(item.name)
            ? "legend disabled-legend"
            : "legend"
        }>
        <div
          className="color-dot"
          style={{
            background: `${stackGrahpcolors?.[startIndex + index]}`
          }}></div>
        <span className="legend-title">
          {CustomTooltip(item?.name, item?.name, 35)}
        </span>
      </div>
    ));
  };

  useEffect(() => {
    setIsPageLoading(true);
    if (data && data.length > 0) {
      setIsPageLoading(false);
    }
  }, [data, filterApplied]);

  return (
    !isPageLoading && (
      <>
        {filterApplied ? (
          <div>
            <div className="filter-legend-wrapper">
              {data?.length <= 17 && renderLegends(0, data?.length)}
            </div>
            {data?.length > 17 && data?.length <= 34 && (
              <div>
                <div className="filter-legend-wrapper">
                  {renderLegends(0, 17)}
                </div>
                <div className="filter-legend-wrapper">
                  {renderLegends(17, data?.length)}
                </div>
              </div>
            )}
            {data?.length > 34 && (
              <div>
                <div className="filtered-legends">{renderLegends(0, 17)}</div>
                <div className="filtered-legends">{renderLegends(17, 34)}</div>
                <div className="filtered-legends">
                  {renderLegends(34, data?.length >= 50 ? 50 : data?.length)}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="legend-custom-wrapper">
            <div className="custom-legends">
              {data?.length > 10 && renderLegends(0, 5)}
              {data?.length > 10 && renderLegends(5, 10)}
              {data?.length > 10 && (
                <div
                  onClick={() => handleLegendClick({ name: "Others" })}
                  className={
                    disabledLegends?.includes("Others")
                      ? "legend disabled-legend"
                      : "legend"
                  }>
                  <div
                    className="color-dot"
                    style={{
                      background: `${stackGrahpcolors?.[11]}`
                    }}></div>
                  <span className="legend-title"> {"Others"}</span>
                </div>
              )}
              {data?.length <= 10 && data?.length >= 5 && renderLegends(0, 5)}
              {data?.length <= 10 &&
                data?.length > 5 &&
                renderLegends(6, data?.length)}
            </div>

            {data?.length < 5 && renderLegends(0, data?.length)}
          </div>
        )}
      </>
    )
  );
};

CustomLegends.propTypes = {
  data: PropTypes.array,
  filterApplied: PropTypes.bool,
  handleLegendClick: PropTypes.func,
  disabledLegends: PropTypes.array
};

export default CustomLegends;
