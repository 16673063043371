import { attachParams } from "./url-helper";
import { getRequest } from "./http-helper";
export const getWHOverview = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range
  };
  const URL = attachParams(
    `/v1/analytics/warehouses/overview/inventory_table`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getMOMPerformance = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range
  };
  const URL = attachParams(`/v1/analytics/queries/query_by_cost`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getWHOverviewTotals = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range
  };
  const URL = attachParams(`/v1/analytics/warehouses/overview/totals`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};
export const getWHOverviewDateRange = async (wh_id) => {
  const params = { data_wh_id: wh_id ? wh_id : "" };
  const URL = attachParams(
    `/v1/analytics/warehouses/overview/date_ranges`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};
export const getWHRecommendationDateRange = async (wh_id) => {
  const params = { data_wh_id: wh_id ? wh_id : "" };
  const URL = attachParams(
    `/v1/analytics/warehouses/recommendations/date_ranges`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getWHRecommendations = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range
  };
  const URL = attachParams(`/v1/analytics/warehouses/recommendations`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    if (error?.response?.status?.toString() === "404") {
      return {
        text: "Warehouse recommendations will be available within 5-7 days of adding the data source.",
        errorstatus: error?.response?.status?.toString(),
        value: null
      };
    } else {
      return {
        text: "Warehouse recommendations for the selected period or account are currently unavailable.",
        errorstatus: error?.response?.status?.toString(),
        value: null
      };
    }
  } else if (data) {
    return data;
  }
};

export const getRecUpdateDate = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range
  };
  const URL = attachParams(
    `/v1/analytics/warehouses/recommendations/last_updated_date`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};
export const getWIDateRanges = async (acc) => {
  const params = {
    data_wh_id: acc?.data_warehouse_info_id
  };
  const URL = attachParams(`/v2/dbx_warehouse/inventory/date_ranges`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getWIData = async (fields) => {
  const params = {
    data_wh_id: fields?.acc?.data_warehouse_info_id,
    start_date: fields?.start_date,
    end_date: fields?.end_date
  };
  const URL = attachParams(`/v2/dbx_warehouse/inventory/table_data`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
export const getWITotals = async (fields) => {
  const params = {
    data_wh_id: fields?.acc?.data_warehouse_info_id,
    start_date: fields?.start_date,
    end_date: fields?.end_date
  };
  const URL = attachParams(`/v2/dbx_warehouse/inventory/totals`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
