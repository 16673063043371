import React, { useState, useEffect, useContext } from "react";
import "./orgsettings.scss";
import GridComponent from "../../components/GridComponent";
import add_white_outline from "../../assets/images/dashboard/add_white_outline.svg";
import delete_red from "../../assets/images/dashboard/delete_red.svg";
import org_edit from "../../assets/images/common/org_edit.svg";
import {
  addGroupAPI,
  addUser,
  deleteGroupAPI,
  deleteUser,
  editGroupAPI,
  editUser,
  getOrgSettingsGroupData,
  getOrgSettingsUsersData
} from "../../utils/settings-helper";
import { getGroupProps, getOrgProps } from "./orgsettingscoldefs";
import AddUserForm from "../../components/AddUserForm";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { getException } from "../../utils/exceptiondictionary";
import {
  getSessionStorage,
  setSessinStorage
} from "../../utils/storage-helper";
import { AuthContext } from "../../utils/authprovider";
import PropTypes from "prop-types";
import { CustomTooltip } from "../../utils/tooltip-helper";
import { useNavigate } from "react-router-dom";
import { handleGridResolution } from "../../utils/common-helper";

const OrgSettings = ({ dwAccOptions }) => {
  const [dwAccs, setDwAccs] = useState(
    dwAccOptions?.map((item) => {
      return {
        label: item?.display_name,
        value: item?.data_warehouse_info_id,
        id: item?.data_warehouse_info_id
      };
    })
  );
  useEffect(() => {
    if (dwAccOptions && dwAccOptions.length > 0) {
      setDwAccs(
        dwAccOptions?.map((item) => {
          return {
            label: item?.display_name,
            value: item?.data_warehouse_info_id,
            id: item?.data_warehouse_info_id
          };
        })
      );
    }
  }, [dwAccOptions]);
  const navigate = useNavigate();
  const customProps = getOrgProps();
  const customGrpProps = getGroupProps();
  const univerSalStyle = {};
  const toast = useToast();
  const { userInfo, setUserinfo } = useContext(AuthContext);

  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const [rowData, setRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [gridFallback, setGridFallback] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(undefined);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [grpColumnDefs, setGrpColumnDefs] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [grpRowData, setGrpRowData] = useState(null);
  const [gridResolution, setGridResolution] = useState(false);
  const [sumData, setSumData] = useState("0");
  const [activeTab, setActiveTab] = useState("1");
  const tabOptions = [
    { value: "1", label: `Users`, id: "1" },
    { value: "2", label: `Groups`, id: "2" }
  ];

  const tabView = tabOptions?.map((tab) => {
    return (
      <div
        key={tab?.id}
        className={`tab ${tab?.id === activeTab ? "active" : "normal-btn"}`}
        onClick={() => {
          setActiveTab(tab?.id);
          if (tab?.id === "2") {
            setSumData(0);
            setGridLoading(true);
            setRowData(null);
            getGroupsData();
          } else if (tab?.id === "1") {
            setSumData(0);
            setGridLoading(true);
            setGrpRowData(null);
            getData();
            // getGroupsData();
          }
        }}>
        <span>{tab?.label}</span>
      </div>
    );
  });

  const column_display_name_mappping = {
    index: "No",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    role: "Role",
    synced_user: "Is Synced",
    active: "Active",
    access_list: "Account Access",
    Action: "Action"
  };

  const group_column_display_name_mappping = {
    index: "No",
    name: "Group Name",
    role: "Role",
    synced_group: "Is Synced",
    users: "No. of Users",
    created_on: "Created On",
    Action: "Action"
  };

  const changeIsModalLoading = (bool) => {
    setIsModalLoading(bool);
  };

  const openDeleteModal = (data, activeTab) => {
    if (activeTab === "1") {
      changeModalOpen(true, {
        action: "delete",
        id: data?.user_id,
        name: `${data?.first_name} ${data?.last_name}`
      });
    } else {
      changeModalOpen(true, {
        action: "delete",
        id: data?.id,
        name: data?.name
      });
    }
  };

  const openEditModal = (data, tab) => {
    if (tab?.toLowerCase() === "groups") {
      changeModalOpen(true, {
        action: "edit",
        id: data?.id,
        name: data?.name,
        role: data?.role,
        users: data?.users,
        synced: data?.synced_group,
        tab
      });
    } else {
      changeModalOpen(true, {
        action: "edit",
        id: data?.user_id,
        email: data?.email,
        name: `${data?.first_name} ${data?.last_name}`,
        role: data?.role,
        access_list: data?.access_list,
        tab
      });
    }
  };

  const changeModalOpen = (bool, actionObj) => {
    setIsModalOpen({
      status: bool,
      action: actionObj?.action,
      id: actionObj?.id,
      name: actionObj?.name,
      role: actionObj?.role,
      email: actionObj?.email,
      access_list: actionObj?.access_list,
      users: actionObj?.users,
      synced: actionObj?.synced,
      tab: actionObj?.tab
    });
  };
  const closeModal = () => {
    setIsModalOpen(undefined);
  };
  const addUserSettings = async (fields) => {
    try {
      const data = await addUser(fields, customToast, dwAccOptions);
      if (data) {
        changeIsModalLoading(false);
        changeModalOpen(true, { action: "add-success", tab: "Users" });
        setGridLoading(true);
        getTableData();
      } else {
        changeIsModalLoading(false);
        closeModal();
      }
    } catch (err) {
      changeIsModalLoading(false);
      closeModal();
    }
  };
  const addGrpSettings = async (fields) => {
    try {
      const data = await addGroupAPI(fields, customToast);
      if (data) {
        changeIsModalLoading(false);
        changeModalOpen(true, { action: "add-success", tab: "Groups" });
        setGridLoading(true);
        getGroupsTableData();
      } else {
        changeIsModalLoading(false);
        closeModal();
      }
    } catch (err) {
      changeIsModalLoading(false);
      closeModal();
    }
  };
  const editUserSettings = async (id, role, dwaccoptions) => {
    try {
      await editUser(id, role, dwaccoptions, rowData);
      changeIsModalLoading(false);
      const me = JSON.parse(getSessionStorage("me"));
      if (id.toString() === me?.user_id?.toString()) {
        setUserinfo({
          ...userInfo,
          info: {
            ...userInfo?.info,
            me: role?.value?.toLowerCase()
          }
        });
        setSessinStorage("me", { ...me, role: role?.value?.toLowerCase() });
      }
      closeModal();
      if (customToast) {
        customToast({
          type: "success",
          ...getException("USEREDITSUCCESS")
        });
      }
      if (me?.user_id?.toString() === id?.toString()) {
        navigate(0);
      } else {
        setGridLoading(true);
        getTableData();
      }
    } catch (err) {
      console.log(err);
      customToast({
        type: "error",
        ...getException(
          err?.response?.data?.errorKey,
          err?.response?.data?.message
        )
      });
      changeIsModalLoading(false);
      closeModal();
    }
  };
  const editGrpSettings = async (id, role, users, name, synced) => {
    try {
      await editGroupAPI(id, role, users, name, synced);
      changeIsModalLoading(false);
      closeModal();
      if (customToast) {
        customToast({
          type: "success",
          ...getException("GROUPEDITSUCCESS")
        });
      }

      setGridLoading(true);
      getGroupsTableData();
    } catch (err) {
      console.log(err);
      customToast({
        type: "error",
        ...getException(
          err?.response?.data?.errorKey,
          err?.response?.data?.message
        )
      });
      changeIsModalLoading(false);
      closeModal();
    }
  };
  const deleteUserSettings = async (id) => {
    try {
      const data = await deleteUser(id, customToast);
      if (data) {
        changeIsModalLoading(false);
        closeModal();
        setGridLoading(true);
        getTableData();
      } else {
        changeIsModalLoading(false);
        closeModal();
      }
    } catch (err) {
      changeIsModalLoading(false);
      closeModal();
    }
  };
  const deleteGrpSettings = async (id) => {
    try {
      await deleteGroupAPI(id, customToast);
      changeIsModalLoading(false);
      closeModal();
      setGridLoading(true);
      getGroupsTableData();
    } catch (err) {
      changeIsModalLoading(false);
      closeModal();
    }
  };

  const createGrpSettingsColDefs = (
    data,
    customProps,
    univerSalStyle,
    column_display_name_mappping
  ) => {
    let res = [];
    if (data) {
      res = data?.map((item) => {
        let obj = {
          field: String(item)
        };
        if (item)
          if (customProps[item]) {
            obj = {
              ...obj,
              headerName: column_display_name_mappping
                ? column_display_name_mappping[`${item ? item : ""}`]
                : "",
              ...customProps[item]
            };
          }
        if (univerSalStyle) {
          obj = { ...obj, ...univerSalStyle };
        }
        return obj;
      });
      res = res.map((item) =>
        item.field === "role"
          ? {
              ...item,
              cellRenderer: (params) => {
                return (
                  params?.value?.[0].toUpperCase() + params?.value?.slice(1)
                );
              }
            }
          : item?.field === "synced_group"
          ? {
              ...item,
              cellRenderer: (params) => {
                return (
                  <div className="wh_creds">
                    <span className="wh__setup">
                      {params?.value?.toString()}
                    </span>
                  </div>
                );
              }
            }
          : item?.field === "users"
          ? {
              ...item,
              cellRenderer: (params) => {
                return params?.value?.length;
              }
            }
          : item
      );
      const me = JSON.parse(getSessionStorage("me"));
      if (me?.role?.toLowerCase() === "admin") {
        res.push({
          headerName: "Action",
          ...customProps["action"],
          field: "action",
          pinned: "right",
          headerClass: "text-align-center",
          cellRenderer: (params) => {
            return (
              <div className="update__field">
                {userInfo?.info?.role === "admin" ? (
                  <button
                    data-id="2"
                    onClick={() => {
                      openEditModal(params?.data, "Groups");
                    }}
                    className="delete_user">
                    <img src={org_edit} />
                  </button>
                ) : null}
                <button
                  data-id="2"
                  onClick={() => {
                    openDeleteModal(params.data, "2");
                  }}
                  className="delete_user">
                  <img src={delete_red} />
                </button>
              </div>
            );
          }
        });
      }
    }
    return res;
  };

  const createSettingsColDefs = (
    data,
    customProps,
    univerSalStyle,
    column_display_name_mappping
  ) => {
    let res = [];
    if (data) {
      res = data?.map((item) => {
        let obj = {
          field: String(item)
        };
        if (item)
          if (customProps[item]) {
            obj = {
              ...obj,
              headerName: column_display_name_mappping
                ? column_display_name_mappping[`${item ? item : ""}`]
                : "",
              ...customProps[item]
            };
          }
        if (univerSalStyle) {
          obj = { ...obj, ...univerSalStyle };
        }
        return obj;
      });
      res = res.map((item) =>
        item.field === "role"
          ? {
              ...item,
              cellRenderer: (params) => {
                return (
                  params?.value?.[0].toUpperCase() + params?.value?.slice(1)
                );
              }
            }
          : item?.field === "synced_user"
          ? {
              ...item,
              cellRenderer: (params) => {
                return (
                  <div className="wh_creds">
                    <span className="wh__setup">
                      {params?.value?.toString()}
                    </span>
                  </div>
                );
              }
            }
          : item?.field === "active"
          ? {
              ...item,
              cellRenderer: (params) => {
                return (
                  <div className="wh_creds">
                    <span className="wh__setup">
                      {params?.value?.toString()}
                    </span>
                  </div>
                );
              }
            }
          : item?.field === "access_list"
          ? {
              ...item,
              cellRenderer: (params) => {
                let wh_names = null;
                const wh_names_list =
                  params?.value?.dw_id_list && params?.value?.dw_id_list?.length
                    ? params?.value?.dw_id_list?.map((id) => {
                        const obj = dwAccs?.find((o) => {
                          return o?.id?.toString() === id?.toString();
                        });
                        return obj;
                      })
                    : dwAccs;
                if (wh_names_list?.length > 2) {
                  wh_names_list?.forEach((value) => {
                    if (!wh_names) {
                      wh_names = value?.label;
                    } else {
                      wh_names = wh_names + ", " + value?.label;
                    }
                  });
                }
                return (
                  <div className="wh_creds">
                    {wh_names_list?.length <= 0 ? (
                      <span> - </span>
                    ) : wh_names_list?.length === dwAccs?.length ? (
                      <span className="wh__setup">All</span>
                    ) : wh_names_list?.length === 1 ? (
                      <span className="wh__setup">
                        {CustomTooltip(
                          wh_names_list?.[0]?.label,
                          wh_names_list?.[0]?.label,
                          50,
                          "info-tooltip"
                        )}
                      </span>
                    ) : wh_names_list?.length === 2 ? (
                      <div className="wh_creds">
                        <span className="wh__setup">
                          {CustomTooltip(
                            wh_names_list?.[0]?.label,
                            wh_names_list?.[0]?.label,
                            50,
                            "info-tooltip"
                          )}
                        </span>
                        <span className="wh__setup">
                          {CustomTooltip(
                            wh_names_list?.[1]?.label,
                            wh_names_list?.[1]?.label,
                            50,
                            "info-tooltip"
                          )}
                        </span>
                      </div>
                    ) : (
                      <div className="wh_creds">
                        <span className="wh__setup">
                          {CustomTooltip(
                            wh_names_list?.[0]?.label,
                            wh_names_list?.[0]?.label,
                            50,
                            "info-tooltip"
                          )}
                        </span>
                        <span className="wh__setup">
                          {CustomTooltip(
                            wh_names_list?.[1]?.label,
                            wh_names_list?.[1]?.label,
                            50,
                            "info-tooltip"
                          )}
                        </span>
                        <span className="wh__count__setup">
                          {CustomTooltip(
                            wh_names,
                            `+ ${wh_names_list?.length - 2}`,
                            50,
                            "info-tooltip"
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                );
              }
            }
          : item
      );
      res.push({
        headerName: "Login Type",
        ...customProps["login_type"],
        field: "login_type",
        cellRenderer: (params) => {
          return (
            <div className="login_creds">
              {params?.data?.user_password_created ? (
                <div className="login__setup">
                  <span>Password</span>
                </div>
              ) : null}
              {params?.data?.sso_user_created ? (
                <div className="login__setup">
                  <span>SSO</span>
                </div>
              ) : null}
            </div>
          );
        }
      });
      const me = JSON.parse(getSessionStorage("me"));
      if (me?.role?.toLowerCase() === "admin") {
        res.push({
          headerName: "Action",
          ...customProps["action"],
          field: "action",
          pinned: "right",
          headerClass: "text-align-center",
          cellRenderer: (params) => {
            return (
              <div className="update__field">
                {userInfo?.info?.role === "admin" ? (
                  <button
                    data-id="2"
                    onClick={() => {
                      openEditModal(params.data, "Users");
                    }}
                    className="delete_user">
                    <img src={org_edit} />
                  </button>
                ) : null}
                <button
                  data-id="2"
                  onClick={() => {
                    if (params?.data?.synced_user) {
                      if (customToast) {
                        customToast({
                          type: "error",
                          ...{
                            header: "Not Allowed",
                            description: "Synced Users Cannot Be Deleted"
                          }
                        });
                      }
                    } else {
                      openDeleteModal(params.data, "1");
                    }
                  }}
                  className="delete_user">
                  <img src={delete_red} />
                </button>
              </div>
            );
          }
        });
      }
    }
    return res;
  };

  const getGroupsTableData = async () => {
    try {
      const __data = await getOrgSettingsGroupData();
      const { groups_list } = __data;
      if (groups_list) {
        const field_names = [
          "index",
          "name",
          "role",
          "synced_group",
          "users",
          "created_on"
        ];
        groups_list?.forEach((element, index) => {
          element.index = index + 1;
        });
        const colDefsArr = createGrpSettingsColDefs(
          field_names,
          customGrpProps,
          univerSalStyle,
          group_column_display_name_mappping
        );
        if (colDefsArr && groups_list) {
          const newData = colDefsArr.map((colDef) => {
            if (colDef.headerName === "Action") {
              return colDef;
            } else {
              return {
                ...colDef,
                unSortIcon: true,
                sortable: true,
                filter: false
              };
            }
          });
          setGrpColumnDefs(newData);
          let screenInnerWidth = window.innerWidth;
          let gridWidth = Object.values(newData).reduce((sum, prop) => {
            if (typeof prop.width === "number" && !isNaN(prop.width)) {
              return sum + prop.width;
            }
            return sum;
          }, 0);
          setGridResolution(
            handleGridResolution(gridWidth, screenInnerWidth, 360)
          );
          setSumData(groups_list?.length);
          setGrpRowData(groups_list);
        }
      } else {
        clearData();
      }
      setGridLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      console.log(err);
      setGridLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
    }
  };

  const getTableData = async () => {
    try {
      const data = await getOrgSettingsUsersData();
      if (data) {
        const field_names = [
          "index",
          "email",
          "first_name",
          "last_name",
          "role",
          "synced_user",
          "active",
          "access_list"
        ];
        data?.forEach((element, index) => {
          element.index = index + 1;
        });
        const colDefsArr = createSettingsColDefs(
          field_names,
          customProps,
          univerSalStyle,
          column_display_name_mappping
        );
        if (colDefsArr && data) {
          const newData = colDefsArr.map((colDef) => {
            if (colDef.headerName === "Action") {
              return colDef;
            } else {
              return {
                ...colDef,
                unSortIcon: true,
                sortable: true,
                filter: false
              };
            }
          });
          setColumnDefs(newData);
          let screenInnerWidth = window.innerWidth;
          let gridWidth = Object.values(newData).reduce((sum, prop) => {
            if (typeof prop.width === "number" && !isNaN(prop.width)) {
              return sum + prop.width;
            }
            return sum;
          }, 0);
          setGridResolution(
            handleGridResolution(gridWidth, screenInnerWidth, 360)
          );
          setRowData(data);
          setSumData(data?.length);
          setRawData(data);
        }
      } else {
        clearData();
      }
      setGridLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      console.log(err);
      setGridLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
    }
  };

  const clearData = () => {
    setRowData([]);
    setGrpRowData([]);
  };

  const getGroupsData = async () => {
    try {
      getGroupsTableData();
      setGridLoading(false);
      setIsPageLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
      console.log(err);
    }
  };

  const getData = async () => {
    try {
      getTableData();
      setGridLoading(false);
      setIsPageLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
      console.log(err);
    }
  };
  const [breadCrumbs] = useState(false);
  useEffect(() => {
    setIsPageLoading(true);
    setGridLoading(true);
    getData();
  }, []);
  return (
    <>
      {isModalOpen && isModalOpen?.status === true && (
        <AddUserForm
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          changeIsLoading={changeIsModalLoading}
          isLoading={isModalLoading}
          addUserSettings={activeTab === "1" ? addUserSettings : addGrpSettings}
          deleteUserSettings={
            activeTab === "1" ? deleteUserSettings : deleteGrpSettings
          }
          editUserSettings={
            activeTab === "1" ? editUserSettings : editGrpSettings
          }
          dwAccOptions={dwAccs}
          userData={rawData}
        />
      )}
      <div className="org-container overflow-auto">
        {!isPageLoading ? (
          <>
            <div className="header-container">
              <div className="header-block">
                {breadCrumbs ? (
                  <div className="breadcrumbs-container"></div>
                ) : null}
                <div className="header">
                  <span>Organization Settings</span>
                </div>
                <div className="tabs__container">{tabView}</div>
              </div>
            </div>
            <div className="grid-block">
              <div className="grid-header-container">
                <div className="grid-header-block">
                  <div className="grid-header">
                    <span>{`Manage ${activeTab === "1" ? "Users" : "Groups"} ${
                      sumData?.toString() !== "null" &&
                      sumData?.toString() !== "undefined" &&
                      sumData?.toString() !== "0"
                        ? `(${sumData})`
                        : ""
                    }`}</span>
                  </div>
                </div>
                <button
                  onClick={() => {
                    changeModalOpen(true, {
                      action: "add-user",
                      tab: activeTab === "1" ? "Users" : "Groups"
                    });
                  }}
                  className={`btn btn-green-variant login-btn ${
                    activeTab === "1" ? "" : "grp__btn"
                  }`}>
                  <img src={add_white_outline} />{" "}
                  {`Add ${activeTab === "1" ? "User" : "Group"}`}
                </button>
              </div>
              <GridComponent
                columnDefs={activeTab === "1" ? columnDefs : grpColumnDefs}
                rowData={activeTab === "1" ? rowData : grpRowData}
                gridFallback={gridFallback}
                gridStyle={
                  activeTab === "1"
                    ? {
                        height: 400,
                        avoidAutoHeightOverflow:
                          rowData?.length >= 0 && rowData?.length <= 8
                            ? false
                            : true
                      }
                    : {
                        height: 400,
                        avoidAutoHeightOverflow:
                          grpRowData?.length >= 0 && grpRowData?.length <= 8
                            ? false
                            : true
                      }
                }
                gridLoading={gridLoading}
                noRowsText={
                  activeTab === "1"
                    ? "No users available"
                    : "No Groups available"
                }
                suppressColumnsSize={gridResolution}
                customRowHeight={41}
              />
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
OrgSettings.propTypes = {
  dwAccOptions: PropTypes.array
};
export default OrgSettings;
