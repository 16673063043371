import { PrettyFormat } from "../../utils/common-helper";
import { CustomTooltip } from "../../utils/tooltip-helper";

const customPropsChargeBack = [
  {
    width: 80,
    cellClass: "grid-cell-left-aligned  cell-border-right",
    field: "S_No",
    filter: false,
    headerName: "S No",
    sortable: true,
    unSortIcon: true
  },
  {
    width: 300,
    cellClass: "grid-cell-left-aligned cell-border-right",
    field: "Cost Center Name",
    filter: false,
    headerName: "Cost Center Name",
    sortable: true,
    unSortIcon: true,
    cellRenderer: (params) => {
      return CustomTooltip(PrettyFormat(params.value), params.value, 10);
    }
  },
  {
    width: 180,
    cellClass: "grid-cell-left-aligned cell-border-right",
    field: "Account",
    filter: false,
    headerName: "Account",
    sortable: true,
    unSortIcon: true,
    cellRenderer: (params) => {
      return CustomTooltip(PrettyFormat(params.value), params.value, 10);
    }
  },
  {
    width: 150,
    cellClass: "grid-cell-left-aligned cell-border-right",
    field: "User",
    filter: false,
    headerName: "User",
    sortable: true,
    unSortIcon: true,
    cellRenderer: (params) => {
      return CustomTooltip(PrettyFormat(params.value), params.value, 35);
    }
  },
  {
    width: 250,
    cellClass: "grid-cell-left-aligned cell-border-right",
    field: "Warehouse",
    filter: false,
    headerName: "Warehouse",
    sortable: true,
    unSortIcon: true,
    cellRenderer: (params) => {
      return CustomTooltip(PrettyFormat(params.value), params.value, 10);
    }
  },
  {
    width: 250,
    cellClass: "grid-cell-left-aligned cell-border-right",
    field: "Database",
    filter: false,
    headerName: "Database",
    sortable: true,
    unSortIcon: true,
    cellRenderer: (params) => {
      return CustomTooltip(PrettyFormat(params.value), params.value, 10);
    }
  },
  {
    width: 250,
    cellClass: "grid-cell-left-aligned cell-border-right",
    field: "Role",
    filter: false,
    headerName: "Role",
    sortable: true,
    unSortIcon: true,
    cellRenderer: (params) => {
      return CustomTooltip(PrettyFormat(params.value), params.value, 10);
    }
  },
  {
    width: 250,
    cellClass: "grid-cell-left-aligned cell-border-right",
    field: "Owner",
    filter: false,
    headerName: "Owner",
    sortable: true,
    unSortIcon: true,
    cellRenderer: (params) => {
      return CustomTooltip(PrettyFormat(params.value), params.value, 10);
    }
  }
];

export const getChargeBackTableProps = () => {
  return customPropsChargeBack;
};
