import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { DateTime } from "luxon";
import Highcharts from "highcharts";
import addExportingModule from "highcharts/modules/exporting";
import addOfflineExportingModule from "highcharts/modules/offline-exporting";
import { stackGrahpcolors } from "../../../utils/cost-reporting-helper";
import {
  pretty_print_number_with_suffix,
  roundToSigDigits
} from "../../../utils/common-helper";
import { costFormatter } from "../../../utils/organization-helper";
import StackGraph from "../../ChartComponent/StackGraph";

addExportingModule(Highcharts);
addOfflineExportingModule(Highcharts);

function DBXCOverviewGraphContainer({
  chartRef,
  chartData,
  granularity,
  chartTitle,
  filterApplied,
  totals,
  yLabel,
  tabValue,
  legendDetails = { align: "right", verticalAlign: "top", layout: "vertical" },
  enableLegends = true,
  useCostFormatter = true
}) {
  const [options, setOptions] = useState(null);

  useEffect(() => {
    if (chartData && Object?.keys(chartData)?.length) {
      const colors = stackGrahpcolors;
      let finalChartData = JSON.parse(JSON.stringify(chartData));
      let categories = finalChartData.x;
      if (granularity?.value) {
        const currentDate = DateTime.local();

        switch (granularity?.value) {
          case "monthly": {
            categories = categories.map((date) => {
              const dateTime = DateTime.fromISO(date);
              const formattedDate = dateTime.toFormat("LLL yyyy");
              const isCurrentMonth = dateTime.hasSame(currentDate, "month");
              return isCurrentMonth ? `${formattedDate} (MTD)` : formattedDate;
            });
            break;
          }
          case "daily":
          case "weekly": {
            const formatString = "LLL dd, yyyy";
            categories = categories.map((date) =>
              DateTime.fromISO(date).toFormat(formatString)
            );
            break;
          }

          default:
            break;
        }
      }
      if (finalChartData?.y.length > 10 && !filterApplied) {
        let top10 = finalChartData.y.sort((a, b) => b.sum - a.sum).slice(0, 10);

        const calculateTotalSum = (arr) => {
          return _.reduce(
            arr,
            (acc, obj) => {
              _.forEach(obj.data, (value, index) => {
                acc[index] = (acc[index] || 0) + value;
              });
              return acc;
            },
            []
          );
        };

        let T10sum = calculateTotalSum(top10);

        let othersTotal = _.map(T10sum, (sumValue, index) => {
          return Number(totals?.granular?.[index]?.[tabValue]) - sumValue;
        });

        const result = {
          name: "Others",
          data: othersTotal.map((value) => (value < 0 ? 0 : value))
        };

        top10.push(result);

        top10 = top10.map((item, index) => ({
          ...item,
          color: colors[index]
        }));

        finalChartData.y = top10.reverse();
      } else {
        const new_data = finalChartData?.y.map((item, index) => ({
          ...item,
          color: colors[index]
        }));
        finalChartData.y = new_data.reverse();
      }
      setOptions({
        ...StackOptions,
        series: finalChartData?.y,
        xAxis: {
          categories: categories,
          title: {
            text: "Date",
            margin: 20,
            style: {
              color: "#222",
              fontSize: "14px"
            }
          },
          labels: {
            formatter: function () {
              return this?.value;
            },
            style: {
              color: "#7A7A7A"
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: yLabel ?? "Cost ($)",
            x: -10,
            style: {
              color: "#222",
              fontSize: "14px"
            }
          },
          labels: {
            formatter: function () {
              return pretty_print_number_with_suffix(Number(this.value));
            }
          },
          stackLabels: {
            enabled: true,
            align: "center",
            style: {
              color: "#222"
            },
            x: 0,
            verticalAlign: "top",
            formatter: function () {
              return useCostFormatter
                ? costFormatter(roundToSigDigits(this.total, 2, false))
                : roundToSigDigits(this.total, 2, false, false).toLocaleString(
                    "en-US"
                  );
            }
          }
        }
      });
    }
  }, [chartData]);

  const StackOptions = {
    chart: {
      type: "column",

      events: {
        render: function () {
          var chart = this,
            legend = chart.legend,
            series = chart.series,
            group = legend.group;
          for (var i = 0, len = legend?.allItems?.length; i < len; i++) {
            (function (i) {
              var item = legend?.allItems[i]?.legendItem;
              const text = item?.label;
              text
                ?.on("mouseover", function () {
                  item.legendTooltip = chart?.renderer
                    .label(series[series.length - 1 - i]?.name, 0, 0)
                    .css({
                      width: 500,
                      color: "white"
                    })
                    .attr({
                      fill: "black",
                      stroke: "none",
                      padding: 10,
                      zIndex: 99,
                      r: 5
                    })
                    .add(group);
                  item.legendTooltip.attr({
                    x:
                      item.x > Number(window?.innerWidth) / 2
                        ? item?.x -
                          Number(item?.textWidth?.replace("px", "")) +
                          30
                        : item?.x,
                    y: item?.y + 20
                  });
                })
                .on("mouseout", function () {
                  if (item.legendTooltip) {
                    item.legendTooltip.destroy();
                    item.legendTooltip = undefined;
                  }
                })
                .on("click", function () {
                  series[series.length - 1 - i].setVisible(
                    !series[series.length - 1 - i].visible
                  );

                  if (item.legendTooltip) {
                    item.legendTooltip.destroy();
                    item.legendTooltip = undefined;
                  }
                });
            })(i);
          }
        }
      }
    },
    title: {
      text: chartTitle ? chartTitle : ""
    },
    lang: {
      noData: "No Data Available"
    },
    tooltip: {
      backgroundColor: "black",
      shared: true,
      useHTML: true,
      borderColor: "#000000",
      borderRadius: 5, // Add rounded corners to the tooltip
      formatter: function () {
        let str = "";
        let data = this?.points;
        data?.forEach((point) => {
          if (parseInt(roundToSigDigits(point?.y, 2, false)) !== 0) {
            str += `<div style='display:flex; align-items: center; margin-bottom: 10px;'><span style='height: 11px;
          width: 11px;
          background-color: ${point?.color};
          border-radius: 50%;
          display: inline-block;
        '></span> &nbsp;<span>${
          point?.series?.name
        }</span>&nbsp; -&nbsp; <span>${
              useCostFormatter
                ? costFormatter(roundToSigDigits(point?.y, 2, false))
                : roundToSigDigits(point?.y, 2, false, false).toLocaleString(
                    "en-US"
                  )
            }</span><span>&nbsp; -&nbsp;${point?.x}</span></div>`;
          }
        });
        return `<div style="color: white;" >${str}<br/>&nbsp;<span style='font-weight: bold; color: white;'>Total: ${
          useCostFormatter
            ? costFormatter(
                roundToSigDigits(this?.points?.[0]?.total, 2, false)
              )
            : roundToSigDigits(
                this?.points?.[0]?.total,
                2,
                false,
                false
              ).toLocaleString("en-US")
        }</span></div>`;
      }
    },
    legend: {
      enabled: enableLegends,
      reversed: true,
      symbolRadius: 6,
      align: legendDetails?.align,
      verticalAlign: legendDetails?.verticalAlign,
      floating: false,
      useHTML: true,
      layout: legendDetails?.layout,
      margin: 32,
      symbolHeight: 12,
      symbolWidth: 12,
      itemStyle: {
        fontWeight: 400
      },
      labelFormatter: function () {
        return this?.name?.length > 25
          ? `<span data-id=${this?.name}>${this?.name?.slice(0, 24)}...</span>`
          : this?.name;
      }
    },
    plotOptions: {
      series: {
        animation: false
      },
      column: {
        borderWidth: 0,
        stacking: "normal",
        dataLabels: {
          enabled: false
        },
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            enabled: false
          }
        },
        groupPadding: 0.1
      }
    },
    credits: {
      enabled: false
    }
  };

  return <StackGraph chartRef={chartRef} options={options} />;
}

DBXCOverviewGraphContainer.propTypes = {
  chartRef: PropTypes.object,
  chartData: PropTypes.object,
  granularity: PropTypes.object,
  groupBy: PropTypes.object,
  forecastCost: PropTypes?.object,
  chartTitle: PropTypes?.string,
  filterApplied: PropTypes.bool,
  totals: PropTypes.object,
  yLabel: PropTypes.string,
  tabValue: PropTypes.string,
  enableLegends: PropTypes.bool,
  useCostFormatter: PropTypes.bool,
  legendDetails: PropTypes.object
};
export default DBXCOverviewGraphContainer;
