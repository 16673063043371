import React from "react";
import PropTypes from "prop-types";
import "./tabs.scss"

const CustomTabs = (props) => {
  const { tabs, onChange, value } = props;
  const handleClick = (item) => {
    onChange(item);
  };
  return (
    <div className="tabs-container">
      {tabs?.length &&
        tabs?.map((item, index) => (
          <div
            key={index}
            className={`tab ${
              item?.value === value.value ? "active-tab" : "normal-tab"
            }`}
            onClick={() => handleClick(item)}>
            <span>{item?.label}</span>
          </div>
        ))}
    </div>
  );
};
CustomTabs.propTypes = {
  tabs: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.object
};
export default CustomTabs;
