import React from "react";
import { Routes, Route } from "react-router-dom";
import ConnectionFallback from "../pages/ConnectionFallback";
import PropTypes from "prop-types";
import FailedQueries from "../pages/FailedQueries";
import QueryGroups from "../pages/QueryGroups";
import WarehouseOverview from "../pages/WarehouseOverview";
import WarehousesUtilization from "../pages/WarehousesUtilization";
import WHMOMPerformance from "../pages/WHMOMPerformance";
import WHRecommendations from "../pages/WHRecommendations";
import QRecommendations from "../pages/QRecommendations";
import SearchOptimization from "../pages/SearchOptimization";
import QTop from "../pages/QTop";
import TableGroups from "../pages/TableGroups";
import TableOverview from "../pages/TableOverview";
import UnusedTables from "../pages/UnusedTables";
import DroppedTables from "../pages/DroppedTables";
import UserOverview from "../pages/UserOverview";
import UsageInsights from "../pages/UsageInsights";
import CRStorage from "../pages/CRStorage";
import QueryOverview from "../pages/QueryOverview";
import WarehouseInventory from "../pages/WHInventory";
import ChangeLog from "../pages/ChangeLog";
import OrgSettings from "../pages/OrgSettings";
import AlertSettings from "../pages/AlertSettings";
import SlackAlertSettings from "../pages/SlackAlertSettings";
import TeamsAlertSettings from "../pages/TeamAlertSettings";
import QueryDetails from "../pages/QueryDetails";
import Billing from "../pages/Billing";
import SSOSettings from "../pages/SSOSettings";
import Chargeback from "../pages/Chargeback";
import CostReporting from "../pages/CostReporting";
import CommonStreamlitComponent from "../components/CommonStreamlitComponent";
import UserProvisioning from "../pages/UserProvisioning";
import DBXClustersInventory from "../pages/DBXPages/DBXClustersInventory";
import DBXSQLWHInventory from "../pages/DBXPages/DBXSQLWHInventory";
import DBXTop50Jobs from "../pages/DBXPages/Top50Jobs";
import DBXTop50FailedJobs from "../pages/DBXPages/DBXTop50FailedJobs";
import DBXTop50Queries from "../pages/DBXPages/Top50Queries";
import DBXTop50FailedQueries from "../pages/DBXPages/DBXTop50FailedQueries";
import DBXClusterOverview from "../pages/DBXPages/DBXClusterOverview";

const DashboardRoutes = ({
  url,
  token,
  acc,
  changeSlackAlertSetup,
  isSlackAlertSetup,
  isTeamAlertSetup,
  TeamsAlert,
  changeTeamsAlertSetup,
  slackAlert,
  changeSlackAlert,
  changeTeamAlert,
  changeActiveModule,
  dwAccOptions
}) => {
  return acc?.connection_type === "SNOWFLAKE" ? (
    <Routes>
      <Route path="/connectionfallback" element={<ConnectionFallback />} />
      <Route
        path="/compute/overview"
        element={<WarehouseOverview url={url} token={token} acc={acc} />}
      />
      <Route
        path="/compute/inventory"
        element={<WarehouseInventory url={url} token={token} acc={acc} />}
      />
      <Route
        path="/governance/changelog"
        element={<ChangeLog url={url} token={token} acc={acc} />}
      />
      <Route
        path="/compute/utilization"
        element={
          <WarehousesUtilization url={url} token={token} acc={acc} />
        }></Route>
      <Route
        path="/compute/momperformance"
        element={<WHMOMPerformance url={url} token={token} acc={acc} />}
      />
      <Route
        path="/compute/recommendations"
        element={
          <WHRecommendations
            url={url}
            token={token}
            acc={acc}
            changeActiveModule={changeActiveModule}
          />
        }
      />
      <Route
        path="/costexplorer/*"
        index
        element={<CommonStreamlitComponent url={url} token={token} acc={acc} />}
      />
      <Route
        path="/costexplorer/searchoptimization"
        element={<SearchOptimization url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/failed"
        element={
          <FailedQueries
            url={url}
            token={token}
            acc={acc}
            changeActiveModule={changeActiveModule}
          />
        }
      />
      <Route
        path="/workloads/groups"
        element={
          <QueryGroups
            url={url}
            token={token}
            acc={acc}
            changeActiveModule={changeActiveModule}
          />
        }
      />
      <Route
        path="/workloads/recommendations"
        element={<QRecommendations url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/recommendations/:date_range"
        element={<QRecommendations url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/recommendations/:date_range/querydetails/:tab/:data_wh_id/:regex_id"
        element={<QueryDetails url={url} token={token} acc={acc} />}
      />

      <Route
        path="/workloads/top50"
        element={<QTop url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/overview"
        element={<QueryOverview url={url} token={token} acc={acc} />}
      />
      <Route
        path="/storage/overview"
        element={<TableOverview url={url} token={token} acc={acc} />}
      />
      <Route
        path="/storage/groups"
        element={
          <TableGroups
            url={url}
            token={token}
            acc={acc}
            changeActiveModule={changeActiveModule}
          />
        }
      />
      <Route
        path="/storage/unused"
        element={<UnusedTables url={url} token={token} acc={acc} />}
      />
      <Route
        path="/storage/dropped"
        element={<DroppedTables url={url} token={token} acc={acc} />}
      />
      <Route
        path="/governance/overview"
        element={<UserOverview url={url} token={token} acc={acc} />}
      />
      <Route
        path="/governance/usageinsights"
        element={<UsageInsights url={url} token={token} acc={acc} />}
      />
      <Route
        path="/credits/storage"
        element={<CRStorage url={url} token={token} acc={acc} />}
      />
      {["/settings/organization", "/settings/organization/:org"].map(
        (path, index) => {
          return (
            <Route
              path={path}
              element={<OrgSettings dwAccOptions={dwAccOptions} />}
              key={index}
            />
          );
        }
      )}
      {["/settings/sso_configuration"].map((path, index) => {
        return <Route path={path} element={<SSOSettings />} key={index} />;
      })}
      {["/settings/user_provisioning"].map((path, index) => {
        return <Route path={path} element={<UserProvisioning />} key={index} />;
      })}
      {["/settings/alerts"].map((path, index) => {
        return (
          <Route
            path={path}
            element={
              <AlertSettings
                isSlackAlertSetup={isSlackAlertSetup}
                isTeamAlertSetup={isTeamAlertSetup}
                changeSlackAlert={changeSlackAlert}
                changeTeamAlert={changeTeamAlert}
                changeSlackAlertSetup={changeSlackAlertSetup}
                changeTeamsAlertSetup={changeTeamsAlertSetup}
              />
            }
            key={index}
          />
        );
      })}
      {["/settings/billing"].map((path, index) => {
        return <Route path={path} element={<Billing />} key={index} />;
      })}
      <Route
        path="/settings/alerts/slack"
        element={
          <SlackAlertSettings
            changeSlackAlertSetup={changeSlackAlertSetup}
            slackAlert={slackAlert}
          />
        }
      />
      <Route
        path="/settings/alerts/teams"
        element={
          <TeamsAlertSettings
            changeTeamsAlertSetup={changeTeamsAlertSetup}
            TeamsAlert={TeamsAlert}
          />
        }
      />
      <Route
        path="/chargeback/costcenter"
        element={
          <Chargeback
            url={url}
            token={token}
            acc={acc}
            dwAccOptions={dwAccOptions}
            changeActiveModule={changeActiveModule}
          />
        }
      />
      <Route
        path="/chargeback/costreporting"
        element={
          <CostReporting
            url={url}
            token={token}
            acc={acc}
            dwAccOptions={dwAccOptions}
          />
        }
      />
    </Routes>
  ) : (
    <Routes>
      <Route path="/connectionfallback" element={<ConnectionFallback />} />
      <Route
        path="/costexplorer/*"
        index
        element={<CommonStreamlitComponent url={url} token={token} acc={acc} />}
      />
      <Route
        path="/compute/clusters/inventory/*"
        index
        element={
          <DBXClustersInventory
            token={token}
            acc={acc}
            changeActiveModule={changeActiveModule}
          />
        }
      />

      <Route
        path="/compute/clusters/overview/*"
        index
        element={<DBXClusterOverview acc={acc}  />}
      />
      <Route
        path="compute/dbsqlwarehouse/overview/*"
        index
        element={<CommonStreamlitComponent url={url} token={token} acc={acc} />}
      />
      <Route
        path="compute/dbsqlwarehouse/inventory/*"
        index
        element={<DBXSQLWHInventory url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/jobs/overview/*"
        index
        element={<CommonStreamlitComponent url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/jobs/top50/*"
        index
        element={<DBXTop50Jobs url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/jobs/failed/*"
        index
        element={<CommonStreamlitComponent url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/jobs/top50failed/*"
        index
        element={<DBXTop50FailedJobs url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/dbsqlqueries/overview"
        index
        element={<CommonStreamlitComponent url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/dbsqlqueries/top50"
        index
        element={<DBXTop50Queries url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/dbsqlqueries/failed"
        index
        element={<CommonStreamlitComponent url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/dbsqlqueries/top50failed"
        index
        element={<DBXTop50FailedQueries url={url} token={token} acc={acc} />}
      />
      <Route
        path="/workloads/notebook/*"
        index
        element={<CommonStreamlitComponent url={url} token={token} acc={acc} />}
      />
      <Route
        path="/storage/*"
        index
        element={<CommonStreamlitComponent url={url} token={token} acc={acc} />}
      />
      <Route
        path="/recommendations/*"
        index
        element={<CommonStreamlitComponent url={url} token={token} acc={acc} />}
      />
      {["/settings/organization", "/settings/organization/:org"].map(
        (path, index) => {
          return (
            <Route
              path={path}
              element={<OrgSettings dwAccOptions={dwAccOptions} />}
              key={index}
            />
          );
        }
      )}
      {["/settings/sso_configuration"].map((path, index) => {
        return <Route path={path} element={<SSOSettings />} key={index} />;
      })}
      {["/settings/user_provisioning"].map((path, index) => {
        return <Route path={path} element={<UserProvisioning />} key={index} />;
      })}
      {["/settings/alerts"].map((path, index) => {
        return (
          <Route
            path={path}
            element={
              <AlertSettings
                isSlackAlertSetup={isSlackAlertSetup}
                isTeamAlertSetup={isTeamAlertSetup}
                changeSlackAlert={changeSlackAlert}
                changeTeamAlert={changeTeamAlert}
                changeSlackAlertSetup={changeSlackAlertSetup}
                changeTeamsAlertSetup={changeTeamsAlertSetup}
              />
            }
            key={index}
          />
        );
      })}
      {["/settings/billing"].map((path, index) => {
        return <Route path={path} element={<Billing />} key={index} />;
      })}
      <Route
        path="/settings/alerts/slack"
        element={
          <SlackAlertSettings
            changeSlackAlertSetup={changeSlackAlertSetup}
            slackAlert={slackAlert}
          />
        }
      />
      <Route
        path="/settings/alerts/teams"
        element={
          <TeamsAlertSettings
            changeTeamsAlertSetup={changeTeamsAlertSetup}
            TeamsAlert={TeamsAlert}
          />
        }
      />
    </Routes>
  );
};
DashboardRoutes.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  changeModalOpen: PropTypes.func,
  changeSlackAlertSetup: PropTypes.func,
  isSlackAlertSetup: PropTypes.bool,
  slackAlert: PropTypes.object,
  TeamsAlert: PropTypes.object,
  isTeamAlertSetup: PropTypes.bool,
  changeTeamsAlertSetup: PropTypes.func,
  dwAccOptions: PropTypes.array,
  changeSlackAlert: PropTypes.func,
  changeTeamAlert: PropTypes.func,
  changeActiveModule: PropTypes.func
};
export default DashboardRoutes;
