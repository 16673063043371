import React from "react";
import PropTypes from "prop-types";
import Spline from "../../ChartComponent/Spline";
import Select, { components } from "react-select";
import chev_down from "../../../assets/images/common/chev_down.svg";
import { roundToSigDigits } from "../../../utils/common-helper";
import Highcharts from "highcharts";
import { formatDateTime } from "../../../utils/date-helper";

const customStyles = {
  control: (base) => ({
    ...base,
    background: "#FFFFFF",
    borderColor: "#E3E3E8"
  }),
  container: (provided) => ({
    ...provided,
    width: 190
  })
};

const LineChart = ({ lineChartData }) => {
  const options = {
    chart: {
      type: "spline" // Use 'spline' with a lowercase "s"
    },
    title: {
      text: null
    },
    xAxis: {
      type: "datetime",
      tickWidth: 0,
      categories: lineChartData.dates?.map((val) => val),
      tickInterval: Math.ceil(lineChartData.dates.length / 20),
      labels: {
        step: 3,
        formatter: function () {
          return Highcharts.dateFormat("%d %b", this.value);
        }
      },
      gridLineWidth: 0.5, // Width of x-axis grid lines
      gridLineColor: "#F0F0F0" // Color of x-axis grid lines
    },
    tooltip: {
      backgroundColor: "black", // Set tooltip background color to black
      borderRadius: 5, // Add rounded corners to the tooltip
      formatter: function () {
        return `<div style="color: white;"><br/>
        <p>Name - <b>${this.series.name}</b></p>  <br/>
        <p>Cost - <b>$${roundToSigDigits(this.y, 2, false)}</b></p> <br/>
        <p>Date - <b>${formatDateTime(this.x, true, true).replace(
          "00:00",
          ""
        )}</b></p>
      </div>`;
      }
    },
    yAxis: {
      title: {
        text: null
      },
      min: 0,
      labels: {
        formatter: function () {
          return "$" + this.value;
        }
      },
      gridLineWidth: 1, // Width of y-axis grid lines
      gridLineColor: "#F0F0F0" // Color of y-axis grid lines
    },
    plotOptions: {
      series: {
        marker: {
          symbol: "circle", // Set the marker symbol to 'circle'
          enabled: false // Disable individual markers for all series
        },
        states: {
          hover: {
            enabled: true
          }
        }
      }
    },
    legend: {
      align: "left",
      verticalAlign: "top",
      layout: "horizontal",
      itemMarginTop: 4,
      itemMarginBottom: 4,
      symbolWidth: 10,
      symbolHeight: 10,
      symbolRadius: 2,
      maxHeight: 150,
      itemStyle: {
        fontSize: "10px"
      }
    },
    series: lineChartData.data
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  return (
    <div className="spline-chart-container">
      <div className="spline-header-container">
        <div className="title-container select-container ">
          <h5>{lineChartData?.title}</h5>
          <p>{lineChartData?.subTitle}</p>
        </div>
        <div className="filter-container">
          <Select
            value={"Daily"}
            styles={customStyles}
            classNamePrefix="selectcategory date_drop"
            placeholder="Daily"
            isDisabled={true}
            isSearchable={false}
            components={{ DropdownIndicator }}
          />
        </div>
      </div>
      <div className="spline-chart">
        <Spline options={options} />
      </div>
    </div>
  );
};
LineChart.propTypes = {
  lineChartData: PropTypes.object,
  linex: PropTypes.array,
  dateRange: PropTypes.object,
  dateRangeOptions: PropTypes.array
};
export default LineChart;
