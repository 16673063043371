import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./forgotpwd.scss";
import { validateCompanyEmail, validateEmail } from "../../utils/validateemail";
import { Amplify, Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { getException } from "../../utils/exceptiondictionary";
import { useToast } from "react-toast-wnm";
import SignUpSignInFlow from "../../components/SignUpSignInFlow";
import back_arrow from "../../assets/images/common/back_arrow2.svg";
import TextField from "@mui/material/TextField";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import SignInHeader from "../../components/SignInHeader";
import warning from "../../assets/images/common/warning.svg";
import { theme } from "../../utils/sign-helper";
import { getAuthConfig, validateRecaptcha } from "../../utils/user-helper";
import awsExports from "../../aws-exports";
import { isBusinessEmail } from "../../utils/business-email-validator";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { env } from "../../env";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [email, setEmail] = useState(
    location && location.state && location.state.email
      ? location.state.email
      : ""
  );
  const [infoError, setInfoError] = useState({ email: "" });
  const [isLoading, setIsLoading] = useState(false);
  const reRef = useRef(null);

  const navigateTo = (path, attrs = {}) => {
    navigate(path, attrs);
  };

  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const getLoginOptions = async () => {
    try {
      const customProvider = await getAuthConfig(email?.toLowerCase());
      return customProvider;
    } catch (error) {
      console.log(error);
      customToast({
        type: "error",
        ...getException(error?.response?.data?.errorKey)
      });
      setIsLoading(false);
    }
  };

  const handleReset = async () => {
    let valid = {};
    try {
      if (reRef && reRef?.current) {
        const token = await reRef?.current?.executeAsync();
        valid = await validateRecaptcha(token);
      }
      if (valid) {
        const customProvider = await getLoginOptions();
        const { cognito_app_client_id } = customProvider;
        if (Object(customProvider).length === 0) {
          Amplify.configure({
            ...awsExports
          });
        } else {
          if (cognito_app_client_id) {
            Amplify.configure({
              ...awsExports,
              aws_user_pools_web_client_id: cognito_app_client_id
            });
          }
        }
        Auth.forgotPassword(email?.trim()?.toLowerCase())
          .then(() => {
            navigateTo("/resetpassword", {
              state: {
                signUpState: "resetpassword",
                email: email.trim()
              }
            });
            //navigateTo("resetpassword");
            setIsLoading(false);
          })
          .catch((e) => {
            if (e.name == "LimitExceededException") {
              customToast({
                type: "error",
                ...getException("PasswordLimitExceededException")
              });
            } else {
              customToast({ type: "error", ...getException(e.name) });
            }
            setIsLoading(false);
          });
      } else {
        customToast({ type: "error", ...getException("CAPTCHA_INVALID") });
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    let errObj = { ...infoError };
    if (email.trim() === "") {
      errObj["email"] = "Please enter email";
    }
    if (email && !validateEmail(email.trim())) {
      errObj["email"] = "Please enter valid email";
    }
    if (email.trim() !== "" && validateCompanyEmail(email.trim())) {
      errObj["email"] = "Please provide company email id";
    }
    if (!isBusinessEmail(email?.trim())) {
      errObj["email"] = "Please provide company email id";
    }
    setInfoError(errObj);
    if (errObj["email"] === "") {
      setIsLoading(true);
      handleReset();
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="parent-container">
        <SignUpSignInFlow welcome />
        <div className="forgotpwd-container">
          <SignInHeader />
          <div className="block">
            <div className="forgotpwd-parent-block">
              <div className="forgotpwd-parent">
                <div className="heading-back">
                  <div
                    className="back-btn"
                    onClick={() => navigateTo("/signin")}>
                    <img src={back_arrow} />
                    <span>Back</span>
                  </div>
                </div>
                <div className="heading">
                  <span>Reset your password</span>
                </div>
                <div className="subheading">
                  <span>Enter your email for verification</span>
                </div>
                <form className="forgotpwd-list" onSubmit={handleClick}>
                  <TextField
                    id="forgotpwdemail"
                    label="Email Address"
                    variant="outlined"
                    autoComplete="email"
                    fullWidth
                    FormHelperTextProps={{
                      className: "email-error",
                      sx: { mt: "6px", ml: 0 }
                    }}
                    value={email}
                    onChange={(e) => {
                      if (infoError["email"] !== "") {
                        setInfoError({ ...infoError, email: "" });
                      }
                      setEmail(e.target.value);
                    }}
                    error={infoError.email !== ""}
                    helperText={
                      <>
                        {infoError.email !== "" && (
                          <img src={warning} alt="warning" />
                        )}
                        <span>{infoError.email}</span>
                      </>
                    }
                    className="w-100"
                  />
                  <input
                    type="submit"
                    value="Continue"
                    className="btn btn-green-variant__login w-100 login-btn"
                  />
                  {env?.REACT_APP_SITEKEY ? (
                    <ReCAPTCHA
                      className="g__recaptcha"
                      sitekey={env?.REACT_APP_SITEKEY} //take from env
                      size="invisible"
                      ref={reRef}
                      //onChange={onChange}
                    />
                  ) : null}
                </form>
              </div>
            </div>
          </div>
          {isLoading && (
            <div className="load">
              <div className="preload"></div>
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};
export default ForgotPassword;
