import React, { useEffect } from "react";
import "./viewcontroller.scss";
import DashboardRoutes from "../../routes/DashboardRoutes";
import PropTypes from "prop-types";
// import plus_add from "../../assets/images/dashboard/plus_add.svg";
// import { Link, useNavigate } from "react-router-dom";

const ViewController = ({
  headerMenu,
  activePage,
  activeModule,
  changeActiveModule,
  changeActivePage,
  profileMenu,
  acc,
  dwAccOptions,
  activeSubmodule,
  changeActiveSubmodule,
  url,
  hasSidebar,
  token,
  changeSlackAlertSetup,
  changeSlackAlert,
  changeTeamAlert,
  isSlackAlertSetup,
  slackAlertLoading,
  changeTeamsAlertSetup,
  isTeamAlertSetup,
  teamsAlertLoading,
  TeamsAlert,
  slackAlert,
  userInfo
}) => {
  let pages = undefined;
  let module = headerMenu?.find((menu) => {
    return (
      menu.page_display_name === activeModule &&
      menu?.dw_type === acc?.connection_type
    );
  });
  if (module) {
    pages = module?.children;
  } else {
    module = profileMenu?.find((menu) => {
      return menu.page_display_name === activeModule;
    });
    pages = module?.children;
  }
  useEffect(() => {
    if (!activeSubmodule) {
      const active_page = pages?.find((page) => {
        return page?.page_display_name === activePage;
      });
      changeActiveSubmodule(active_page?.submodule_name);
    }
  }, []);
  const submoduleView =
    pages &&
    pages?.map((page, index) => {
      if (page?.isHidden) {
        return null;
      } else if (page?.module_name === "Settings") {
        return (
          <div
            onClick={() => {
              if (
                userInfo?.info?.redirect_to_billing?.toString() === "false" ||
                (page?.module_name === "Settings" &&
                  page?.page_display_name === "Organization Settings")
              ) {
                changeActivePage(
                  page?.page_display_name,
                  page?.noUrl ? "" : page?.page_url,
                  page?.module_name,
                  page?.submodule_name ? page?.submodule_name : ""
                );
              } else {
                changeActivePage("Billing", "", "Settings");
              }
            }}
            className={`item ${
              activePage === page?.page_display_name ? "active" : ""
            }`}
            key={index}>
            <span>{page?.page_display_name}</span>
            <div className="underline_div"></div>
          </div>
        );
      } else if (
        page?.dw_type === "DATABRICKS" &&
        page?.submodule_name !== activeSubmodule
      ) {
        return null;
      } else {
        return (
          <div
            onClick={() => {
              if (
                userInfo?.info?.redirect_to_billing?.toString() === "false" ||
                (page?.module_name === "Settings" &&
                  page?.page_display_name === "Organization Settings")
              ) {
                changeActivePage(
                  page?.page_display_name,
                  page?.noUrl ? "" : page?.page_url,
                  page?.module_name,
                  page?.submodule_name ? page?.submodule_name : ""
                );
              } else {
                changeActivePage("Billing", "", "Settings");
              }
            }}
            className={`item ${
              activePage === page?.page_display_name ? "active" : ""
            }`}
            key={index}>
            <span>{page?.page_display_name}</span>
            <div className="underline_div"></div>
          </div>
        );
      }
    });
  return (
    <div className={`view-ctrl-container ${hasSidebar ? "" : "no-sidebar"}`}>
      <div className="viewctrl-header-container">
        <div className="header-block">
          <span>{activeModule}</span>
        </div>
      </div>
      <div className="ctrl-main-block">
        {submoduleView && submoduleView?.length ? (
          <div className="submodule-header">
            <div className="absolute-submodule">{submoduleView}</div>
          </div>
        ) : null}
        <DashboardRoutes
          url={url}
          token={token}
          acc={acc}
          changeSlackAlertSetup={changeSlackAlertSetup}
          isSlackAlertSetup={isSlackAlertSetup}
          slackAlertLoading={slackAlertLoading}
          changeTeamAlert={changeTeamAlert}
          changeSlackAlert={changeSlackAlert}
          slackAlert={slackAlert}
          changeTeamsAlertSetup={changeTeamsAlertSetup}
          isTeamAlertSetup={isTeamAlertSetup}
          teamsAlertLoading={teamsAlertLoading}
          TeamsAlert={TeamsAlert}
          dwAccOptions={dwAccOptions}
          changeActiveModule={changeActiveModule}
        />
      </div>
    </div>
  );
};
ViewController.propTypes = {
  headerMenu: PropTypes.array,
  activeModule: PropTypes.string,
  activePage: PropTypes.string,
  changeActiveModule: PropTypes.func,
  changeActivePage: PropTypes.func,
  isMenuLoading: PropTypes.bool,
  acc: PropTypes.object,
  dwAccOptions: PropTypes.array,
  changeAcc: PropTypes.func,
  url: PropTypes.string,
  token: PropTypes.string,
  changeModalOpen: PropTypes.func,
  changeSlackAlertSetup: PropTypes.func,
  isSlackAlertSetup: PropTypes.bool,
  slackAlertLoading: PropTypes.bool,
  slackAlert: PropTypes.object,
  changeTeamsAlertSetup: PropTypes.func,
  isTeamAlertSetup: PropTypes.bool,
  teamsAlertLoading: PropTypes.bool,
  TeamsAlert: PropTypes.object,
  profileMenu: PropTypes.array,
  userInfo: PropTypes.object,
  hasSidebar: PropTypes.bool,
  changeTeamAlert: PropTypes.func,
  changeSlackAlert: PropTypes.func,
  activeSubmodule: PropTypes?.string,
  changeActiveSubmodule: PropTypes?.func
};

export default ViewController;
