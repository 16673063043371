import React, { useState } from "react";
import PropTypes from "prop-types";
import eyeCon from "../../assets/images/common/eye.svg";
import eyeConSlash from "../../assets/images/common/eye-slash.svg";
import info_img from "../../assets/images/common/info.svg";
import external_link from "../../assets/images/common/external_link.svg";
import "./connectionform.scss";
import { CustomTooltip } from "../../utils/tooltip-helper";
import Select, { components } from "react-select";
import drpdwn from "../../assets/images/common/drpdwn_indicator.svg";
//import { TextareaAutosize } from "@mui/material";
import { InputTextarea } from "primereact/inputtextarea";
const ConnectionEdit = ({
  connection,
  closeModal,
  setupConnection,
  changeIsLoading,
  editFields,
  deleteConnection,
  status
}) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    multiValue: (styles, { data }) => {
      if (data?.label === "Select All") {
        return {
          ...styles,
          display: "none"
        };
      } else {
        return {
          ...styles
        };
      }
    },
    container: (provided) => {
      return {
        ...provided
        //width: 180
      };
    }
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={drpdwn} />
      </components.DropdownIndicator>
    );
  };
  const authOptions = [
    { label: "User + Password", value: "PASSWORD" },
    { label: "Key Pair", value: "KEY_PAIR" }
  ];
  const dbxOptions = [
    { value: "AZURE", label: "AZURE" },
    { value: "AWS", label: "AWS" },
    { value: "GCP", label: "GCP" }
  ];
  const getDbxOption = (value) => {
    const res = dbxOptions?.find((item) => {
      return item?.value?.toLowerCase() === value?.toLowerCase();
    });
    return res;
  };
  const [fields, setFields] = useState(
    connection?.name?.toLowerCase() === "snowflake"
      ? {
          accname: editFields?.data?.host,
          conname: editFields?.data?.display_name,
          role: editFields?.data?.role,
          warehouse: editFields?.data?.warehouse,
          database: editFields?.data?.database,
          username: editFields?.data?.user,
          auth_method:
            editFields?.data?.authentication_type === "PASSWORD"
              ? { label: "User + Password", value: "PASSWORD" }
              : { label: "Key Pair", value: "KEY_PAIR" },
          pword: "",
          pvt_key: "",
          enc_pwd: ""
        }
      : {
          acc_id: editFields?.data?.account_id,
          accname: editFields?.data?.host,
          conname: editFields?.data?.display_name,
          role: editFields?.data?.role,
          warehouse: editFields?.data?.warehouse,
          database: editFields?.data?.database,
          username: editFields?.data?.user,
          dbx_pwd: "",
          connection_type: "DATABRICKS",
          connection_cloud: getDbxOption(editFields?.data?.connection_cloud)
        }
  );
  const [infoError, setInfoError] = useState(
    connection?.name?.toLowerCase() === "snowflake"
      ? {
          accname: "",
          conname: "",
          role: "",
          warehouse: "",
          auth_method: "",
          database: "",
          username: "",
          pword: "",
          pvt_key: "",
          enc_pwd: ""
        }
      : {
          acc_id: "",
          accname: "",
          conname: "",
          role: "",
          warehouse: "",
          database: "",
          username: "",
          dbx_pwd: "",
          connection_type: "",
          connection_cloud: ""
        }
  );
  const [eyeSlash, setEyeSlash] = useState(false);
  const [pwdType, setPwdType] = useState("password");
  const [encEyeSlash, setEncEyeSlash] = useState(false);
  const [encPwdType, setEncPwdType] = useState("password");
  const [dbxPwdType, setDbxPwdType] = useState("password");
  const [dbxEyeSlash, setDbxEyeSlash] = useState(false);
  const clearFields = () => {
    setFields({
      accname: editFields?.data?.host,
      conname: editFields?.data?.display_name,
      role: editFields?.data?.role,
      warehouse: editFields?.data?.warehouse,
      auth_method:
        editFields?.data?.authentication_type === "PASSWORD"
          ? { label: "User + Password", value: "PASSWORD" }
          : { label: "Key Pair", value: "KEY_PAIR" },
      database: editFields?.data?.database,
      username: editFields?.data?.user,
      pvt_key: "",
      enc_pwd: "",
      pword: ""
    });
  };
  const [finalFields, setFinalFields] = useState(
    connection?.name?.toLowerCase() === "snowflake"
      ? {
          auth_method:
            editFields?.data?.authentication_type === "PASSWORD"
              ? { label: "User + Password", value: "PASSWORD" }
              : { label: "Key Pair", value: "KEY_PAIR" }
        }
      : {}
  );
  const handleClick =
    connection?.name?.toLowerCase() === "snowflake"
      ? () => {
          let errorObj = { ...infoError };
          if (fields.accname === "") {
            errorObj["accname"] = "Please enter account identifier";
          }
          if (fields.conname === "") {
            errorObj["conname"] = "Please enter connection name";
          }
          if (fields.role === "") {
            errorObj["role"] = "Please enter role";
          }
          if (fields.warehouse === "") {
            errorObj["warehouse"] = "Please enter warehouse";
          }
          if (fields.auth_method?.value === "") {
            errorObj["auth_method"] = "Please enter authentication method";
          }
          if (fields.database === "") {
            errorObj["database"] = "Please enter database";
          }
          if (fields?.auth_method?.label === "User + Password") {
            if (fields.username === "") {
              errorObj["username"] = "Please enter username";
            }
            // if (fields.pword === "") {
            //   errorObj["pword"] = "Please enter password";
            // }
          }
          // if (fields?.auth_method?.label === "Key Pair") {
          //   if (fields.pvt_key === "") {
          //     errorObj["pvt_key"] = "Please enter private key";
          //   }
          // }
          setInfoError(errorObj);
          if (fields?.auth_method?.label === "User + Password") {
            if (
              errorObj.accname === "" &&
              errorObj.conname === "" &&
              errorObj.role === "" &&
              errorObj.warehouse === "" &&
              errorObj?.auth_method === "" &&
              errorObj.username === "" &&
              errorObj.database === "" &&
              errorObj.pword === ""
            ) {
              changeIsLoading(true);
              setupConnection(finalFields);
              closeModal();
            }
          } else if (fields?.auth_method?.label === "Key Pair") {
            if (
              errorObj.accname === "" &&
              errorObj.conname === "" &&
              errorObj.role === "" &&
              errorObj.warehouse === "" &&
              errorObj?.auth_method === "" &&
              errorObj?.pvt_key === "" &&
              errorObj.pword === ""
            ) {
              changeIsLoading(true);
              setupConnection(finalFields);
              closeModal();
            }
          }
        }
      : () => {
          let errorObj = { ...infoError };
          if (fields.acc_id === "") {
            errorObj["acc_id"] = "Please enter account identifier";
          }
          if (fields.conname === "") {
            errorObj["conname"] = "Please enter connection name";
          }
          if (fields.username === "") {
            errorObj["username"] = "Please enter client ID";
          }
          if (fields.role === "") {
            errorObj["role"] = "Please enter Service Principal ID";
          }
          // if (fields.dbx_pwd === "") {
          //   errorObj["dbx_pwd"] =
          //     "Please enter the Service Principal Client Secret";
          // }
          if (fields.database === "") {
            errorObj["database"] = "Please enter catalog name";
          }
          if (fields.warehouse === "") {
            errorObj["warehouse"] = "Please enter DBSQL Warehouse ID";
          }
          if (fields.accname === "") {
            errorObj["accname"] = "Please enter Warehouse Workspace URL";
          }

          setInfoError(errorObj);
          if (
            errorObj.acc_id === "" &&
            errorObj?.accname === "" &&
            errorObj?.conname === "" &&
            errorObj.role === "" &&
            errorObj.dbx_pwd === "" &&
            errorObj.database === "" &&
            errorObj.warehouse === ""
          ) {
            changeIsLoading(true);
            setupConnection(finalFields);
            closeModal();
          }
        };
  const fieldList =
    connection?.name?.toLowerCase() === "snowflake"
      ? [
          {
            id: "1",
            name: "conname",
            label: "Connection Name",
            type: "text",
            placeholder: "Enter Connection Name",
            value: fields.conname,
            isDisabled: false,
            onChange: (e) => {
              if (infoError["conname"] !== "") {
                setInfoError({ ...infoError, conname: "" });
              }
              setFields({ ...fields, conname: e.target.value });
              setFinalFields({ ...finalFields, conname: e.target.value });
            }
          },
          {
            id: "2",
            name: "accname",
            label: "Account Identifier",
            type: "text",
            placeholder: "Enter Account Identifier",
            value: fields.accname,
            isDisabled: true,
            desc: "Account Identifier of the snowflake instance (might include account, region, cloud environment).",
            additionalInfo: (
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.snowflake.com/en/user-guide/admin-account-identifier.html">
                Read More <img src={external_link} />
              </a>
            ),
            onChange: (e) => {
              if (infoError["accname"] !== "") {
                setInfoError({ ...infoError, accname: "" });
              }
              setFields({ ...fields, accname: e.target.value });
              setFinalFields({ ...finalFields, accname: e.target.value });
            }
          },
          {
            id: "3",
            name: "role",
            label: "Role",
            type: "text",
            placeholder: "Enter Role",
            isDisabled: false,
            desc: "The role you created for Chaos Genius to access Snowflake.",
            value: fields.role,
            onChange: (e) => {
              if (infoError["role"] !== "") {
                setInfoError({ ...infoError, role: "" });
              }
              setFields({ ...fields, role: e.target.value });
              setFinalFields({ ...finalFields, role: e.target.value });
            }
          },
          {
            id: "4",
            name: "warehouse",
            label: "Warehouse",
            type: "text",
            placeholder: "Enter Warehouse",
            value: fields.warehouse,
            isDisabled: false,
            desc: "The warehouse you created for Chaos Genius to access data into.",
            onChange: (e) => {
              if (infoError["warehouse"] !== "") {
                setInfoError({ ...infoError, warehouse: "" });
              }
              setFields({ ...fields, warehouse: e.target.value });
              setFinalFields({ ...finalFields, warehouse: e.target.value });
            }
          },
          {
            id: "5",
            name: "username",
            label: "User name",
            type: "text",
            placeholder: "Enter User name",
            value: fields.username,
            isDisabled: false,
            //hidden: fields?.auth_method?.label !== "User + Password",
            desc: "The username you created to allow Chaos Genius to access the database.",
            onChange: (e) => {
              if (infoError["username"] !== "") {
                setInfoError({ ...infoError, username: "" });
              }
              setFields({ ...fields, username: e.target.value });
              setFinalFields({ ...finalFields, username: e.target.value });
            }
          },
          {
            id: "6",
            name: "auth_method",
            label: "Authentication Method",
            type: "dropdown",
            options: authOptions,
            placeholder: "Select Authentication Method",
            isDisabled: false,
            value: fields?.auth_method,
            onChange: (e) => {
              if (infoError["auth_menthod"] !== "") {
                setInfoError({ ...infoError, auth_method: "" });
              }
              setFields({
                ...fields,
                auth_method: e
              });
              setFinalFields({
                ...finalFields,
                auth_method: e
              });
            }
          },

          {
            id: "7",
            name: "pword",
            label: "Password",
            textType: "password",
            type: pwdType,
            eyeSlash: eyeSlash,
            placeholder: "Enter Password",
            value: fields.pword,
            hidden: fields?.auth_method?.label !== "User + Password",
            isDisabled: false,
            desc: "Password associated with the username",
            onChange: (e) => {
              if (infoError["pword"] !== "") {
                setInfoError({ ...infoError, pword: "" });
              }
              setFields({ ...fields, pword: e.target.value });
              setFinalFields({ ...finalFields, pword: e.target.value });
            }
          },
          {
            id: "8",
            name: "pvt_key",
            label: "Private Key",
            type: "textarea",
            placeholder: "Enter Private key",
            value: fields.pvt_key,
            isDisabled: false,
            hidden: fields?.auth_method?.label !== "Key Pair",
            desc: "",
            onPaste: (event) => {
              event.preventDefault();
              const pastedText = event.clipboardData.getData("Text");
              if (infoError["pvt_key"] !== "") {
                setInfoError({ ...infoError, pvt_key: "" });
              }
              setFields({ ...fields, pvt_key: pastedText });
              setFinalFields({ ...finalFields, pvt_key: pastedText });
            },
            onChange: (e) => {
              if (infoError["pvt_key"] !== "") {
                setInfoError({ ...infoError, pvt_key: "" });
              }
              setFields({ ...fields, pvt_key: e.target.value });
              setFinalFields({ ...finalFields, pvt_key: e.target.value });
            }
          },
          {
            id: "9",
            name: "enc_pwd",
            label: "Private Key Encryption Password (Optional)",
            type: encPwdType,
            textType: "password",
            eyeSlash: encEyeSlash,
            placeholder: "Enter Private Key Encryption Password",
            value: fields.enc_pwd,
            hidden: fields?.auth_method?.label !== "Key Pair",
            isDisabled: false,
            desc: "Password associated with the Private Key",
            onChange: (e) => {
              setFields({ ...fields, enc_pwd: e.target.value });
              setFinalFields({ ...finalFields, enc_pwd: e.target.value });
            }
          },
          {
            id: "10",
            name: "database",
            label: "Database",
            type: "text",
            placeholder: "Enter Database",
            isDisabled: true,
            value: fields.database,
            desc: "Snowflake Meta Information database having the account usage details.Keep this unchanged unless you are creating replicated database from snowflake shared database.",
            onChange: (e) => {
              if (infoError["database"] !== "") {
                setInfoError({ ...infoError, database: "" });
              }
              setFields({ ...fields, database: e.target.value });
              setFinalFields({ ...finalFields, database: e.target.value });
            }
          }
        ]
      : [
          {
            id: "1",
            name: "acc_id",
            label: "Databricks Account ID",
            type: "text",
            placeholder: "Enter Databricks Account ID",
            value: fields.acc_id,
            isDisabled: true,
            desc: "",
            onChange: (e) => {
              if (infoError["acc_id"] !== "") {
                setInfoError({ ...infoError, acc_id: "" });
              }
              setFields({ ...fields, acc_id: e.target.value });
              setFinalFields({ ...finalFields, acc_id: e.target.value });
            }
          },
          {
            id: "2",
            name: "conname",
            label: "Connection Name",
            type: "text",
            placeholder: "Enter Connection Name",
            value: fields.conname,
            isDisabled: false,
            desc: "",
            onChange: (e) => {
              if (infoError["conname"] !== "") {
                setInfoError({ ...infoError, conname: "" });
              }
              setFields({ ...fields, conname: e.target.value });
              setFinalFields({ ...finalFields, conname: e.target.value });
            }
          },
          {
            id: "3",
            name: "username",
            label: "Service Principal Client ID",
            type: "text",
            placeholder: "Enter Service Principal Client ID",
            value: fields.username,
            isDisabled: false,
            desc: "",
            onChange: (e) => {
              if (infoError["username"] !== "") {
                setInfoError({ ...infoError, username: "" });
              }
              setFields({ ...fields, username: e.target.value });
              setFinalFields({ ...finalFields, username: e.target.value });
            }
          },
          {
            id: "4",
            name: "role",
            label: "Service Principal ID",
            type: "text",
            placeholder: "Enter Service Principal ID",
            value: fields.role,
            isDisabled: false,
            desc: "",
            onChange: (e) => {
              if (infoError["role"] !== "") {
                setInfoError({ ...infoError, role: "" });
              }
              setFields({ ...fields, role: e.target.value });
              setFinalFields({ ...finalFields, role: e.target.value });
            }
          },
          {
            id: "5",
            name: "dbx_pwd",
            label: "Service Principal Client Secret",
            type: dbxPwdType,
            textType: "password",
            eyeSlash: dbxEyeSlash,
            placeholder: "Enter Service Pricipal Client Secret",
            isDisabled: false,
            desc: "",
            value: fields.dbx_pwd,
            onChange: (e) => {
              if (infoError["pword"] !== "") {
                setInfoError({ ...infoError, dbx_pwd: "" });
              }
              setFields({ ...fields, dbx_pwd: e.target.value });
              setFinalFields({ ...finalFields, dbx_pwd: e.target.value });
            }
          },
          {
            id: "6",
            name: "database",
            label: "Catalog Name",
            type: "text",
            placeholder: "Enter Catalog Name",
            value: fields.database,
            isDisabled: false,
            desc: "",
            onChange: (e) => {
              console.log("hello");
              if (infoError["database"] !== "") {
                setInfoError({ ...infoError, database: "" });
              }
              setFields({ ...fields, database: e.target.value });
              setFinalFields({ ...finalFields, database: e.target.value });
            }
          },
          {
            id: "7",
            name: "warehouse",
            label: "DBSQL Warehouse ID",
            type: "text",
            placeholder: "Enter DBSQL Warehouse ID",
            value: fields.warehouse,
            isDisabled: false,
            desc: "",
            onChange: (e) => {
              if (infoError["warehouse"] !== "") {
                setInfoError({ ...infoError, warehouse: "" });
              }
              setFields({ ...fields, warehouse: e.target.value });
              setFinalFields({ ...finalFields, warehouse: e.target.value });
            }
          },
          {
            id: "8",
            name: "accname",
            label: "Workspace URL Where the DBSQL Warehouse is created",
            type: "text",
            placeholder: "Enter Workspace URL",
            value: fields.accname,
            isDisabled: false,
            desc: "",
            onChange: (e) => {
              if (infoError["accname"] !== "") {
                setInfoError({ ...infoError, accname: "" });
              }
              setFields({ ...fields, accname: e.target.value });
              setFinalFields({ ...finalFields, accname: e.target.value });
            }
          },
          {
            id: "9",
            name: "connection_cloud",
            label: "Connection Cloud",
            options: dbxOptions,
            type: "dropdown",
            placeholder: "Enter Connection Cloud",
            value: fields?.connection_cloud,
            isDisabled: false,
            desc: "",
            onChange: (e) => {
              if (infoError["connection_cloud"] !== "") {
                setInfoError({ ...infoError, connection_cloud: "" });
              }
              setFields({ ...fields, connection_cloud: e });
              setFinalFields({
                ...finalFields,
                connection_cloud: e
              });
            }
          }
        ];
  const fieldView = fieldList?.map((field) => {
    return field?.hidden ? null : (
      <div className="form-group" key={field.id}>
        <div className="label-parent">
          <div className="label-container">
            <label htmlFor={field.name}>{field.label}</label>
            {field?.desc ? (
              <div className="img-container">
                {CustomTooltip(
                  field?.desc,
                  <img className="info" src={info_img} />,
                  10,
                  "info-tooltip",
                  "right"
                )}
              </div>
            ) : null}
          </div>
          {field?.additionalInfo ? field?.additionalInfo : null}
        </div>
        {field.textType === "password" ? (
          <div className="cg-relative-position">
            <input
              type={field.type}
              className="form-control"
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              // aria-describedby="emailHelp"
              placeholder={field.placeholder}
            />
            {field?.eyeSlash ? (
              <img
                className="cg-eye cg-absolute-position"
                src={eyeCon}
                aria-hidden="true"
                onClick={() => {
                  toggleSlash(field);
                }}
              />
            ) : (
              <img
                className="cg-eye cg-absolute-position"
                src={eyeConSlash}
                aria-hidden="true"
                onClick={() => {
                  toggleSlash(field);
                }}
              />
            )}
          </div>
        ) : field?.type === "dropdown" ? (
          <div className="select-container">
            <Select
              value={field?.value}
              options={field?.options}
              styles={customStyles}
              isDisabled={field?.isDisabled}
              classNamePrefix="selectcategory"
              placeholder="select"
              isSearchable={false}
              components={{ DropdownIndicator }}
              onChange={field?.onChange}
            />
          </div>
        ) : field?.type === "textarea" ? (
          <InputTextarea
            className="txt__area"
            placeholder="Enter Private key"
            onChange={field?.onChange}
            onPaste={field?.onPaste}
            value={field?.value}
          />
        ) : (
          <input
            type={field.type}
            className="form-control"
            id={field.name}
            onChange={field.onChange}
            value={field.value}
            disabled={field?.isDisabled}
            // aria-describedby="emailHelp"
            placeholder={field.placeholder}
          />
        )}
        {infoError[`${field.name}`] !== "" ? (
          <div className="connection__fail">
            <p>{infoError[`${field.name}`]}</p>
          </div>
        ) : null}
      </div>
    );
  });
  const handleDelete = () => {
    deleteConnection({ data_wh_id: connection?.id });
    closeModal();
  };

  const toggleSlash = (field) => {
    if (field?.name === "pword") {
      setEyeSlash(!eyeSlash);
      if (pwdType && pwdType === "password") {
        setPwdType("text");
      } else if (pwdType && pwdType === "text") {
        setPwdType("password");
      }
    } else if (field?.name === "enc_pwd") {
      setEncEyeSlash(!encEyeSlash);
      if (encPwdType && encPwdType === "password") {
        setEncPwdType("text");
      } else if (encPwdType && encPwdType === "text") {
        setEncPwdType("password");
      }
    } else if (field?.name === "dbx_pwd") {
      setDbxEyeSlash(!dbxEyeSlash);
      if (dbxPwdType && dbxPwdType === "password") {
        setDbxPwdType("text");
      } else if (dbxPwdType && dbxPwdType === "text") {
        setDbxPwdType("password");
      }
    }
  };

  return (
    <>
      <div className="modal-container"></div>
      <div className="form-container">
        <div className="form-parent">
          {status === "edit" ? (
            <div className="form-header">
              <div className="header">
                <span>
                  Edit{" "}
                  {connection?.name === "DATABRICKS"
                    ? "Databricks"
                    : "Snowflake"}{" "}
                  Integration
                </span>
              </div>
              <div
                className="close-img"
                onClick={() => {
                  clearFields();
                  closeModal(true);
                }}>
                <span>✕</span>
              </div>
            </div>
          ) : (
            <div className="form-header">
              <div className="header">
                <span>Delete Data Connection</span>
              </div>
              <div
                className="close-img"
                onClick={() => {
                  clearFields();
                  closeModal(true);
                }}>
                <span>✕</span>
              </div>
            </div>
          )}
          <div
            className={`field-container ${
              status === "edit" ? "" : "del__container"
            }`}>
            {status === "edit" ? (
              fieldView
            ) : (
              <div>
                <span className="del__text">
                  {`Are you sure you wish to delete the data connection ${editFields?.data?.display_name}?`}
                </span>
              </div>
            )}
          </div>
          {status === "edit" ? (
            <div className="button-container">
              <button className="btn btn btn-dark" onClick={handleClick}>
                Save Changes
              </button>
              <button
                onClick={() => {
                  clearFields();
                  closeModal(true);
                }}
                className="btn btn-outline-dark">
                Cancel
              </button>
            </div>
          ) : (
            <div className="button-container delete__container">
              <button className="btn btn btn-orange" onClick={handleDelete}>
                Delete
              </button>
              <button
                onClick={() => {
                  clearFields();
                  closeModal(true);
                }}
                className="btn btn-outline-dark">
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
ConnectionEdit.propTypes = {
  connection: PropTypes.object,
  closeModal: PropTypes.func,
  setupConnection: PropTypes.func,
  changeIsLoading: PropTypes.func,
  editFields: PropTypes?.object,
  status: PropTypes?.string,
  deleteConnection: PropTypes?.func
};
export default ConnectionEdit;
