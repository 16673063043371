import { DateTime } from "luxon";
import { CustomTooltip } from "../../utils/tooltip-helper";

const customProps = {
  index: {
    type: "rightAligned",
    suppressSizeToFit: true,
    width: 70,
    pinned: "left",
    headerClass: "text-align-right"
  },
  first_name: {
    width: 200,
    suppressSizeToFit: true,
    cellClass: "grid-cell-left-aligned"
  },
  last_name: {
    width: 150,
    suppressSizeToFit: true,
    cellClass: "grid-cell-left-aligned"
  },
  email: {
    width: 246,
    suppressSizeToFit: true,
    cellClass: "grid-cell-left-aligned"
  },
  login_type: {
    width: 200,
    suppressSizeToFit: true,
    cellClass:
      "grid-cell-left-aligned flex-justify-space-between cell-border-right"
  },
  role: {
    width: 100,
    suppressSizeToFit: true,
    cellClass: "grid-cell-left-aligned"
  },
  synced_user: {
    width: 105,
    suppressSizeToFit: true,
    cellClass:
      "grid-cell-left-aligned flex-justify-space-between cell-border-right",
    headerName: "Is Synced",
    field: "synced_user"
  },
  active: {
    width: 105,
    suppressSizeToFit: true,
    cellClass:
      "grid-cell-left-aligned flex-justify-space-between cell-border-right",
    headerName: "Active",
    field: "active"
  },
  action: {
    width: 100,
    cellClass: "grid-cell-left-aligned "
  },
  access_list: {
    width: 300,
    suppressSizeToFit: true,
    cellClass:
      "grid-cell-left-aligned flex-justify-space-between cell-border-right",
    headerName: "Account Access",
    field: "access_list"
  }
};
const customGrpProps = {
  index: {
    type: "rightAligned",
    suppressSizeToFit: true,
    width: 70,
    pinned: "left"
  },
  name: {
    width: 280,
    //suppressSizeToFit: true,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CustomTooltip(params.value, params.value, 10);
    }
  },
  role: {
    width: 100,
    suppressSizeToFit: true,
    cellClass: "grid-cell-left-aligned"
  },
  synced_group: {
    width: 105,
    suppressSizeToFit: true,
    cellClass:
      "grid-cell-left-aligned flex-justify-space-between cell-border-right",
    headerName: "Is Synced",
    field: "synced_group"
  },
  created_on: {
    width: 202,
    suppressSizeToFit: true,
    cellClass: "grid-cell-left-aligned",
    headerName: "Created On",
    field: "created_on",
    cellRenderer: (params) => {
      return DateTime.fromISO(params?.value).toFormat("dd MMMM yyyy");
    }
  },
  users: {
    width: 150,
    suppressSizeToFit: true,
    type: "rightAligned"
  },

  action: {
    width: 100,
    suppressSizeToFit: true,
    cellClass: "grid-cell-left-aligned ",
    headerClass: "text-align-center"
  }
};

export const getGroupProps = () => {
  return customGrpProps;
};

export const getOrgProps = () => {
  return customProps;
};
