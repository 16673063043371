import sqlFormatter from "@sqltools/formatter";
import React from "react";
import hljs from "highlight.js";
import sql from "highlight.js/lib/languages/sql";
// TODO: Place this in a css file
import "highlight.js/styles/github.css";
import _ from "lodash";
import { DateTime } from "luxon";
hljs.registerLanguage("sql", sql);

export const PrettyFormat = (value, format) => {
  try {
    let sqlFormattedCode = sqlFormatter.format(value);
    if (format) {
      sqlFormattedCode = highlightSQL(sqlFormattedCode, format);
    }
    return (
      <pre>
        <code
          className="language-sql"
          dangerouslySetInnerHTML={{ __html: sqlFormattedCode }}
        />
      </pre>
    );
  } catch (e) {
    console.log(e);
    return value;
  }
};

export const highlightSQL = (value, format) => {
  return hljs.highlight(format, value).value;
};

export const getValueObj = (arr) => {
  let res = [];
  if (arr && arr.length) {
    res = arr?.map((item) => {
      return { value: item, label: String(item) };
    });
  }
  return res;
};

export const getMaxDate = (arr) => {
  const allDateKeys = _.chain(arr)
    .flatMap((obj) => Object.keys(obj))
    .filter((key) => DateTime.fromISO(key).isValid)
    .uniq()
    .value();

  // Find the maximum date
  const maxDate = _.maxBy(allDateKeys, (date) =>
    DateTime.fromISO(date).toMillis()
  );
  return maxDate;
};

export const getMappedValueObj = (arr) => {
  let res = [];
  if (arr && arr.length) {
    res = arr?.map((item, index) => {
      return { value: index, label: String(item) };
    });
  }
  return res;
};

export const getNonUnderscoreValueObj = (arr) => {
  let res = [];
  if (arr && arr.length) {
    res = arr?.map((item) => {
      return {
        value: item,
        label: String(item).toLowerCase().replace(/_/g, " ")
      };
    });
  }
  return res;
};

export const getSortingValueObj = (obj) => {
  let res = [];
  if (obj) {
    const keys = Object.keys(obj);
    if (keys.length > 0) {
      res = keys.map((key) => {
        return { value: key, label: obj[`${key}`] };
      });
    }
  }
  return res;
};

export const createColDefs = (
  data,
  customProps,
  univerSalStyle,
  column_display_name_mappping
) => {
  let res = [];
  if (data) {
    res = data?.map((item) => {
      let obj = {
        field: String(item)
      };
      if (item)
        if (customProps[item]) {
          obj = {
            ...obj,
            headerName: column_display_name_mappping
              ? column_display_name_mappping[`${item ? item : ""}`]
              : "",
            ...customProps[item]
          };
        }
      if (univerSalStyle) {
        obj = { ...obj, ...univerSalStyle };
      }
      return obj;
    });
  }
  return res;
};

export const flattenArray = (array) => {
  return _.flatMapDeep(array, (item) =>
    [item].concat(item.children ? flattenArray(item.children) : [])
  );
};

export const roundToSigDigits = (
  number,
  sigDigits = 2,
  ignoreError = false,
  overrideSig = false
) => {
  try {
    if (isNaN(number) || number === null) {
      return "-";
    }

    const absNum = Math.abs(number);
    let finalNum;
    if (absNum === 0) {
      finalNum = 0;
    } else if (absNum < 1) {
      finalNum = number.toFixed(
        overrideSig
          ? overrideSig
          : -Math.floor(Math.log10(Math.abs(number))) + (sigDigits - 1)
      );
    } else if (absNum < 10) {
      finalNum = number.toFixed(1);
    } else {
      finalNum = number.toFixed();
    }
    return parseFloat(finalNum);
  } catch (e) {
    if (!ignoreError) {
      console.log(e);
      throw e;
    } else {
      return number;
    }
  }
};
const getBaseLog = (x, y) => {
  return Math.log(y) / Math.log(x);
};
export const prettyPrintBytes = (
  number,
  binary = false,
  ignoreError = false
) => {
  try {
    const size_name = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const basis = binary ? 1024 : 1000;
    if (number.toString() === "0") return "0";

    const i = parseInt(Math.floor(getBaseLog(basis, number)));
    const p = Math.pow(basis, i);
    const s = roundToSigDigits(number / p);
    return `${s}${size_name[i]}`;
  } catch (e) {
    if (!ignoreError) {
      console.log(e);
      throw e;
    } else {
      return number;
    }
  }
};

export const pretty_print_number_with_suffix = (num) => {
  num = parseFloat(roundToSigDigits(num, 3));
  let magnitude = 0;
  while (Math.abs(num) >= 1000) {
    magnitude += 1;
    num /= 1000.0;
  }
  return roundToSigDigits(num) + ["", "K", "M", "B", "T"][magnitude];
};
export function validatePassword(pw) {
  return (
    /[A-Z]/.test(pw) &&
    /[a-z]/.test(pw) &&
    /[0-9]/.test(pw) &&
    /[^A-Za-z0-9]/.test(pw) &&
    pw.length >= 6 &&
    pw.length < 51
  );
}

export const secondsConverter = (s) => {
  if (!s) {
    return "0";
  }

  const obj = {
    hour: (s - (s % 3600)) / 3600,
    min: ((s - (s % 60)) / 60) % 60,
    sec: s % 60
  };
  if (
    obj?.hour?.toString() === "0" &&
    obj?.min?.toString() === "0" &&
    obj?.sec?.toString() === "0"
  ) {
    return "0";
  }
  return (
    `${obj?.hour?.toString() === "0" ? "" : `${obj?.hour?.toString()} hrs`} ` +
    `${obj?.min?.toString() === "0" ? "" : `${obj?.min?.toString()} mins`} ` +
    `${
      obj?.hour?.toString() === "0"
        ? obj?.sec?.toString() === "0"
          ? ""
          : `${obj?.sec?.toString()} secs`
        : ""
    }`
  );
};

export const removeByAttr = function (arr, attr, value) {
  let i = arr.length;
  while (i--) {
    if (
      arr[i] &&
      Object.prototype.hasOwnProperty.call(arr[i], attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }
  return arr;
};
export const searchByRegex = (string, regex) => {
  let result = -1;
  if (!string || !regex) {
    return -1;
  }
  const givenString = string;
  for (let i = 0; i < regex.length; i++) {
    result = givenString.search(regex[i]);
    if (result > -1) {
      break;
    }
  }
  return result;
};
export const handleGridResolution = (gridWidth, screenInnerWidth, padding) => {
  const relativeWidth = screenInnerWidth - padding;
  if (gridWidth < relativeWidth) {
    return true;
  } else {
    return false;
  }
};

export const getHeightbasedOnRows = (
  gridHeight,
  rowHeight,
  padding,
  rowData,
  defaultHeight
) => {
  if (!rowData || !rowData?.length) {
    return defaultHeight;
  }
  const allRowsHeight = rowData?.length * rowHeight;
  const calculatedHeight = gridHeight - padding;
  if (calculatedHeight > allRowsHeight) {
    return allRowsHeight + rowHeight + 10;
  } else if (allRowsHeight > calculatedHeight) {
    return calculatedHeight - 58;
  }
};
export const getGridHeight = (gridData, innerHeight, rowHeight, minHeight) => {
  return !gridData?.length || gridData?.length < 5
    ? minHeight
    : getHeightbasedOnRows(innerHeight, rowHeight, 48, gridData, 180);
};
export const getAutoheightOverflow = (gridData, rowLength = 5) => {
  return gridData?.length >= 0 && gridData?.length < rowLength ? false : true;
};
export const moveInArray = function (arr, from, to) {
  let res = arr;
  if (Object.prototype.toString.call(arr) !== "[object Array]") {
    throw new Error("Please provide a valid array");
  }
  var item = res.splice(from, 1);
  if (!item.length) {
    throw new Error("There is no item in the array at index " + from);
  }
  res.splice(to, 0, item[0]);
  return res;
};
export const removeDropdown = (dwAccOptions, resources, key, key_phrase) => {
  const displayNamesSet = new Set(
    resources.map((item) => item?.[key]?.[key_phrase])
  );
  const filteredArray2 = dwAccOptions?.filter(
    (item) => !displayNamesSet.has(item[key_phrase])
  );
  return filteredArray2;
};

export const removeHTML__ = (htmlString) => {
  let cleanText = htmlString;
  if (
    htmlString &&
    (typeof htmlString === "string" || htmlString instanceof String)
  ) {
    // Remove wrapping <div> tag
    cleanText = htmlString?.replace(/<div[^>]*>/g, "");
    cleanText = cleanText?.replace(/<\/div>/g, "");
    // Remove HTML tags
    cleanText = cleanText?.replace(/<[^>]+>/g, "");
    // Add newline after <li> tags
    cleanText = cleanText?.replace(/<\/?li>/g, "\n");
  }
  return cleanText;
};

export const cleanJSONkeys__ = (jsonString) => {
  // Parse JSON string
  const data = JSON.parse(jsonString);
  // Format key-value pairs
  let formattedText = "";
  for (const key in data) {
    formattedText += `${key}: ${data[key]}\n`;
  }
  return formattedText;
};

export const isValidDate = (dateString) => {
  const isoDate = DateTime.fromISO(dateString);
  const httpDate = DateTime.fromHTTP(dateString);

  return isoDate.isValid || httpDate.isValid;
};
