export const getException = (name, message) => {
  const exceptiondictionary = {
    CAPTCHA_INVALID: {
      header: "Error",
      description: "Captcha Validation Error"
    },
    EMAIL_DOMAIN_INCORRECT: {
      header: "Error",
      description: "Email Domain of New User should be same as Admin User"
    },
    ENTERPRISE_ONLY: {
      header: "Error",
      description:
        "Single sign-on is only available with Enterprise Plan. Please upgrade or contact support."
    },
    NotAuthorizedException: {
      header: "Failed to Sign In",
      description: message ? message : "The email or password is incorrect"
    },
    AlertPreferenceSaved: {
      header: "Success",
      description: "Alert Preferences Saved Successfully"
    },
    USER_PLAN_NOT_ALLOWED: {
      header: "Error",
      description:
        "Your current plan doesn't support this feature. Please upgrade your plan or contact sales@chaosgenius.io"
    },
    UserNotConfirmedException: {
      header: "This user is not verified",
      description: "Verification link sent to email."
    },
    UsernameExistsException: {
      header: "User already exists",
      description: "Please proceed to sign in."
    },
    FailedToSendCode: {
      header: "Error",
      description: "Failed to send code. Please try again"
    },
    CodeMismatchException: {
      header: "Code Mismatch",
      description: "Please enter correct OTP"
    },
    genericError: {
      header: "Error",
      description: message
        ? message
        : "Something went wrong. Please try again later."
    },
    SignUpSuccessful: {
      header: "Signup successful",
      description: "please sign in"
    },
    PassworResetSuccess: {
      header: "Success!",
      description: "Password reset successful. Please log in."
    },
    TextCopySuccessful: {
      header: "text copied",
      description: ""
    },
    QueryCopySuccessful: {
      header: "Query Text Copied Successfully"
    },
    ACCOUNTCREATED: {
      header: "account connected successfully",
      description: ""
    },
    ACCOUNTCREATIONFAILURE: {
      header: "account connection failed",
      description: ""
    },
    INVALIDPASSWORDEXCEPTION: {
      header: "Password requirements failed",
      description:
        "password should be at least 6 characters long, with atleast 1 special and one uppercase character"
    },
    SIGNUP_TOKEN_EMAIL_MISMATCH: {
      description: "Signup email does not match email in token.",
      header: ""
    },
    SIGNUP_USER_EMAIL_EXISTS: {
      description: "User with email already exists.",
      header: ""
    },
    SIGNUP_USER_CREATION_FAIL: {
      description: message ? message : "Unable to create user/account.",
      header: ""
    },
    DATAWH_ENCRYPTION_FAIL: {
      description: "Unable to encrypt credentials.",
      header: ""
    },
    DATAWH_SF_CONNECTION_FAIL: {
      description: "Unable to connect to snowflake using credentials.",
      header: ""
    },
    DATAWH_CREATION_FAIL: {
      description: "Unable to create data warehouse info object.",
      header: ""
    },
    AUTH_INVALID_CODE: {
      description: "Invalid authorization code.",
      header: ""
    },
    AUTH_INVALID_SCHEME: {
      description: "Invalid authorization scheme.",
      header: ""
    },
    AUTH_NO_EMAIL_IN_TOKEN: {
      description: "No Email ID in token.",
      header: ""
    },
    AUTH_INVALID_TOKEN: {
      description: "Unable to verify token.",
      header: ""
    },
    USER_IS_NOT_ADMIN: {
      description: "User is not an admin.",
      header: "Error"
    },
    USER_DOES_NOT_EXIST: {
      description: "Unable to find user.",
      header: ""
    },
    ACCOUNT_DOES_NOT_EXIST: {
      description: "Unable to find account.",
      header: ""
    },
    UNKNOWN_ERROR: {
      description: "Internal Server Error.",
      header: ""
    },
    AuthenticationError: {
      description: message ? message : "",
      header: "Authentication Failed"
    },
    ObjectCreationError: {
      description: message ? message : "",
      header: "User Creation Failed"
    },
    SnowflakeConnectionError: {
      description: message ? message : "",
      header: "Snowflake Connection Error."
    },
    AuthorizationError: {
      description: message ? message : "",
      header: "Authorization Failed."
    },
    ObjectNotFoundError: {
      description: message ? message : "",
      header: "User Not Found."
    },
    ContractDetailsOverlap: {
      description: message ? message : "",
      header: "Contract Details Overlap"
    },
    REQRECSUCCESS: {
      description: "",
      header: "recommendations requested successfully"
    },
    REQRECFAILED: {
      description: "",
      header: "recommendations request failed"
    },
    MONITORING_DETAILS_NOT_ACCESSIBLE: {
      description: message ? message : "",
      header: ""
    },
    MONITORING_TASK_NOT_STARTED_IN_SF: {
      description: message ? message : "",
      header: ""
    },
    UnknownError: {
      description: message ? message : "",
      header: ""
    },
    USERDELETIONSUCCESS: {
      description: "",
      header: "User Deleted Successfully"
    },
    GROUPDELETIONSUCCESS: {
      description: "",
      header: "Group Deleted Successfully"
    },
    USEREDITSUCCESS: {
      description: "",
      header: "User Edited Successfully"
    },
    GROUPEDITSUCCESS: {
      description: "",
      header: "Group Edited Successfully"
    },
    CANNOT_DELETE_SELF_USER: {
      description:
        "User deletion for own account disabled; Contact Chaos Genius or your Account Admin for support.",
      header: "Deletion Failed"
    },
    LimitExceededException: {
      header: "Limit Exceeded",
      description:
        "You have exceeded maximum number attempts. Please try again later."
    },
    PasswordLimitExceededException: {
      header: "Limit Exceeded",
      description:
        "You have exceeded maximum number of password reset attempts. Please try again later."
    },
    ExpiredCodeException: {
      header: "Code Expired",
      description: "Your code has expired. Please request a new one."
    },
    INVITE_NO_EMAILS: {
      header: "No Emails Provided",
      description: "Please provide at least one email address."
    },
    INVITE_INVALID_EMAILS: {
      header: "Invalid Emails Provided",
      description: "Please provide valid email addresses."
    },
    INVITE_NOT_COMPANY_EMAILS: {
      header: "Not a company email",
      description: "Please provide valid company email addresses."
    },
    INVITE_NOT_COMPANY_DOMAIN: {
      header: "Invalid Emails Provided",
      description: "Enter Valid Email with same domain as user"
    }
  };
  return exceptiondictionary[name]
    ? exceptiondictionary[name]
    : exceptiondictionary["genericError"];
};
