import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import "./signin.scss";
import { validateCompanyEmail, validateEmail } from "../../utils/validateemail";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { getException } from "../../utils/exceptiondictionary";
import SignUpSignInFlow from "../../components/SignUpSignInFlow";
import { useAuth } from "../../utils/auth-helper";
import eyeCon from "../../assets/images/common/eye.svg";
import eyeConSlash from "../../assets/images/common/eye-slash.svg";
import warning from "../../assets/images/common/warning.svg";
// import { IconButton, InputAdornment, TextField, ThemeProvider, createTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import SignInHeader from "../../components/SignInHeader";
import { theme } from "../../utils/sign-helper";
import key__img from "../../assets/images/key__img.svg";
import { getAuthConfig, validateRecaptcha } from "../../utils/user-helper";
import awsExports from "../../aws-exports";
import SVG from "react-inlinesvg";
import { setLocalStorage } from "../../utils/storage-helper";
import { isBusinessEmail } from "../../utils/business-email-validator";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { env } from "../../env";

const SignIn = () => {
  const { onLogin } = useAuth();
  const location = useLocation();
  const toast = useToast();
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [eyeSlash, setEyeSlash] = useState(false);
  const [pwdType, setPwdType] = useState("password");
  const [pword, setPword] = useState("");
  const [isSSOProbable, setISSSOProbable] = useState(
    location.pathname.includes("signin/sso") ? true : false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [infoError, setInfoError] = useState({
    email: "",
    pword: ""
  });
  const [rememberMe, setRememberMe] = useState(false);
  const reRef = useRef(null);

  const navigateTo = (path, attrs) => {
    navigate(path, attrs);
  };
  const handleClick = (e) => {
    e.preventDefault();
    let errObj = { ...infoError };
    if (email.trim() === "") {
      errObj["email"] = "Please enter email";
    }
    if (email.trim() !== "" && !validateEmail(email.trim())) {
      errObj["email"] = "Please enter valid email";
    }
    if (email.trim() !== "" && validateCompanyEmail(email.trim())) {
      errObj["email"] = "Please provide company email id";
    }
    if (!isBusinessEmail(email?.trim())) {
      errObj["email"] = "Please provide company email id";
    }
    if (pword.trim() === "") {
      errObj["pword"] = "Please enter password";
    }
    setInfoError(errObj);
    if (errObj["email"] === "" && errObj["pword"] === "") {
      setIsLoading(true);
      handleSignIn();
    }
  };
  const handleSSOClick = (e) => {
    e.preventDefault();
    let errObj = { ...infoError };
    if (email.trim() === "") {
      errObj["email"] = "Please enter email";
    }
    if (email.trim() !== "" && !validateEmail(email.trim())) {
      errObj["email"] = "Please enter valid email";
    }
    if (email.trim() !== "" && validateCompanyEmail(email.trim())) {
      errObj["email"] = "Please provide company email id";
    }
    if (!isBusinessEmail(email?.trim())) {
      errObj["email"] = "Please provide company email id";
    }
    setInfoError(errObj);
    if (errObj["email"] === "") {
      setIsLoading(true);
      handleSSOSignIn();
    }
  };

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(email?.trim()?.toLowerCase());
      navigateTo("/signup", {
        state: {
          signUpState: "confirmmail",
          email: email.trim(),
          pword: pword.trim()
        }
      });
    } catch (err) {
      customToast({ type: "error", ...getException("FailedToSendCode") });
    }
  }

  const handleSSOSignIn = async () => {
    let valid = {};
    try {
      if (reRef && reRef?.current) {
        const token = await reRef?.current?.executeAsync();
        valid = await validateRecaptcha(token);
      }
      if (valid) {
        const customProvider = await getLoginOptions(); //should be an API response
        const { idps, cognito_app_client_id } = customProvider;
        if (Object(customProvider)?.length === 0) {
          customToast({
            type: "error",
            header: "Failed to Sign In",
            description: "The email or password is incorrect"
          });
          setIsLoading(false);
        } else {
          if (
            !customProvider ||
            !idps ||
            !idps.length ||
            !idps[0] ||
            !idps[0].cognito_idp_identifier ||
            !cognito_app_client_id
          ) {
            //setISSSOProbable(false);
            let errObj = { ...infoError };
            errObj["email"] =
              "Your account is not configured to use SSO. Please log in with a password";
            setInfoError(errObj);
            setIsLoading(false);
          } else {
            if (
              customProvider &&
              idps &&
              idps.length &&
              idps[0] &&
              idps[0].cognito_idp_identifier &&
              cognito_app_client_id
            ) {
              Amplify.configure({
                ...awsExports,
                aws_user_pools_web_client_id: cognito_app_client_id
              });
              setLocalStorage("webclientID", cognito_app_client_id);
              await Auth.federatedSignIn({
                customProvider: idps[0].cognito_idp_identifier
              });
              if (rememberMe) {
                await Auth.rememberDevice();
              }
              onLogin();
              //setIsLoading(false);
            } else {
              let errObj = { ...infoError };
              errObj["email"] =
                "Your account is not configured to use SSO. Please log in with a password";
              setInfoError(errObj);
              setRememberMe(false);
              setIsLoading(false);
            }
          }
        }
      } else {
        customToast({ type: "error", ...getException("CAPTCHA_INVALID") });
        setRememberMe(false);
        setIsLoading(false);
      }
    } catch (error) {
      let errObj = { ...infoError };
      errObj["email"] =
        "Your account is not configured to use SSO. Please log in with a password";
      setInfoError(errObj);
      setRememberMe(false);
      console.log(error);
      //customToast({ type: "error", ...getException(error.name) });
      setIsLoading(false);
    }
  };
  const getLoginOptions = async () => {
    try {
      const customProvider = await getAuthConfig(email?.toLowerCase());
      return customProvider;
    } catch (error) {
      setRememberMe(false);
      console.log(error);
      customToast({
        type: "error",
        ...getException(error?.response?.data?.errorKey)
      });
      setIsLoading(false);
    }
  };
  const handleSignIn = async () => {
    let valid = {};
    try {
      if (reRef && reRef?.current) {
        const token = await reRef?.current?.executeAsync();
        valid = await validateRecaptcha(token);
      }
      if (valid) {
        const customProvider = await getLoginOptions();
        const { password_login_enabled, cognito_app_client_id } =
          customProvider;
        if (Object(customProvider).length === 0) {
          customToast({
            type: "error",
            header: "Failed to Sign In",
            description: "The email or password is incorrect"
          });
          setIsLoading(false);
        } else {
          if (!password_login_enabled) {
            let errObj = { ...infoError };
            errObj["pword"] =
              "Log in using password for this account is disabled. Please use single sign-on";
            setInfoError(errObj);
            setIsLoading(false);
          } else {
            Amplify.configure({
              ...awsExports,
              aws_user_pools_web_client_id: cognito_app_client_id
            });
            setLocalStorage("webclientID", cognito_app_client_id);
            await Auth.signIn(email?.trim()?.toLowerCase(), pword.trim());
            if (rememberMe) {
              await Auth.rememberDevice();
            }
            onLogin();
            setIsLoading(false);
          }
        }
      } else {
        customToast({ type: "error", ...getException("CAPTCHA_INVALID") });
        setRememberMe(false);
        setIsLoading(false);
      }
    } catch (error) {
      setRememberMe(false);
      console.log(error);
      customToast({
        type: "error",
        ...getException(error?.name)
      });
      switch (error.name) {
        case "UserNotConfirmedException": {
          resendConfirmationCode();
        }
      }
      setIsLoading(false);
    }
  };

  const toggleSlash = () => {
    setEyeSlash(!eyeSlash);
    if (pwdType && pwdType === "password") {
      setPwdType("text");
    } else if (pwdType && pwdType === "text") {
      setPwdType("password");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="parent-container">
        <SignUpSignInFlow welcome />
        <div className="sign-in-container">
          <SignInHeader />
          <div className="block">
            <div className="signin-parent-block">
              <div className="signin-parent">
                <div className="heading">
                  {isSSOProbable ? (
                    <span>Log in to Chaos Genius with SSO</span>
                  ) : (
                    <span>Log in to your account</span>
                  )}
                </div>
                {isSSOProbable ? (
                  <div className="sub__heading">
                    <p>
                      {`Single sign-on allows you to log in to the Chaos Genius
                      using your organization's identity provider.`}
                    </p>
                    <p className="margin__btm__12">
                      {`If you are unsure whether your organization is using
                      single sign-on, reach out to your administrator.`}
                    </p>
                  </div>
                ) : null}
                <form
                  className="login-list"
                  onSubmit={!isSSOProbable ? handleClick : handleSSOClick}>
                  <TextField
                    label="Email Address"
                    variant="outlined"
                    autoComplete="email"
                    fullWidth
                    FormHelperTextProps={{
                      className: "email-error",
                      sx: { mt: "6px", ml: 0 }
                    }}
                    type="email"
                    value={email}
                    onChange={(e) => {
                      if (infoError["email"] !== "") {
                        setInfoError({ ...infoError, email: "" });
                      }
                      setEmail(e.target.value);
                    }}
                    error={infoError.email !== ""}
                    helperText={
                      <>
                        {infoError.email !== "" && (
                          <img src={warning} alt="warning" />
                        )}
                        <span>{infoError.email}</span>
                      </>
                    }
                  />
                  {!isSSOProbable ? (
                    <>
                      <TextField
                        label="Password"
                        variant="outlined"
                        type={pwdType}
                        autoComplete="current-password"
                        fullWidth
                        FormHelperTextProps={{
                          className: "email-error",
                          sx: { mt: "6px", ml: 0 }
                        }}
                        value={pword}
                        onChange={(e) => {
                          if (infoError["pword"] !== "") {
                            setInfoError({ ...infoError, pword: "" });
                          }
                          setPword(e.target.value);
                        }}
                        error={infoError.pword !== ""}
                        helperText={
                          <>
                            {infoError.pword !== "" && (
                              <img src={warning} alt="warning" />
                            )}
                            <span>{infoError.pword}</span>
                          </>
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                className="cg-eye-btn"
                                onClick={() => {
                                  toggleSlash();
                                }}
                                edge="end">
                                <img
                                  className="cg-eye"
                                  src={eyeSlash ? eyeCon : eyeConSlash}
                                  aria-hidden="true"
                                />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      <input
                        type="submit"
                        className="btn btn-green-variant__login w-100 login-btn"
                        value="Login"
                      />
                    </>
                  ) : (
                    <input
                      type="submit"
                      className="btn btn-green-variant__login w-100 login-btn"
                      value="Login"
                    />
                  )}
                  {env?.REACT_APP_SITEKEY ? (
                    <ReCAPTCHA
                      className="g__recaptcha"
                      sitekey={env?.REACT_APP_SITEKEY} //take from env
                      size="invisible"
                      ref={reRef}
                      //onChange={onChange}
                    />
                  ) : null}
                </form>
                {!isSSOProbable ? (
                  <div className="signup-link-container">
                    <span
                      className="forgot-link"
                      onClick={() => {
                        navigateTo("../forgotpassword", {
                          state: { email: email.trim() }
                        });
                      }}>
                      Forgot password?
                    </span>
                  </div>
                ) : (
                  <div className="signup-link-container">
                    <span
                      className="forgot-link"
                      onClick={() => {
                        if (infoError["email"] !== "") {
                          setInfoError({ ...infoError, email: "" });
                        }
                        navigateTo("/signin");
                        setISSSOProbable(!isSSOProbable);
                      }}>
                      Log in with a password
                    </span>
                  </div>
                )}
                {!isSSOProbable ? (
                  <>
                    <div className="seperator__container">
                      <div className="seperator___line"></div>
                      <span>OR</span>
                      <div className="seperator___line"></div>
                    </div>
                    <div
                      className="sso__enable__container"
                      onClick={() => {
                        if (infoError["email"] !== "") {
                          setInfoError({ ...infoError, email: "" });
                        }
                        if (infoError["pword"] !== "") {
                          setInfoError({ ...infoError, pword: "" });
                        }
                        setISSSOProbable(!isSSOProbable);
                        navigateTo("/signin/sso");
                      }}>
                      <SVG src={key__img} />
                      <span>Single sign-on</span>
                    </div>
                  </>
                ) : null}
                {!isSSOProbable ? (
                  <div className="signup-link-container">
                    <span>
                      Don’t have an account?{" "}
                      <span
                        className="signup-link"
                        onClick={() => {
                          navigateTo("/signup");
                        }}>
                        Sign Up
                      </span>
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {isLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};
export default SignIn;
