import React, { useState, useEffect } from "react";
import "./dashboard.scss";
import AppHeader from "../../components/AppHeader";
//import DashboardRoutes from "../../routes/DashboardRoutes";
import {
  addDashboardIcons,
  //getDashboardHeader,
  getDashboardHeaderNew,
  getDashboardKeys,
  getDashboardKeysDBX,
  getDashboardLink,
  getDashboardLinkWithSubmodule
} from "../../utils/dashboard-helper";
import { useAuth } from "../../utils/auth-helper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { getDWAcc } from "../../utils/user-helper";
import { getSessionStorage, removeSSItem } from "../../utils/storage-helper";
import ViewController from "../ViewController";
import ParentRoutes from "../../routes/ParentRoutes";
import { checkSingleParamKey } from "../../utils/url-helper";
const Dashboard = () => {
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const [token] = useState(userInfo?.token);
  const [headerMenu, setHeaderMenu] = useState([]);
  const [activeModule, setActiveModule] = useState("");
  const [acc, setAcc] = useState(null);
  const [activePage, setActivePage] = useState("");
  const [dwAccOptions, setDwAccOptions] = useState([]);
  const [activeSubmodule, setActiveSubmodule] = useState("");
  const [isMenuLoading, setIsMenuLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const [rawData, setRawData] = useState(null);
  const [isSlackAlertSetup, setIsSlackAlertSetup] = useState(false);
  const [isTeamAlertSetup, setIsTeamAlertSetup] = useState(false);
  const [slackAlert, setSlackAlert] = useState({});
  const [TeamsAlert, setTeamsAlert] = useState({});
  const { pathname, search } = useLocation();

  const dataSourceData = [
    {
      is_module: true,
      module_name: "Data Source",
      page_display_name: "Data Source",
      page_url: undefined,
      dontDisplayChildren: true
    },
    {
      is_module: false,
      module_name: "Data Source",
      page_display_name: "Select Connection",
      page_url: undefined
    },
    {
      is_module: false,
      module_name: "Connect Accounts",
      page_display_name: "Select Connection",
      page_url: undefined
    }
  ];

  const dataSourceMenu = [
    {
      children: [
        {
          img: "Select Connection",
          is_module: false,
          link: "Select Connection",
          module_name: "Data Source",
          page_display_name: "Select Connection",
          page_url: undefined
        },
        {
          img: "Connect Accounts",
          is_module: false,
          link: "",
          module_name: "Data Source",
          page_display_name: "Connect Accounts",
          page_url: undefined
        }
      ],
      img: "Data Source",
      is_module: true,
      link: "Data Source",
      module_name: "",
      page_display_name: "Data Source",
      page_url: ""
    }
  ];

  const clearData = () => {
    setActiveModule("");
    setActivePage("");
    const data = dataSourceData;
    setRawData(data);
    const menuList = createMenuList(data);
    setHeaderMenu(menuList);
  };

  const profileMenuData = [
    {
      img: "Organization Settings",
      is_module: false,
      link: "Organization Settings",
      module_name: "Settings",
      page_display_name: "Organization Settings",
      page_url: ""
    },
    {
      img: "Alert Settings",
      is_module: false,
      link: "Alert Settings",
      module_name: "Settings",
      page_display_name: "Alert Settings",
      page_url: ""
    },
    {
      img: "Billing",
      is_module: false,
      link: "Billing",
      isHidden: false,
      module_name: "Settings",
      page_display_name: "Billing",
      page_url: ""
    },
    {
      img: "SSO Configuration",
      is_module: false,
      link: "SSO Configuration",
      module_name: "Settings",
      page_display_name: "SSO Configuration",
      page_url: ""
    },
    {
      img: "User Provisioning",
      is_module: false,
      link: "User Provisioning",
      module_name: "Settings",
      page_display_name: "User Provisioning",
      page_url: ""
    },
    {
      img: "Settings",
      is_module: true,
      link: "Settings",
      module_name: "",
      page_display_name: "Settings",
      page_url: ""
    }
  ];
  const chargeBackMenuData = [
    {
      img: "Chargeback",
      is_module: false,
      link: "Cost Reporting",
      module_name: "Chargeback",
      page_display_name: "Cost Reporting",
      page_url: "",
      dw_type: "SNOWFLAKE"
    },
    {
      img: "Chargeback",
      is_module: false,
      link: "Cost Center",
      module_name: "Chargeback",
      page_display_name: "Cost Center",
      page_url: "",
      dw_type: "SNOWFLAKE"
    },
    {
      img: "Chargeback",
      is_module: true,
      link: "Chargeback",
      module_name: "Chargeback",
      dw_type: "SNOWFLAKE",
      page_display_name: "Chargeback",
      page_url: ""
    }
  ];
  const profileMenu = [
    {
      children: [
        {
          img: "Organization Settings",
          is_module: false,
          link: "Organization Settings",
          module_name: "Settings",
          page_display_name: "Organization Settings",
          page_url: ""
        },
        {
          img: "Alert Settings",
          is_module: false,
          link: "Alert Settings",
          module_name: "Settings",
          page_display_name: "Alert Settings",
          page_url: ""
        },
        {
          img: "Billing",
          is_module: false,
          link: "Billing",
          isHidden: false,
          module_name: "Settings",
          page_display_name: "Billing",
          page_url: ""
        },
        {
          img: "SSO Configuration",
          is_module: false,
          link: "SSO Configuration",
          module_name: "Settings",
          page_display_name: "SSO Configuration",
          page_url: ""
        },
        {
          img: "User Provisioning",
          is_module: false,
          link: "User Provisioning",
          module_name: "Settings",
          page_display_name: "User Provisioning",
          page_url: ""
        }
      ],
      img: "Settings",
      is_module: true,
      link: "Settings",
      module_name: "",
      page_display_name: "Settings",
      page_url: ""
    }
  ];

  const changeSlackAlertSetup = (bool) => {
    setIsSlackAlertSetup(bool);
  };
  const changeTeamsAlertSetup = (bool) => {
    setIsTeamAlertSetup(bool);
  };

  const createList = (modules, children) => {
    modules?.forEach((module) => {
      module["children"] = children.filter((child) => {
        return (
          child.module_name === module?.page_display_name &&
          child?.dw_type === module?.dw_type
        );
      });
    });
    return modules;
  };
  const createMenuList = (data) => {
    let list = [];
    if (data && data.length) {
      const modules = addDashboardIcons(
        data?.filter((item) => {
          return item?.is_module;
        })
      );

      const children = addDashboardIcons(
        data?.filter((item) => {
          return !item?.is_module;
        })
      );
      list = createList(modules, children);
    }
    return list;
  };

  const changeActiveModule = (
    str,
    url,
    page = undefined,
    param = undefined,
    search = "",
    submodule = undefined,
    acc__obj
  ) => {
    const __acc = acc__obj && Object?.keys(acc__obj)?.length ? acc__obj : acc;
    setActiveModule(str);
    if (!page) {
      setActivePage("");
    } else {
      setActivePage(page);
    }
    if (submodule) {
      changeActiveSubmodule(submodule);
    }
    removeSSItem("pageFilters");
    const link =
      __acc?.connection_type === "DATABRICKS"
        ? getDashboardLinkWithSubmodule(page ? page : str, str, submodule)
        : getDashboardLink(page ? page : str, str, submodule);
    setUrl(url);
    let param_ = param?.replace("/dashboard/", "");
    let wh_id_check = checkSingleParamKey(search, "dw_id");
    if (param_ && param_ !== link) {
      if (!wh_id_check) {
        let dw_id = __acc?.data_warehouse_info_id;
        navigate(`/dashboard/${param_}?dw_id=${dw_id}${search ?? ""}`);
      } else {
        navigate(`/dashboard/${param_}${search ?? ""}`);
      }
    } else {
      if (!wh_id_check) {
        let dw_id = __acc?.data_warehouse_info_id;
        navigate(`/dashboard/${link}?dw_id=${dw_id}${search ?? ""}`);
      } else {
        navigate(`/dashboard/${link}${search ?? ""}`);
      }
    }
  };

  const changeActivePage = (str, url, module_name, submodule) => {
    let module =
      headerMenu &&
      headerMenu?.length &&
      headerMenu?.find((menu) => {
        return (
          str ===
          menu?.children?.find((child) => {
            return submodule && child?.submodule_name
              ? child?.page_display_name === str &&
                  child?.module_name === module_name &&
                  child?.dw_type === acc?.connection_type &&
                  child?.submodule_name === submodule
              : child?.page_display_name === str &&
                  child?.module_name === module_name &&
                  child?.dw_type === acc?.connection_type;
          })?.page_display_name
        );
      });
    if (module) {
      setActivePage(str);
      if (submodule) {
        changeActiveSubmodule(submodule);
      }
      changeActiveModule(
        module?.page_display_name,
        url,
        str,
        undefined,
        "",
        submodule
      );
    } else {
      module =
        profileMenu &&
        profileMenu.length &&
        profileMenu?.find((menu) => {
          return (
            str ===
            menu?.children?.find((child) => {
              return (
                child?.page_display_name === str &&
                child?.module_name === module_name
              );
            })?.page_display_name
          );
        });
      if (module) {
        setActivePage(str);
        changeActiveModule(module?.page_display_name, url, str);
      }
    }
  };

  const changeAcc = (e) => {
    setAcc(e);
    if (!e?.setup_done) {
      clearData();
      navigate("/dashboard/connectionfallback");
    } else {
      const param = pathname?.replace("/dashboard/", "");
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.forEach((_value, key) => {
        urlParams.delete(key);
      });
      urlParams.set("dw_id", e.data_warehouse_info_id);
      urlParams.set("filter", false);
      removeSSItem("pageFilters");
      const newUrl = `${pathname}?${urlParams.toString()}`;
      navigate(newUrl);
      if (
        param === "connectionfallback" ||
        param === "" ||
        e?.connection_type !== acc?.connection_type
      ) {
        navigate("");
        navigate(0);
      } else {
        if (!acc?.setup_done) {
          clearData();
          setIsLoading(true);
          setIsMenuLoading(true);
          getMenu();
        }
      }
    }
  };

  const changeSlackAlert = (status) => {
    setSlackAlert(status);
  };
  const changeTeamAlert = (status) => {
    setTeamsAlert(status);
  };
  const getAccOptions = (arr) => {
    const options = arr?.map((opt) => {
      return {
        id: opt?.data_warehouse_info_id,
        value: opt?.display_name,
        label: opt?.display_name,
        ...opt
      };
    });
    return options;
  };
  const dwAccOptionsChanged = (dw_acc_id = undefined) => {
    getDwAccOptions(dw_acc_id);
  };
  const getDwAccOptions = async (dw_acc_id) => {
    const dwAcc = await getDWAcc();
    const options = getAccOptions(dwAcc);
    setDwAccOptions(options ? options : []);
    if (dw_acc_id) {
      const accObj = options?.find((opt) => {
        return opt?.id.toString() === dw_acc_id?.toString() && opt?.user_access;
      });
      changeAcc(accObj);
    } else {
      const accObj = options.find((opt) => {
        return opt?.user_access;
      });
      changeAcc(accObj);
    }
  };
  const getIncompleteDW = (dwArr) => {
    let incompleteArr = [];
    incompleteArr =
      dwArr &&
      dwArr?.filter((acc) => {
        if (acc?.connection_type?.toUpperCase() === "DATABRICKS") {
          return acc?.connection_type?.toUpperCase() !== "DATABRICKS";
        } else {
          return (
            (acc?.account_id.toString() !== "1" &&
              acc?.num_contracts.toString() === "0") ||
            !acc?.num_contracts
          );
        }
      });
    return incompleteArr;
  };

  const changeActiveSubmodule = (str) => {
    setActiveSubmodule(str);
  };

  const getModules = (data) => {
    let result = [];
    if (!data || !data?.length) {
      return [];
    } else {
      data?.forEach((item) => {
        const module = result?.some((res) => {
          return (
            res?.page_display_name === item?.module_name &&
            item?.dw_type === res?.dw_type
          );
        });
        if (!module) {
          result.push({
            img: item?.module_name,
            is_module: true,
            link: item?.module_name,
            module_name: item?.module_name,
            dw_type: item?.dw_type,
            page_display_name: item?.module_name,
            page_url: ""
          });
        }
      });
    }
    return result;
  };

  const flattenSubmodules = (modules, data) => {
    if (!data || !data?.length) {
      return modules;
    }
    data?.forEach((item) => {
      modules?.push({
        is_module: false,
        module_name: item?.module_name,
        page_display_name: item?.page_display_name,
        dw_type: item?.dw_type,
        page_url: item?.page_url,
        submodule_name: item?.submodule_name
      });
    });
    return modules;
  };

  const getMenu = async () => {
    let data;
    const orgonboarded = getSessionStorage("orgonboarded");
    let module = undefined;
    try {
      data = await getDashboardHeaderNew();
      const modules = getModules(data);
      const add_submodules = flattenSubmodules(modules, data);

      if (userInfo?.info?.role === "admin") {
        add_submodules.push({
          is_module: true,
          module_name: "Organization",
          page_display_name: "Organization",
          dw_type: "SNOWFLAKE",
          page_url: undefined,
          dontDisplayChildren: true
        });
        add_submodules.push({
          is_module: false,
          module_name: "Organization",
          page_display_name: "Organization",
          dw_type: "SNOWFLAKE",
          page_url: undefined,
          submodule_name: ""
        });
        add_submodules.push(...chargeBackMenuData);
      }
      add_submodules.push({
        is_module: true,
        module_name: "Data Source",
        page_display_name: "Data Source",
        page_url: undefined,
        dontDisplayChildren: true
      });
      add_submodules.push({
        is_module: false,
        module_name: "Compute",
        page_display_name: "Inventory",
        page_url: undefined,
        dw_type: "SNOWFLAKE",
        submodule_name: ""
      });
      add_submodules.push({
        is_module: false,
        module_name: "Compute",
        page_display_name: "Recommendations",
        page_url: undefined,
        dw_type: "SNOWFLAKE",
        submodule_name: ""
      });
      add_submodules.push({
        is_module: false,
        module_name: "Workloads",
        page_display_name: "Query Groups",
        page_url: undefined,
        dw_type: "SNOWFLAKE",
        submodule_name: ""
      });
      add_submodules.push({
        is_module: false,
        module_name: "Workloads",
        page_display_name: "Failed Queries",
        page_url: undefined,
        dw_type: "SNOWFLAKE",
        submodule_name: ""
      });
      add_submodules.push({
        is_module: false,
        module_name: "Workloads",
        page_display_name: "Recommendations",
        page_url: undefined,
        dw_type: "SNOWFLAKE",
        submodule_name: ""
      });
      add_submodules.push({
        is_module: false,
        module_name: "Storage",
        page_display_name: "Table Groups",
        page_url: undefined,
        dw_type: "SNOWFLAKE",
        submodule_name: ""
      });
      add_submodules.push({
        is_module: false,
        module_name: "Governance",
        page_display_name: "Changelog",
        page_url: undefined,
        dw_type: "SNOWFLAKE",
        submodule_name: ""
      });
      add_submodules.push({
        is_module: false,
        module_name: "Data Source",
        page_display_name: "Select Connection",
        page_url: undefined
      });
      add_submodules.push({
        is_module: false,
        module_name: "Data Source",
        page_display_name: "Connect Accounts",
        page_url: undefined
      });
      add_submodules.unshift({
        is_module: false,
        module_name: "Home",
        page_display_name: "Home Dashboard",
        dw_type: "SNOWFLAKE",
        submodule_name: "",
        page_url: undefined
      });
      add_submodules.unshift({
        is_module: true,
        module_name: "Home",
        page_display_name: "Home",
        dw_type: "SNOWFLAKE",
        page_url: undefined,
        dontDisplayChildren: true
      });
      add_submodules.splice(2, 0, {
        is_module: true,
        module_name: "Cost Explorer",
        page_display_name: "Cost Explorer",
        dw_type: "SNOWFLAKE",
        page_url: undefined,
        dontDisplayChildren: true
      }); //Change this to push once API arrives
      add_submodules.push({
        is_module: false,
        module_name: "Cost Explorer",
        page_display_name: "Overview",
        dw_type: "SNOWFLAKE",
        page_url: undefined
      }); //Change this to push once API arrives
      let acc__obj = undefined;
      setRawData(add_submodules);
      const dwAcc = await getDWAcc();
      const dw_acc_id = getSessionStorage("dw_acc_id");
      const options = getAccOptions(dwAcc);
      if (dw_acc_id) {
        acc__obj = options?.find((opt) => {
          return opt?.id.toString() === dw_acc_id && opt?.user_access;
        });
      } else {
        acc__obj = options?.find((opt) => {
          return opt?.user_access;
        });
      }

      if (!userInfo?.info?.onboarded) {
        navigate("/onboard");
        return;
      }
      if (!orgonboarded) {
        navigate("/onboard/invite");
        return;
      }
      if (
        userInfo?.info?.redirect_to_billing.toString() === "false" &&
        (!dwAcc || dwAcc.length == 0)
      ) {
        navigate("/selectconnection/");
        return;
      }

      const incompleteDW = getIncompleteDW(dwAcc);
      if (
        userInfo?.info?.redirect_to_billing.toString() === "false" &&
        (!incompleteDW || incompleteDW?.length > 0)
      ) {
        navigate(
          `/selectconnection/connectaccounts/SF/step_3/${incompleteDW[0].data_warehouse_info_id}`
        );

        setIsLoading(false);
        setIsMenuLoading(false);
      } else {
        if (add_submodules) {
          let menuList = createMenuList(add_submodules);
          const activeItemName =
            acc__obj?.connection_type === "DATABRICKS"
              ? getDashboardKeysDBX(params && params["*"])
              : getDashboardKeys(params && params["*"]);
          let activeItem = add_submodules?.find((item) => {
            return activeItemName?.submodule
              ? item?.page_display_name === activeItemName?.name &&
                  item?.module_name === activeItemName?.module &&
                  item?.dw_type === acc__obj?.connection_type &&
                  item?.submodule_name === activeItemName?.submodule
              : item?.page_display_name === activeItemName?.name &&
                  item?.module_name === activeItemName?.module &&
                  item?.dw_type === acc__obj?.connection_type;
          });
          if (!activeItem) {
            activeItem = dataSourceData?.find((item) => {
              return (
                item?.page_display_name === activeItemName?.name &&
                item?.module_name === activeItemName?.module
              );
            });
          }

          if (!activeItem) {
            activeItem = profileMenuData?.find((item) => {
              return (
                item?.page_display_name === activeItemName?.name &&
                item?.module_name === activeItemName?.module
              );
            });
          }
          setHeaderMenu(menuList);

          if (activeItem) {
            if (
              userInfo?.info?.redirect_to_billing.toString() === "false" ||
              (activeItem?.module_name === "Settings" &&
                activeItem?.page_display_name === "Organization Settings")
            ) {
              if (activeItem?.is_module) {
                changeActiveModule(
                  activeItem?.page_display_name,
                  activeItem?.page_url,
                  activeItem?.children
                    ? activeItem?.children[0].page_display_name
                    : undefined,

                  pathname,
                  search,
                  activeItem?.submodule_name,
                  acc__obj
                );
              } else {
                module =
                  menuList &&
                  menuList.length &&
                  menuList?.find((menu) => {
                    return (
                      activeItem.page_display_name ===
                      menu?.children?.find((child) => {
                        return activeItem?.submodule_name
                          ? child?.page_display_name ===
                              activeItem.page_display_name &&
                              child?.module_name === activeItem?.module_name &&
                              child?.dw_type === acc__obj?.connection_type &&
                              activeItem?.submodule_name ===
                                child?.submodule_name
                          : child?.page_display_name ===
                              activeItem.page_display_name &&
                              child?.module_name === activeItem?.module_name &&
                              child?.dw_type === acc__obj?.connection_type;
                      })?.page_display_name
                    );
                  });
                if (module) {
                  setActivePage(activeItem.page_display_name);
                  changeActiveModule(
                    module?.page_display_name,
                    activeItem.page_url,
                    activeItem.page_display_name,
                    params && params["*"],
                    search,
                    activeItem?.submodule_name,
                    acc__obj
                  );
                } else {
                  module =
                    dataSourceMenu &&
                    dataSourceMenu.length &&
                    dataSourceMenu?.find((menu) => {
                      return (
                        activeItem.page_display_name ===
                        menu?.children?.find((child) => {
                          return (
                            child?.page_display_name ===
                              activeItem.page_display_name &&
                            child?.module_name === activeItem?.module_name
                          );
                        })?.page_display_name
                      );
                    });
                  if (!module) {
                    module =
                      profileMenu &&
                      profileMenu.length &&
                      profileMenu?.find((menu) => {
                        return (
                          activeItem.page_display_name ===
                          menu?.children?.find((child) => {
                            return (
                              child?.page_display_name ===
                                activeItem.page_display_name &&
                              child?.module_name === activeItem?.module_name
                            );
                          })?.page_display_name
                        );
                      });
                  }
                  setActivePage(activeItem.page_display_name);
                  changeActiveModule(
                    module?.page_display_name,
                    activeItem.page_url,
                    activeItem.page_display_name,
                    undefined,
                    "",
                    undefined,
                    acc__obj
                  );
                }
              }
            } else {
              setActivePage("Billing");
              changeActiveModule("Settings", "", "Billing");
            }
          } else if (
            params["*"] === "" ||
            !params["*"] === "connectionfallback"
          ) {
            if (userInfo?.info?.redirect_to_billing.toString() === "false") {
              let accObj = undefined;
              const dw_acc_id = getSessionStorage("dw_acc_id");
              const options = getAccOptions(dwAcc);
              setDwAccOptions(options ? options : []);
              if (dw_acc_id) {
                accObj = options?.find((opt) => {
                  return opt?.id.toString() === dw_acc_id && opt?.user_access;
                });
              } else {
                accObj = options.find((opt) => {
                  return opt?.user_access;
                });
              }
              if (
                accObj?.connection_type === "SNOWFLAKE" &&
                accObj?.setup_done
              ) {
                setActivePage("Home");

                changeActiveModule(
                  "Home",
                  "",
                  module?.children && !module?.dontDisplayChildren
                    ? module?.children[0]?.page_display_name
                    : "",
                  undefined,
                  "",
                  undefined,
                  accObj
                );
                setIsMenuLoading(false);
                setIsLoading(false);
              } else if (accObj?.setup_done) {
                const obj = menuList?.find((obj) => {
                  return obj?.dw_type === accObj?.connection_type;
                });
                if (Object.keys(obj)?.length && obj?.children?.length) {
                  setActivePage(obj?.children[0]?.page_display_name);
                  changeActiveModule(
                    obj?.page_display_name,
                    obj?.children[0]?.page_url,
                    obj?.children[0]?.page_display_name,
                    undefined,
                    "",
                    obj?.children[0]?.submodule_name,
                    accObj
                  );
                }
              }
            } else {
              setActivePage("Billing");
              changeActiveModule("Settings", "", "Billing");
            }
          } else {
            clearData();
            let accObj = undefined;
            const dw_acc_id = getSessionStorage("dw_acc_id");
            const options = getAccOptions(dwAcc);
            setDwAccOptions(options ? options : []);
            if (dw_acc_id) {
              accObj = options?.find((opt) => {
                return opt?.id.toString() === dw_acc_id && opt?.user_access;
              });
            } else {
              accObj = options.find((opt) => {
                return opt?.user_access;
              });
            }
            if (accObj?.connection_type === "SNOWFLAKE" && accObj?.setup_done) {
              setActivePage("Home");
              changeActiveModule(
                "Home",
                "",
                module?.children && !module?.dontDisplayChildren
                  ? module?.children[0]?.page_display_name
                  : "",
                undefined,
                "",
                undefined,
                accObj
              );
              navigate(0);
            } else if (accObj?.setup_done) {
              const obj = menuList?.find((obj) => {
                return obj?.dw_type === accObj?.connection_type;
              });
              if (Object.keys(obj)?.length && obj?.children?.length) {
                setActivePage(obj?.children[0]?.page_display_name);
                changeActiveModule(
                  obj?.page_display_name,
                  obj?.children[0]?.page_url,
                  obj?.children[0]?.page_display_name,
                  undefined,
                  "",
                  obj?.children[0]?.submodule_name,
                  accObj
                );
              }
              navigate(0);
            }
            // navigate("/dashboard/home");
            // navigate(0);
          }
          // setActiveView(menuList[0]?.page_url);
        }
        if (dwAcc && dwAcc.length) {
          setDwAccOptions(options ? options : []);
          if (dw_acc_id) {
            const accObj = options?.find((opt) => {
              return opt?.id.toString() === dw_acc_id && opt?.user_access;
            });
            if (accObj && Object.keys(accObj)?.length) {
              setAcc(accObj);
              if (!accObj?.setup_done) {
                clearData();
                navigate("/dashboard/connectionfallback");
                setIsLoading(false);
                setIsMenuLoading(false);
                return;
              }
            }
          } else {
            const accObj = options.find((opt) => {
              return opt?.user_access;
            });
            setAcc(accObj);
            if (!accObj?.setup_done) {
              clearData();
              const data = dataSourceData;
              setRawData(data);
              const menuList = createMenuList(data);
              setHeaderMenu(menuList);
              if (!params["*"]?.includes("settings")) {
                navigate("/dashboard/connectionfallback");
              }
              setIsLoading(false);
              setIsMenuLoading(false);
              return;
            }
          }
        }
        setIsMenuLoading(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    setIsMenuLoading(true);
    getMenu();
  }, []);

  useEffect(() => {
    const param = pathname?.replace("/dashboard/", "");
    const activeItemName =
      acc?.connection_type === "DATABRICKS"
        ? getDashboardKeysDBX(param)
        : getDashboardKeys(param);
    if (rawData) {
      let activeItem = rawData?.find((item) => {
        return activeItemName?.submodule
          ? item?.page_display_name === activeItemName?.name &&
              item?.module_name === activeItemName?.module &&
              item?.dw_type === acc?.connection_type &&
              item?.submodule_name === activeItemName?.submodule
          : item?.page_display_name === activeItemName?.name &&
              item?.module_name === activeItemName?.module &&
              item?.dw_type === acc?.connection_type;
      });
      if (!activeItem) {
        activeItem = dataSourceData?.find((item) => {
          return (
            item?.page_display_name === activeItemName?.name &&
            item?.module_name === activeItemName?.module
          );
        });
      }
      if (!activeItem) {
        activeItem = profileMenuData?.find((item) => {
          return (
            item?.page_display_name === activeItemName?.name &&
            item?.module_name === activeItemName?.module
          );
        });
      }
      if (activeItem) {
        if (activeItem?.is_module) {
          setActiveModule(activeItem?.page_display_name);
          if (!activeItem?.children) {
            setActivePage("");
          } else {
            setActivePage(activeItem?.children[0]?.page_display_name);
            setUrl(activeItem?.children[0]?.page_url);
            if (activeItem?.children[0]?.submodule_name) {
              changeActiveSubmodule(activeItem?.children[0]?.submodule_name);
            }
          }
        } else {
          let module =
            headerMenu &&
            headerMenu.length &&
            headerMenu?.find((menu) => {
              return (
                activeItem.page_display_name ===
                menu?.children?.find((child) => {
                  return (
                    child?.page_display_name === activeItem.page_display_name &&
                    child?.module_name === activeItem?.module_name &&
                    child?.dw_type === activeItem?.dw_type
                  );
                })?.page_display_name
              );
            });
          if (module) {
            setActivePage(activeItem.page_display_name);
            setActiveModule(module?.page_display_name);
            setUrl(activeItem?.page_url);
            if (activeItem?.submodule_name) {
              changeActiveSubmodule(activeItem?.submodule_name);
            }
            // setUrl(activeItem?.page_url);
          } else {
            module =
              dataSourceMenu &&
              dataSourceMenu.length &&
              dataSourceMenu?.find((menu) => {
                return (
                  activeItem.page_display_name ===
                  menu?.children?.find((child) => {
                    return (
                      child?.page_display_name ===
                        activeItem.page_display_name &&
                      child?.module_name === activeItem?.module_name
                    );
                  })?.page_display_name
                );
              });
            if (!module) {
              module =
                profileMenu &&
                profileMenu.length &&
                profileMenu?.find((menu) => {
                  return (
                    activeItem.page_display_name ===
                    menu?.children?.find((child) => {
                      return (
                        child?.page_display_name ===
                          activeItem.page_display_name &&
                        child?.module_name === activeItem?.module_name
                      );
                    })?.page_display_name
                  );
                });
            }
            setActivePage(activeItem.page_display_name);
            changeActiveModule(
              module?.page_display_name,
              activeItem.page_url,
              activeItem.page_display_name,
              params && params["*"]
            );
            if (activeItem?.submodule_name) {
              changeActiveSubmodule(activeItem?.submodule_name);
            }
          }
        }
      }
    } else {
      if (acc && Object.keys(acc)?.length) {
        if (!acc?.setup_done) {
          const data = dataSourceData;
          setRawData(data);
          const menuList = createMenuList(data);
          setHeaderMenu(menuList);
          if (!param?.includes("settings")) {
            navigate("/dashboard/connectionfallback");
          }
        }
      }
    }
  }, [pathname]);

  return (
    <div className="app-container">
      <AppHeader
        {...(headerMenu && headerMenu.length && acc && dwAccOptions
          ? {
              headerMenu: headerMenu,
              activeModule: activeModule,
              activeSubmodule: activeSubmodule,
              changeActiveSubmodule: changeActiveSubmodule,
              activePage: activePage,
              changeActiveModule: changeActiveModule,
              changeActivePage: changeActivePage,
              isMenuLoading,
              acc,
              dwAccOptions,
              changeAcc
            }
          : {
              isMenuLoading,
              acc,
              dwAccOptions,
              activeModule: activeModule,
              activePage: activePage,
              changeActiveModule: changeActiveModule,
              changeActivePage: changeActivePage,
              activeSubmodule: activeSubmodule,
              changeActiveSubmodule: changeActiveSubmodule,
              changeAcc
            })}
      />
      <div className="main-block">
        {!isLoading && (
          <Sidebar
            {...(headerMenu && headerMenu.length
              ? {
                  headerMenu: headerMenu,
                  activeModule: activeModule,
                  activePage: activePage,
                  changeActiveModule: changeActiveModule,
                  changeActivePage: changeActivePage,
                  isMenuLoading,
                  dwAccOptions,
                  profileMenu: profileMenu,
                  activeSubmodule: activeSubmodule,
                  changeActiveSubmodule: changeActiveSubmodule,
                  userInfo: userInfo,
                  acc: acc,
                  changeAcc: changeAcc,
                  //visible: acc?.setup_done ? true : false
                  visible: true,
                  enableLogout: false
                }
              : {
                  isMenuLoading,
                  // visible: acc?.setup_done ? true : false
                  visible: true
                })}
          />
        )}
        {!isLoading &&
          (activeModule === "Data Source" ||
          activeModule === "Home" ||
          activeModule === "Organization" ||
          activeModule === "Cost Explorer" ? (
            <ParentRoutes
              {...(headerMenu && headerMenu.length
                ? {
                    headerMenu: headerMenu,
                    activeModule: activeModule,
                    activePage: activePage,
                    changeActiveModule: changeActiveModule,
                    changeActivePage: changeActivePage,
                    isMenuLoading,
                    dwAccOptions,
                    profileMenu: profileMenu,
                    activeSubmodule: activeSubmodule,
                    changeActiveSubmodule: changeActiveSubmodule,
                    dwAccOptionsChanged
                  }
                : {
                    isMenuLoading
                  })}
              url={url}
              token={token}
              acc={acc}
            />
          ) : (
            <ViewController
              {...(headerMenu && headerMenu.length
                ? {
                    headerMenu: headerMenu,
                    activeModule: activeModule,
                    activePage: activePage,
                    changeActiveModule: changeActiveModule,
                    changeActivePage: changeActivePage,
                    isMenuLoading,
                    dwAccOptions,
                    profileMenu: profileMenu,
                    activeSubmodule: activeSubmodule,
                    changeActiveSubmodule: changeActiveSubmodule,
                    userInfo
                  }
                : {
                    isMenuLoading,
                    activeModule: activeModule,
                    activePage: activePage,
                    changeActiveModule: changeActiveModule,
                    changeActivePage: changeActivePage,
                    profileMenu: profileMenu,
                    activeSubmodule: activeSubmodule,
                    changeActiveSubmodule: changeActiveSubmodule,
                    userInfo,
                    dwAccOptions
                  })}
              url={url}
              token={token}
              acc={acc}
              changeSlackAlertSetup={changeSlackAlertSetup}
              isSlackAlertSetup={isSlackAlertSetup}
              changeSlackAlert={changeSlackAlert}
              changeTeamAlert={changeTeamAlert}
              slackAlert={slackAlert}
              changeTeamsAlertSetup={changeTeamsAlertSetup}
              isTeamAlertSetup={isTeamAlertSetup}
              TeamsAlert={TeamsAlert}
              //hasSidebar={acc?.setup_done ? true : false}
              hasSidebar={true}
            />
          ))}
      </div>
      {isLoading && (
        <div className="load">
          <div className="preload"></div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
