import { CustomTooltip } from "../../utils/tooltip-helper";
import {
  PrettyFormat,
  roundToSigDigits,
  secondsConverter
} from "../../utils/common-helper";
import { DownloadLinkButton } from "../../utils/query-helper";

export const getQGProps = (hasTrialFallback) => {
  const customProps = {
    index: {
      type: "rightAligned",
      width: 75,
      pinned: "left",
      headerClass: "text-align-right"
    },
    regex_id: {
      hide: true
    },
    query_text_regex: {
      width: 247,
      filter: true,
      pinned: "left",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(PrettyFormat(params.value), params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    warehouses: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    users: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    count: {
      width: 150,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      },
      headerClass: "text-align-right"
    },
    sum_partitions_scanned: {
      width: 200,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      },
      headerClass: "text-align-right"
    },
    avg_partitions_scanned: {
      width: 200,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_queued_overload_time: {
      width: 180,
      type: "rightAligned",
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_execution_time: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        return secondsConverter(roundToSigDigits(params?.value, 2, false));
      }
    },
    avg_execution_time: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        return secondsConverter(roundToSigDigits(params?.value, 2, false));
      }
    },
    avg_queued_overload_time: {
      width: 178,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_query_cost: {
      width: 109,
      type: "rightAligned",
      headerClass: "text-align-right",
      pinned: "right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      },
      suppressSizeToFit: true
    },
    avg_query_cost: {
      width: 145,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_gb_spilled_to_remote_storage: {
      width: 155,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_gb_spilled_to_remote_storage: {
      width: 155,
      type: "rightAligned",
      headerClass: "text-align-right",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    "Query Ids": {
      width: 90,
      pinned: "right",
      sortable: false,
      cellRenderer: (params) => {
        return DownloadLinkButton(
          params.value,
          hasTrialFallback && params?.rowIndex > 1 ? true : false
        );
      }
    }
  };

  return customProps;
};
