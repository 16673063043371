import React from "react";
import { Routes, Route } from "react-router-dom";
import PropTypes from "prop-types";
import DataSource from "../pages/DataSource";
import HealthDashboard from "../pages/HealthDashboard";
import OrganizationSummary from "../pages/OrganizationSummary";
import CostExplorer from "../pages/CostExplorer";
import ConnectAccounts from "../pages/ConnectAccounts";
import SelectConnection from "../pages/SelectConnection";
import ConnectionFallback from "../pages/ConnectionFallback";
import DBXCostExplorer from "../pages/DBXCostExplorer";
const ParentRoutes = ({
  url,
  token,
  acc,
  dwAccOptions,
  activeModule,
  changeActiveModule,
  dwAccOptionsChanged
}) => {
  return (
    <Routes>
      <Route
        path="/selectconnection"
        element={<SelectConnection isOnboarded={true} />}
      />
      <Route path="/connectionfallback" element={<ConnectionFallback />} />
      {[
        "/selectconnection/connectaccounts/:acc",
        "/selectconnection/connectaccounts/:acc/:step/:dwacc"
      ].map((path, index) => (
        <Route
          path={path}
          key={index}
          element={<ConnectAccounts isOnboarded={true} />}
        />
      ))}

      <Route
        path="/home"
        exact
        element={
          <HealthDashboard
            url={url}
            token={token}
            acc={acc}
            changeActiveModule={changeActiveModule}
          />
        }
      />
      <Route
        path="/organization_summary"
        exact
        element={
          <OrganizationSummary
            url={url}
            token={token}
            acc={acc}
            changeActiveModule={changeActiveModule}
          />
        }
      />
      {acc?.connection_type === "SNOWFLAKE" ? (
        <Route
          path="/costexplorer/*"
          index
          element={
            <CostExplorer
              url={url}
              token={token}
              acc={acc}
              changeActiveModule={changeActiveModule}
            />
          }
        />
      ) : (
        <Route
          path="/costexplorer/*"
          index
          element={
            <DBXCostExplorer
              url={url}
              token={token}
              acc={acc}
              changeActiveModule={changeActiveModule}
            />
          }
        />
      )}

      <Route
        path="/costexplorer"
        element={
          <CostExplorer
            url={url}
            token={token}
            acc={acc}
            changeActiveModule={changeActiveModule}
          />
        }
      />

      <Route
        path="/datasource"
        exact
        element={
          <DataSource
            url={url}
            token={token}
            acc={acc}
            dwAccOptions={dwAccOptions}
            activeModule={activeModule}
            dwAccOptionsChanged={dwAccOptionsChanged}
          />
        }
      />
    </Routes>
  );
};
ParentRoutes.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  dwAccOptions: PropTypes.array,
  activeModule: PropTypes.string,
  changeActiveModule: PropTypes?.func,
  changeActivePage: PropTypes?.func,
  dwAccOptionsChanged: PropTypes?.func
};
export default ParentRoutes;
