import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./overview.scss";
import {
  createAllWorkspaceOptions,
  createClusterGraphdata,
  createGroupByOptions,
  getAllWorkspaceListAPI,
  getClusterOverviewDataAPI,
  getClusterOverviewDateRangeAPI,
  getClusterOverviewMetaAPI,
  getTotalDisplayDataAPI,
  pageTabOptions
} from "../../../utils/dbx-cluster-helper";
import ClusterOverviewPage from "../../../components/DBXComponents/ClusterOverview/ClusterOverviewPage";
import {
  createDateRangeOptions,
  defaultGranularityOptions,
  findDateRangeValue,
  getStartAndEndDates
} from "../../../utils/date-helper";
import { useNavigate } from "react-router-dom";
import { createColDefs } from "../../../utils/common-helper";
import { getClusterOverviewProps } from "./clusteroverviewdefs";
import { getSessionStorage } from "../../../utils/storage-helper";

const DBXClusterOverview = (props) => {
  const { acc } = props;
  const navigate = useNavigate();
  const [hasTrialFallback] = useState(
    getSessionStorage("plan_name").toLowerCase() === "trial" ? true : false
  );
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [allWorkspaces, setAllWorkspaces] = useState([]);
  const [selectedWorkSpace, setSelectedWorkSpace] = useState([]);
  const [pageTabs, setPageTabs] = useState({});
  const [navigationState, setNavigationState] = useState({});
  const [dateRange, setDateRange] = useState({});
  const [dateRangeOptions, setDateOptions] = useState([]);
  const [dateRangeValues, setDateRangeValues] = useState({});
  const [groupBy, setGroupBy] = useState({});
  const [granularity, setGranularity] = useState(
    defaultGranularityOptions?.[0]
  );
  const [groupByOptions, setGroupByOptions] = useState([]);
  const [granularityOptions, setGranularityOptions] = useState([]);
  const [totalValues, setTotalValues] = useState({});
  const [overviewAPIData, setOverviewAPIData] = useState({});
  const [metaAPIData, setMetaAPIData] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [costGraphData, setCostGraphData] = useState({});
  const [clusterGraphData, setClusterGraphData] = useState({});
  const [gridLoading, setGridLoading] = useState(true);
  const [chartLoading, setChartLoading] = useState(true);
  const [memorizedData, setMemorizedData] = useState([]);
  const [costGraphTabs, setCostGraphTabs] = useState({
    label: "$",
    value: "cost"
  });
  const [clusterGraphTabs, setClusterGraphTabs] = useState({
    label: "Count",
    value: "active_cluster_count"
  });

  // initial load
  useEffect(() => {
    if (acc) {
      setIsPageLoading(true);
      getPageInformations(acc);
    }
  }, [acc]);

  //  clear memories
  const clearMemorizedData = () => {
    setMemorizedData([]);
  };

  const getAllSearchParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = {};

    urlParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  };

  // handle grid and Chart loading
  const handleGridAndChartLoading = (bool) => {
    setGridLoading(bool);
    setChartLoading(bool);
  };

  // handle page tabs
  const handlePageTabs = (obj) => {
    setIsPageLoading(true);
    handleGridAndChartLoading(true);
    setPageTabs(obj);
    const updatedState = {
      cluster_type: obj?.value,
      start_date: navigationState?.start_date,
      end_date: navigationState?.end_date
    };
    clearMemorizedData();
    getDateRange(
      acc,
      updatedState?.start_date,
      updatedState?.end_date,
      groupBy?.value,
      granularity?.value,
      obj?.value
    );
    setNavigationState(updatedState);
  };

  // handle group by dropdown changes
  const handleGroupByChange = (e) => {
    setGroupBy(e);
    clearMemorizedData();
    let updatedState = {
      ...navigationState,
      group_by: e?.value
    };
    handleGridAndChartLoading(true);
    getOverviewData(
      acc,
      navigationState?.start_date,
      navigationState?.end_date,
      e.value,
      granularity.value,
      pageTabs?.value,
      1,
      50,
      [],
      [],
      false
    );
    setNavigationState(updatedState);
    handleURLandNavigation(updatedState);
  };

  // initialize the page
  const getPageInformations = (acc) => {
    getAllworkspace(acc);
    const searchParams = getAllSearchParams();
    setGranularityOptions(defaultGranularityOptions);
    let filterValues = getSessionStorage("pageFilters");
    filterValues = JSON.parse(filterValues);
    const start_date = searchParams?.start_date;
    const end_date = searchParams?.end_date;
    const group_by = searchParams?.group_by;
    const date_grouping = searchParams?.granularity;
    const cluster_type = searchParams?.cluster_type;
    const page_num = searchParams?.page;
    const pageSize = searchParams?.size;
    // const workspace_filter = searchParams?.workspace_filter || [];
    setPageTabs(
      pageTabOptions.find((item) => item.value === cluster_type) ||
        pageTabOptions[0]
    );
    setGranularity(
      defaultGranularityOptions.find((item) => item.value === date_grouping) ||
        defaultGranularityOptions[0]
    );
    if (start_date && end_date && group_by) {
      const initialState = {
        start_date: start_date || dateRangeValues?.start_date,
        end_date: end_date || dateRangeValues?.end_date,
        group_by: group_by || groupBy?.value,
        granularity: date_grouping || granularity.value,
        cluster_type: cluster_type || pageTabs?.value,
        page: page_num,
        size: pageSize
      };
      setNavigationState(initialState);
      getDateRange(
        acc,
        start_date,
        end_date,
        group_by,
        date_grouping,
        cluster_type,
        page_num,
        pageSize,
        filterValues || [],
        selectedWorkSpace
      );
    } else {
      const updatedState = {
        ...navigationState,
        cluster_type: pageTabOptions?.[0]?.value
      };
      setNavigationState(updatedState);
      getDateRange(acc);
    }
  };

  // handle date range dropdown
  const handleDateRangeChange = (e) => {
    setDateRange(e);
    clearMemorizedData();
    let newDates;
    if (
      e.label === "Last 3 months" ||
      e.label === "Last 6 months" ||
      e.label === "Last 1 year"
    ) {
      if (e.label === "Last 1 year") {
        newDates = getStartAndEndDates(e?.value, 13, dateRangeValues?.end_date);
      } else if (e.label === "Last 6 months") {
        newDates = getStartAndEndDates(e?.value, 6, dateRangeValues?.end_date);
      } else if (e.label === "Last 3 months") {
        newDates = getStartAndEndDates(e?.value, 3, dateRangeValues?.end_date);
      }
    } else {
      newDates = getStartAndEndDates(e?.value, 1, dateRangeValues?.end_date);
    }
    setNavigationState({
      ...navigationState,
      start_date: newDates?.startDate,
      end_date: newDates?.endDate
    });
    handleURLandNavigation({
      ...navigationState,
      start_date: newDates?.startDate,
      end_date: newDates?.endDate
    });
    getClusterOverviewMetaData(
      acc,
      newDates?.startDate,
      newDates?.endDate,
      groupBy.value,
      granularity?.value,
      pageTabs?.value,
      1,
      50,
      []
    );
  };

  // handle granularity dropdown
  const handleGranularityChange = (e) => {
    setGranularity(e);
    clearMemorizedData();
    let filterVlues = getSessionStorage("pageFilters");
    filterVlues = JSON.parse(filterVlues);
    getTotalValues(
      acc,
      navigationState?.start_date,
      navigationState?.end_date,
      navigationState?.group_by,
      e.value,
      navigationState?.cluster_type,
      1,
      50,
      filterVlues ?? []
    );
    const updatedState = { ...navigationState, date_grouping: e.value };
    setNavigationState(updatedState);
    handleURLandNavigation(updatedState);
  };
  // get all workspace API call
  const getAllworkspace = async (acc) => {
    try {
      const workSpaceList = await getAllWorkspaceListAPI(acc);
      if (workSpaceList) {
        const list = createAllWorkspaceOptions(workSpaceList);
        setAllWorkspaces(list);
      }
    } catch (error) {
      setAllWorkspaces([]);
      console.log(error);
    }
  };
  // DateRange API call
  const getDateRange = async (
    acc,
    startDate = null,
    endDate = null,
    group_by = null,
    date_grouping,
    cluster_type = "overall",
    page_num,
    pageSize,
    filter_values = []
  ) => {
    try {
      const dateRangeOption = await getClusterOverviewDateRangeAPI(acc);
      if (dateRangeOption?.values) {
        setMemorizedData([]);
        const options = createDateRangeOptions(
          dateRangeOption?.values?.start_date,
          dateRangeOption?.values?.end_date
        );
        setDateRangeValues(dateRangeOption?.values);
        setDateOptions(options);
        if (startDate && endDate) {
          const selectedOption = findDateRangeValue(
            options,
            startDate,
            endDate
          );
          setDateRange(selectedOption);
          getClusterOverviewMetaData(
            acc,
            startDate,
            endDate,
            group_by,
            date_grouping,
            cluster_type,
            page_num,
            pageSize,
            filter_values
          );
        } else {
          setDateRange(options?.[options?.length - 1]);
          getClusterOverviewMetaData(
            acc,
            dateRangeOption?.values?.start_date,
            dateRangeOption?.values?.end_date,
            group_by,
            date_grouping,
            cluster_type
          );
        }
      }
    } catch (error) {
      console.log(error);
      setIsPageLoading(false);
    }
  };

  // Metadata API call
  const getClusterOverviewMetaData = async (
    acc,
    start_date,
    end_date,
    group_by = "cluster_name",
    date_grouping,
    cluster_type = navigationState?.cluster_type || "overall",
    page_num,
    pageSize,
    filter_values = [],
    workspace_filter = selectedWorkSpace || []
  ) => {
    try {
      const metaData = await getClusterOverviewMetaAPI(
        acc,
        start_date,
        end_date,
        cluster_type,
        workspace_filter
      );
      if (metaData) {
        setMetaAPIData(metaData);
        const grpOptions = createGroupByOptions(metaData?.metadata?.groupby);
        setGroupByOptions(grpOptions);
        const defaultValue =
          grpOptions.find((item) => item.value === group_by) || grpOptions[1];
        setGroupBy(defaultValue);
        const updatedState = {
          ...navigationState,
          start_date,
          end_date,
          group_by: defaultValue.value,
          cluster_type
        };
        setNavigationState(updatedState);
        getTotalValues(
          acc,
          start_date,
          end_date,
          defaultValue.value,
          date_grouping || granularity?.value,
          cluster_type,
          page_num,
          pageSize,
          filter_values
        );
        setIsPageLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsPageLoading(false);
    }
  };

  const getTotalValues = async (
    acc,
    start_date = dateRangeValues?.start_date,
    end_date = dateRangeValues?.end_date,
    group_by = groupBy.value,
    date_grouping = granularity?.value || "monthly",
    cluster_type = pageTabs?.value || "overall",
    page_num,
    pageSize,
    filter_values = [],
    workspace_filter
  ) => {
    try {
      const totalData = await getTotalDisplayDataAPI(
        acc,
        start_date,
        end_date,
        group_by,
        date_grouping,
        cluster_type
      );
      if (totalData) {
        setTotalValues(totalData);
        getOverviewData(
          acc,
          start_date,
          end_date,
          group_by,
          date_grouping,
          cluster_type,
          page_num,
          pageSize,
          filter_values,
          workspace_filter,
          false,
          totalData?.totals
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Overview data API call
  const getOverviewData = async (
    acc,
    start_date = navigationState?.start_date,
    end_date = navigationState?.end_date,
    group_by = navigationState?.group_by,
    date_grouping = "monthly",
    cluster_type = "overall",
    page_num = 1,
    page_size = 50,
    filter_values = [],
    workspace_filter = selectedWorkSpace,
    useOldData = false,
    totals = totalValues?.totals
  ) => {
    const customProps = getClusterOverviewProps(hasTrialFallback, totals);
    if (
      memorizedData?.[page_num - 1]?.aggregated_data &&
      memorizedData?.[page_num - 1]?.analysis_data &&
      useOldData
    ) {
      setOverviewAPIData(memorizedData?.[page_num - 1]);
      const displayNames = _.reduce(
        memorizedData?.[page_num - 1]?.metadata?.columns,
        (acc, obj) => {
          acc[obj.id] = obj.title;
          return acc;
        },
        {}
      );
      if (displayNames) {
        const colDefsArr = createColDefs(
          memorizedData?.[page_num - 1]?.aggregated_data?.columns,
          customProps,
          null,
          displayNames
        );
        colDefsArr.unshift({
          cellClass: "rightAligned",
          headerClass: "rightAligned",
          field: "index",
          filter: false,
          headerName: "S No.",
          width: 80
        });
        setColumnDefs(colDefsArr);
      }
      const CostGraph = createClusterGraphdata(
        memorizedData?.[page_num - 1]?.analysis_data?.data,
        group_by,
        costGraphTabs?.value
      );
      const ClusterGraph = createClusterGraphdata(
        memorizedData?.[page_num - 1]?.analysis_data?.data,
        group_by,
        clusterGraphTabs?.value
      );
      setCostGraphData(CostGraph);
      setClusterGraphData(ClusterGraph);
      const rowdata = memorizedData?.[page_num - 1]?.aggregated_data?.data.map(
        (item, index) => ({
          ...item,
          index: (page_num - 1) * page_size + (index + 1)
        })
      );
      setGridData(rowdata);
      const updatedState = {
        start_date,
        end_date,
        group_by,
        date_grouping,
        cluster_type,
        page: page_num,
        size: page_size,
        filterval: filter_values
      };
      handleURLandNavigation(updatedState);
      handleGridAndChartLoading(false);
      setIsPageLoading(false);
    } else {
      try {
        const overViewData = await getClusterOverviewDataAPI(
          acc,
          start_date,
          end_date,
          group_by,
          date_grouping,
          cluster_type,
          page_num,
          page_size,
          filter_values,
          workspace_filter
        );

        if (overViewData) {
          let apiData;
          if (!useOldData) {
            apiData = [];
            apiData[page_num - 1] = overViewData;
          } else {
            apiData = JSON.parse(JSON.stringify(memorizedData));
            apiData[page_num - 1] = overViewData;
          }
          setMemorizedData(apiData);

          setOverviewAPIData(overViewData);
          const displayNames = _.reduce(
            overViewData?.metadata?.columns,
            (acc, obj) => {
              acc[obj.id] = obj.title;
              return acc;
            },
            {}
          );
          if (displayNames) {
            const colDefsArr = createColDefs(
              overViewData?.aggregated_data?.columns,
              customProps,
              null,
              displayNames
            );
            colDefsArr.unshift({
              cellClass: "rightAligned",
              headerClass: "rightAligned",
              suppressSizeToFit: true,
              field: "index",
              filter: false,
              headerName: "S No.",
              width: 80
            });
            setColumnDefs(colDefsArr);
          }
          const CostGraph = createClusterGraphdata(
            overViewData?.analysis_data?.data,
            group_by,
            costGraphTabs?.value
          );
          const ClusterGraph = createClusterGraphdata(
            overViewData?.analysis_data?.data,
            group_by,
            clusterGraphTabs?.value
          );
          setCostGraphData(CostGraph);
          setClusterGraphData(ClusterGraph);
          const rowdata = overViewData?.aggregated_data?.data.map(
            (item, index) => ({
              ...item,
              index: (page_num - 1) * page_size + (index + 1)
            })
          );
          setGridData(rowdata);
          const updatedState = {
            start_date,
            end_date,
            group_by,
            date_grouping,
            cluster_type,
            page: page_num,
            size: page_size
          };
          handleURLandNavigation(updatedState);
          handleGridAndChartLoading(false);
          setIsPageLoading(false);
        }
      } catch (error) {
        console.log(error);
        setOverviewAPIData([]);
        setGridData([]);
        setCostGraphData({});
        setClusterGraphData({});
        setIsPageLoading(false);
        handleGridAndChartLoading(false);
      }
    }
  };
  // Handle cost graph tabs
  const handleCostGraphTabs = (obj) => {
    const CostGraph = createClusterGraphdata(
      overviewAPIData?.analysis_data?.data,
      groupBy?.value,
      obj?.value
    );
    setCostGraphTabs(obj);
    setCostGraphData(CostGraph);
  };
  // Handle cluster graph tabs
  const handleClusterGraphTabs = (obj) => {
    const ClusterGraph = createClusterGraphdata(
      overviewAPIData?.analysis_data?.data,
      groupBy?.value,
      obj?.value
    );
    setClusterGraphTabs(obj);
    setClusterGraphData(ClusterGraph);
  };

  // Handle page URL
  const handleURLandNavigation = (navigationState, shouldNavigate = false) => {
    const url = new URLSearchParams(window.location.search);
    for (const key in navigationState) {
      if (Object.prototype.hasOwnProperty.call(navigationState, key)) {
        const value = navigationState[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            url.append(key, item);
          });
        } else {
          url.set(key, value);
        }
      }
    }
    const newUrl = `${window.location.pathname}?${url.toString()}`;

    if (shouldNavigate) {
      navigate(newUrl);
    } else {
      window.history.pushState(null, "", newUrl);
    }
  };

  return isPageLoading ? (
    <div className="load">
      <div className="preload"></div>
    </div>
  ) : (
    <ClusterOverviewPage
      acc={acc}
      dateRange={dateRange}
      dateRangeOption={dateRangeOptions}
      handleDateRangeChange={handleDateRangeChange}
      pageTabOptions={pageTabOptions}
      pageTabs={pageTabs}
      groupBy={groupBy}
      groupByOptions={groupByOptions}
      granularityOptions={granularityOptions}
      granularity={granularity}
      handleGranularityChange={handleGranularityChange}
      handlePageTabs={handlePageTabs}
      handleGroupByChange={handleGroupByChange}
      totalValues={totalValues}
      columnDefs={columnDefs}
      gridData={gridData}
      costGraphData={costGraphData}
      clusterGraphData={clusterGraphData}
      costGraphTabs={costGraphTabs}
      clusterGraphTabs={clusterGraphTabs}
      handleCostGraphTabs={handleCostGraphTabs}
      handleClusterGraphTabs={handleClusterGraphTabs}
      gridLoading={gridLoading}
      chartLoading={chartLoading}
      getOverviewData={getOverviewData}
      navigationState={navigationState}
      handleGridAndChartLoading={handleGridAndChartLoading}
      setNavigationState={setNavigationState}
      handleURLandNavigation={handleURLandNavigation}
      paginationInfo={overviewAPIData?.pagination}
      metaAPIData={metaAPIData}
      allWorkspaces={allWorkspaces}
      clearMemorizedData={clearMemorizedData}
      setSelectedWorkSpace={setSelectedWorkSpace}
      selectedWorkSpace={selectedWorkSpace}
    />
  );
};

DBXClusterOverview.propTypes = {
  acc: PropTypes.object
};

export default DBXClusterOverview;
