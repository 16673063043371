import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./tablegroups.scss";
import chev_down from "../../assets/images/common/chev_down.svg";
import {
  createColDefs,
  getAutoheightOverflow,
  getGridHeight,
  getSortingValueObj,
  getValueObj,
  handleGridResolution
} from "../../utils/common-helper";
import GridComponent from "../../components/GridComponent";
import Select, { components } from "react-select";
import { getTGProps } from "./tablegroupscoldefs";
import {
  getTableGroupsData,
  // getTGDatabaseName,
  getTGDateRange,
  getTGOwners,
  getTGSortingOptions
} from "../../utils/table-helper";
import { getSessionStorage } from "../../utils/storage-helper";
import Button from "../../components/customElements/Button";
import FilterIcon from "../../assets/images/grid/Filter.svg";
import ExportCSV from "../../assets/images/grid/exportcsv.svg";
import CustomFilter from "../../components/CustomFilter/CustomFilter";
import expand from "../../assets/images/common/expand.svg";
import groupSvg from "../../assets/images/dashboard/group.svg";
import {
  TGFilterColumns,
  applyFilterToGrid,
  clearAllFilters,
  filterTypes,
  postFilter
} from "../../utils/filter-helper";
import ExpandComponent from "../../components/ExpandComponent";

const TableGroups = ({ acc, changeActiveModule }) => {
  const [hasTrialFallback] = useState(
    getSessionStorage("plan_name").toLowerCase() === "trial" ? true : false
  );
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };

  const customProps = getTGProps(hasTrialFallback);
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const univerSalStyle = {
    cellStyle: (params) => {
      if (hasTrialFallback && params.rowIndex > 1) {
        return {
          userSelect: "none",
          "-webkit-user-select": "none"
        };
      }
      return {};
    }
  };

  const [rowData, setRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [gridData, setGridData] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [sortingOptions, setSortingOptions] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [sorting, setSorting] = useState(null);
  const [gridFallback, setGridFallback] = useState(false);
  const [gridResolution, setGridResolution] = useState(true);
  const [gridTotalWidth, setGridTotalWidth] = useState(null);

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [downloadCSV, setDownloadCSV] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [expandModal, setExpandModal] = useState(false);
  const [applyFilter, setApplyFilter] = useState([
    {
      column: TGFilterColumns?.[0],
      condition: filterTypes?.[0],
      value: [],
      dropDown: false,
      previuosValue: [],
      previousMenuList: []
    }
  ]);

  const handleExpand = (bool) => {
    setExpandModal(bool);
  };

  const getTableData = async (dateRange, sorting, dbName = [], owner = []) => {
    try {
      if (
        sorting &&
        Object.keys(sorting).length > 0 &&
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        acc &&
        Object.keys(acc).length > 0
      ) {
        const data = await getTableGroupsData({
          data_wh_id: acc?.id,
          date_range: dateRange?.label,
          sorted_by: sorting?.value,
          database_name: dbName,
          owner: owner
        });
        const maps = data?.column_display_name_mappping;
        maps.index = "S No.";
        data.columns.unshift("index");
        if (data) {
          const colDefsArr = createColDefs(
            data?.columns,
            customProps,
            univerSalStyle,
            maps
          );
          if (colDefsArr && data?.data) {
            data.data.forEach((value, index) => {
              value.index = index + 1;
            });
            const newData = colDefsArr.map((colDef) => ({
              ...colDef,
              unSortIcon: true,
              sortable: true,
              filter: false
            }));
            setColumnDefs(newData);
            setRowData(data?.data);
            setGridData(data?.data);
            let screenInnerWidth = window.innerWidth;
            let gridWidth = Object.values(newData).reduce((sum, prop) => {
              if (typeof prop.width === "number" && !isNaN(prop.width)) {
                return sum + prop.width;
              }
              return sum;
            }, 0);
            setGridResolution(
              handleGridResolution(gridWidth, screenInnerWidth, 360)
            );
            setGridTotalWidth(gridWidth);
          }
        } else {
          clearData();
        }
        setGridLoading(false);
        if (gridFallback) {
          setGridFallback(false);
        }
      }
    } catch (err) {
      console.log(err);
      setGridLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
    }
  };

  const clearData = () => {
    setRowData([]);
    setGridData([]);
  };

  const getOwners = async (dateRange) => {
    try {
      if (
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        Object.keys(acc).length > 0
      ) {
        const ownerObj = await getTGOwners({
          data_wh_id: acc?.id,
          date_range: dateRange?.label
        });
        return ownerObj;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    let sortingArr = [];
    let dateRangeArr = [];
    // let dbNameArr = [];
    // let ownerArr = null;
    try {
      const dateRangeObj = await getTGDateRange(acc ? acc?.id : "");
      const sortingOptionsObj = await getTGSortingOptions();
      if (dateRangeObj?.values) {
        dateRangeArr = getValueObj(dateRangeObj?.values);

        setDateRangeOptions(dateRangeArr);
        setDateRange(dateRangeArr[0]);
      }
      if (sortingOptionsObj?.values) {
        sortingArr = getSortingValueObj(sortingOptionsObj?.values);
        setSortingOptions(sortingArr);
        setSorting(sortingArr[0]);
      }
      if (dateRangeArr && sortingArr && acc) {
        getTableData(dateRangeArr[0], sortingArr[0], [], []);
      } else {
        clearData();
      }
      if (dateRangeArr && acc) {
        getOwners(dateRangeArr[0]);
      } else {
        clearData();
      }
      setGridLoading(false);
      setIsPageLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
      console.log(err);
    }
  };

  const handlePopover = (e) => {
    if (!hasTrialFallback) {
      e?.stopPropagation();
      setShowFilters(!showFilters);
    }
  };

  const handleExport = () => {
    if (!hasTrialFallback) {
      setDownloadCSV(true);
      setTimeout(() => {
        setDownloadCSV(false);
      }, 3000);
    }
  };
  const clearGridFilter = () => {
    setGridData(rowData);
    setFilterApplied(false);
    const filterData = clearAllFilters(applyFilter);
    setApplyFilter(filterData);
  };
  const handleGridFilter = (applyifilters) => {
    const filteredData = applyFilterToGrid(applyifilters, rowData);
    setGridData(filteredData);
    const handleFilterValue = postFilter(applyifilters);
    setApplyFilter(handleFilterValue);
    setFilterApplied(true);

    setTimeout(() => {
      setGridLoading(false);
    }, 1500);
  };
  useEffect(() => {
    setIsPageLoading(true);
    setGridLoading(true);
    getData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let dateRangeArr = [];

      setGridLoading(true);
      if (acc) {
        const dateRangeObj = await getTGDateRange(acc ? acc?.id : "");
        if (dateRangeObj?.values) {
          dateRangeArr = getValueObj(dateRangeObj?.values);
          setDateRangeOptions(dateRangeArr);
          setDateRange(dateRangeArr[0]);
        }
        if (dateRangeArr && dateRangeArr?.length && sorting) {
          getTableData(dateRangeArr[0], sorting, []);
        }
      }
    };
    fetchData();
  }, [acc]);
  return (
    <>
      {expandModal && (
        <>
          <ExpandComponent
            columnDefs={columnDefs}
            rowData={gridData}
            gridFallback={gridFallback}
            handleExpand={handleExpand}
            gridStyle={{
              height: getGridHeight(gridData, window?.innerHeight, 30, 180),
              avoidAutoHeightOverflow: getAutoheightOverflow(gridData)
            }}
            gridLoading={gridLoading}
            noRowsText={
              "Table groups for the selected period or account are currently unavailable. Coming soon..."
            }
            downloadCSV={downloadCSV}
            tableName={"Top 50 Tables"}
            hasTrialFallback={hasTrialFallback}
            changeActiveModule={changeActiveModule}
            suppressColumnsSize={handleGridResolution(
              gridTotalWidth,
              window.innerWidth,
              96
            )}
          />
        </>
      )}
      <div className="tg-container overflow-auto">
        {!isPageLoading ? (
          <>
            <div className="grid-block">
              <div className="grid-header-container">
                <div className="grid-header-block">
                  <div className="grid-header">
                    <span>Top 50 Tables</span>
                  </div>
                </div>
                <div className="select-container">
                  <Select
                    value={dateRange}
                    options={dateRangeOptions}
                    styles={customStyles}
                    classNamePrefix="selectcategory"
                    placeholder="select"
                    isSearchable={false}
                    components={{ DropdownIndicator }}
                    onChange={async (e) => {
                      setGridLoading(true);
                      setDateRange(e);
                      getTableData(e, sorting, [], []);
                    }}
                  />
                </div>
              </div>
              <div className="grid-filter-container">
                <div className="top-filter">
                  <div className="select-container-groupby">
                    <Select
                      value={(() => {
                        return {
                          ...sorting,
                          label: `Sort By • ${
                            sorting?.label == "None" || sorting == null
                              ? "Unsorted"
                              : sorting?.label
                          }`
                        };
                      })()}
                      isSearchable={false}
                      classNamePrefix="selectcategory"
                      placeholder="select"
                      components={{ group: groupSvg, DropdownIndicator }}
                      styles={customStyles}
                      options={sortingOptions}
                      onChange={(e) => {
                        setGridLoading(true);
                        setSorting(e);
                        getTableData(dateRange, e);
                      }}
                    />
                  </div>
                  <div className="select-container">
                    <Button
                      onClick={handlePopover}
                      disabled={hasTrialFallback}
                      className={
                        showFilters
                          ? "qg-filter-button-active "
                          : filterApplied
                          ? "qg-filter-active"
                          : "qg-filter-button"
                      }>
                      <img src={FilterIcon} alt="" />
                      <span>Filter</span>
                    </Button>
                    {showFilters && !hasTrialFallback && (
                      <CustomFilter
                        column={TGFilterColumns}
                        gridData={rowData}
                        applyFilter={applyFilter}
                        setApplyFilter={setApplyFilter}
                        close={handlePopover}
                        handleFilter={handleGridFilter}
                        clearFilter={clearGridFilter}
                        isMenuOpen={showFilters}
                      />
                    )}
                  </div>
                </div>
                <div className="top-filter date-container ">
                  <Button
                    onClick={() => {
                      handleExpand(true);
                    }}
                    className={"qg-filter-button expand__button"}>
                    <img src={expand} alt="" />
                    <span>Expand</span>
                  </Button>
                  <div>
                    <Button
                      onClick={handleExport}
                      disabled={hasTrialFallback}
                      className={"qg-filter-button"}>
                      <img src={ExportCSV} alt="" />
                      <span>Export</span>
                    </Button>
                  </div>
                </div>
              </div>

              <GridComponent
                columnDefs={columnDefs}
                rowData={gridData}
                gridFallback={gridFallback}
                gridStyle={{
                  height: getGridHeight(
                    gridData,
                    window?.innerHeight - 262,
                    30,
                    180
                  ),
                  avoidAutoHeightOverflow: getAutoheightOverflow(gridData)
                }}
                hasTrialFallback={hasTrialFallback}
                gridLoading={gridLoading}
                noRowsText={
                  "Table groups for the selected period or account are currently unavailable. Coming soon..."
                }
                changeActiveModule={changeActiveModule}
                downloadCSV={downloadCSV}
                tableName={"Top 50 Tables"}
                suppressColumnsSize={gridResolution}
              />
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
TableGroups.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  changeActiveModule: PropTypes.func
};
export default TableGroups;
