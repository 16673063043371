import { DateTime } from "luxon";
import { getSessionStorage } from "./storage-helper";

export const defaultGranularityOptions = [
  { label: "Monthly", value: "monthly" },
  { label: "Weekly", value: "weekly" },
  { label: "Daily", value: "daily" }
];

export const formatDate = (date) => {
  if (date !== null && date !== undefined) {
    const month = [
      "Jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    const newDate = new Date(date);
    const dateString =
      newDate.getDate() +
      "  " +
      month[newDate.getMonth()] +
      "  " +
      newDate.getFullYear();
    return dateString;
  }
  return "-";
};

export const customFormatDate = (date, granularity) => {
  const dt = DateTime.fromISO(date);
  if (granularity?.value === "monthly") {
    return dt.toFormat("yyyy-MM");
  } else if (
    granularity?.value === "daily" ||
    granularity?.value === "weekly"
  ) {
    return dt.toFormat("yyyy-MM-dd");
  }
  return date; // Default to ISO if granularity is not recognized
};

export const customDateTimeFormat = (date, format) => {
  const dt = DateTime.fromISO(date);
  return dt.toFormat(format);
};
export const formatDateTime = (
  date,
  asString = false,
  includeTime = false,
  includeTimezone = false
) => {
  if (date !== null && date !== undefined) {
    const timezone = getTimezone();
    const jsDate = new Date(date);
    const timezonedDate = DateTime.fromJSDate(jsDate, { zone: timezone });
    if (asString === true) {
      const datetimeFormat = `dd LLL yyyy${
        includeTime === true ? " HH:mm" : ""
      }${includeTimezone === true ? " ZZZZ" : ""}`;
      return timezonedDate.toFormat(datetimeFormat);
    } else {
      return timezonedDate;
    }
  }
  return "-";
};
export function getMonthsArray(startDateString, endDateString) {
  const startDate = DateTime.fromISO(startDateString);
  const endDate = DateTime.fromISO(endDateString);

  const monthsArray = [];

  let currentDate = startDate;

  while (currentDate <= endDate) {
    monthsArray.push(currentDate.toFormat("yyyy-MM"));
    currentDate = currentDate.plus({ months: 1 });
  }
  return monthsArray;
}
export function getMonthRange(monthString, end_date) {
  let mtd = false;
  const currentDate = DateTime.local();
  const requestedDate = DateTime.fromFormat(monthString, "yyyy-MM");

  let startDate, endDate;

  if (requestedDate.hasSame(currentDate, "month")) {
    // If the requested month is the current month
    startDate =
      currentDate?.get("day") === 1
        ? currentDate?.minus({ months: 1 })
        : requestedDate.startOf("month");
    endDate = currentDate?.get("day") === 1 ? currentDate : end_date;
    mtd = true;
  } else {
    startDate = requestedDate.startOf("month");
    endDate = requestedDate.endOf("month").plus({ days: 1 });
  }

  return { startDate, endDate, mtd };
}
export const getTimezone = () => {
  const globalSetting = getSessionStorage("GlobalSetting");
  if (globalSetting && globalSetting?.timezone) {
    return globalSetting.timezone;
  } else {
    return "UTC";
  }
};

export const getDateRangeOptionsArray = (arr, end_date) => {
  const today = DateTime.fromISO(end_date).minus({ days: 1 });
  const currentYear = today.year;
  const currentMonth = today.month;
  const currentDate = today.day;

  return arr.map((date) => {
    const [year, month] = date.split("-");
    const dt = DateTime.fromObject({
      year: parseInt(year, 10),
      month: parseInt(month, 10)
    });
    const monthLabel = dt.toFormat("MMM");
    let valueLabel = `${monthLabel} ${year}`;

    if (
      parseInt(year, 10) === currentYear &&
      parseInt(month, 10) === currentMonth
    ) {
      valueLabel = `${monthLabel} ${year}`;
      return {
        label: `01 - ${currentDate} ${monthLabel} ${year} (MTD)`,
        value: valueLabel
      };
    }

    return { label: valueLabel, value: valueLabel };
  });
};

export const getStartAndEndDates = (
  dateStr = "",
  numOfMonths = 1,
  endDateStr
) => {
  const today = DateTime.fromISO(endDateStr);
  const startDate = DateTime.fromFormat(dateStr, "MMM yyyy").startOf("month");

  let endDate = startDate
    .plus({ months: numOfMonths - 1 })
    .endOf("month")
    .plus({ days: 1 });

  if (numOfMonths > 1) {
    endDate = today;
  } else if (endDate > today) {
    endDate = today;
  }

  return {
    startDate: startDate.toFormat("yyyy-MM-dd"),
    endDate: endDate.toFormat("yyyy-MM-dd")
  };
};

export const createDateRangeOptions = (startDate, endDate) => {
  let options = getMonthsArray(startDate, endDate);
  const customOptions = [];
  options = getDateRangeOptionsArray(options, endDate)?.reverse();
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate);

  const diffInMonths = Math.floor(end.diff(start, "months").months);
  if (diffInMonths >= 3 && options?.[3]?.value) {
    customOptions.push({
      label: "Last 3 months",
      value: options?.[3].value
    });
  }
  if (diffInMonths >= 6 && options?.[6]?.value) {
    customOptions.push({
      label: "Last 6 months",
      value: options?.[6].value
    });
  }
  if (diffInMonths >= 12 && options?.[options?.length - 1]?.value) {
    customOptions.push({
      label: "Last 1 year",
      value: options?.[options?.length - 1].value
    });
  }
  options = [...options, ...customOptions];

  return options;
};

export const findDateRangeValue = (options, startDate, endDate) => {
  const start = DateTime.fromISO(startDate);
  const end = DateTime.fromISO(endDate).minus({ days: 1 });

  const diffInMonths = Math.floor(end.diff(start, "months").months);
  if (diffInMonths >= 3 && diffInMonths < 6) {
    const option = options.find((item) => item.label === "Last 3 months");
    if (option) return option;
  } else if (diffInMonths >= 6 && diffInMonths < 12) {
    const option = options.find((item) => item.label === "Last 6 months");
    if (option) return option;
  } else if (diffInMonths >= 12) {
    const option = options.find((item) => item.label === "Last 1 year");
    if (option) return option;
  } else if (diffInMonths >= 0 && diffInMonths < 3) {
    const monthLabel = end.toFormat("MMM");
    const year = end.year;
    const valueLabel = `${monthLabel} ${year}`;
    const option = options.find((item) => item.value === valueLabel);
    if (option) return option;
  }

  return null;
};
