import React from "react";
import "./treecomponent.scss";
import PropTypes from "prop-types";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";

const TreeComponent = ({ data, tableStyle, tableClass, columnView }) => {
  return (
    <div className="tree__div">
      <TreeTable
        value={data}
        scrollable={tableStyle?.scrollable}
        scrollHeight={tableStyle?.scrollHeight}
        frozenWidth={tableStyle?.frozenWidth}
        className={tableClass ? tableClass : ""}
        resizableColumns={tableStyle?.resizableColumns}
        showGridlines={tableStyle?.showGridlines}
        tableStyle={{
          width: tableStyle?.width
        }}>
        {columnView &&
          columnView?.map((col) => {
            return !col?.hidden ? (
              <Column
                key={col?.field}
                field={col?.field}
                header={col?.name}
                expander={col?.expander}
                frozen={col?.frozen}
                hidden={col?.hidden}
                style={col?.style}
                headerStyle={col?.headerStyle}
                body={col?.body}
              />
            ) : null;
          })}
      </TreeTable>
    </div>
  );
};
TreeComponent.propTypes = {
  data: PropTypes.array,
  columnView: PropTypes?.array,
  tableStyle: PropTypes?.object,
  tableClass: PropTypes?.string
};
export default TreeComponent;
