import { Auth } from "aws-amplify";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./signup.scss";
import ConfirmMail from "../../components/ConfirmMail";
import { validateEmail, validateCompanyEmail } from "../../utils/validateemail";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { getException } from "../../utils/exceptiondictionary";
import SignUpSignInFlow from "../../components/SignUpSignInFlow";
import eyeCon from "../../assets/images/common/eye.svg";
import eyeConSlash from "../../assets/images/common/eye-slash.svg";
import { validatePassword } from "../../utils/common-helper";
//import sanitizeHtml from "sanitize-html";
import warning from "../../assets/images/common/warning.svg";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../utils/sign-helper";
import SignInHeader from "../../components/SignInHeader";
import { isBusinessEmail } from "../../utils/business-email-validator";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { validateRecaptcha } from "../../utils/user-helper";
import { env } from "../../env";
import AppTitle from "../../components/customElements/AppTitle";

const SignUp = () => {
  const toast = useToast();
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const location = useLocation();
  const navigate = useNavigate();

  const locationQueryObjEmail =
    location &&
    location.search &&
    new URLSearchParams(location.search)?.get("email");

  const [email, setEmail] = useState(
    locationQueryObjEmail
      ? locationQueryObjEmail
      : location && location.state && location.state.email
      ? location.state.email
      : ""
  );
  const [infoError, setInfoError] = useState({
    email: "",
    pword: "",
    org: ""
  });
  const [pword, setPword] = useState(
    location && location.state && location.state.pword
      ? location.state.pword
      : ""
  );
  const [org, setOrg] = useState("");
  const [eyeSlash, setEyeSlash] = useState(false);
  const [pwdType, setPwdType] = useState("password");
  const [isSignUp, setIsSignUp] = useState(
    location && location.state && location.state.signUpState === "confirmmail"
      ? false
      : true
  );
  const [isLoading, setIsLoading] = useState(false);
  const reRef = useRef(null);

  const changeIsLoading = (chnge) => {
    setIsLoading(chnge);
  };
  const changeSignUp = (sign) => {
    setIsSignUp(sign);
  };
  const createAccAPI = async () => {
    let valid = {};
    try {
      if (reRef && reRef?.current) {
        const token = await reRef?.current?.executeAsync();
        valid = await validateRecaptcha(token);
      }
      if (valid) {
        await Auth.signUp({
          username: email.trim()?.toLowerCase(),
          password: pword.trim(),
          attributes: {
            email: email.trim()?.toLowerCase(),
            given_name: " ",
            family_name: " ",
            // other custom attributes
            "custom:organization": org.trim()
          },
          autoSignIn: {
            // optional - enables auto sign in after user is confirmed
            enabled: false
          }
        });
        changeSignUp(false);
        changeIsLoading(false);
      } else {
        customToast({ type: "error", ...getException("CAPTCHA_INVALID") });
        changeIsLoading(false);
      }
    } catch (error) {
      customToast({ type: "error", ...getException(error.name) });
      changeIsLoading(false);
    }
  };
  const handleCreateAcc = (e) => {
    e.preventDefault();
    let errObj = { ...infoError };
    if (email.trim() === "") {
      errObj["email"] = "Please add email";
    }
    if (email.trim() !== "" && !validateEmail(email.trim())) {
      errObj["email"] = "Enter Valid Email";
    }
    if (email.trim() !== "" && validateCompanyEmail(email.trim())) {
      errObj["email"] = "Please provide company email id";
    }
    if (!isBusinessEmail(email?.trim())) {
      errObj["email"] = "Please provide company email id";
    }
    if (pword.trim() === "") {
      errObj["pword"] = "Please enter password";
    }
    if (!validatePassword(pword.trim())) {
      errObj["pword"] =
        "Password should be 6 - 50 characters, one digit, one uppercase and one special character";
    }
    if (org.trim() === "") {
      errObj["org"] = "Please enter organization information";
    }
    setInfoError(errObj);
    if (
      errObj["email"] === "" &&
      errObj["pword"] === "" &&
      errObj["org"] === ""
    ) {
      changeIsLoading(true);
      createAccAPI();
    }
  };

  const toggleSlash = () => {
    setEyeSlash(!eyeSlash);
    if (pwdType && pwdType === "password") {
      setPwdType("text");
    } else if (pwdType && pwdType === "text") {
      setPwdType("password");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AppTitle
        title={
          "Create an Account - Chaos Genius | Databricks & Snowflake Observability"
        }
      />
      <div className="parent-container">
        <SignUpSignInFlow />
        <div className="sign-up-container">
          <SignInHeader />
          {isSignUp && (
            <div className="block">
              <div className="signup-parent">
                <div className="heading">
                  <span>Create a new account</span>
                </div>
                <form className="signup-list" onSubmit={handleCreateAcc}>
                  <div className="sign-up-textfields">
                    <TextField
                      id="outlined-basic"
                      label="Work Email"
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        className: "email-error",
                        sx: {
                          mt: infoError.email !== "" ? "6px" : "0px",
                          ml: 0
                        }
                      }}
                      name="email"
                      type="email"
                      autoComplete="section-signup email username"
                      value={email}
                      onChange={(e) => {
                        if (infoError["email"] !== "") {
                          setInfoError({ ...infoError, email: "" });
                        }
                        setEmail(e.target.value);
                      }}
                      error={infoError.email !== ""}
                      helperText={
                        <>
                          {infoError.email !== "" && (
                            <img src={warning} alt="warning" />
                          )}
                          <span>{infoError.email}</span>
                        </>
                      }
                    />
                    <TextField
                      label="Organization Name"
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        className: "email-error",
                        sx: {
                          mt: infoError.email !== "" ? "6px" : "0px",
                          ml: 0
                        }
                      }}
                      name="organization"
                      autoComplete="section-signup organization"
                      value={org}
                      onChange={(e) => {
                        if (infoError["org"] !== "") {
                          setInfoError({ ...infoError, org: "" });
                        }
                        setOrg(e.target.value);
                      }}
                      error={infoError.org !== ""}
                      helperText={
                        <>
                          {infoError.org !== "" && <img src={warning} />}
                          <span>{infoError.org}</span>
                        </>
                      }
                    />
                    <TextField
                      label="Password"
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        className: "email-error pword-error",
                        sx: {
                          mt: infoError.email !== "" ? "6px" : "0px",
                          ml: 0
                        }
                      }}
                      name="password"
                      autoComplete="section-signup new-password"
                      value={pword}
                      onChange={(e) => {
                        if (infoError["pword"] !== "") {
                          setInfoError({ ...infoError, pword: "" });
                        }
                        setPword(e.target.value);
                      }}
                      error={infoError.pword !== ""}
                      helperText={
                        infoError.pword !== "" ? (
                          <>
                            <img src={warning} alt="warning" />
                            <span>{infoError.pword}</span>
                          </>
                        ) : (
                          <span className="signup-password-req">
                            *Password should be 6 - 50 characters, one digit,
                            one uppercase and one special character
                          </span>
                        )
                      }
                      type={pwdType}
                      InputProps={{
                        className: "signup-password-input",
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              className="cg-eye-btn"
                              aria-label="toggle password visibility"
                              onClick={() => {
                                toggleSlash();
                              }}
                              onMouseDown={(e) => {
                                e.preventDefault();
                              }}
                              edge="end">
                              <img
                                className="cg-eye"
                                src={eyeSlash ? eyeCon : eyeConSlash}
                                aria-hidden="true"
                              />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                  <div className="submit-div">
                    <input
                      type="submit"
                      className="btn btn-green-variant__login w-100 login-btn"
                      value="Create Account"
                      disabled={!org || !email || !pword}
                    />
                    <div className="signin-link-container">
                      <span>
                        Already have an account?{" "}
                        <span
                          className="signin-link"
                          onClick={() => {
                            navigate("/signin");
                          }}>
                          Login
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="form-check-group">
                    <p className="form-check-label">
                      By clicking &ldquo;Create account&rdquo;, you agree to the
                      Chaos Genius{" "}
                      <a
                        href="https://www.chaosgenius.io/terms.html"
                        target="_blank"
                        rel="noreferrer">
                        User Agreement
                      </a>
                      ,{" "}
                      <a
                        href="https://www.chaosgenius.io/privacy.html"
                        target="_blank"
                        rel="noreferrer">
                        Privacy Policy
                      </a>{" "}
                      &{" "}
                      <a
                        href="https://www.chaosgenius.io/cookies.html"
                        target="_blank"
                        rel="noreferrer">
                        Cookie Policy
                      </a>
                    </p>
                  </div>
                  {env?.REACT_APP_SITEKEY ? (
                    <ReCAPTCHA
                      className="g__recaptcha"
                      sitekey={env?.REACT_APP_SITEKEY} //take from env
                      size="invisible"
                      ref={reRef}
                      //onChange={onChange}
                    />
                  ) : null}
                </form>
              </div>
            </div>
          )}
          {!isSignUp && (
            <div className="block">
              <ConfirmMail
                username={email}
                pword={pword}
                org={org}
                changeHidden={changeSignUp}
                changeIsLoading={changeIsLoading}
              />
            </div>
          )}
        </div>
        {isLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};
export default SignUp;
