import { CustomTooltip } from "../../utils/tooltip-helper";
import { PrettyFormat, roundToSigDigits } from "../../utils/common-helper";
const customProps = {
  costexplorer: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    type: { id: "type", title: "Usage Type" }
  },
  compute: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    credits_compute: {
      id: "credits_compute",
      title: "Credits",
      isNumber: true
    },
    service_type: { id: "service_type", title: "Service Type" },
    resource_name: { id: "resource_name", title: "Resource Name" }
  },
  warehouse: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    warehouse_name: { id: "warehouse_name", title: "Warehouse Name" },
    sum_creds: { id: "sum_creds", title: "Credits Used", isNumber: true },
    count_queries: {
      id: "count_queries",
      title: "No. of Queries",
      isNumber: true
    },
    warehouse_size: { id: "warehouse_size", title: "Warehouse Size" }
  },
  data_transfer: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    transfer_type: {
      id: "transfer_type",
      title: "Transfer Type"
    },
    source_cloud: {
      id: "source_cloud",
      title: "Source Cloud"
    },
    target_cloud: {
      id: "target_cloud",
      title: "Target Cloud"
    }
  },
  storage: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    bytes_total: {
      id: "bytes_total",
      title: "Avg Bytes",
      prettyPrint: true,
      isAvg: true,
      isNumber: true
    }
  },
  search_optimization: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    credits_used: { id: "credits_used", title: "Credits", isNumber: true },
    database_name: { title: "Database Name", id: "database_name" },
    table_name: { title: "Table Name", id: "table_name" }
  },
  cloud_services: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    warehouse_name: { id: "warehouse_name", title: "Warehouse Name" },
    warehouse_size: { id: "warehouse_size", title: "Warehouse Size" }
  },
  materialized_view: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    credits: { id: "credits", title: "Credits", isNumber: true },
    table_name: { title: "Table Name", id: "table_name" },
    database_name: { title: "Database Name", id: "database_name" },
    schema_name: { title: "Schema Name", id: "schema_name" }
  },
  ai_services: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    credits: { id: "credits", title: "Credits", isNumber: true },
    warehouse_name: { id: "warehouse_name", title: "Warehouse Name" },
    model_name: { id: "model_name", title: "Model Name" },
    function: { id: "function", title: "Function" }
  },
  snowpark: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    compute_pool_name: { id: "compute_pool_name", title: "Compute Pool Name" },
    application_name: { id: "application_name", title: "Application Name" }
  },
  query_acceleration: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    credits: { id: "credits", title: "Credits", isNumber: true },
    warehouse_name: { id: "warehouse_name", title: "Warehouse Name" }
  },
  serverless_task: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    credits: { id: "credits", title: "Credits", isNumber: true },
    task_name: { id: "task_name", title: "Task Name" }
  },
  autocluster: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    credits_used: { id: "credits_used", title: "Credits", isNumber: true },
    database_name: { title: "Database Name", id: "database_name" },
    table_name: { title: "Table Name", id: "table_name" }
  },
  pipe: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    credits_used: { id: "credits_used", title: "Credits", isNumber: true },
    pipe_name: { id: "pipe_name", title: "Name" },
    pipe_catalog: { id: "pipe_catalog", title: "Database" },
    pipe_owner: { id: "pipe_owner", title: "Owner" }
  },
  replication: {
    cost: {
      id: "cost",
      title: "Accrued Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    current_cost: {
      id: "current_cost",
      title: "Current Cost($)",
      hasSuffix: "$",
      isNumber: true
    },
    date: { id: "date", title: "Date" },
    credits: { id: "credits", title: "Credits Used", isNumber: true },
    transfer_cost: {
      id: "transfer_cost",
      title: "Accrued Transfer Cost($)",
      overrideWidth: 230,
      hasSuffix: "$",
      isNumber: true
    },
    database_name: { title: "Database Name", id: "database_name" },
    source_account: { title: "Source Account", id: "source_account" },
    compute_cost: {
      title: "Accrued Compute Cost($)",
      overrideWidth: 230,
      id: "compute_cost",
      hasSuffix: "$",
      isNumber: true
    }
  }
};
export const getCostExpProps = () => {
  return customProps;
};

export const getQGProps = () => {
  const customQGProps = {
    index: {
      type: "rightAligned",
      width: 75
    },
    regex_id: {
      hide: true
    },
    "Query Group": {
      width: 350,
      filter: true,
      cellRenderer: (params) => {
        return CustomTooltip(PrettyFormat(params.value), params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    Warehouses: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    Users: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    Count: {
      width: 150,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false, false);
      }
    },
    "Cloud Service Cost ($)": {
      width: 200,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false, false);
      }
    },
    "Compute Cost ($)": {
      width: 160,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false, false);
      }
    },
    "Total Cost ($)": {
      width: 160,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false, false);
      }
    },
    "Compute Credits": {
      width: 160,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false, false);
      }
    },
    "Cloud Services Credits": {
      width: 198,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false, false);
      }
    }
  };

  return customQGProps;
};
